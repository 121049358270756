@import "elements/inter.css";
@import "elements/inter.css";
@import "~jquery-modal/jquery.modal.css";
@import "~css-toggle-switch/dist/toggle-switch.css";
@import "~nouislider/distribute/nouislider.css";
@import "~react-datepicker/dist/react-datepicker.css";
.group::after {
  content: "";
  display: table;
  clear: both;
}
/* stylelint-disable at-rule-no-unknown */
/* stylelint-enable at-rule-no-unknown */
.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fullscreen.fixed {
  position: fixed;
}
.center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.horizontal-middle-absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.vertical-middle-absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.center-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.center-content.fixed {
  position: fixed;
}
@media (max-width: 768px) {
  .center-content {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: none;
  }
}
.text-nowrap {
  white-space: nowrap;
}
.text-ellipsis {
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis-block {
  display: block;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
.display-flex {
  display: flex;
}
.align-center {
  display: flex;
  align-items: center;
}
.display-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-column {
  display: flex;
  flex-direction: column;
}
.clickable {
  cursor: pointer;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.noselect {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* Non-prefixed version, currently
                          supported by Chrome and Opera */
}
.ws-sort-field {
  cursor: pointer;
}
.ws-sort-field--asc::after {
  content: " \2193";
}
.ws-sort-field--desc::after {
  content: " \2191";
}
.w-100 {
  width: 100%;
}
.mw-100 {
  max-width: 100%;
}
.h-100 {
  height: 100%;
}
.border-radius-xs {
  border-radius: 4px;
}
.border-radius-sm {
  border-radius: 8px;
}
.card-with-help__card .card-with-help__help {
  cursor: pointer;
  visibility: hidden;
}
.card-with-help__card:hover .card-with-help__help {
  visibility: visible;
}
.sans-serif {
  font-family: "Inter", sans-serif;
}
html {
  font-family: "Inter", sans-serif;
}
body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
.text-link {
  text-decoration: none;
}
.text-link:hover {
  text-decoration: underline;
}
.text-link:hover.no-underline {
  text-decoration: none;
}
a {
  text-decoration: none;
  color: #00cba5;
}
a:hover {
  text-decoration: underline;
}
a:hover.no-underline {
  text-decoration: none;
}
a:hover {
  color: #009976;
}
.indigo-text-link {
  text-decoration: none;
  color: #3d5afe;
}
.indigo-text-link:hover {
  text-decoration: underline;
}
.indigo-text-link:hover.no-underline {
  text-decoration: none;
}
.indigo-text-link:hover {
  color: #3d5afe;
}
.indigo-light-text-link {
  text-decoration: none;
  color: #8187ff;
}
.indigo-light-text-link:hover {
  text-decoration: underline;
}
.indigo-light-text-link:hover.no-underline {
  text-decoration: none;
}
.indigo-light-text-link:hover {
  color: #8187ff;
}
.secondary-text-link {
  color: #9b9b9b;
  text-decoration: none;
}
.secondary-text-link:hover {
  color: #4a4a4a;
}
h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin: 10px 0;
  color: #162d54;
}
h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #162d54;
}
h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #162d54;
}
h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #162d54;
}
h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin: 10px 0;
}
p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}
p.small {
  font-size: 14px;
  line-height: 18px;
}
.page-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin: 10px 0;
  color: #162d54;
}
.small-page-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 20px 0;
  color: #162d54;
}
.heading-2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #162d54;
}
.heading-3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #162d54;
}
.section-title {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #162d54;
  text-transform: uppercase;
  margin: 48px 0 24px;
}
.section-subtitle {
  display: block;
  color: #9b9b9b;
  margin: -14px 0 18px;
}
.heading-4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #162d54;
}
.heading-5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin: 10px 0;
}
.item-title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}
.item-title.large {
  font-size: 24px;
  line-height: 30px;
}
.item-title.small {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.quote {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  font-weight: 300;
  color: #162d54;
}
@media (max-width: 768px) {
  .quote {
    font-size: 24px;
    line-height: 30px;
  }
}
.caption-text {
  color: #9caac0;
}
.red-text {
  color: #e86232;
}
/***************************************************/
/* Run `npm run build-colors` if you change these! */
/***************************************************/
/** button hovers only **/
/************************/
.color--enabled {
  color: #018576;
}
.color--disabled {
  color: #ce2e3a;
}
.color-navy-black {
  color: #051a3c;
}
.color-navy-high {
  color: #162d54;
}
.color-navy-medium {
  color: #5e6785;
}
.color-navy-low {
  color: #9caac0;
}
.color-navy-grey {
  color: #c2cad7;
}
.color-navy-light-grey {
  color: #d8dde6;
}
.color-navy-lighter-grey {
  color: #eff1f6;
}
.color-navy-lightest-grey {
  color: #f4f5f9;
}
.color-white {
  color: #fff;
}
.color-white-32 {
  color: rgba(255, 255, 255, 0.32);
}
.color-white-24 {
  color: rgba(255, 255, 255, 0.24);
}
.color-white-12 {
  color: rgba(255, 255, 255, 0.12);
}
.color-white-8 {
  color: rgba(255, 255, 255, 0.08);
}
.color-indigo-dark {
  color: #0031ca;
}
.color-indigo {
  color: #3d5afe;
}
.color-indigo-light {
  color: #8187ff;
}
.color-indigo-92 {
  color: #4d67fe;
}
.color-indigo-76 {
  color: #6c82fe;
}
.color-indigo-68 {
  color: #7b8ffe;
}
.color-indigo-32 {
  color: #c1caff;
}
.color-indigo-24 {
  color: #d0d7ff;
}
.color-indigo-16 {
  color: #e0e5ff;
}
.color-indigo-8 {
  color: #eff2ff;
}
.color-indigo-4 {
  color: #f7f8ff;
}
.color-teal-dark {
  color: #009976;
}
.color-teal {
  color: #00cba5;
}
.color-teal-light {
  color: #60ffd6;
}
.color-teal-12 {
  color: #e0f9f4;
}
.color-light-teal {
  color: #f4fcfa;
}
.color-red {
  color: #ce2e3a;
}
.color-light-red {
  color: #fbeeef;
}
.color-red-orange {
  color: #e86232;
}
.color-yellow {
  color: #edaf1f;
}
.color-light-yellow {
  color: #fef9ed;
}
.color-medium-green {
  color: #13aa88;
}
.color-green {
  color: #018576;
}
.color-light-green {
  color: #ebf5f4;
}
.color-space-grey {
  color: #4a4a4a;
}
.color-demon-grey {
  color: #666;
}
.color-slate-grey {
  color: #9b9b9b;
}
.color-mid-grey {
  color: #bbb;
}
.color-light-grey {
  color: #ddd;
}
.color-lightest-grey {
  color: #f5f5f5;
}
.sans-serif {
  font-family: "Inter", sans-serif;
}
.fw-extralight {
  font-weight: 200;
}
.fw-light {
  font-weight: 300;
}
.fw-regular {
  font-weight: 400;
}
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.fw-extrabold {
  font-weight: 800;
}
.text-h3 {
  font-size: 48px;
  line-height: 54px;
  font-weight: 400;
}
.text-h4 {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 0.25px;
}
.text-h5 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}
.text-h6 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.15px;
}
h3.h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 48px;
  line-height: 54px;
  font-weight: 400;
}
h4.h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 0.25px;
}
h5.h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}
h6.h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.text-subtitle1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.text-subtitle2 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.text-body1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.text-body2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
}
.text-btnlower {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: none;
}
.text-btnupper {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.text-caption {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.text-overline {
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.text-extralight {
  font-weight: 200;
}
.text-light {
  font-weight: 300;
}
.text-regular {
  font-weight: 400;
}
.text-medium {
  font-weight: 500;
}
.text-semibold {
  font-weight: 600;
}
.text-bold {
  font-weight: 700;
}
.text-extrabold {
  font-weight: 800;
}
.text-nowrap {
  text-wrap: nowrap;
}
.flex {
  display: flex;
}
.flex-inline {
  display: inline-flex;
}
.flex-ac {
  align-items: center;
}
.flex-afs {
  align-items: flex-start;
}
.flex-ab {
  align-items: baseline;
}
.flex-afe {
  align-items: flex-end;
}
.flex-asfe {
  align-self: flex-end;
}
.flex-jsa {
  justify-content: space-around;
}
.flex-jsb {
  justify-content: space-between;
}
.flex-jc {
  justify-content: center;
}
.flex-jfs {
  justify-content: flex-start;
}
.flex-jfe {
  justify-content: flex-end;
}
.flex-js {
  justify-content: stretch;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow {
  flex-grow: 1;
}
.flex-half {
  flex: 1 1 50%;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-rowrev {
  flex-direction: row-reverse;
}
.flex-colrev {
  flex-direction: column-reverse;
}
.gap-xxl {
  gap: 64px;
}
.gap-xlx {
  gap: 48px;
}
.gap-xl {
  gap: 32px;
}
.gap-lg {
  gap: 24px;
}
.gap-md {
  gap: 16px;
}
.gap-sm {
  gap: 8px;
}
.gap-xs {
  gap: 4px;
}
.pabs {
  position: absolute;
}
.pabs--right {
  right: 0;
}
.on-hover .on-hover--visible {
  display: none;
}
.on-hover:hover .on-hover--visible {
  display: initial;
}
.prel {
  position: relative;
}
.dib {
  display: inline-block;
}
.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}
.MuiDivider-root {
  margin: 8px 0;
}
/*
    Spacing utility classes - generated from the @spacing map

    The following block is for the purpose of searching the codebase for the generated classes
        m-xs m-sm m-md m-lg m-xl m-xxl p-xs p-sm p-md p-lg p-xl p-xxl
        mt-xs mt-sm mt-md mt-lg mt-xl mt-xxl pt-xs pt-sm pt-md pt-lg pt-xl pt-xxl
        mb-xs mb-sm mb-md mb-lg mb-xl mb-xxl pb-xs pb-sm pb-md pb-lg pb-xl pb-xxl
        ml-xs ml-sm ml-md ml-lg ml-xl ml-xxl pl-xs pl-sm pl-md pl-lg pl-xl pl-xxl
        mr-xs mr-sm mr-md mr-lg mr-xl mr-xxl pr-xs pr-sm pr-md pr-lg pr-xl pr-xxl
        my-xs my-sm my-md my-lg my-xl my-xxl py-xs py-sm py-md py-lg py-xl py-xxl
        mx-xs mx-sm mx-md mx-lg mx-xl mx-xxl px-xs px-sm px-md px-lg px-xl px-xxl
*/
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.mr-0 {
  margin-right: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.m-xs {
  margin: 4px;
}
.mt-xs {
  margin-top: 4px;
}
.mb-xs {
  margin-bottom: 4px;
}
.my-xs {
  margin-top: 4px;
  margin-bottom: 4px;
}
.ml-xs {
  margin-left: 4px;
}
.mr-xs {
  margin-right: 4px;
}
.mx-xs {
  margin-left: 4px;
  margin-right: 4px;
}
.p-xs {
  padding: 4px !important;
}
.pt-xs {
  padding-top: 4px !important;
}
.pb-xs {
  padding-bottom: 4px !important;
}
.py-xs {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.pl-xs {
  padding-left: 4px !important;
}
.pr-xs {
  padding-right: 4px !important;
}
.px-xs {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.m-sm {
  margin: 8px;
}
.mt-sm {
  margin-top: 8px;
}
.mb-sm {
  margin-bottom: 8px;
}
.my-sm {
  margin-top: 8px;
  margin-bottom: 8px;
}
.ml-sm {
  margin-left: 8px;
}
.mr-sm {
  margin-right: 8px;
}
.mx-sm {
  margin-left: 8px;
  margin-right: 8px;
}
.p-sm {
  padding: 8px !important;
}
.pt-sm {
  padding-top: 8px !important;
}
.pb-sm {
  padding-bottom: 8px !important;
}
.py-sm {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.pl-sm {
  padding-left: 8px !important;
}
.pr-sm {
  padding-right: 8px !important;
}
.px-sm {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.m-md {
  margin: 16px;
}
.mt-md {
  margin-top: 16px;
}
.mb-md {
  margin-bottom: 16px;
}
.my-md {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ml-md {
  margin-left: 16px;
}
.mr-md {
  margin-right: 16px;
}
.mx-md {
  margin-left: 16px;
  margin-right: 16px;
}
.p-md {
  padding: 16px !important;
}
.pt-md {
  padding-top: 16px !important;
}
.pb-md {
  padding-bottom: 16px !important;
}
.py-md {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.pl-md {
  padding-left: 16px !important;
}
.pr-md {
  padding-right: 16px !important;
}
.px-md {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.m-lg {
  margin: 24px;
}
.mt-lg {
  margin-top: 24px;
}
.mb-lg {
  margin-bottom: 24px;
}
.my-lg {
  margin-top: 24px;
  margin-bottom: 24px;
}
.ml-lg {
  margin-left: 24px;
}
.mr-lg {
  margin-right: 24px;
}
.mx-lg {
  margin-left: 24px;
  margin-right: 24px;
}
.p-lg {
  padding: 24px !important;
}
.pt-lg {
  padding-top: 24px !important;
}
.pb-lg {
  padding-bottom: 24px !important;
}
.py-lg {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.pl-lg {
  padding-left: 24px !important;
}
.pr-lg {
  padding-right: 24px !important;
}
.px-lg {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.m-xl {
  margin: 32px;
}
.mt-xl {
  margin-top: 32px;
}
.mb-xl {
  margin-bottom: 32px;
}
.my-xl {
  margin-top: 32px;
  margin-bottom: 32px;
}
.ml-xl {
  margin-left: 32px;
}
.mr-xl {
  margin-right: 32px;
}
.mx-xl {
  margin-left: 32px;
  margin-right: 32px;
}
.p-xl {
  padding: 32px !important;
}
.pt-xl {
  padding-top: 32px !important;
}
.pb-xl {
  padding-bottom: 32px !important;
}
.py-xl {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.pl-xl {
  padding-left: 32px !important;
}
.pr-xl {
  padding-right: 32px !important;
}
.px-xl {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.m-xlx {
  margin: 48px;
}
.mt-xlx {
  margin-top: 48px;
}
.mb-xlx {
  margin-bottom: 48px;
}
.my-xlx {
  margin-top: 48px;
  margin-bottom: 48px;
}
.ml-xlx {
  margin-left: 48px;
}
.mr-xlx {
  margin-right: 48px;
}
.mx-xlx {
  margin-left: 48px;
  margin-right: 48px;
}
.p-xlx {
  padding: 48px !important;
}
.pt-xlx {
  padding-top: 48px !important;
}
.pb-xlx {
  padding-bottom: 48px !important;
}
.py-xlx {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.pl-xlx {
  padding-left: 48px !important;
}
.pr-xlx {
  padding-right: 48px !important;
}
.px-xlx {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.m-xxl {
  margin: 64px;
}
.mt-xxl {
  margin-top: 64px;
}
.mb-xxl {
  margin-bottom: 64px;
}
.my-xxl {
  margin-top: 64px;
  margin-bottom: 64px;
}
.ml-xxl {
  margin-left: 64px;
}
.mr-xxl {
  margin-right: 64px;
}
.mx-xxl {
  margin-left: 64px;
  margin-right: 64px;
}
.p-xxl {
  padding: 64px !important;
}
.pt-xxl {
  padding-top: 64px !important;
}
.pb-xxl {
  padding-bottom: 64px !important;
}
.py-xxl {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}
.pl-xxl {
  padding-left: 64px !important;
}
.pr-xxl {
  padding-right: 64px !important;
}
.px-xxl {
  padding-left: 64px !important;
  padding-right: 64px !important;
}
.m-auto {
  margin: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.p-auto {
  padding: auto !important;
}
.pt-auto {
  padding-top: auto !important;
}
.pb-auto {
  padding-bottom: auto !important;
}
.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}
.pl-auto {
  padding-left: auto !important;
}
.pr-auto {
  padding-right: auto !important;
}
.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}
.no-boxshadow {
  box-shadow: none !important;
}
.no-border {
  border: none;
}
.overflow-a {
  overflow: auto;
}
.list-disc {
  list-style-type: disc;
}
.ws-analytics-card {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 8px 16px -4px rgba(22, 45, 84, 0.08);
  display: flex;
  flex-direction: column;
}
.ws-analytics-card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  height: 48px;
}
.ws-analytics-card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
}
.attribute-filter .MuiInputBase-root {
  height: 100%;
}
.attribute-filter--tag {
  height: auto;
}
.attribute-filter__start-adornment {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  max-height: unset;
  padding-top: 4px;
  padding-bottom: 4px;
}
.attribute-filter__input--collapsed {
  padding-left: 0px;
  padding-right: 0px;
  width: 1px !important;
}
.attribute-filter__helper-text {
  display: flex;
  justify-content: space-between;
}
.ws-banner-container {
  position: fixed;
  top: 54px;
  right: 0;
  left: 0;
  z-index: 1002;
}
.portal-page .ws-banner-container {
  top: 64px;
  left: 250px;
  transition: left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
@media (max-width: 1200px) {
  .portal-page .ws-banner-container {
    top: 56px;
    left: 0;
  }
}
.portal-page--minimized .ws-banner-container {
  left: 72px;
}
@media (max-width: 1200px) {
  .portal-page--minimized .ws-banner-container {
    left: 0;
  }
}
.ws-banner {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 12px 24px;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.ws-banner__content {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #5e6785;
}
.ws-banner__icon {
  width: 18px;
  height: 18px;
  margin-right: 25px;
  fill: #5e6785;
}
.ws-banner__multiline-content {
  display: flex;
  flex-direction: column;
}
.ws-banner__title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #162d54;
}
.ws-banner__actions {
  display: flex;
  align-items: center;
}
.ws-banner__actions .MuiButton-root {
  margin-left: 12px;
}
.ws-banner__close {
  width: 14px;
  height: 14px;
  margin-left: 12px;
  fill: #5e6785;
  cursor: pointer;
  transition: 200ms fill linear;
}
.ws-banner__close:hover {
  fill: #162d54;
}
.ws-banner--success {
  background-color: #ebf5f4;
}
.ws-banner--success .ws-banner__icon {
  fill: #018576;
}
.ws-banner--error {
  background-color: #fbeeef;
}
.ws-banner--error .ws-banner__icon {
  fill: #ce2e3a;
}
.ws-banner--multiline {
  flex-direction: column;
  align-items: flex-start;
  height: auto;
}
.ws-banner--multiline .ws-banner__content {
  align-items: flex-start;
}
.ws-banner--multiline .ws-banner__icon {
  width: 24px;
  height: 24px;
}
.ws-banner--multiline .ws-banner__actions {
  align-self: flex-end;
}
.MuiToggleButton-root {
  text-transform: none;
  line-height: 16px;
  font-size: 14px;
  letter-spacing: 1.25px;
}
.MuiToggleButton-root .MuiButton-root.MuiToggleButton-sizeMedium,
.MuiToggleButton-root .MuiButton-root.MuiToggleButton-sizeLarge,
.MuiToggleButton-root .MuiButton-root.MuiButton-sizeMedium,
.MuiToggleButton-root .MuiButton-root.MuiButton-sizeLarge {
  padding-top: 8px;
  padding-bottom: 8px;
}
.MuiToggleButton-root .MuiButton-root:disabled {
  color: #9caac0;
}
.MuiToggleButton-root .MuiButton-root:disabled .MuiButton-startIcon svg,
.MuiToggleButton-root .MuiButton-root:disabled .MuiButton-endIcon svg {
  fill: #9caac0;
}
.MuiToggleButton-root .MuiButton-root:disabled:hover {
  pointer-events: none;
}
.MuiButton-text,
.MuiButton-outlined,
.MuiIconButton-root {
  color: #3d5afe;
}
.MuiButton-text .MuiButton-startIcon svg,
.MuiButton-outlined .MuiButton-startIcon svg,
.MuiIconButton-root .MuiButton-startIcon svg,
.MuiButton-text .MuiButton-endIcon svg,
.MuiButton-outlined .MuiButton-endIcon svg,
.MuiIconButton-root .MuiButton-endIcon svg {
  fill: #3d5afe;
}
.MuiButton-text:hover,
.MuiButton-outlined:hover,
.MuiIconButton-root:hover {
  color: #0031ca;
  background-color: #eff2ff;
}
.MuiButton-text:hover .MuiButton-startIcon svg,
.MuiButton-outlined:hover .MuiButton-startIcon svg,
.MuiIconButton-root:hover .MuiButton-startIcon svg,
.MuiButton-text:hover .MuiButton-endIcon svg,
.MuiButton-outlined:hover .MuiButton-endIcon svg,
.MuiIconButton-root:hover .MuiButton-endIcon svg {
  fill: #0031ca;
}
.MuiButton-text.ws-button-selected,
.MuiButton-outlined.ws-button-selected,
.MuiIconButton-root.ws-button-selected {
  color: #0031ca;
  background-color: #d0d7ff;
}
.MuiButton-text.ws-button-selected .MuiButton-startIcon svg,
.MuiButton-outlined.ws-button-selected .MuiButton-startIcon svg,
.MuiIconButton-root.ws-button-selected .MuiButton-startIcon svg,
.MuiButton-text.ws-button-selected .MuiButton-endIcon svg,
.MuiButton-outlined.ws-button-selected .MuiButton-endIcon svg,
.MuiIconButton-root.ws-button-selected .MuiButton-endIcon svg {
  fill: #0031ca;
}
.MuiButton-text.ws-button-disabled,
.MuiButton-outlined.ws-button-disabled,
.MuiIconButton-root.ws-button-disabled {
  color: #9caac0;
  border-color: #eff1f6;
  background-color: #eff1f6;
}
.MuiButton-text {
  /* forces sizing into compliance with other buttons */
  border: 1px solid transparent;
}
.MuiButton-text:hover {
  border-color: #eff2ff;
}
.MuiButton-text.MuiButton-textError {
  color: #ce2e3a;
}
.MuiButton-text.MuiButton-textError:hover,
.MuiButton-text.MuiButton-textError:focus,
.MuiButton-text.MuiButton-textError:active,
.MuiButton-text.MuiButton-textError.active {
  background-color: #fbeeef;
}
.MuiButton-text.MuiButton-textError:disabled {
  color: #9caac0;
}
.MuiButton-outlined.MuiButton-outlinedError {
  border: 1px solid #ce2e3a;
  color: #ce2e3a;
}
.MuiButton-outlined.MuiButton-outlinedError:hover,
.MuiButton-outlined.MuiButton-outlinedError:focus,
.MuiButton-outlined.MuiButton-outlinedError:active,
.MuiButton-outlined.MuiButton-outlinedError.active {
  color: #ce2e3a;
  border-color: #ce2e3a;
  background-color: #fbeeef;
}
.MuiButton-outlined.MuiButton-outlinedError:disabled {
  border-color: #eff1f6;
  color: #9caac0;
}
.MuiButton-contained.MuiButton-containedError {
  border: 1px solid #ce2e3a;
  background-color: #ce2e3a;
}
.MuiButton-contained.MuiButton-containedError:hover,
.MuiButton-contained.MuiButton-containedError:active,
.MuiButton-contained.MuiButton-containedError.active {
  border-color: #a4242e;
  background-color: #a4242e;
}
.MuiButton-contained.MuiButton-containedError:focus {
  border-color: #8f2028;
  background-color: #8f2028;
}
.MuiButton-outlined {
  border: 1px solid #3d5afe;
}
.MuiButton-outlined:hover,
.MuiButton-outlined:focus,
.MuiButton-outlined:active,
.MuiButton-outlined.active,
.MuiButton-outlined.ws-button-selected {
  border-color: #3d5afe;
}
.MuiButton-outlined:disabled {
  border-color: #eff1f6;
}
.MuiButton-contained {
  border: 1px solid #3d5afe;
  color: #fff;
  background-color: #3d5afe;
  box-shadow: none;
}
.MuiButton-contained .MuiButton-startIcon svg,
.MuiButton-contained .MuiButton-endIcon svg {
  fill: #fff;
}
.MuiButton-contained:hover {
  border-color: #4d67fe;
  background-color: #4d67fe;
  box-shadow: none;
  color: #fff;
}
.MuiButton-contained:focus,
.MuiButton-contained.ws-button-selected {
  border-color: #6c82fe;
  background-color: #6c82fe;
}
.MuiButton-contained:active,
.MuiButton-contained.active {
  border-color: #7b8ffe;
  background-color: #7b8ffe;
}
.MuiButton-contained:disabled {
  border-color: #eff1f6;
  background-color: #eff1f6;
}
.MuiButton-contained.ws-button-disabled {
  color: #9caac0;
  border-color: #eff1f6;
  background-color: #eff1f6;
}
.MuiButtonGroup-root .MuiButton-root {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.25px;
  font-weight: 500;
  text-transform: none;
}
.MuiButtonGroup-root .MuiButton-contained.ws-button-disabled {
  border-color: #eff1f6;
}
.MuiButtonGroup-root .MuiButton-contained.ws-button-disabled:not(:last-of-type) {
  border-right: 1px solid #eff1f6;
}
.MuiButtonGroup-root .MuiButton-outlined {
  color: #5e6785;
  border-color: #c2cad7;
}
.MuiButtonGroup-root .MuiButton-outlined svg {
  fill: #5e6785;
  width: 18px;
  height: 18px;
}
.MuiButtonGroup-root .MuiButton-outlined:hover,
.MuiButtonGroup-root .MuiButton-outlined:focus,
.MuiButtonGroup-root .MuiButton-outlined:active,
.MuiButtonGroup-root .MuiButton-outlined.active {
  color: #5e6785;
  border-color: #c2cad7;
}
.MuiButtonGroup-root .MuiButton-outlined:disabled {
  color: #9caac0;
}
.MuiButtonGroup-root .MuiButton-outlined:disabled svg {
  fill: #9caac0;
}
.MuiButtonGroup-root .MuiButton-outlined.active {
  color: #3d5afe;
  background-color: #eff2ff;
}
.MuiButtonGroup-root .MuiButton-outlined.active svg {
  fill: #3d5afe;
}
.wsmui-icon-btn-group .MuiButton-root {
  padding: 8px 10px;
}
.ws-ds-button-container {
  display: block;
}
.ws-ds-button-container .MuiButton-root {
  margin-right: 8px;
}
.ws-ds-button-container .MuiButton-root:last-child {
  margin-right: 0;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button .MuiButton-root {
  margin-right: 0;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button .MuiButton-root:last-child {
  padding-left: 0;
  padding-right: 0;
  min-width: 20px;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButton-root {
  font-weight: inherit;
  text-transform: inherit;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-right: 0;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButton-root:last-child {
  padding-left: 0;
  padding-right: 0;
  min-width: 20px;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type) {
  border: 1px solid #3d5afe;
  color: #fff;
  background-color: #3d5afe;
  box-shadow: none;
  border-right: 1px solid #0031ca;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type) .MuiButton-startIcon svg,
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type) .MuiButton-endIcon svg {
  fill: #fff;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type):hover {
  border-color: #4d67fe;
  background-color: #4d67fe;
  box-shadow: none;
  color: #fff;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type):focus,
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type).ws-button-selected {
  border-color: #6c82fe;
  background-color: #6c82fe;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type):active,
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type).active {
  border-color: #7b8ffe;
  background-color: #7b8ffe;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type):disabled {
  border-color: #eff1f6;
  background-color: #eff1f6;
}
.ws-ds-button-container .MuiButtonGroup-root.split-button-alternate .MuiButtonGroup-grouped:not(:last-of-type).ws-button-disabled {
  color: #9caac0;
  border-color: #eff1f6;
  background-color: #eff1f6;
}
.MuiIconButton-root.MuiIconButton-sizeMedium {
  padding: 6px;
}
.MuiIconButton-root svg {
  fill: #5e6785;
}
.MuiIconButton-root:hover svg {
  fill: #162d54;
}
.MuiIconButton-root:disabled,
.MuiIconButton-root.ws-button-disabled {
  color: #9caac0;
  border-color: unset;
  background-color: unset;
}
.MuiIconButton-root:disabled svg,
.MuiIconButton-root.ws-button-disabled svg {
  fill: #9caac0;
}
.MuiButton-iconSizeSmall svg[class^="wsicon-"],
.MuiIconButton-sizeSmall svg[class^="wsicon-"] {
  width: 20px;
  height: 20px;
}
.MuiButton-iconSizeMedium svg[class^="wsicon-"],
.MuiIconButton-sizeMedium svg[class^="wsicon-"] {
  width: 18px;
  height: 18px;
}
.ws-icon-btn__iconSize24 svg[class^="wsicon-"] {
  width: 24px;
  height: 24px;
}
.MuiButton-iconSizeLarge svg[class^="wsicon-"],
.MuiIconButton-sizeLarge svg[class^="wsicon-"] {
  width: 35px;
  height: 35px;
}
.ws-svg-icon-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 12px;
  font-size: 1.75rem;
}
.ws-chart-legend {
  margin: 0;
  list-style: none;
}
.ws-chart-legend__item {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  height: 32px;
}
.ws-chart-legend__item:not(:first-child) {
  margin-top: 8px;
}
@media (max-width: 600px) {
  .ws-chart-legend__item {
    height: 16px;
  }
}
.ws-chart-legend__icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 8px;
  border-radius: 2px;
  background-color: #3d5afe;
}
.ws-chart-legend__item-count {
  font-weight: 700;
  margin-right: 8px;
}
.ws-chart-legend__item-name {
  color: #5e6785;
}
.MuiTooltip-popper .chart-legend-tooltip {
  color: #000;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
  padding: 0;
  background-color: #fff;
}
.MuiTooltip-popper .chart-legend-tooltip .MuiTooltip-arrow {
  color: #fff;
}
.retain__chart-tooltip-data-row.retain__chart-tooltip-data-description {
  height: auto;
  text-align: justify;
}
.MuiCheckbox-root,
.MuiRadio-root {
  color: #162d54;
}
.MuiCheckbox-root.Mui-disabled,
.MuiRadio-root.Mui-disabled {
  color: #9caac0;
}
.MuiCheckbox-root:hover,
.MuiRadio-root:hover {
  background-color: rgba(61, 90, 254, 0.04);
}
.MuiCheckbox-root:focus-within,
.MuiRadio-root:focus-within,
.MuiCheckbox-root:focus,
.MuiRadio-root:focus {
  background-color: rgba(61, 90, 254, 0.24);
}
.MuiCheckbox-root.Mui-checked,
.MuiRadio-root.Mui-checked,
.MuiCheckbox-root.MuiCheckbox-indeterminate,
.MuiRadio-root.MuiCheckbox-indeterminate {
  color: #3d5afe;
}
.MuiCheckbox-root.Mui-checked.Mui-disabled,
.MuiRadio-root.Mui-checked.Mui-disabled,
.MuiCheckbox-root.MuiCheckbox-indeterminate.Mui-disabled,
.MuiRadio-root.MuiCheckbox-indeterminate.Mui-disabled {
  color: #c1caff;
}
.MuiSwitch-root .MuiSwitch-track {
  background-color: #d8dde6;
  opacity: 1;
}
.MuiSwitch-root .MuiSwitch-switchBase:hover {
  background-color: rgba(61, 90, 254, 0.04);
}
.MuiSwitch-root .MuiSwitch-switchBase:focus-within,
.MuiSwitch-root .MuiSwitch-switchBase:focus {
  background-color: rgba(61, 90, 254, 0.24);
}
.MuiSwitch-root .Mui-checked .MuiSwitch-thumb {
  background-color: #3d5afe;
}
.MuiSwitch-root .Mui-checked + .MuiSwitch-track {
  background-color: #c1caff;
  opacity: 1;
}
.MuiSwitch-root .Mui-disabled .MuiSwitch-thumb {
  background-color: #eff1f6;
}
.MuiSwitch-root .Mui-disabled + .MuiSwitch-track {
  background-color: #f4f5f9;
  opacity: 1;
}
.MuiSwitch-root .Mui-disabled.Mui-checked .MuiSwitch-thumb {
  background-color: #c1caff;
  opacity: 1;
}
.MuiSwitch-root .Mui-disabled.Mui-checked + .MuiSwitch-track {
  background-color: #eff2ff;
  opacity: 1;
}
.ws-chip-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
}
.ws-chip-list .MuiChip-root {
  margin-top: 4px;
}
.ws-chip-list--right {
  justify-content: flex-end;
}
.MuiChip-root:not(.MuiChip-pill) {
  border-radius: 4px;
  background-color: #051a3c14;
}
.MuiChip-root:not(.MuiChip-pill) .MuiChip-label {
  color: #5e6785;
}
.MuiChip-root:not(.MuiChip-pill) .MuiChip-sizeSmall {
  height: 16px;
}
.MuiChip-root:not(.MuiChip-pill) .MuiChip-sizeSmall .MuiChip-avatarSmall {
  height: 14px;
  width: 14px;
}
.MuiChip-root:not(.MuiChip-pill) .MuiChip-pill {
  padding-top: 200px;
  background-color: #d8dde6;
}
.MuiChip-root:not(.MuiChip-pill) .MuiChip-pill .MuiChip-colorPrimary {
  color: #fff;
}
.MuiChip-root:not(.MuiChip-pill) .MuiChip-labelSmall {
  padding: 0 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.chip--red {
  background-color: #fbeeef;
}
.chip--red .MuiChip-label {
  color: #ce2e3a;
}
.chip--green {
  background-color: #ebf5f4;
}
.chip--green .MuiChip-label {
  color: #018576;
}
.MuiDialog-paper {
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 11px 15px 0px #00000033, 0px 9px 46px 0px #0000001f, 0px 24px 38px 0px #00000024;
}
.MuiDialogTitle-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #162d54;
}
.MuiDialogTitle-root .MuiIconButton-root:last-child {
  margin-right: -8px;
}
.MuiDialogActions-root {
  box-sizing: border-box;
  height: 64px;
  padding: 0 16px;
}
.MuiDialog-paper {
  color: #5e6785;
}
.MuiDialog-paper.MuiDialog-paperWidthXs {
  max-width: 280px;
}
.MuiDialog-paper.MuiDialog-paperWidthSm {
  max-width: 420px;
}
.MuiDialog-paper.MuiDialog-paperWidthMd {
  max-width: 560px;
}
.MuiDialog-paper.MuiDialog-paperWidthLg {
  max-width: 768px;
}
.MuiDialog-paper.MuiDialog-paperWidthXl {
  max-width: 1200px;
}
.ws-confirmation-dialog .MuiDialog-container .MuiDialog-paper {
  overflow: hidden;
}
.ws-dialog-popover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 16px;
  padding-bottom: 0px;
  border-top-style: solid;
  border-top-color: #051a3c14;
}
.ws-dialog-popover .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  gap: 8px;
}
.ws-dialog--fullWidth .MuiDialogContent-root {
  padding-left: 0;
  padding-right: 0;
}
.ws-filter-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  min-height: 48px;
}
.ws-filter-toolbar--dialog {
  height: auto;
}
.ws-filter-toolbar__filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.ws-filter-toolbar__buttons {
  display: flex;
  flex: 0 0 auto;
}
.ws-filter-toolbar__buttons .MuiButton-root {
  margin-left: 8px;
}
.ws-filter-button:hover:enabled .ws-filter-button__clear-icon {
  background-color: #e0e5ff;
}
.ws-filter-button:hover:enabled .ws-filter-button__clear-icon .MuiSvgIcon-root {
  color: #0031ca;
}
.ws-filter-button__button {
  padding: 6px 16px;
  border: 1px solid #c2cad7;
  border-radius: 20px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: none;
  color: #5e6785;
  background-color: #fff;
}
.ws-filter-button__button .MuiButton-startIcon svg,
.ws-filter-button__button .MuiButton-endIcon svg {
  fill: #5e6785;
}
.ws-filter-button__button:hover,
.ws-filter-button__button:active,
.ws-filter-button__button:focus {
  border-color: #162d54;
  color: #162d54;
}
.ws-filter-button__button:hover {
  background-color: #f7f8ff;
}
.ws-filter-button__button:focus {
  background-color: #d0d7ff;
}
.ws-filter-button__button:active {
  background-color: #c1caff;
}
.ws-filter-button__button:disabled {
  border-color: #eff1f6;
  color: #9caac0;
}
.ws-filter-button__button:disabled .MuiButton-startIcon svg,
.ws-filter-button__button:disabled .MuiButton-endIcon svg {
  fill: #9caac0;
}
.ws-filter-button__button--start-icon {
  padding-left: 9px;
}
.ws-filter-button__button--end-icon {
  padding-right: 9px;
}
.ws-filter-button__button--clear-icon {
  padding-right: 40px;
}
.ws-filter-button__button--label-icon .MuiButton-startIcon {
  margin-right: 0;
  margin-left: 0;
}
.ws-filter-button__button--icon-only {
  padding: 6px;
  min-width: 0;
}
.ws-filter-button__button--active {
  background-color: #eff2ff;
  color: #3d5afe;
}
.ws-filter-button__button--active .MuiButton-startIcon svg,
.ws-filter-button__button--active .MuiButton-endIcon svg {
  fill: #3d5afe;
}
.ws-filter-button__button--active:hover,
.ws-filter-button__button--active:focus,
.ws-filter-button__button--active:active {
  color: #0031ca;
}
.ws-filter-button__button--active:hover .MuiButton-startIcon svg,
.ws-filter-button__button--active:focus .MuiButton-startIcon svg,
.ws-filter-button__button--active:active .MuiButton-startIcon svg,
.ws-filter-button__button--active:hover .MuiButton-endIcon svg,
.ws-filter-button__button--active:focus .MuiButton-endIcon svg,
.ws-filter-button__button--active:active .MuiButton-endIcon svg {
  fill: #0031ca;
}
.ws-filter-button__button--active:hover {
  background-color: #e0e5ff;
}
.ws-filter-button__label {
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ws-filter-button__count {
  margin-left: 4px;
}
.ws-filter-button__clear-icon {
  position: absolute;
  right: 2px;
  padding: 4px;
  height: 30px;
  margin-top: 1px;
  background-color: #eff2ff;
}
.ws-filter-button__clear-icon .MuiSvgIcon-root {
  fill: #3d5afe;
}
.ws-filter-button__clear-icon:hover {
  background-color: #e0e5ff;
}
.ws-filter-button__clear-icon:focus {
  background-color: #d0d7ff;
}
.ws-filter-button__clear-icon:active {
  background-color: #c1caff;
}
.ws-filter-button__clear-icon:hover,
.ws-filter-button__clear-icon:focus,
.ws-filter-button__clear-icon:active {
  color: #0031ca;
}
.ws-filter-button__clear-icon:hover .MuiSvgIcon-root,
.ws-filter-button__clear-icon:focus .MuiSvgIcon-root,
.ws-filter-button__clear-icon:active .MuiSvgIcon-root {
  fill: #0031ca;
}
.ws-filter-menu__container {
  overflow-y: hidden;
}
.ws-filter-menu__container:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d8dde6;
}
.ws-filter-menu__container .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-bottom: 1px solid #d8dde6;
}
.ws-filter-menu__container .MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid #d8dde6;
  border-radius: unset;
}
.ws-filter-menu__container ul {
  padding-inline-start: 0;
}
.ws-filter-button__input {
  height: 48px;
  margin-bottom: 16px;
}
.ws-filter-button-autocomplete__only_btn {
  display: none;
}
.ws-filter-button__single_option:hover .ws-filter-button-autocomplete__only_btn {
  display: block;
}
.ws-filter-button__single_option:hover .ws-filter_button__single_option__text--has-only {
  max-width: 410px;
}
.ws-filter-menu {
  padding: 8px 0;
}
.ws-filter-menu__item {
  padding-left: 24px;
  padding-right: 24px;
}
.ws-filter-menu__item .MuiSvgIcon-root[data-testid="CheckBoxOutlineBlankIcon"] {
  fill: #5e6785;
}
.ws-filter-menu__item .MuiSvgIcon-root[data-testid="CheckBoxIcon"] {
  fill: #3d5afe;
}
.MuiMenuItem-root.ws-filter-menu__item--custom {
  height: auto;
  flex-direction: column;
  align-items: flex-start;
}
.MuiMenuItem-root.ws-filter-menu__item--custom:hover,
.MuiMenuItem-root.ws-filter-menu__item--custom.Mui-focusVisible {
  background-color: #fff;
  cursor: default;
}
.MuiMenuItem-root.ws-filter-menu__item--custom .MuiInputBase-input {
  margin: 0;
  padding: 4px 0 5px;
  height: auto;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.ws-filter-button-autocomplete__clear {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.1);
  height: 48px;
}
.ws-filter-button__clear {
  margin-right: 24px;
  height: 36px;
}
.ws-filter-menu__item-meta {
  display: inline-flex;
  color: #5e6785;
  margin-left: 24px;
}
#ws-filter-button-autocomplete_input {
  padding-left: 10px;
  border-bottom: none;
}
.ws-filter-button__options {
  min-height: 108px;
  max-height: 260px;
  overflow-y: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
.ws-filter-button-autocomplete__no_results {
  height: 47px;
  display: flex;
  padding: 4px 24px;
  align-items: center;
}
.ws-filter-dialog:not(.MuiDialog-paperFullScreen) {
  width: 420px;
  height: 600px;
}
.ws-filter-dialog .ws-filter-toolbar__filters {
  flex-direction: column;
  width: 100%;
  gap: 0;
}
.ws-filter-dialog .ws-filter-container {
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.ws-filter-dialog .ws-filter-button {
  display: block;
}
.ws-filter-dialog .ws-filter-button__button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  border: 0;
  border-radius: 0;
  background-color: #fff;
}
.ws-filter-dialog .ws-filter-button__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #5e6785;
}
.ws-filter-dialog .ws-filter-button__label,
.ws-filter-dialog .ws-filter-button__count {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #5e6785;
}
.ws-filter-dialog .ws-filter-button__button--active {
  background-color: #fff;
}
.ws-filter-dialog .ws-filter-button__button--active .ws-filter-button__title {
  font-weight: 700;
}
.ws-filter-dialog .ws-filter-container--open {
  background-color: #f4f5f9;
  border-color: #d8dde6;
}
.ws-filter-dialog .ws-filter-container--open .ws-filter-button__button {
  background-color: #f4f5f9 !important;
}
.ws-filter-dialog .ws-filter-menu {
  padding: 0;
}
.ws-filter-dialog .ws-filter-menu .MuiMenuItem-root {
  padding-left: 24px;
  padding-right: 24px;
}
.ws-filter-dialog .MuiDialogContent-root {
  padding-right: 0;
  padding-left: 0;
}
.ws-filter-button__button--open {
  border-top: 1px solid;
  background-color: #fff;
  border-color: #3d5afe;
}
.ws-filter-button__button--open .ws-filter-button__label {
  color: #162d54;
}
.ws-filter-button__button--datepicker .ws-filter-button__label {
  max-width: 150px;
}
.ws-filter-button-daterange__action-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}
.ws-filter-tenure__input {
  height: 48px;
}
.ws-filter-tenure__input .MuiInputBase-input {
  height: 48px;
  padding-left: 8px;
}
.MuiSelect-select {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}
.MuiOutlinedInput-root {
  border-color: #c2cad7;
}
.MuiOutlinedInput-input {
  border-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.MuiOutlinedInput-input:focus,
.MuiOutlinedInput-input:active {
  margin-bottom: 0;
  border-bottom: 0;
}
.mui-input-forms-overrides {
  margin-top: 0;
  margin-bottom: 0;
  color: #162d54;
}
.mui-input-forms-overrides::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.mui-input-forms-overrides:-ms-input-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.mui-input-forms-overrides::-ms-input-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.mui-input-forms-overrides:-moz-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.mui-input-forms-overrides::-moz-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.MuiOutlinedInput-input,
.MuiInput-underline .MuiInput-input {
  margin-top: 0;
  margin-bottom: 0;
  color: #162d54;
}
.MuiOutlinedInput-input::-webkit-input-placeholder,
.MuiInput-underline .MuiInput-input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.MuiOutlinedInput-input:-ms-input-placeholder,
.MuiInput-underline .MuiInput-input:-ms-input-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.MuiOutlinedInput-input::-ms-input-placeholder,
.MuiInput-underline .MuiInput-input::-ms-input-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.MuiOutlinedInput-input:-moz-placeholder,
.MuiInput-underline .MuiInput-input:-moz-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.MuiOutlinedInput-input::-moz-placeholder,
.MuiInput-underline .MuiInput-input::-moz-placeholder {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #9caac0 !important;
}
.MuiInputLabel-root {
  margin-bottom: 0;
}
.MuiInputLabel-root.Mui-focused {
  color: #3d5afe;
}
.fix-inputs input[type="text"],
.fix-inputs input[type="password"],
.fix-inputs input[type="email"],
.fix-inputs input[type="tel"],
.fix-inputs input[type="date"],
.fix-inputs input[type="number"] {
  padding: 16.5px 14px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  display: block;
  min-width: 50px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.fix-inputs input[type="text"]:active,
.fix-inputs input[type="password"]:active,
.fix-inputs input[type="email"]:active,
.fix-inputs input[type="tel"]:active,
.fix-inputs input[type="date"]:active,
.fix-inputs input[type="number"]:active,
.fix-inputs input[type="text"]:focus,
.fix-inputs input[type="password"]:focus,
.fix-inputs input[type="email"]:focus,
.fix-inputs input[type="tel"]:focus,
.fix-inputs input[type="date"]:focus,
.fix-inputs input[type="number"]:focus {
  border-bottom: 0;
  margin-bottom: 0;
}
.MuiListItem-root {
  height: 48px;
  padding: 0 24px;
}
.MuiListItem-root .wsicon {
  width: 24px;
  height: 24px;
  fill: #5e6785;
}
.MuiListItem-root .MuiSvgIcon-root {
  fill: #5e6785;
}
.MuiListItem-root .MuiListItemText-root {
  margin: 0;
}
.MuiListItem-root:hover {
  text-decoration: none;
}
.MuiListItem-root:where(button) {
  -webkit-appearance: none;
          appearance: none;
  background: transparent;
  border: transparent;
}
a.MuiListItem-root {
  cursor: pointer;
}
.MuiListItemAvatar-root {
  width: 48px;
  min-width: 48px;
  margin-right: 0;
  padding: 0;
  text-align: center;
}
.MuiListItemAvatar-root .MuiAvatar-root {
  background: none;
  border-radius: 0;
  justify-content: flex-start;
}
.MuiListItemText-primary {
  /* ellipsis on unspecified width */
  /* NOTE: this does not work in IE 11, longer text will simply be hidden */
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #162d54;
}
.MuiListItemText-secondary {
  /* ellipsis on unspecified width */
  /* NOTE: this does not work in IE 11, longer text will simply be hidden */
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #162d54;
}
.ws-list-item-dark .MuiListItemText-primary,
.ws-list-item-dark .MuiListItemText-secondary {
  color: rgba(255, 255, 255, 0.87);
}
.ws-list-item-dark .wsicon,
.ws-list-item-dark .MuiSvgIcon-root {
  fill: rgba(255, 255, 255, 0.6);
}
.ws-list-item-error .MuiListItemText-primary {
  color: #ce2e3a;
}
.ws-list-item-error.ws-list-item-hover:hover .MuiListItemText-primary {
  color: #ce2e3a;
}
.ws-list-item-hover:hover {
  background-color: rgba(61, 90, 254, 0.04);
}
.ws-list-item-hover:hover .MuiListItemText-primary {
  color: #162d54;
}
.ws-list-item-hover:hover.ws-list-item-dark {
  background-color: rgba(255, 255, 255, 0.04);
}
.ws-list-item-hover:hover.ws-list-item-dark .MuiListItemText-primary {
  color: #fff;
}
.ws-list-item-hover:hover.ws-list-item-dark .MuiListItemText-secondary {
  color: rgba(255, 255, 255, 0.6);
}
.ws-list-item-hover:hover.ws-list-item-dark .wsicon,
.ws-list-item-hover:hover.ws-list-item-dark .MuiSvgIcon-root {
  fill: #fff;
}
@media (min-width: 769px) {
  .ws-list-item--revealOnRowHover > * {
    visibility: hidden;
  }
  .MuiListItem-root:hover .ws-list-item--revealOnRowHover > * {
    visibility: visible;
  }
}
.ws-list-item-selected,
.ws-list-item-selected:hover {
  background-color: #eff2ff;
}
.ws-list-item-selected .MuiListItemText-primary,
.ws-list-item-selected:hover .MuiListItemText-primary {
  color: #162d54;
}
.ws-list-item-selected.ws-list-item-dark,
.ws-list-item-selected:hover.ws-list-item-dark {
  background-color: rgba(255, 255, 255, 0.08);
}
.ws-list-item-selected.ws-list-item-dark .MuiListItemText-primary,
.ws-list-item-selected:hover.ws-list-item-dark .MuiListItemText-primary {
  color: #fff;
}
.ws-list-item-selected.ws-list-item-dark .MuiListItemText-secondary,
.ws-list-item-selected:hover.ws-list-item-dark .MuiListItemText-secondary {
  color: rgba(255, 255, 255, 0.6);
}
.ws-list-item-selected.ws-list-item-dark .wsicon,
.ws-list-item-selected:hover.ws-list-item-dark .wsicon,
.ws-list-item-selected.ws-list-item-dark .MuiSvgIcon-root,
.ws-list-item-selected:hover.ws-list-item-dark .MuiSvgIcon-root {
  fill: #fff;
}
.ws-list-item-divider {
  border-bottom: 1px solid #d8dde6;
}
.ws-list-item-dense {
  height: 32px;
}
.ws-list-item-double {
  height: 64px;
}
.ws-list-item-double .MuiListItemText-primary {
  color: #162d54;
}
.ws-list-item-triple {
  height: 88px;
}
.ws-list-item-triple.ws-list-item-long-text .MuiListItemText-secondary {
  max-height: 40px;
  /* two line ellipsis */
  -webkit-line-clamp: 2;
}
.ws-list-item-triple .MuiAvatar-root {
  align-items: flex-start;
  justify-content: center;
}
.ws-list-item-variable {
  height: auto;
}
.ws-list-item-variable.ws-list-item-long-text .MuiListItemText-secondary {
  max-height: none;
  /* two line ellipsis */
  -webkit-line-clamp: initial;
}
.ws-list-item-triple,
.ws-list-item-variable {
  align-items: flex-start;
  padding: 8px 24px;
}
.ws-list-item-triple .ws-list-item__secondary-content,
.ws-list-item-variable .ws-list-item__secondary-content {
  flex-direction: column;
  align-items: flex-end;
}
.ws-list-item-triple .ws-list-item__meta,
.ws-list-item-variable .ws-list-item__meta {
  padding: 0;
  color: #5e6785;
}
.ws-list-item-triple .MuiListItemAvatar-root,
.ws-list-item-variable .MuiListItemAvatar-root {
  height: 100%;
}
.ws-list-item-inset {
  margin-left: 72px;
  width: calc(100% - 72px);
  padding-left: 0;
}
.ws-list-item-inset .ws-list-item__extra-icons {
  position: absolute;
  left: -72px;
}
.ws-list-item-inset .MuiListItemAvatar-root {
  position: absolute;
  left: -48px;
  margin: 0;
}
.ws-list-item-inset.ws-list-item-double .MuiListItemAvatar-root {
  margin: 12px 0;
}
.ws-list-item-inset.ws-list-item-dense .MuiAvatar-root {
  height: auto;
  padding-top: 8px;
}
.ws-list-item__primary-content {
  flex-shrink: 1;
  margin-right: auto;
}
.ws-list-item__secondary-content {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  margin-left: 8px;
  align-items: center;
  justify-items: flex-end;
}
.ws-list-item__meta {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: right;
  color: #5e6785;
}
.ws-list-item__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  column-gap: 8px;
}
.ws-list-item__extra-icons {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  left: 0;
  top: 0;
  width: 24px;
  margin-top: 8px;
  color: #5e6785;
}
.ws-list-item__extra-icons .wsicon,
.ws-list-item__extra-icons .MuiSvgIcon-root {
  fill: #5e6785;
}
.ws-list-item__extra-icons > span:first-child {
  height: 24px;
}
.ws-list-item__extra-icons > span:not(:first-child) {
  height: 20px;
}
.ws-list-item__extra-icons > span > * {
  padding: 0;
}
.ws-menu__root {
  box-sizing: border-box;
  min-width: 150px;
  max-height: 230px;
  color: #5e6785;
}
.ws-menu__heading.ws-menu__heading {
  opacity: 1;
}
.ws-menu-item__link {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #3d5afe;
}
.ws-menu-item__link:hover {
  color: #0031ca;
}
.MuiMenuItem-root {
  height: 48px;
}
.MuiMenuItem-root:hover {
  background-color: #eff2ff;
}
.ws-menu__dropdown {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}
.ws-menu__dropdown-helper {
  padding: 0 16px;
}
.ws-menu__dropdown-helper .MuiListItemText-primary {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #5e6785;
}
.ws-menu__dropdown-item {
  padding: 0 16px;
  cursor: pointer;
}
.ws-stepper__label {
  cursor: pointer;
  align-items: flex-start;
}
.ws-stepper__label:hover .MuiStepLabel-label {
  color: #3d5afe;
}
.ws-stepper__label:hover .MuiStepLabel-iconContainer .MuiSvgIcon-root:not(.ws-stepper__completed-icon) {
  fill: #3d5afe;
}
.ws-stepper__label--text {
  font-weight: 700;
}
.MuiStepLabel-label.Mui-active .ws-stepper__label {
  font-weight: 700;
  color: #162d54;
}
.ws-stepper__description {
  font-weight: 400;
  color: #5e6785;
}
.ws-stepper__button-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
@media (max-width: 1210px) {
  .ws-stepper__button-row {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
  }
}
.ws-stepper__button-row > .MuiButton-root:not(:last-child) {
  margin-right: 10px;
}
@media (max-width: 1210px) {
  .ws-stepper__button-row > .MuiButton-root:not(:last-child) {
    margin-bottom: 10px;
  }
}
.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}
@media (min-width: 769px) {
  .TableCell--revealOnRowHover > * {
    visibility: hidden;
  }
  .MuiTableRow-root:hover .TableCell--revealOnRowHover > * {
    visibility: visible;
  }
}
.MuiTableRow-root:not(.MuiTableRow--no-hover):hover,
.MuiTableRow-root.MuiTableRow--hover {
  background-color: #e86232;
}
.MuiTableRow-root.Mui-selected {
  background-color: #eff2ff;
}
.MuiTableRow-root:hover.Mui-selected {
  background-color: #e0e5ff;
}
.MuiTableRow-head:hover {
  background: none;
}
.MuiTableCell-root {
  padding: 0 24px;
}
.MuiTab-root {
  color: #5e6785;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.MuiTab-root:hover {
  color: #3d5afe;
}
.ds-tabs--full-width .MuiTab-root {
  flex-grow: 1;
  max-width: none;
}
#root .SnackbarContainer-root {
  z-index: 2000;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root {
  background-color: #051a3c;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root.SnackbarItem-variantError {
  background-color: #ce2e3a;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .SnackbarItem-message {
  flex-grow: 1;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-text,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiIconButton-root {
  color: #8187ff;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-text svg[data-testid="CloseIcon"],
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined svg[data-testid="CloseIcon"],
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiIconButton-root svg[data-testid="CloseIcon"] {
  fill: rgba(255, 255, 255, 0.6);
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-text:hover,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:hover,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiIconButton-root:hover {
  color: #8187ff;
  background-color: rgba(129, 135, 255, 0.24);
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-text:hover svg[data-testid="CloseIcon"],
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:hover svg[data-testid="CloseIcon"],
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiIconButton-root:hover svg[data-testid="CloseIcon"] {
  fill: #fff;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-text:focus,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:focus,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiIconButton-root:focus {
  color: #8187ff;
  background-color: rgba(129, 135, 255, 0.24);
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-text:active,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:active,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiIconButton-root:active,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-text.active,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined.active,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiIconButton-root.active {
  color: #8187ff;
  background-color: rgba(129, 135, 255, 0.24);
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined {
  border-color: #8187ff;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:hover,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:focus,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:active,
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined.active {
  border-color: #8187ff;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-outlined:disabled {
  border-color: #eff1f6;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-contained {
  color: #fff;
  border-color: #8187ff;
  background-color: #8187ff;
}
.SnackbarItem-wrappedRoot .SnackbarContent-root .MuiButton-contained:hover {
  border-color: rgba(129, 135, 255, 0.24);
  background-color: rgba(129, 135, 255, 0.24);
  box-shadow: none;
}
.ds-tooltip__tooltip {
  background-color: #051a3c;
  box-sizing: border-box;
  max-width: 280px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  padding: 4px 8px;
  text-align: center;
}
.ds-html-tooltip__tooltip {
  box-sizing: border-box;
  padding: 16px;
  max-width: 280px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #162d54;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
}
.ds-tooltip__tooltip a {
  text-decoration: none;
  color: #8187ff;
}
.ds-tooltip__tooltip a:hover {
  text-decoration: underline;
}
.ds-tooltip__tooltip a:hover.no-underline {
  text-decoration: none;
}
.ds-tooltip__tooltip a:hover {
  color: #8187ff;
}
.ds-html-tooltip__tooltip a {
  text-decoration: none;
  color: #3d5afe;
}
.ds-html-tooltip__tooltip a:hover {
  text-decoration: underline;
}
.ds-html-tooltip__tooltip a:hover.no-underline {
  text-decoration: none;
}
.ds-html-tooltip__tooltip a:hover {
  color: #3d5afe;
}
.pane-set__container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #c2cad7;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}
.pane-set__pane {
  box-sizing: border-box;
  flex: 0 0 33.333%;
  height: 100%;
  border-right: 1px solid #c2cad7;
  display: flex;
  flex-direction: column;
}
.pane-set__pane.pane-1 {
  flex: 0 0 23.4%;
  overflow: auto;
}
.pane-set__pane.pane-2 {
  flex: 0 0 34%;
}
.pane-set__pane.pane-2 .MuiList-root {
  padding-top: 0;
  padding-bottom: 0;
}
.pane-set__pane.pane-3 {
  flex: 0 1 42.6%;
}
.pane-set__pane.pane-3 .MuiList-root {
  padding-top: 0;
  padding-bottom: 0;
}
.pane-set__pane.pane-3 .pane-set__pane-control {
  display: none;
}
.pane-set__pane:last-child {
  border-right: none;
}
@media (max-width: 1200px) {
  .pane-set__pane {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    transition: left 0.4s ease-in-out;
    z-index: 1;
    flex: none;
    border-right: 0;
    background: #fff;
  }
  .pane-set__pane.pane-3 .pane-set__pane-control {
    display: flex;
  }
  .pane-set__pane.shift-right {
    left: 100%;
  }
  .pane-set__container {
    overflow: hidden;
    display: block;
    height: 100%;
    position: relative;
  }
}
.pane-set__subject-item {
  display: flex;
  background-color: #fff;
  cursor: pointer;
}
.pane-set__subject-item p {
  max-height: 40px;
  -webkit-line-clamp: 2;
  max-width: calc(33vw - 140px);
}
@media (max-width: 1200px) {
  .pane-set__subject-item p {
    max-width: calc(100vw - 250px);
  }
}
@media (max-width: 900px) {
  .pane-set__subject-item p {
    max-width: calc(100vw - 150px);
  }
}
.pane-set__subject-item.ws-list-item-selected {
  background-color: #eff2ff;
}
.pane-set__subject-item .MuiListItemText-primary {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #162d54;
}
.pane-set__subject-item .ws-list-item__secondary-content {
  justify-content: space-between;
  height: 100%;
}
.pane-set__item__list {
  overflow-y: auto;
  flex-grow: 1;
}
.pane-set__subject__list {
  background-color: #fff;
}
.pane-set__pane-control {
  border-bottom: 1px solid #d8dde6;
  background: #fff;
  display: flex;
}
.pane-set__menu ul.MuiList-root {
  border-bottom: 1px solid #d8dde6;
}
.pane-set__menu-item {
  cursor: pointer;
}
.ws-daterange-input:hover {
  border-color: #162d54;
}
.ws-daterange-input .MuiInputBase-input {
  padding: 8px 14px 4px !important;
  border-bottom: none;
  color: #162d54;
}
.ws-daterange-input .MuiInputBase-input:focus {
  border-bottom: none;
}
.ws-daterange-container {
  display: flex;
}
.ws-daterange-container .react-datepicker__header--custom {
  background: white;
}
.ws-daterange-header-container {
  display: flex;
  padding: 0 10px;
}
.ws-daterange-header-container__expanded {
  display: block;
  width: 100%;
  min-width: 260px;
  padding: 0 10px;
}
.ws-daterange-header-container__expanded .ws-daterange-dropdown__btn {
  display: flex;
}
.ws-daterange-select-container {
  padding: 0px 4px;
}
.ws-daterange-select {
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  height: 100%;
  height: 340px;
  left: 3px;
  width: 100%;
  background-color: white;
  margin: -1%;
  box-shadow: 0px 8px 48px 8px rgba(94, 103, 133, 0.24);
}
.ws-daterange-dropdown__btn {
  padding: 6px 16px;
  border: 1px solid #c2cad7;
  border-radius: 20px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: none;
  color: #5e6785;
  background-color: #fff;
  display: "flex";
  justify-content: center;
  align-items: center;
}
.ws-daterange-dropdown__btn .MuiButton-startIcon svg,
.ws-daterange-dropdown__btn .MuiButton-endIcon svg {
  fill: #5e6785;
}
.ws-daterange-dropdown__btn:hover,
.ws-daterange-dropdown__btn:active,
.ws-daterange-dropdown__btn:focus {
  border-color: #162d54;
  color: #162d54;
}
.ws-daterange-dropdown__btn:hover {
  background-color: #f7f8ff;
}
.ws-daterange-dropdown__btn:focus {
  background-color: #d0d7ff;
}
.ws-daterange-dropdown__btn:active {
  background-color: #c1caff;
}
.ws-daterange-dropdown__btn:disabled {
  border-color: #eff1f6;
  color: #9caac0;
}
.ws-daterange-dropdown__btn:disabled .MuiButton-startIcon svg,
.ws-daterange-dropdown__btn:disabled .MuiButton-endIcon svg {
  fill: #9caac0;
}
.ws-daterange-option-chip__container {
  flex-basis: 29%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2%;
}
.ws-daterange-option-chip {
  border-radius: 12px;
  min-width: 40px;
  background-color: white;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: none;
  font-weight: 600;
}
.ws-daterange-option-chip__active {
  border-radius: 12px;
  min-width: 40px;
  background-color: white;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: none;
  font-weight: 600;
  background-color: #eff2ff;
}
.ws-daterange-option-chip__active .MuiChip-label {
  color: #3d5afe;
}
.ws-daterange-button-container--closed {
  display: none;
}
.ws-daterange-picker {
  display: flex;
}
.ws-daterange-picker .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
  top: -10px;
}
.ws-daterange-picker--horizontal {
  align-items: center;
  justify-content: space-between;
}
.ws-daterange-picker--horizontal .MuiTextField-root:last-child {
  margin-left: 10px;
}
.ws-daterange-picker--vertical {
  flex-direction: column;
}
.ws-daterange-picker--vertical .MuiTextField-root:last-child {
  margin-top: 12px;
}
.ws-metric-card {
  padding: 20px 24px;
  box-shadow: 0px 1px 24px 8px rgba(94, 103, 133, 0.08);
  border-radius: 8px;
  background-color: #fff;
}
.ws-metric-card .ws-metric-card__title-help {
  display: none;
}
.ws-metric-card:hover .ws-metric-card__title-help {
  display: block;
}
.ws-metric-card__header {
  margin-bottom: 24px;
  max-width: 300px;
  color: #162d54;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.15px;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ws-metric-card__content {
  display: flex;
  align-items: center;
  height: 40px;
  column-gap: 24px;
}
.ws-metric-card__counts__container {
  display: flex;
  flex-direction: column;
  color: #5e6785;
  gap: 8px;
}
.ws-metric-card__counts__container .ws-metric-card__negative_count {
  font-weight: 700;
  color: #ce2e3a;
}
.ws-metric-card__counts__container .ws-metric-card__positive_count {
  font-weight: 700;
  color: #018576;
}
.ws-metric-card__counts__container .ws-metric-card__default_count {
  font-weight: 700;
  color: #162d54;
}
.ws-metric-card__summary--empty {
  color: #9caac0;
}
.ws-avatar {
  width: 24px;
  height: 24px;
  font-size: 12px;
  background-color: #9caac0;
  line-height: 0;
  letter-spacing: 0;
}
.ws-three-line-list-item {
  display: flex;
  height: auto;
  padding: 4px 24px 8px;
}
.ws-three-line-list-item .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  fill: #3d5afe;
}
.ws-three-line-list-item__selectable {
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding-left: 13px;
  padding-bottom: 16px;
}
.ws-three-line-list-item__selectable:hover {
  background-color: #eff2ff;
}
.ws-three-line-list-item__checkbox {
  width: auto;
}
.ws-three-line-list-item__selected {
  background-color: #eff2ff;
}
.ws-three-line-list-item__content {
  display: flex;
  flex-direction: column;
}
.ws-three-line-list-item__meta-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 24px;
}
.ws-three-line-list-item__meta {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: right;
  color: #5e6785;
}
.MuiTableCell-root .ws-three-line-list-item {
  padding-left: 0;
  padding-right: 0;
}
.shadow-xsm {
  box-shadow: 0px 8px 16px -4px rgba(22, 45, 84, 0.08);
}
.shadow-sm {
  box-shadow: 0px 4px 8px -4px rgba(22, 45, 84, 0.08), 0px 16px 24px rgba(22, 45, 84, 0.08);
}
.shadow-md {
  box-shadow: 0px 4px 12px -4px rgba(22, 45, 84, 0.12), 0px 16px 32px rgba(22, 45, 84, 0.16);
}
.shadow-lg {
  box-shadow: 0px 120px 120px rgba(22, 45, 84, 0.08), 0px 64px 64px rgba(22, 45, 84, 0.12), 0px 32px 32px rgba(22, 45, 84, 0.04), 0px 24px 24px rgba(22, 45, 84, 0.04), 0px 4px 24px rgba(22, 45, 84, 0.04), 0px 4px 4px rgba(22, 45, 84, 0.04);
}
.elevation-zero {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: none;
}
.elevation-one {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 8px 16px -4px rgba(22, 45, 84, 0.08);
}
.elevation-two {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 8px -4px rgba(22, 45, 84, 0.08), 0px 16px 24px rgba(22, 45, 84, 0.08);
}
.elevation-three {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px -4px rgba(22, 45, 84, 0.12), 0px 16px 32px rgba(22, 45, 84, 0.16);
}
.elevation-four {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 120px 120px rgba(22, 45, 84, 0.08), 0px 64px 64px rgba(22, 45, 84, 0.12), 0px 32px 32px rgba(22, 45, 84, 0.04), 0px 24px 24px rgba(22, 45, 84, 0.04), 0px 4px 24px rgba(22, 45, 84, 0.04), 0px 4px 4px rgba(22, 45, 84, 0.04);
}
.sb-card-base__size {
  height: 144px;
  width: 316px;
}
.ws-conversation-bubble__message__container {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f4f5f9;
  border-radius: 4px;
  margin-top: 4px;
  color: #162d54;
}
.ws-conversation-bubble .ws-conversation-bubble__message {
  font-weight: 500;
}
.ws-conversation-bubble .ws-conversation-bubble__message a {
  color: #3d5afe;
  text-decoration: underline;
}
.ws-conversation-bubble.ws-conversation-bubble--blue .ws-conversation-bubble__message a {
  color: white;
}
.ws-conversation-bubble--blue .ws-conversation-bubble__message__container {
  float: right;
  background-color: #3d5afe;
  color: #fafbfb;
}
.ws-conversation-bubble--left {
  margin-right: 64px;
}
.ws-conversation-bubble--right {
  margin-left: 64px;
}
.ws-tile {
  width: 100%;
  height: 260px;
  box-shadow: 0px 8px 16px -4px rgba(22, 45, 84, 0.08);
}
.ws_tile__media_container {
  height: 120px;
  background-color: #d8dde6;
}
.ws-tile__img {
  mix-blend-mode: multiply;
  opacity: 0.4;
}
.ws_tile__content_container {
  padding: 0 16px;
}
.ws_tile__header {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 8px;
}
.ws_tile__header__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ws_tile__header__title .ws_tile__header__text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.ws_tile__header__subheader {
  display: flex;
  align-items: center;
  margin-top: 8px;
  min-height: 16px;
}
.ws-tile__content {
  padding: 0;
  padding-top: 8px;
  padding-bottom: 16px;
}
.ws-tile__content .ws-tile__text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ws-menubutton-popper {
  width: 296px;
  z-index: 99;
}
.ws-menubutton-popper .text-caption {
  color: #5e6785;
}
.ws-menubutton-popper .MuiTextField-root {
  box-sizing: border-box;
  width: 100%;
}
.ws-menubutton-popper .MuiFormHelperText-root {
  margin-bottom: 8px;
}
.ws-menubutton-paper {
  padding: 10px 0;
}
.ws-menubutton-paper > :not(.MuiList-root) {
  padding-right: 12px;
  padding-left: 12px;
}
.ws-menubutton-paper .MuiListItem-root {
  padding-right: 12px;
  padding-left: 12px;
}
.horizontal-progress-bar__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  flex: 1;
}
.horizontal-progress-bar__label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #5e6785;
}
.horizontal-progress-bar__helper-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #9caac0;
}
.horizontal-progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
}
.horizontal-progress-bar .progress-bar {
  height: 24px;
  background-color: #eff2ff;
  width: 100%;
  border-radius: 4px;
}
.horizontal-progress-bar .progress-bar__inner {
  border-radius: 4px;
  height: 24px;
  box-sizing: border-box;
}
.horizontal-progress-bar .progress-bar__content {
  color: #fff;
  padding-top: 1px;
  padding-left: 3px;
}
.horizontal-progress-bar .progress-bar__content .text-subtitle2 {
  font-weight: 700;
}
.horizontal-progress-bar .progress-bar__content.progress-zero {
  color: #162d54;
}
.horizontal-progress-bar .progress-bar__content--at-end {
  color: #162d54;
}
.horizontal-progress-bar__empty .progress-bar__content {
  color: #9caac0;
}
.horizontal-progress-bar__empty .progress-bar__content.progress-zero {
  color: #162d54;
}
.horizontal-progress-bar__empty .progress-bar__content--at-end {
  color: #162d54;
}
.horizontal-progress-bar__small {
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
.horizontal-progress-bar__small .progress-bar {
  height: 16px;
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
}
.horizontal-progress-bar__small .progress-bar__inner {
  height: 16px;
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
}
.horizontal-progress-bar__small .progress-bar__content {
  left: 4px;
  padding-left: 0;
}
.horizontal-progress-bar__small .progress-bar__content .text-caption {
  font-weight: 700;
}
.horizontal-progress-bar__small .progress-bar__content.progress-zero {
  color: #162d54;
}
.horizontal-progress-bar__large .progress-bar {
  height: 32px;
  border-radius: 4px;
}
.horizontal-progress-bar__large .progress-bar__content {
  padding-top: 1px;
  padding-left: 3px;
}
.horizontal-progress-bar__large .progress-bar__content.progress-zero {
  color: #162d54;
}
.horizontal-progress-bar__large .progress-bar__inner {
  height: 32px;
  border-radius: 4px;
}
.horizontal-progress-bar__na .progress-bar__content {
  padding-top: 1px;
  color: #5e6785;
}
.progress-bar-color__primary .progress-bar {
  background-color: #eff2ff;
}
.progress-bar-color__primary .progress-bar__inner {
  background: linear-gradient(to right, #0031ca 0%, #8187ff 100%);
}
.progress-bar-color__primary .progress-bar__content {
  color: #fff;
}
.progress-bar-color__primary .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__primary .progress-bar__content--at-end {
  color: #162d54;
}
.progress-bar-color__green .progress-bar {
  background-color: #ebf5f4;
}
.progress-bar-color__green .progress-bar__inner {
  background-color: #018576;
}
.progress-bar-color__green .progress-bar__content {
  color: #fff;
}
.progress-bar-color__green .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__green .progress-bar__content--at-end {
  color: #162d54;
}
.progress-bar-color__medium-green .progress-bar {
  background-color: #ebf5f4;
}
.progress-bar-color__medium-green .progress-bar__inner {
  background-color: #13aa88;
}
.progress-bar-color__medium-green .progress-bar__content {
  color: #162d54;
}
.progress-bar-color__medium-green .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__medium-green .progress-bar__content--at-end {
  color: #162d54;
}
.progress-bar-color__yellow .progress-bar {
  background-color: #fef9ed;
}
.progress-bar-color__yellow .progress-bar__inner {
  background-color: #edaf1f;
}
.progress-bar-color__yellow .progress-bar__content {
  color: #162d54;
}
.progress-bar-color__yellow .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__yellow .progress-bar__metric {
  border-left: 2px dashed #3d5afe;
}
.progress-bar-color__yellow .progress-bar__content--at-end {
  color: #162d54;
}
.progress-bar-color__orange-red .progress-bar {
  background-color: #fbeeef;
}
.progress-bar-color__orange-red .progress-bar__inner {
  background-color: #e86232;
}
.progress-bar-color__orange-red .progress-bar__content {
  color: #162d54;
}
.progress-bar-color__orange-red .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__orange-red .progress-bar__content--at-end {
  color: #162d54;
}
.progress-bar-color__red .progress-bar {
  background-color: #fbeeef;
}
.progress-bar-color__red .progress-bar__inner {
  background-color: #ce2e3a;
}
.progress-bar-color__red .progress-bar__content {
  color: #fff;
}
.progress-bar-color__red .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__red .progress-bar__content--at-end {
  color: #162d54;
}
.progress-bar-color__navy-low .progress-bar {
  background-color: #eff1f6;
}
.progress-bar-color__navy-low .progress-bar__inner {
  background: #9caac0;
}
.progress-bar-color__navy-low .progress-bar__content {
  color: #fff;
}
.progress-bar-color__navy-low .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__navy-low .progress-bar__content--at-end {
  color: #162d54;
}
.progress-bar-color__good-to-bad .progress-bar {
  background-color: #eff2ff;
}
.progress-bar-color__good-to-bad .progress-bar__inner {
  background: linear-gradient(to right, #018576 0%, #13aa88 25%, #edaf1f 50%, #e86232 75%, #ce2e3a 100%);
}
.progress-bar-color__good-to-bad .progress-bar__content {
  color: #fff;
}
.progress-bar-color__good-to-bad .progress-bar__content.progress-zero {
  color: #162d54;
}
.progress-bar-color__good-to-bad .progress-bar__content--at-end {
  color: #162d54;
}
.sb-horizontal-progress-bar__background-card {
  height: 144px;
  width: 316px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 24px 24px;
}
.bar-chart__item {
  display: flex;
  justify-content: space-between;
  --lh: 1.4rem;
  padding: 0;
  height: 56px;
}
.bar-chart__item .MuiTableCell-root {
  border: 0;
}
.MuiTableRow-root:not(.MuiTableRow--no-hover):hover,
.MuiTableRow-root.MuiTableRow--hover {
  background-color: #eff2ff;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #eff2ff;
}
.bar-chart__item--label-text-box {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 12px 0 12px 24px;
  line-height: var(--lh);
}
.bar-chart__item--label-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  display: -webkit-box;
  text-align: right;
  padding-right: 1rem;
  color: #5e6785;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  float: right;
}
.bar-chart__item--bar {
  flex: 1;
  padding: 12px 0;
  width: 100%;
  gap: 0px;
  height: 56px;
}
.bar-chart__item--bar-inner {
  min-width: 72px;
}
.bar-chart__table-cell--bar-wrapper {
  flex: 1;
  width: 100%;
  padding: 0;
}
.bar-chart__table-cell--bar-wrapper .MuiTableCell-sizeMedium {
  height: 56px;
}
.bar-chart__item--comparison-arrow {
  width: 48px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 8px;
}
.bar-chart__item--dense {
  height: 40px;
  --lh: 0.9rem;
}
.bar-chart__item--dense .MuiTableCell-root {
  height: 40px;
}
.bar-chart__item--dense .bar-chart__item--bar {
  padding: 4px 0;
}
.bar-chart__item--dense .bar-chart__item--label-text-box {
  height: 40px;
  padding: 0 0 0 24px;
}
.bar-chart__item--dense .bar-chart__item--label-text {
  -webkit-line-clamp: 1;
}
.bar-chart__item--dense .bar-chart__item--comparison-arrow {
  padding: 0 24px 0 8px;
}
.bar-chart__item--dense .bar-chart__item--bar-no-compare {
  padding-right: 24px;
}
.bar-chart__item--dense .bar-chart__item--bar-no-label {
  padding-left: 24px;
}
.bar-chart__item--bar-no-compare {
  padding-right: 24px;
}
.bar-chart__item--bar-no-label {
  padding-left: 24px;
}
.bar-chart-card__on-card-wrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
}
.bar-chart-card__no-card-wrapper {
  display: flex;
  flex-direction: column;
}
.bar-chart-card__fixed-height-wrapper {
  background: #fff;
}
.bar-chart__card--sort-icon {
  fill: #5e6785;
}
.bar-chart__card--sort-reverse-icon {
  fill: #5e6785;
  transform: rotate(0.5turn) scaleX(-1);
}
.bar-chart__card--divider {
  margin: 0;
}
.bar-chart__table {
  margin: 8px 0;
}
.bar-chart__table-wrapper-scrollable {
  width: 100%;
  overflow: hidden;
}
.bar-chart__tooltip-base-class {
  background-color: #fff;
}
.bar-chart__tooltip {
  min-width: 240px;
  padding: 8px 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px -4px rgba(22, 45, 84, 0.12), 0px 16px 32px rgba(22, 45, 84, 0.16);
  color: #162d54;
}
.ds-card {
  border-radius: 8px;
}
.ds-card .ds-card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #c2cad7;
}
.ds-card .ds-card-actions .ds-card-actions-left,
.ds-card .ds-card-actions .ds-card-actions-right {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.comparison-arrow__text--na {
  color: #5e6785;
}
.comparison-arrow__text--na:hover {
  color: #162d54;
}
.comparison-arrow__text--positive {
  color: #018576;
}
.comparison-arrow__text--negative {
  color: #ce2e3a;
}
.MuiDataGrid-root {
  border: none;
}
.MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: #f7f8ff;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none;
}
.MuiDataGrid-root.hide-header-separators .MuiDataGrid-iconSeparator {
  display: none;
}
.MuiDataGrid-root.no-hover .MuiDataGrid-row:hover {
  background-color: transparent;
}
.MuiDataGrid-root.no-hover .MuiDataGrid-row.Mui-hovered {
  background-color: transparent;
}
.MuiDataGrid-root.wrap-text .MuiDataGrid-cellContent {
  white-space: wrap;
}
.header__outer--medium,
.header__outer--auto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 14px 24px;
  align-self: stretch;
  flex: 1 0 0;
}
.header__outer--medium__description,
.header__outer--auto__description {
  flex-wrap: wrap;
  row-gap: 0px;
}
.header__outer--medium {
  height: 36px;
}
.header__outer--auto {
  height: auto;
}
.header__text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.03px;
}
.header__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
.ws-page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 0 24px;
  box-sizing: border-box;
}
.ws-page-header__small {
  background: white;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 8px 16px -4px rgba(22, 45, 84, 0.08);
}
.ws-page-header__title {
  display: flex;
  color: #162d54;
  transition: font-size 200ms;
}
.ws-page-header__title-medium {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 0.25px;
}
.ws-page-header__title-small,
.ws-page-header--minimized .ws-page-header__title-medium {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.ws-page-header--minimized .ws-page-header__subtitle {
  display: none;
}
.ws-page-header__tabs {
  border-bottom: 1px solid #c2cad7;
  height: 48px;
  width: 100%;
}
.ws-page-header__tab-content {
  position: relative;
}
.ds-tabs--full-width .ws-page-header__tab-content {
  display: flex;
  flex-grow: 1;
  text-align: center;
}
.ws-page-header__tabs--beta-label {
  background: #9200b0;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -7px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
}
.ws-page-header__tab-container_hidden {
  display: none;
}
.ws-page-header__button-container {
  display: flex;
  flex-direction: row;
  height: 36px;
  gap: 8px;
}
.ws-page-header__top-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  width: 100%;
  padding: 24px 0;
  transition: padding 200ms;
}
.ws-page-header--minimized .ws-page-header__top-container {
  padding: 10px 0;
}
.ws-page-header__tab-container {
  max-width: 1400px;
  width: 100%;
  overflow: visible;
  display: flex;
  align-items: flex-start;
}
.ws-page-header__tab-container a.MuiTab-root {
  text-decoration: none;
}
.ws-page-header__tab-container > .MuiTabs-root {
  overflow: visible;
}
.ws-page-header__tab-container > .MuiTabs-root > .MuiTabs-scroller {
  overflow: visible !important;
}
.ws-page-header__title-container {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
}
.ws-page-header__leading-icon {
  color: #162d54;
  width: 40px;
  height: 40px;
  transition: width 200ms, height 200ms;
}
.ws-page-header__leading-icon svg {
  width: 40px;
  height: 40px;
  transition: width 200ms, height 200ms;
}
.ws-page-header--minimized .ws-page-header__leading-icon {
  width: 24px;
  height: 24px;
}
.ws-page-header--minimized .ws-page-header__leading-icon svg {
  width: 24px;
  height: 24px;
}
.portal-info-icon.ws-page-header__tooltip {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #5e6785;
  cursor: pointer;
}
.ws-page-header--minimized .portal-info-icon.ws-page-header__tooltip {
  align-items: center;
}
.shared-card-header {
  display: inherit;
  background-color: #fff;
}
.shared-card-header .portal-info-icon {
  display: none;
}
.shared-card-header:hover .portal-info-icon {
  display: flex;
}
.ws-page-header__mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
}
.ws-sm-page-header {
  height: 56px;
  padding: 0 24px;
}
.filter-dialog__title {
  padding-left: 16px;
  padding-right: 24px;
  align-items: flex-start;
}
.filter-dialog__title .ws-dialog__title-container__description {
  color: #5e6785;
}
.filter-dialog__content {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.retain-simple-histogram-chart {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-height: 50px;
  width: 150px;
}
.retain-simple-histogram-chart__bar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: hidden;
}
.retain-simple-histogram-chart__bar-container div {
  width: 7px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.retain-simple-histogram-chart__bar-container .retain-simple-histogram-chart__shadow-bar {
  background-color: #eff2ff;
  flex-grow: 1;
  margin-bottom: -1px;
  z-index: 1;
}
.retain-simple-histogram-chart__bar-container .retain-simple-histogram-chart__filled-bar {
  background-color: #3d5afe;
  z-index: 2;
  bottom: 0;
}
.retain-simple-histogram-chart__bar-container .retain-simple-histogram-chart__filled-bar:hover {
  background-color: #8187ff;
}
.retain-simple-histogram-chart__bar-container .retain-simple-histogram-chart__partial-bar {
  border: 2px solid #3d5afe;
  z-index: 2;
  bottom: 0;
  box-sizing: border-box;
}
.retain-simple-histogram-chart__bar-container .retain-simple-histogram-chart__partial-bar:hover {
  border: 2px solid #8187ff;
}
.sb-simple-histogram-chart__background-card {
  height: 144px;
  width: 316px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 24px 24px;
}
.tooltip-light {
  background-color: #fff;
  color: #000;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.24);
}
.tooltip-light .MuiTooltip-arrow {
  color: #fff;
}
.tooltip-legacy {
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
}
.hover-warning {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 38px;
  width: auto;
  height: 38px;
  padding: 9px;
  border-radius: 19px;
  background-color: #edaf1f;
  overflow: hidden;
  transition: max-width 200ms ease;
}
.hover-warning svg {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: #fff;
}
.hover-warning:hover {
  max-width: 150px;
}
.hover-warning:hover .hover-warning__message {
  width: auto;
  z-index: 1;
}
.hover-warning__message {
  z-index: -1;
  color: #fff;
  font-size: 13px;
  white-space: pre;
}
.tooltip {
  display: inline;
  position: relative;
}
.tooltip::before,
.tooltip::after {
  transition: opacity 400ms ease-out;
  z-index: 900;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.tooltip::after {
  content: attr(data-tooltip) " ";
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  width: 260px;
  color: white;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  text-transform: none;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
}
.tooltip::before {
  content: " ";
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 10px solid rgba(0, 0, 0, 0.8);
}
.tooltip:hover {
  cursor: pointer;
}
.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}
.tooltip.tooltip-left::before {
  left: 100%;
  bottom: 50%;
  transform: translate(0, 50%);
  border-right-color: rgba(0, 0, 0, 0.8);
  border-top-color: rgba(0, 0, 0, 0);
  border-width: 8px;
}
.tooltip.tooltip-left::after {
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translate(16px, -50%);
  width: 50px;
}
@media (max-width: 768px) {
  .tooltip::before,
  .tooltip::after {
    display: none;
  }
}
.tooltip__parent {
  position: relative;
}
.tooltip__container {
  position: absolute;
  z-index: 900;
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  background-color: #3d5afe;
  text-align: center;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
}
.tooltip__container a:not(.btn) {
  color: #fff;
  border-bottom: 1px dashed #fff;
}
.tooltip__container a:not(.btn):hover {
  text-decoration: none;
  border-bottom-style: solid;
}
.tooltip__container--top {
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
}
.tooltip__container--top::before {
  content: " ";
  position: absolute;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 10px solid #3d5afe;
}
.tooltip__container--right {
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}
.tooltip__container--right::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: -19px;
  transform: translateY(-50%);
  border: 10px solid rgba(0, 0, 0, 0);
  border-left: 10px solid #3d5afe;
}
.tooltip__container--left {
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.tooltip__container--left::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: -19px;
  transform: translateY(-50%);
  border: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid #3d5afe;
}
.tooltip__container--bottom {
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.tooltip__container--bottom::before {
  content: " ";
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid #3d5afe;
}
.tooltip__container--top-left {
  left: 50%;
  bottom: 0;
  transform: translate(-90%, -45%);
}
.tooltip__container--top-left::before {
  content: " ";
  position: absolute;
  bottom: -19px;
  left: 90%;
  transform: translateX(-50%);
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 10px solid #3d5afe;
}
.content {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 768px) {
  .content {
    max-width: 100%;
  }
}
.content--center {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .content--center {
    display: block;
  }
}
table {
  color: #555;
  font-weight: 300;
  font-size: 20px;
  text-align: left;
}
table td {
  padding: 0 15px;
}
html,
body {
  margin: 0;
  padding: 0;
  color: #555;
}
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}
.center {
  text-align: center;
}
.phone-container {
  position: relative;
  margin: 40px 0;
}
.pin {
  height: 15px;
  margin: 0 10px -2px;
}
.header {
  background: #162d54;
  text-align: left;
  height: 50px;
  overflow: hidden;
}
.header .logo {
  margin: 16px 20px;
}
.heading {
  font-weight: 500;
  font-size: 18px;
}
.hidden {
  display: none;
}
.mobile-only {
  display: none;
}
@media (max-width: 768px) {
  .hidden-mobile {
    display: none !important;
  }
  .mobile-only {
    display: block;
  }
  body > div.hero {
    height: 525px;
    background-position-x: -1940px;
  }
  .carousel {
    max-width: none;
  }
}
.company-logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 110px;
  width: 400px;
}
.pac-container {
  z-index: 1600;
}
.btn {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  border-radius: 4px;
  font-size: 15px;
  line-height: 18px;
  padding: 9px 36px;
  height: 38px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.2s;
  -webkit-appearance: none;
}
.btn.tight {
  padding-left: 18px;
  padding-right: 18px;
}
.btn svg {
  transition-property: fill;
  transition-duration: 0.2s;
}
.btn.loading {
  position: relative;
}
.btn.loading::after {
  content: "";
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: none;
  position: absolute;
  top: 13px;
  right: 17px;
  margin: 0;
  font-size: 10px;
  text-indent: -9999em;
  border-right: 3px solid rgba(255, 255, 255, 0);
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  border-left: 3px solid #caf5ed;
  border-top: 3px solid #caf5ed;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  display: inline-block;
}
.btn.loading:not(.btn-large)::after {
  width: 15px;
  height: 15px;
  top: 8px;
  right: 6px;
}
.btn-group {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.btn-group button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex: 1 1 0%;
  padding: 8px 36px;
  color: #000;
  border: none;
  border-radius: 0;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  text-transform: none;
  word-wrap: break-spaces;
}
.btn-group button:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.btn-group button:last-child {
  border-right: 1px solid #ddd;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.btn-group button:disabled {
  cursor: not-allowed;
}
.btn-group button:not(:disabled):hover {
  background-color: #f5f5f5;
}
.btn-group .btn--selected {
  color: #fff;
  background-color: #00cba5;
}
.btn-group .btn--selected:hover,
.btn-group .btn--selected:not(:disabled):hover {
  background-color: #00cba5;
}
.icon-btn-group,
.corner-icon-btn-group {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.icon-btn-group button,
.corner-icon-btn-group button {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  text-transform: none;
  word-wrap: break-spaces;
  padding: 8px 36px;
  border: 1px solid #ddd;
  margin-right: 10px;
  background-color: #fff;
}
.icon-btn-group button:disabled,
.corner-icon-btn-group button:disabled {
  cursor: not-allowed;
}
.icon-btn-group button:not(:disabled):hover,
.corner-icon-btn-group button:not(:disabled):hover {
  background-color: #f5f5f5;
}
.icon-btn-group button {
  justify-content: center;
  flex: 1 1 0%;
  height: 50px;
  border-radius: 30px;
  margin-right: 10px;
}
.icon-btn-group svg,
.icon-btn-group img {
  width: 20px;
  height: 20px;
}
.icon-btn-group span {
  padding-left: 10px;
}
.icon-btn-group .btn--selected {
  background-color: #00cba5;
  border-color: #00cba5;
  color: #fff;
}
.icon-btn-group .btn--selected svg {
  fill: #fff;
}
.icon-btn-group .btn--selected:disabled {
  cursor: not-allowed;
}
.icon-btn-group .btn--selected:not(:disabled):hover {
  background-color: #60ffd6;
}
.table-btn {
  width: 160px;
}
.table-btn a {
  font-size: 12px;
  color: #555;
  padding: 2px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-decoration: none;
}
.corner-icon-btn-group__top-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 200ms;
}
.corner-icon-btn-group__top-right .wsicon-approve {
  position: absolute;
  top: 2px;
  right: 4px;
  height: 20px;
  width: 20px;
  fill: #fff;
}
.corner-icon-btn-group__top-right-bg::after,
.corner-icon-btn-group__top-right-bg::before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.corner-icon-btn-group__top-right-bg::after {
  border-color: transparent;
  border-top-color: #00cba5;
  border-width: 40px;
  margin-left: -40px;
}
.corner-icon-btn-group__top-right-bg::before {
  border-color: transparent;
  border-top-color: #00cba5;
  border-width: 41px;
  margin-left: -41px;
}
.corner-icon-btn-group__top-right-bg::after,
.corner-icon-btn-group__top-right-bg::before {
  top: 0;
  left: 100%;
  transition: border-top-color 200ms;
}
.corner-icon-btn-group button {
  position: relative;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 175px;
  height: 100px;
  margin-right: 10px;
}
.corner-icon-btn-group button > svg,
.corner-icon-btn-group button > img {
  width: 30px;
  height: 30px;
}
.corner-icon-btn-group span {
  white-space: nowrap;
  margin-top: 5px;
}
.corner-icon-btn-group .btn--selected {
  border-color: #00cba5;
}
.corner-icon-btn-group .btn--selected .corner-icon-btn-group__top-right {
  opacity: 1;
}
.corner-icon-btn-group .btn--selected:disabled {
  cursor: not-allowed;
}
.corner-icon-btn-group .btn--selected:not(:disabled):hover {
  border-color: #60ffd6;
}
.corner-icon-btn-group .btn--selected:not(:disabled):hover .corner-icon-btn-group__top-right-bg::after,
.corner-icon-btn-group .btn--selected:not(:disabled):hover .corner-icon-btn-group__top-right-bg::before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.corner-icon-btn-group .btn--selected:not(:disabled):hover .corner-icon-btn-group__top-right-bg::after {
  border-color: transparent;
  border-top-color: #60ffd6;
  border-width: 40px;
  margin-left: -40px;
}
.corner-icon-btn-group .btn--selected:not(:disabled):hover .corner-icon-btn-group__top-right-bg::before {
  border-color: transparent;
  border-top-color: #60ffd6;
  border-width: 41px;
  margin-left: -41px;
}
.corner-icon-btn-group .btn--selected:not(:disabled):hover .corner-icon-btn-group__top-right-bg::after,
.corner-icon-btn-group .btn--selected:not(:disabled):hover .corner-icon-btn-group__top-right-bg::before {
  top: 0;
  left: 100%;
}
/* -------------  Button Sizes  ------------- */
.btn-large {
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 48px;
  height: 48px;
}
.btn-large.tight {
  padding-left: 24px;
  padding-right: 24px;
}
.btn-medium {
  border-radius: 4px;
  font-size: 15px;
  line-height: 18px;
  padding: 9px 36px;
  height: 38px;
}
.btn-medium.tight {
  padding-left: 18px;
  padding-right: 18px;
}
.btn-small {
  border-radius: 4px;
  font-size: 13px;
  line-height: 16px;
  padding: 7px 18px;
  height: 30px;
}
.btn-small.tight {
  padding-left: 9px;
  padding-right: 9px;
}
.fullwidth {
  width: 100%;
}
/* -------------  Button Styles  ------------- */
.btn-primary {
  background: #00cba5;
  color: #fff;
}
.btn-primary:hover {
  color: #fff;
  text-decoration: none;
  background: #009976;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-primary:disabled,
.btn-primary.btn--disabled {
  background: #97d2c8;
  color: #caf5ed;
  outline: none;
  cursor: default;
}
.btn-primary:disabled:hover,
.btn-primary.btn--disabled:hover {
  background: #97d2c8;
  color: #caf5ed;
  outline: none;
}
.btn-primary-reversed {
  background: #fff;
  color: #00cba5;
}
.btn-primary-reversed:hover {
  color: #00cba5;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.9);
}
.btn--white {
  background: #fff;
  color: #4a4a4a;
  border: 1px solid #ddd;
}
.btn--white:hover {
  color: #4a4a4a;
  text-decoration: none;
  background: #fafafa;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn--red {
  color: #fff;
  background-color: #e86232;
  border-color: #e86232;
}
.btn--red:hover {
  background-color: #e5501b;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn--red:disabled,
.btn--red.btn--disabled {
  opacity: 0.5;
  background-color: #e86232;
  cursor: default;
}
.btn--red:disabled:hover,
.btn--red.btn--disabled:hover {
  opacity: 0.5;
  background-color: #e86232;
  box-shadow: none;
}
.btn--navy {
  color: #fff;
  background-color: #162d54;
  border-color: #162d54;
}
.btn--navy:hover {
  background-color: #112240;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn--navy:disabled,
.btn--navy.btn--disabled {
  opacity: 0.5;
  background-color: #162d54;
  cursor: default;
}
.btn--navy:disabled:hover,
.btn--navy.btn--disabled:hover {
  opacity: 0.5;
  background-color: #162d54;
  box-shadow: none;
}
.btn--violet {
  background: #3d5afe;
  color: #fff;
}
.btn--violet:hover {
  color: #fff;
  text-decoration: none;
  background: #7085fe;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn--violet:disabled,
.btn--violet.btn--disabled {
  opacity: 0.5;
  color: #fff;
  background: #3d5afe;
  cursor: default;
}
.btn--violet:disabled:hover,
.btn--violet.btn--disabled:hover {
  opacity: 0.5;
  color: #fff;
  background: #3d5afe;
  box-shadow: none;
}
.btn-secondary {
  color: #4a4a4a;
  border: 1px solid #bbb;
  background: transparent;
  font-weight: 500;
}
.btn-secondary:hover {
  color: #000;
  text-decoration: none;
  border: 1px solid #9b9b9b;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-secondary:disabled,
.btn-secondary.btn--disabled {
  color: #9b9b9b;
  background: #f5f5f5;
  outline: none;
  cursor: default;
}
.btn-secondary:disabled:hover,
.btn-secondary.btn--disabled:hover {
  color: #9b9b9b;
  background: #f5f5f5;
  outline: none;
  box-shadow: none;
}
.btn-secondary-reversed {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.btn-secondary-reversed:hover {
  color: #fff;
  text-decoration: none;
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-warning {
  color: #e86232;
  border: 2px solid #e86232;
  background: #fff;
}
.btn-warning:hover {
  background: rgba(252, 102, 53, 0.5);
}
.btn-special {
  border-radius: 19px;
}
.btn-special.btn-large {
  border-radius: 24px;
}
.btn-special.btn-small {
  border-radius: 15px;
}
.btn-back {
  width: 12px;
  height: 22px;
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/backArrow-black.svg") no-repeat 50% 50%;
  opacity: 1;
  padding: 11px 16px;
  cursor: pointer;
}
.link-btn {
  border: 0;
  padding: 0;
  font-size: 13px;
  line-height: 18px;
  text-decoration: none;
  color: #00cba5;
  background: none;
  cursor: pointer;
}
.link-btn:hover {
  color: #009976;
  text-decoration: underline;
}
.btn--reversed.btn-primary {
  background: #fff;
  color: #00cba5;
}
.btn--reversed.btn-primary:hover {
  color: #00cba5;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.9);
}
.btn--reversed.btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.btn--reversed.btn-secondary:hover {
  color: #fff;
  text-decoration: none;
  border-color: rgba(255, 255, 255, 0.7);
}
.btn--reversed.btn--violet {
  background: #fff;
  color: #3d5afe;
  border: 1px solid #eff2ff;
}
.btn--reversed.btn--violet:hover {
  color: #3d5afe;
  text-decoration: none;
  background: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-color: #3d5afe;
}
.btn--reversed.btn--violet:disabled,
.btn--reversed.btn--violet.btn--disabled {
  opacity: 0.5;
  color: #3d5afe;
  background: #fff;
  cursor: default;
}
.btn--reversed.btn--violet:disabled:hover,
.btn--reversed.btn--violet.btn--disabled:hover {
  opacity: 0.5;
  color: #3d5afe;
  background: #fff;
  box-shadow: none;
}
.href-btnhover:hover {
  color: #fff;
}
.loader,
.loader::after {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.loader {
  display: none;
  position: absolute;
  top: 13px;
  right: 17px;
  margin: 0;
  font-size: 10px;
  text-indent: -9999em;
  border-right: 3px solid rgba(255, 255, 255, 0);
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  border-left: 3px solid #caf5ed;
  border-top: 3px solid #caf5ed;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
.circle-loader {
  position: relative;
}
.circle-loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-right: 3px solid rgba(255, 255, 255, 0);
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  border-left: 3px solid #caf5ed;
  border-top: 3px solid #caf5ed;
  transform: translateZ(0);
  animation: 1.1s load8 infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bouncer,
.bouncer::before,
.bouncer::after {
  color: #162d54;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: bounce 1.4s infinite ease-in-out both;
}
.bouncer {
  position: relative;
  animation-delay: -0.16s;
}
.bouncer::before,
.bouncer::after {
  content: "";
  position: absolute;
}
.bouncer::before {
  left: -19px;
  animation-delay: -0.32s;
}
.bouncer::after {
  left: 19px;
}
@keyframes bounce {
  0%,
  80%,
  100% {
    box-shadow: 0 14px 0 -7px;
  }
  40% {
    box-shadow: 0 14px 0 0;
  }
}
.loader2,
.loader2::after {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.loader2 {
  margin: 0;
  font-size: 10px;
  border-right: 3px solid rgba(255, 255, 255, 0);
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  border-left: 3px solid #162d54;
  border-top: 3px solid #162d54;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
.loader2-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 2000;
}
.loader2-container.fixed {
  position: fixed;
}
.countdown {
  height: 40px;
  width: 40px;
}
.countdown__svg {
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}
.countdown__circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: #162d54;
  fill: rgba(0, 0, 0, 0);
  animation: countdown 5s linear forwards;
}
.countdown--medium {
  height: 30px;
  width: 30px;
}
.countdown--medium .countdown__svg {
  width: 30px;
  height: 30px;
}
@keyframes countdown {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 113px;
  }
}
.pageload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 2px 18px rgba(22, 45, 84, 0.2);
}
.pageload-container .circle-loader {
  width: 50px;
  height: 50px;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track::before,
.slick-track::after {
  display: table;
  content: "";
}
.slick-track::after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  list-style: none;
  display: block;
  margin: 0 auto;
  padding: 0;
}
.slick-dots li {
  display: inline-block;
}
.slick-dots li button {
  margin: 10px;
  padding: 5px;
  height: 8px;
  width: 8px;
  background-color: #9d9d9d;
  border-radius: 50%;
  font-size: 0;
  border: none;
  outline: none;
  cursor: pointer;
}
.slick-dots li.slick-active button {
  height: 12px;
  width: 12px;
  background-color: #00cba5;
}
.positions-inner-container {
  max-width: 620px;
  box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.12);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}
.positions-inner-container.featured-inactive {
  margin: 0 auto;
}
.positions-inner-container.featured-inactive .position-detail-column {
  overflow-x: visible;
}
.positions-inner-container.featured-inactive .detail {
  padding-bottom: 0;
}
@media (max-width: 768px) {
  .positions-inner-container {
    border-left: 0;
    border-right: 0;
  }
}
.page-header,
#positions-list {
  background: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}
#positions-list {
  min-height: 300px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
}
#positions-list .list-bouncer.bouncer {
  left: 50%;
  margin-left: -12px;
  top: 30px;
}
@media (max-width: 768px) {
  #positions-list {
    border-left: 0;
    border-right: 0;
  }
}
#pagination {
  padding: 24px 0 0 24px;
}
#pagination a.page {
  margin: 0 4px;
  border-radius: 100px;
  width: 24px;
  text-align: center;
  display: inline-block;
}
#pagination a.page.selected {
  color: white;
  background: #00cba5;
  height: 24px;
  line-height: 22px;
}
.page-header {
  padding: 28px 24px;
}
.page-header .logged-in__header {
  display: block;
}
@media (max-width: 768px) {
  .page-header .logged-in__header {
    display: none;
  }
}
.page-header h1,
.page-header h2 {
  font-size: 18px;
  line-height: 24px;
  color: #162d54;
  margin: 8px 0;
  font-weight: 300;
}
.page-header h1 strong,
.page-header h2 strong {
  font-weight: 700;
}
.page-header a {
  color: #9b9b9b;
}
.page-header span {
  padding-left: 20px;
  position: relative;
  color: #162d54;
}
.page-header span::before {
  content: "";
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/pinBlue.svg");
  width: 12px;
  height: 14px;
  position: absolute;
  left: 0;
  top: 5px;
}
.page-header .another-city {
  font-size: 12px;
  line-height: 16px;
}
.page-header .another-city a {
  -webkit-text-decoration: None;
          text-decoration: None;
  display: inline-block;
  border-bottom: 1px dashed #9b9b9b;
}
.page-header .another-city a:hover {
  text-decoration: none;
  color: #4a4a4a;
  border-bottom-color: #4a4a4a;
}
#map {
  position: fixed;
  left: 50%;
  top: 160px;
  margin-left: 210px;
  width: 285px;
  height: 248px;
  background: #162d54;
}
@media (max-width: 768px) {
  #map {
    display: none;
  }
}
.desktop-location-description {
  position: fixed;
  left: 50%;
  top: 430px;
  margin-left: 210px;
  max-width: 300px;
  font-size: 12px;
  color: #4a4a4a;
}
@media (max-width: 768px) {
  .desktop-location-description {
    display: none;
  }
}
.mobile-location-description {
  display: none !important;
  font-size: 12px;
  color: #4a4a4a;
  padding: 30px 12px 0;
}
@media (max-width: 768px) {
  .mobile-location-description {
    display: block !important;
  }
}
.my-jobs #job-categories-cloud {
  display: none;
}
.list-no-results {
  text-align: center;
  padding-top: 50px;
}
.list-no-results p {
  margin: 0;
}
.list-no-results a {
  display: inline-block;
  margin-top: 20px;
  text-transform: uppercase;
}
@media (max-width: 640px) {
  .page-header {
    border: 0;
    padding: 12px;
    position: relative;
  }
  .page-header h1 {
    font-size: 18px;
    line-height: 24px;
    margin: 6px 0;
  }
}
#job-count {
  padding: 16px;
  font-size: 18px;
  text-align: center;
  background: #00cba5;
  color: #fff;
  font-weight: 100;
  box-sizing: border-box;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
#job-count .btn.btn-primary-reversed {
  margin-left: 12px;
  background: #fff;
  color: #00cba5;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  border-radius: 4px;
  font-size: 15px;
  line-height: 18px;
  padding: 9px 36px;
  height: 38px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.2s;
  -webkit-appearance: none;
}
#job-count .btn.btn-primary-reversed:hover {
  color: #00cba5;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.9);
}
#job-count .btn.btn-primary-reversed.tight {
  padding-left: 18px;
  padding-right: 18px;
}
#job-count .btn.btn-primary-reversed svg {
  transition-property: fill;
  transition-duration: 0.2s;
}
#job-count .btn.btn-primary-reversed.loading {
  position: relative;
}
#job-count .btn.btn-primary-reversed.loading::after {
  content: "";
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: none;
  position: absolute;
  top: 13px;
  right: 17px;
  margin: 0;
  font-size: 10px;
  text-indent: -9999em;
  border-right: 3px solid rgba(255, 255, 255, 0);
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  border-left: 3px solid #caf5ed;
  border-top: 3px solid #caf5ed;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  display: inline-block;
}
#job-count .btn.btn-primary-reversed.loading:not(.btn-large)::after {
  width: 15px;
  height: 15px;
  top: 8px;
  right: 6px;
}
#job-count:empty {
  padding: 0;
  border: none;
}
#job-count > b {
  font-weight: 500;
}
#positions-container {
  color: #4a4a4a;
  max-width: 940px;
  margin: auto;
  position: relative;
  margin-bottom: 30px;
}
#positions-container.app-positions-container {
  margin-bottom: 0;
  padding-bottom: 100px;
}
#positions-container.app-positions-container .positions-inner-container {
  border: 0;
  box-shadow: none;
}
#positions-container.featured .detail-hidden {
  display: none;
}
.position-title {
  font-weight: 500;
}
.position-company-name {
  font-weight: 300;
}
.position-location {
  font-weight: 300;
}
.positions-list .position-title {
  color: #000;
  font-size: 16px;
  line-height: 18px;
  max-width: 80%;
}
@media (max-width: 768px) {
  .positions-list .position-title {
    font-size: 14px;
  }
}
.positions-list .position-company-name {
  color: #4a4a4a;
}
.positions-list .position-location {
  color: #9b9b9b;
}
.positions-list .position-company-name,
.positions-list .position-location {
  font-size: 13px;
  line-height: 18px;
  max-width: 80%;
}
.positions-list .position-estimate-commute {
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
}
.positions-list .position-estimate-commute a {
  display: inline-block;
  color: #9b9b9b;
  border-bottom: 1px dashed #9b9b9b;
}
.positions-list .position-estimate-commute a:hover {
  text-decoration: none;
  color: #4a4a4a;
  border-bottom-color: #4a4a4a;
}
.positions-list .position-wage-shift {
  position: absolute;
  right: 24px;
  top: 8px;
  text-align: right;
}
@media (max-width: 768px) {
  .positions-list .position-wage-shift {
    right: 12px;
  }
}
.positions-list .amount,
.positions-list .unit {
  color: #162d54;
}
.positions-list .amount {
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .positions-list .amount {
    font-size: 14px;
  }
}
.positions-list .unit {
  font-size: 11px;
}
.position-start-times {
  font-size: 11px;
  line-height: 16px;
  color: #9caac0;
  margin-top: -2px;
}
.position-commute-time {
  display: inline-block;
  position: relative;
  background: #eff1f6;
  border-radius: 3px;
  padding: 0 8px 0 28px;
  height: 20px;
  box-sizing: border-box;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  color: #9b9b9b;
  font-weight: 300;
}
.position-commute-time svg {
  position: absolute;
  top: 2px;
  left: 6px;
  height: 16px;
  width: 16px;
  fill: #bbb;
  margin: 0 5px 0 1px;
}
.position-commute-time.position-transit-time svg {
  height: 14px;
}
.shift-tag {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 0 8px 0 26px;
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 300;
  line-height: 22px;
  margin-right: 4px;
  position: relative;
  box-sizing: border-box;
  height: 24px;
}
.shift-tag::before {
  position: absolute;
  display: block;
  content: "";
  width: 22px;
  height: 22px;
  top: -1px;
  left: 3px;
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: 0 0;
}
.shift-tag.day-shift,
.shift-tag.swing-shift {
  border-color: #ffcc25;
  background: #fefbf2;
}
.shift-tag.day-shift::before {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/day-shift.png");
}
.shift-tag.swing-shift::before {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/swing-shift.png");
}
.shift-tag.night-shift {
  border-color: #c2cad8;
  background: #eff1f6;
}
.shift-tag.night-shift::before {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/night-shift.png");
}
.interested-btn {
  width: 100%;
}
.position-row {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding: 12px 24px 24px;
}
.position-row:hover {
  background: #f5f5f5;
}
.position-row:hover .check-box {
  border-left: 20px solid #f5f5f5;
  border-top: 20px solid #f5f5f5;
}
.position-row .position-title {
  margin: 0;
}
.position-row .position-applied {
  font-style: italic;
  font-size: 13px;
}
.position-row .position-commute-times {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 768px) {
  .position-row {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 18px;
  }
}
.position-list {
  text-align: left;
  max-width: 1200px;
}
.position-list .inverted {
  color: #00cba5;
  background: #fff;
  font-weight: 500;
  border: 2px solid #00cba5;
  border-radius: 6px;
  outline: none;
}
.position-list .inverted::before {
  content: "";
  position: absolute;
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/checkmark-teal.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 12px;
  width: 16px;
  margin-left: -24px;
  margin-top: 4px;
}
.position-list .disabled {
  background: #ccc;
  color: #eee;
}
.position-rating {
  color: #e4b558;
  margin-top: 18px;
  margin-bottom: 4px;
}
.position-group-header {
  background-color: #eff1f6;
  padding: 15px 24px;
}
.position-group-header h4 {
  margin: 0;
}
.check-box {
  display: none;
}
.selected .check-box {
  display: block;
  position: absolute;
  background: #00cba5;
  right: 0;
  bottom: 0;
  border: 20px solid #fff;
  border-bottom: 20px solid #00cba5;
  border-right: 20px solid #00cba5;
  color: #fff;
  height: 0;
  width: 0;
  line-height: 14px;
}
.position:hover .check-box {
  border: 20px solid #f5f5f5;
  border-bottom: 20px solid #00cba5;
  border-right: 20px solid #00cba5;
}
.position-map {
  position: relative;
  height: 285px;
  width: 285px;
}
.position-map .position-map-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.position-map .position-map-commute .wsicon-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-75%);
  width: 26px;
  height: 34px;
  cursor: pointer;
  fill: #162d54;
  stroke: #162d54;
  stroke-width: 0;
}
.position-map .position-map-commute-dialog {
  position: absolute;
  height: 36px;
  width: 200px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: -50px;
  padding: 6px 0;
  box-sizing: border-box;
  color: #000;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}
.position-map .position-map-commute-dialog::after,
.position-map .position-map-commute-dialog::before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.position-map .position-map-commute-dialog::after {
  border-color: transparent;
  border-top-color: #fff;
  border-width: 5px;
  margin-left: -5px;
}
.position-map .position-map-commute-dialog::before {
  border-color: transparent;
  border-top-color: #fff;
  border-width: 12px;
  margin-left: -12px;
}
.position-map .position-map-commute-dialog p {
  margin: 10px 20px 6px;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  max-height: 36px;
}
.position-map .position-map-commute-dialog a {
  font-size: 16px;
}
.position-map .position-map-commute-dialog .wsicon-driving,
.position-map .position-map-commute-dialog .wsicon-transit {
  height: 12px;
  width: 12px;
  fill: #4a4a4a;
}
.position-map .position-map-commute-dialog .wsicon-chevron-right {
  height: 8px;
  width: 8px;
  fill: #4a4a4a;
}
.position-map .position-map-commute-dialog .position-commute-time {
  color: #162d54;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding-left: 30px;
}
.position-map .position-map-commute-dialog .position-commute-time .wsicon-driving {
  fill: #162d54;
  width: 20px;
  height: 20px;
}
.position-map .position-map-commute-dialog .position-commute-time .wsicon-transit {
  fill: #162d54;
  width: 17px;
  height: 20px;
}
.position-map .position-map-commute-dialog .position-commute-time .wsicon-chevron-down {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  fill: #000;
  position: relative;
  top: 2px;
}
.position-map .position-map-commute-dialog .position-commute-time.clickable:hover {
  color: #60ffd6;
}
.position-map .position-map-commute-dialog .position-commute-time.clickable:hover .wsicon-driving,
.position-map .position-map-commute-dialog .position-commute-time.clickable:hover .wsicon-transit {
  fill: #60ffd6;
}
@media (max-width: 768px) {
  .position-map .position-map-commute-dialog .position-commute-time {
    font-size: 12px;
  }
  .position-map .position-map-commute-dialog .position-commute-time .wsicon-driving {
    width: 17px;
    height: 16px;
    margin-top: 1px;
  }
  .position-map .position-map-commute-dialog .position-commute-time .wsicon-transit {
    width: 14px;
    height: 16px;
    margin-top: 1px;
  }
}
.position-map.mobile {
  display: none;
  position: relative;
  width: 100%;
  height: 142px;
  border-top: 1px solid #ddd;
  background: #efefef;
}
.position-map.mobile > img {
  width: 640px;
  transform: translateX(-50%);
  left: 50%;
  position: relative;
}
@media (min-width: 768px) {
  .position-aside .position-map .position-map-commute-dialog {
    height: 100px;
    margin-top: -84px;
  }
  .position-aside .position-map .position-map-commute-dialog .wsicon-chevron-right {
    fill: #00cba5;
  }
}
.position-map-mobile-container {
  margin: 18px -12px 27px;
}
.position-map-mobile-address {
  margin: 0;
  padding: 5px;
  background-color: #eff1f6;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.position-bonus.mobile {
  margin-bottom: 0;
  margin-top: -22px;
  display: none;
}
.position-bonus.mobile > span {
  margin-top: -13px;
}
.position-detail {
  display: none;
}
.position-detail .bouncer {
  position: fixed;
  top: 150px;
  left: 50%;
  margin-left: -7px;
  text-align: center;
}
@media (min-width: 620px) {
  .position-detail .bouncer {
    margin-left: -167px;
  }
}
.position-inactive .position-details {
  display: none;
}
.company-header {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.company-header h1 {
  text-align: center;
  font-size: 24px;
}
.company-header img {
  display: block;
  max-width: 400px;
  max-height: 110px;
  width: auto;
  height: auto;
}
.company-header p {
  font-size: 14px;
  color: #9b9b9b;
  text-align: center;
  margin: 0;
}
.company-header .role-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 6px;
  height: 36px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.company-header .role-container .spacer {
  width: 6px;
  height: 1px;
  display: inline-block;
}
.company-header .role-container .role {
  background: #eff1f6;
  padding: 4px 16px;
  border-radius: 18px;
  font-size: 13px;
  line-height: 18px;
  margin: 2px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
  text-decoration: none;
  color: black;
}
.company-header .role-container .role:hover {
  background: #d8dde6;
}
.company-header .role-container .role.selected,
.company-header .role-container .role.selected:hover {
  background: #00cba5;
  color: #fff;
}
@media (max-width: 768px) {
  .company-header .role-container {
    flex-direction: column;
    height: auto;
  }
}
.company-browse-all {
  display: block;
  max-width: 620px;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 20px;
  color: #9caac0;
  border: 1px solid #9caac0;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}
.company-browse-all:hover {
  color: #162d54;
  border-color: #162d54;
  text-decoration: none;
}
@media (max-width: 768px) {
  .company-browse-all {
    margin: 80px 10px 0;
  }
}
.company-share {
  position: fixed;
  left: 50%;
  top: 100px;
  margin-left: 210px;
  z-index: 1;
}
@media (max-width: 768px) {
  .company-share {
    position: absolute;
    left: auto;
    right: 10px;
    top: 10px;
    margin-left: 0;
  }
}
#filters-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}
#filters-container nav {
  display: flex;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  font-size: 14px;
  font-weight: 500;
}
#filters-container .filter-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #ddd;
  border-radius: 25px;
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 300;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}
#filters-container .filter-button:not(:last-child) {
  margin-right: 5px;
}
#filters-container .filter-button:hover,
#filters-container .filter-button:active,
#filters-container .filter-button:focus {
  color: #4a4a4a;
  text-decoration: none;
  background: #f5f5f5;
}
#filters-container .filter-button .filter-label {
  display: inline-block;
}
#filters-container .filter-button.filtered .filter-label {
  color: #00cba5;
}
#filters-container .filter-button.filtered svg {
  fill: #00cba5;
}
@media (max-width: 768px) {
  #filters-container {
    padding-left: 0;
    padding-right: 0;
    border-left: 0;
    border-right: 0;
  }
  #filters-container nav {
    box-shadow: 0 2px 12px 0 rgba(22, 45, 84, 0.12);
  }
  #filters-container .filter-button {
    flex: 1;
    font-size: 12px;
  }
  #filters-container .filter-button.filtered .filter-label {
    line-height: 14px;
  }
}
#filters-container .count {
  background: #00cba5;
  color: #fff;
  border-radius: 100px;
  padding: 0 7px;
  font-size: 14px;
  line-height: 21px;
  margin: 0 4px;
}
@media (max-width: 768px) {
  #filters-container .count {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0 0 0 4px;
  }
}
#filters-container .dropdown {
  display: inline-block;
  margin-left: 4px;
}
#filters-container .dropdown svg {
  height: 12px;
  width: 12px;
  position: relative;
  top: 2px;
}
@media (max-width: 768px) {
  #filters-container #btn-more .dropdown {
    display: none;
  }
}
#filters-container .role-container {
  padding-top: 12px;
  padding-bottom: 6px;
  height: 36px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
#filters-container .role-container .spacer {
  width: 6px;
  height: 1px;
  display: inline-block;
}
#filters-container .role-container .role {
  background: #eff1f6;
  padding: 4px 16px;
  border-radius: 18px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
}
#filters-container .role-container .role:hover {
  background: #d8dde6;
}
#filters-container .role-container .role.selected,
#filters-container .role-container .role.selected:hover {
  background: #00cba5;
  color: #fff;
}
#filter-prefs {
  padding: 20px;
  color: #fff;
  background: #00cba5;
  text-align: center;
}
#filter-prefs a {
  color: #fff;
}
#filter-prefs p {
  color: #f5f5f5;
  margin: 0;
}
#filter-prefs p a {
  text-decoration: underline;
}
#filter-prefs p a:hover {
  color: #f5f5f5;
}
#filter-prefs .filter-prefs-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}
#filter-prefs .filter-prefs-buttons a {
  flex-basis: 50%;
  font-weight: 400;
  padding: 5px;
  box-sizing: border-box;
}
#filter-prefs .filter-prefs-buttons a:hover {
  color: #fff;
  background-color: #60ffd6;
  text-decoration: none;
}
#filter-prefs .filter-prefs-buttons a:first-child {
  border-right: 1px solid #f4fcfa;
}
@media (max-width: 768px) {
  #filter-prefs {
    padding: 10px;
  }
  #filter-prefs p {
    font-size: 18px;
    font-weight: 200;
  }
  #filter-prefs .filter-prefs-buttons a {
    font-size: 21px;
  }
}
#shift-modal h4,
#more-modal h4,
#role-modal h4 {
  color: #000;
  margin-bottom: 0;
}
#shift-modal hr,
#more-modal hr,
#role-modal hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #ddd;
}
#shift-modal .description,
#more-modal .description,
#role-modal .description {
  color: #9b9b9b;
  max-width: 300px;
  margin: 0;
  margin-top: -16px;
}
@media (min-width: 768px) {
  #shift-modal .description,
  #more-modal .description,
  #role-modal .description {
    padding-left: 48px;
  }
}
#shift-modal .checkbox-single > label,
#more-modal .checkbox-single > label,
#role-modal .checkbox-single > label {
  border: none;
}
#shift-modal .shift-icon,
#more-modal .shift-icon,
#role-modal .shift-icon {
  position: absolute;
  right: 20px;
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/day-shift.png");
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  height: 40px;
  width: 40px;
  top: 14px;
}
@media (max-width: 768px) {
  #shift-modal .shift-icon,
  #more-modal .shift-icon,
  #role-modal .shift-icon {
    display: none;
  }
}
#shift-modal .shift-icon.swing-shift,
#more-modal .shift-icon.swing-shift,
#role-modal .shift-icon.swing-shift {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/swing-shift.png");
}
#shift-modal .shift-icon.night-shift,
#more-modal .shift-icon.night-shift,
#role-modal .shift-icon.night-shift {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/night-shift.png");
}
#shift-modal .wage-container,
#more-modal .wage-container,
#role-modal .wage-container {
  height: 100px;
}
#shift-modal .wage-container p,
#more-modal .wage-container p,
#role-modal .wage-container p {
  font-size: 14px;
  margin-bottom: 30px;
}
#shift-modal .wage-container .ws-range,
#more-modal .wage-container .ws-range,
#role-modal .wage-container .ws-range {
  width: 90%;
  margin: 0 auto;
  padding-right: 10px;
}
#shift-modal,
#more-modal,
#role-modal,
#commute-modal {
  padding-bottom: 100px;
}
#shift-modal .btn-primary,
#more-modal .btn-primary,
#role-modal .btn-primary,
#commute-modal .btn-primary {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
#job-categories-cloud,
#job-cities-cloud {
  max-width: 940px;
  margin: 0 auto;
}
#job-categories-cloud::after,
#job-cities-cloud::after {
  content: "";
  display: table;
  clear: both;
}
#job-categories-cloud .job-categories-container,
#job-cities-cloud .job-categories-container,
#job-categories-cloud .job-cities-container,
#job-cities-cloud .job-cities-container {
  max-width: 620px;
}
#job-categories-cloud strong,
#job-cities-cloud strong {
  display: block;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
#job-categories-cloud a,
#job-cities-cloud a {
  display: block;
  width: 25%;
  float: left;
  color: #4a4a4a;
  font-size: 14px;
}
@media (max-width: 768px) {
  #job-categories-cloud,
  #job-cities-cloud {
    padding-left: 12px;
    padding-right: 12px;
  }
  #job-categories-cloud strong,
  #job-cities-cloud strong {
    text-align: center;
  }
  #job-categories-cloud a,
  #job-cities-cloud a {
    width: 50%;
    margin-bottom: 10px;
  }
}
#job-cities-cloud {
  padding-top: 30px;
}
@media (max-width: 640px) {
  .position-map.mobile,
  .position-bonus.mobile {
    display: block;
  }
  .position-detail .heading > .position-rating {
    display: none;
  }
}
@media (max-width: 880px) {
  .position-map {
    display: none;
  }
}
.list-welcome .sohp__jobs-cards {
  margin-top: 28px;
}
.list-welcome .sohp__jobs-cards .job-card--new {
  transition: box-shadow 100ms linear;
  box-shadow: 0 2px 11px 2px rgb(22 45 4.66666667%);
}
.list-welcome .sohp__jobs-cards .job-card--new:hover {
  box-shadow: 0 2px 11px 1px rgb(22 45 1.90909091%);
}
.list-welcome .sohp__jobs-cards:not(.slick-initialized) {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.list-welcome .sohp__jobs-cards:not(.slick-initialized) .job-card--new {
  margin: 10px;
}
@media (min-width: 768px) {
  .list-welcome .sohp__jobs-cards {
    width: 596px;
    margin-left: -4px;
  }
  .list-welcome .sohp__jobs-cards .job-card--new {
    width: 300px !important;
  }
  .list-welcome .sohp__jobs-cards .slick-list {
    padding: 10px 20px;
    margin-left: -20px;
  }
  .list-welcome .sohp__jobs-cards .slick-slide:not(:last-child) {
    margin-right: 20px;
  }
}
@media (max-width: 768px) {
  .list-welcome .sohp__jobs-cards {
    position: static;
    margin-top: 20px;
  }
  .list-welcome .sohp__jobs-cards .job-card--new {
    width: 272px !important;
  }
  .list-welcome .sohp__jobs-cards .slick-list {
    margin: 0 -24px;
    padding: 10px;
  }
  .list-welcome .sohp__jobs-cards .slick-slide:not(:last-child) {
    margin-right: 10px;
  }
}
.list-welcome .sohp__jobs-card-all {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 442px;
  padding: 100px 40px 35px;
  text-align: center;
  cursor: auto;
}
.list-welcome .sohp__jobs-card-all svg {
  width: 60px;
  height: 60px;
  fill: #9caac0;
}
.list-welcome .sohp__jobs-card-all .sohp__industries {
  flex-wrap: wrap;
  justify-content: center;
}
.list-welcome .sohp__jobs-card-all .sohp__industries a {
  color: #9caac0;
  font-size: 13px;
  line-height: 21px;
}
.list-welcome .sohp__jobs-card-all .sohp__industries span::after {
  color: #9caac0;
}
.list-welcome .sohp__jobs-card-all .sohp__industries span:nth-child(3)::after {
  display: none;
}
@media (max-width: 768px) {
  .list-welcome .sohp__jobs-card-all {
    height: 425px;
  }
  .list-welcome .sohp__jobs-card-all .sohp__industries span:nth-child(odd)::after {
    display: inline;
  }
  .list-welcome .sohp__jobs-card-all .sohp__industries span:nth-child(even)::after {
    display: none;
  }
}
.list-welcome .sohp__jobs-card-all-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-welcome .sohp__jobs-card-link {
  margin-top: 10px;
  color: #9caac0;
  font-size: 21px;
  line-height: 27px;
  font-weight: 400;
  text-transform: uppercase;
}
.list-welcome .sohp__jobs-card-link:hover {
  color: #9caac0;
}
.list-welcome .sohp__slick-dots {
  display: block;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
.list-welcome .sohp__slick-dots li {
  display: inline-block;
}
.list-welcome .sohp__slick-dots li button {
  margin: 10px 5px;
  padding: 5px;
  height: 8px;
  width: 8px;
  background-color: #c2cad7;
  border-radius: 50%;
  font-size: 0;
  border: none;
  outline: none;
  cursor: pointer;
}
.list-welcome .sohp__slick-dots li.slick-active button {
  height: 12px;
  width: 12px;
  background-color: #00cba5;
}
.list-welcome .see-more-jobs {
  padding: 0 24px 24px;
  background: #fff;
  font-size: 18px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  color: #162d54;
}
@media (max-width: 768px) {
  .list-welcome .see-more-jobs {
    padding: 0 12px 24px;
  }
}
.list-welcome .see-more-jobs strong {
  font-weight: 700;
}
.list-welcome__page-header {
  background: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  padding: 28px 24px;
}
.list-welcome__page-header .get-hired {
  color: #3d5afe;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}
.list-welcome__page-header h1 {
  font-size: 34px;
  font-weight: 700;
  margin-top: 4px;
  margin-bottom: 24px;
}
.detail {
  padding: 21px 48px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .detail {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.detail-item.expanding {
  line-height: 26px;
  max-height: 76px;
  overflow: hidden;
  position: relative;
}
.detail-item.expanding .expand-btn {
  display: none;
  position: absolute;
  bottom: -3px;
  right: 0;
  background: #fff;
  padding-left: 20px;
  padding-right: 33px;
  color: #00cba5;
  cursor: pointer;
}
.detail-item.expanding .expand-btn::before {
  height: 26px;
  width: 40px;
  display: inline-block;
  content: " ";
  position: absolute;
  left: -40px;
  background: #fff;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
}
.detail-benefits span:not(:last-child)::after {
  content: ", ";
}
.position-detail {
  max-width: 940px;
  margin: auto;
  position: relative;
  text-align: left;
  color: #4a4a4a;
}
.position-detail h1 {
  margin: 0;
  line-height: 24px;
}
.position-detail .position-key-info,
.position-detail .amount,
.position-detail .unit {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
}
.position-detail .position-details a {
  color: #4a4a4a;
  text-decoration: none;
}
.position-detail .position-detail-column .position-title {
  font-size: 24px;
  line-height: 30px;
  display: block;
  margin-bottom: -4px;
}
.position-detail .position-detail-column .position-company-name,
.position-detail .position-detail-column .position-location {
  position: relative;
  padding-left: 24px;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  color: #4a4a4a;
}
.position-detail .position-detail-column .position-company-name::before,
.position-detail .position-detail-column .position-location::before {
  content: "";
  width: 16px;
  height: 14px;
  position: absolute;
  top: 50%;
  margin-top: -7px;
}
.position-detail .position-company-name {
  margin-right: 12px;
}
.position-detail .position-company-name::before {
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/icon-company.svg") no-repeat;
  left: 0;
}
.position-detail .position-location::before {
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/icon-pin.svg") no-repeat;
  left: 4px;
}
.position-detail ul {
  padding-left: 20px;
  margin-bottom: 34px;
  margin-top: 16px;
}
.position-detail li {
  line-height: 24px;
  margin-bottom: 3px;
}
.position-detail.position-inactive .back {
  position: absolute;
  top: 39px;
}
.position-detail:not(.position-inactive) .back {
  position: fixed;
  top: 96px;
}
.position-detail .back .back-inner {
  max-width: 940px;
  margin-left: -50px;
  height: 24px;
  width: 24px;
}
.position-detail .back svg {
  opacity: 0.5;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.position-detail .back:hover svg {
  opacity: 1;
}
.position-detail table {
  font-size: 16px;
}
.position-detail table td {
  padding: 0;
  vertical-align: top;
}
.position-detail table td:nth-child(1) {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #bbb;
  padding-right: 32px;
}
.position-detail .position-description {
  white-space: pre-wrap;
  margin-top: 20px;
}
.position-detail p {
  margin: 0;
}
.position-detail h5 {
  margin-bottom: 5px;
}
.position-detail pre {
  font-family: "Inter", sans-serif;
  white-space: pre-wrap;
  margin: 0;
}
.position-detail div.heading,
.position-detail p.heading {
  text-transform: uppercase;
  color: #000;
  font-weight: 300;
  margin: 16px 0;
}
.position-detail div.heading > div,
.position-detail p.heading > div {
  display: inline;
  margin-left: 10px;
}
.position-detail .detail-item,
.position-detail p.detail-item {
  margin-bottom: 48px;
  line-height: 24px;
}
.position-detail .interested-btn {
  line-height: 18px;
}
.position-detail .position {
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 20px;
  padding-bottom: 23px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 3px;
  cursor: initial;
}
.position-detail .position .btn {
  position: absolute;
  right: 4px;
  top: 4px;
}
.position-detail .benefit {
  display: inline-block;
  padding-top: 36px;
  margin-right: 14px;
  font-weight: 300;
}
.position-detail .benefit.benefit-health {
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/benefit-health.png");
}
.position-detail .benefit.benefit-dental {
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/benefit-dental.png");
}
.position-detail .benefit.benefit-vision {
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/benefit-vision.png");
}
.position-detail .benefit.benefit-health,
.position-detail .benefit.benefit-dental,
.position-detail .benefit.benefit-vision {
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center 0;
}
.position-detail .position-aside {
  position: fixed;
  left: 50%;
  top: 67px;
  margin-left: 185px;
}
.position-detail .position-aside h3 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.position-detail .position-aside button,
.position-detail .position-aside a {
  border-radius: 19px;
}
.position-detail .position-aside button.btn-large,
.position-detail .position-aside a.btn-large {
  border-radius: 24px;
}
.position-detail .position-aside button.btn-small,
.position-detail .position-aside a.btn-small {
  border-radius: 15px;
}
.position-detail .position-aside .apply-holder {
  margin-bottom: 48px;
  text-align: center;
}
.position-detail .position-aside .apply-holder a:nth-child(2) {
  text-transform: uppercase;
  display: block;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .position-detail .position-aside {
    top: initial;
    bottom: 0;
    padding: 8px 0;
    left: 0;
    margin-left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    background-color: #fff;
  }
  .position-detail .position-aside h3,
  .position-detail .position-aside .position-map {
    display: none;
  }
  .position-detail .position-aside .apply-holder {
    width: 100%;
    display: flex;
    margin: 0 20px;
  }
  .position-detail .position-aside .apply-holder .btn-large {
    padding: 14px 20px;
    margin-right: 20px;
  }
}
.position-detail .position-bonus {
  background: #fef9ed;
  padding: 20px 48px;
  margin: -10px -48px 36px -48px;
}
.position-detail .position-bonus h5 {
  color: #edaf1f;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.position-detail .position-bonus > b {
  font-weight: 700;
}
.position-detail .detail-type {
  font-size: 10px;
  display: inline;
  background: #f5f5f5;
  padding: 5px 8px;
  border-radius: 3px;
  margin-left: 6px;
}
.position-detail .position-detail-column {
  background: #fff;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .position-detail.position-inactive .back,
  .position-detail:not(.position-inactive) .back {
    position: absolute;
    top: 21px;
    left: 12px;
  }
  .position-detail.position-inactive .back .back-inner,
  .position-detail:not(.position-inactive) .back .back-inner {
    width: 20px;
    height: 36px;
    max-width: none;
    margin-left: 0;
  }
  .position-detail .position-key-info,
  .position-detail .amount,
  .position-detail .unit {
    font-size: 14px;
    line-height: 18px;
  }
  .position-detail h5 {
    margin-bottom: -4px;
  }
  .position-detail table td {
    padding-bottom: 6px;
  }
  .position-detail table td:nth-child(1) {
    font-size: 12px;
    line-height: 18px;
    min-width: 60px;
    padding-right: 0;
  }
  .position-detail .position {
    padding: 16px 16px 12px 44px;
  }
  .position-detail .position-title {
    font-size: 18px;
    line-height: 21px;
  }
  .position-detail .position-company-name,
  .position-detail .position-location {
    font-size: 14px;
    line-height: 16px;
  }
  .position-detail .detail-item {
    margin-bottom: 40px;
  }
  .position-detail .position-company-desc {
    margin-top: 16px;
  }
  .interested-container {
    padding: 8px;
    padding-top: 9px;
    padding-left: 44px;
    width: 100%;
    border-right: 2px solid #ddd;
  }
  #job-count .btn.btn-primary-reversed {
    margin-top: 8px;
  }
}
.position-inactive__details-button-container {
  text-align: center;
  margin-top: 50px;
}
.position-detail-inactive__container {
  max-width: 980px;
  margin: 0 auto;
}
.position-detail-inactive__container hr {
  border: 1px solid #9b9b9b;
  border-bottom: 0;
  margin: 55px auto;
}
.position-detail-inactive__container__joblist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.position-detail-inactive__container__joblist .job-card {
  flex: 0 0 320px;
  width: 320px;
  height: 176px;
  margin: 0;
  overflow: hidden;
}
.position-detail-inactive__container__joblist .job-card .job-card-map {
  display: none;
}
@media (max-width: 768px) {
  .position-detail-inactive__container__joblist {
    flex-direction: column;
  }
  .position-detail-inactive__container__joblist .job-card {
    flex-basis: auto;
    height: auto;
    width: 100%;
    margin: 5px 0;
  }
}
.position-detail-inactive__section {
  margin-top: 48px;
}
.position-detail-inactive__section h2 {
  font-weight: 300;
}
@media (max-width: 768px) {
  .position-detail-inactive__section {
    margin: 0 10px;
  }
}
.position-detail-inactive__cta {
  max-width: 620px;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
  background-color: #fef9ed;
  text-align: center;
  font-size: 18px;
}
@media (max-width: 768px) {
  .position-detail-inactive__cta {
    width: 100%;
    padding: 25px;
    font-size: 14px;
  }
}
.position-detail-inactive__cta__success {
  line-height: 24px;
}
.position-detail-inactive__cta__success svg {
  display: block;
  margin: 0 auto 20px;
  width: 40px;
  height: 40px;
  fill: #00cba5;
}
.position-detail-inactive__cta__email {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.position-detail-inactive__cta__email input {
  flex: 0 0 300px;
  margin: 0 -2px 0 2px;
  padding-left: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
}
.position-detail-inactive__cta__email input:active,
.position-detail-inactive__cta__email input:focus {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
}
.position-detail-inactive__cta__email input.has-error {
  border: 1px solid #e86232;
  margin-bottom: 0;
}
.position-detail-inactive__cta__email input.has-error:active,
.position-detail-inactive__cta__email input.has-error:focus,
.position-detail-inactive__cta__email input.has-error:hover {
  border: 1px solid #e86232;
}
.position-detail-inactive__cta__email .btn.btn-primary {
  flex: 0 0 140px;
  height: 46px;
  padding: 9px 20px;
}
@media (max-width: 768px) {
  .position-detail-inactive__cta__email input {
    flex-basis: 60%;
  }
  .position-detail-inactive__cta__email .btn.btn-primary {
    flex-basis: 40%;
    padding: 9px 10px;
  }
}
.position-detail-inactive__browse {
  display: block;
  box-sizing: border-box;
  margin-top: 80px;
  padding: 20px;
  color: #9caac0;
  border: 1px solid #9caac0;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  transition: all 100ms ease-in;
}
.position-detail-inactive__browse:hover {
  color: #162d54;
  border-color: #162d54;
  text-decoration: none;
}
@media (max-width: 768px) {
  .position-detail-inactive__browse {
    margin: 80px 10px 0;
  }
}
.schedule #checklist {
  position: fixed;
  bottom: 18px;
  right: 3%;
  padding: 14px 20px;
  z-index: 1;
}
.schedule .summary {
  font-weight: 300;
  overflow-x: hidden;
}
.schedule .summary.canceled {
  opacity: 0.5;
}
.schedule .warning {
  color: #edaf1f;
  font-weight: 500;
}
.schedule #summary-container > .notice {
  background: #e0f9f4;
  text-align: center;
  padding: 20px;
  font-weight: 300;
  color: #4a4a4a;
}
.schedule #summary-container > .notice a {
  font-weight: 500;
}
.schedule #summary-container > .notice p {
  margin-bottom: 0;
}
.schedule #summary-container .notice-success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  text-align: left;
}
.schedule #summary-container .notice-success svg {
  fill: #00cba5;
  width: 24px;
  height: 24px;
  margin-right: 7px;
}
.schedule #summary-container .notice-success span {
  font-weight: 500;
}
@media (max-width: 768px) {
  .schedule #summary-container .notice-success {
    font-size: 15px;
  }
}
.schedule #summary-container #unverified-phone {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.schedule #summary-container #unverified-phone span {
  font-weight: 300;
  font-size: 14px;
  color: #4a4a4a;
}
.schedule #summary-container #unverified-phone a {
  padding: 15px 100px 13px;
}
.schedule #summary-container #unverified-phone #phone {
  max-width: 400px;
  margin: 10px 0 30px;
}
.schedule #summary-container #unverified-phone .phone-error {
  display: none;
  color: #e86232;
  margin-top: -25px;
  padding-bottom: 25px;
}
.schedule #summary-container #verified-thanks {
  display: none;
}
.schedule #summary-container .position-map {
  width: 100%;
  margin: 0;
}
.schedule #summary-container .position-map .position-map-image {
  background-position: 50%;
}
.schedule .position-summary {
  text-align: center;
  padding: 20px;
}
.schedule .position-summary h2 {
  margin-bottom: 4px;
}
.schedule .position-summary a {
  display: block;
  margin: 14px 0;
  text-transform: uppercase;
  font-weight: 300;
  color: #bbb;
}
.schedule .date-summary,
.schedule .address-summary {
  position: relative;
  padding: 16px;
  padding-left: 50px;
  border-top: 1px solid #ddd;
}
.schedule .date-summary::before,
.schedule .address-summary::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 16px;
  left: 18px;
  top: 20px;
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/calendarIcon.svg");
  background-repeat: no-repeat;
}
.schedule .address-summary::before {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/icon-pin.svg");
}
.schedule .travel-summary {
  padding: 20px;
  padding-top: 0;
  min-height: 82px;
}
.schedule .travel-summary .bouncer {
  margin: 22px auto;
}
.schedule .travel-summary span {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  color: #bbb;
}
.schedule .travel-summary h4 {
  margin: 16px 0;
}
.schedule .travel-summary hr {
  border: none;
  border-top: 1px solid #ddd;
}
.schedule .travel {
  display: none;
}
.schedule .schedule-actions {
  text-align: center;
  background: #f9f9f9;
  box-shadow: 1px 4px 12px 0 #000;
  text-transform: uppercase;
  color: #4a4a4a;
  cursor: pointer;
}
.schedule .schedule-actions div {
  min-height: 62px;
}
.schedule .schedule-actions div:hover {
  background: #f1f1f1;
}
.schedule .schedule-actions .reschedule {
  width: 50%;
  float: left;
  border-right: 1px solid #ddd;
}
.schedule .schedule-actions .reschedule.disabled {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.schedule .schedule-actions .reschedule.disabled:hover {
  cursor: default;
  background: #f9f9f9;
}
.schedule .schedule-actions img {
  display: block;
  margin: auto;
}
.schedule .schedule-actions span {
  top: -6px;
  font-size: 14px;
  position: relative;
}
.schedule #interview-checklist-container {
  box-sizing: border-box;
  max-width: 560px;
  margin: 0 auto;
}
.schedule #interview-checklist-container h2 {
  color: #162d54;
  font-size: 30px;
  margin-top: 48px;
  margin-bottom: 12px;
}
.schedule #interview-checklist-container .warning {
  font-weight: 300;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .schedule #interview-checklist-container h2,
  .schedule #interview-checklist-container > p {
    padding: 0 18px;
  }
}
.schedule #interview-checklist {
  list-style: none;
  padding-left: 0;
}
.schedule .checklist-item {
  padding: 30px 40px 12px 80px;
  border-top: 1px solid #ddd;
  position: relative;
  text-align: left;
}
.schedule .checklist-item::after {
  content: "";
  display: table;
  clear: both;
}
.schedule .checklist-item img {
  width: 50px;
  position: absolute;
  top: 30px;
  left: 10px;
}
.schedule .checklist-item .item-desc {
  margin-top: 2px;
}
.schedule .position-detail .interested-btn {
  display: none;
}
.schedule .section {
  padding: 20px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-size: 15px;
}
.schedule .portal-schedule .section {
  padding: 0;
}
.schedule .section-title {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0;
}
.schedule .section-subtitle {
  margin: 20px 32px;
  text-align: center;
}
.schedule__success-links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: #666;
}
.schedule__success-links a {
  margin-bottom: 20px;
}
.schedule__success-links a:last-child {
  font-weight: 300 !important;
}
.schedule__action-links {
  display: flex;
  align-items: center;
}
.schedule__action-links a {
  color: #666;
  font-size: 14px;
}
.schedule__action-links span {
  margin: 0 10px;
}
.schedule-disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schedule-disabled img {
  height: 200px;
  width: 200px;
  padding: 30px 30px 0;
}
.schedule-disabled h2 {
  color: #000;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  padding: 0 40px;
}
.schedule-disabled p {
  text-align: center;
  padding: 0 20px;
}
.schedule-disabled a {
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px 0 80px;
}
@media (min-width: 768px) {
  .schedule-disabled {
    padding: 0 180px;
  }
}
.auto-apply_notice {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  background-color: #eff2ff;
  text-align: left;
}
.auto-apply_notice svg {
  fill: #3d5afe;
  width: 50px;
  height: 50px;
  margin-right: 7px;
}
@media (max-width: 768px) {
  .auto-apply_notice {
    font-size: 15px;
  }
}
.schedule__warning {
  padding: 20px;
  color: #edaf1f;
  background-color: #fef9ed;
}
@media (min-width: 768px) {
  .schedule__warning {
    text-align: center;
  }
  .schedule__warning > span {
    display: block;
  }
}
@media (max-width: 768px) {
  .schedule__warning {
    padding: 10px;
  }
}
#nav {
  z-index: 1000;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  background-color: #162d54;
  height: 54px;
  line-height: 24px;
}
#nav .nav-container {
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#nav .nav-container .dropdown .dropdown-menu {
  width: 150px;
  left: auto;
  display: none;
}
#nav .nav-container .dropdown .dropdown-menu a {
  text-align: left;
  color: #000;
  margin-left: 0;
  height: 24px;
  line-height: 24px;
}
#nav .nav-container .dropdown.active .dropdown-menu {
  display: block;
}
#nav .nav-container svg {
  fill: #fff;
  opacity: 1;
}
.body--new-ui-check-in #nav .nav-container svg {
  fill: currentColor;
  margin-right: 4px;
}
#nav .nav-container .wsicon-home {
  width: 20px;
}
#nav .nav-container .wsicon-search {
  width: 18px;
  height: 16px;
}
#nav .nav-container .wsicon-cards {
  width: 18px;
}
#nav .nav-container .wsicon-profile {
  width: 17px;
}
#nav .nav-container .wsicon-more {
  height: 14px;
}
#nav.white-nav .nav-link {
  color: #4a4a4a;
}
#nav.white-nav .nav-link:hover {
  color: #4a4a4a;
  border-bottom-color: #4a4a4a;
}
#nav.white-nav .employers-link {
  color: #4a4a4a;
  height: 54px;
  line-height: 32px;
  border: 0;
  border-bottom: 5px solid transparent;
  border-radius: 0;
}
#nav.white-nav .employers-link:hover {
  color: #4a4a4a;
  border-bottom-color: #4a4a4a;
}
#nav.white-nav .logo-navy {
  display: block;
}
#nav.white-nav .logo-standard {
  display: none;
}
#nav.white-nav .nav__right-join {
  color: #4a4a4a;
  border-color: #4a4a4a;
}
#nav.white-nav .nav__right-join:hover {
  color: #000;
  border-color: #000;
}
#nav.white-nav.blog .logo-blog {
  display: block;
}
#nav.white-nav.blog .logo-navy {
  display: none;
}
#nav .employers-link {
  font-weight: 300;
  line-height: 16px;
  text-transform: none;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  margin-left: 10px;
}
#nav .logo-link {
  display: inline-block;
  line-height: 0;
}
#nav .logo {
  margin: 16px 20px;
}
@media (max-width: 768px) {
  #nav .logo {
    margin: 0;
  }
}
#nav .logo-navy,
#nav .logo-blog {
  display: none;
}
#nav .nav__left {
  display: flex;
}
#nav .nav__links {
  display: flex;
  align-items: center;
}
#nav .nav__right {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
@media (max-width: 768px) {
  #nav .nav__right {
    margin: 0;
  }
}
#nav .nav-link {
  border-bottom: 5px solid transparent;
  padding: 0 10px;
  cursor: pointer;
  color: #ddd;
  text-decoration: none;
  font-weight: 300;
  position: relative;
}
#nav .nav-link:hover {
  color: #fff;
  border-bottom-color: #fff;
}
#nav .nav-link .wsicon-chevron-down {
  width: 8px;
  height: 8px;
}
@media (min-width: 768px) {
  #nav .nav-link {
    height: 49px;
    line-height: 49px;
  }
}
#nav .email-link {
  float: left;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
#nav.lp-nav {
  background-color: transparent;
  margin-top: 10px;
}
#nav.lp-nav.lp-nav-blue {
  background-color: #162d54;
  margin: 0;
}
#nav.lp-nav .nav-link {
  height: auto;
  padding: 0 3px;
  margin-right: 20px;
  font-size: 13px;
  line-height: 31px;
}
#nav.lp-nav .nav__right-join {
  box-sizing: border-box;
  width: 80px;
  padding-left: 14px;
  padding-right: 14px;
  margin-right: 30px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-transform: uppercase;
}
#nav.lp-nav .nav__left .nav-link {
  line-height: 12px;
  padding: 8px 3px;
  height: 12px;
  margin-top: 10px;
}
#nav.lp-nav .nav__left .nav-link.active {
  border-bottom-color: #00cba5;
}
@media (max-width: 768px) {
  #nav.lp-nav .logo {
    width: 120px;
  }
  #nav.lp-nav .dropdown {
    margin-right: 10px;
  }
  #nav.lp-nav .nav-link {
    margin-right: 10px;
  }
  #nav.lp-nav .nav-link:last-child {
    margin-right: 0;
  }
  #nav.lp-nav .nav-container .dropdown {
    display: block;
  }
}
@media (max-width: 768px) {
  #nav {
    padding: 14px;
  }
  #nav #how-it-works-link {
    display: none;
  }
  #nav .email-link {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
body.sohp #nav .employers-link {
  border-bottom: 3px solid #00cba5;
}
body.standard-nav {
  padding-top: 54px;
}
body.standard-nav #nav {
  position: fixed;
}
body.standard-nav #nav .nav-link {
  color: #fff;
  font-size: 14px;
}
body.standard-nav #nav .nav-link.active {
  border-bottom-color: #00cba5;
  font-weight: 500;
}
body.standard-nav #nav .nav-link:hover {
  border-bottom-color: #9caac0;
}
body.standard-nav #nav .nav-container .nav-desktop,
body.standard-nav #nav .nav-container .nav-mobile {
  display: none;
}
body.standard-nav #nav .nav-container .dropdown {
  align-items: center;
  margin-right: 18px;
}
body.standard-nav #nav .nav-container .dropdown .login-link-name {
  padding-right: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 48px;
}
@media (max-width: 320px) {
  body.standard-nav #nav .nav-container .dropdown {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  body.standard-nav #nav .nav-container .dropdown-user {
    position: absolute;
    right: 4px;
    top: 7px;
    margin: 0;
  }
  body.standard-nav #nav .nav-container .dropdown-user svg {
    width: 20px;
    height: 14px;
    fill: #ddd;
  }
  body.standard-nav #nav .nav-container .dropdown-user .dropdown-menu {
    top: 25px;
    left: auto;
  }
}
@media (max-width: 768px) {
  body.standard-nav.candidate-nav #nav {
    padding: 0;
    width: 100vw;
  }
}
body.standard-nav.candidate-nav #nav .nav-container .nav-desktop {
  display: flex;
  align-items: center;
  height: 54px;
}
body.standard-nav.candidate-nav #nav .nav-container .nav-desktop .dropdown {
  display: flex;
}
body.standard-nav.candidate-nav #nav .nav-container .nav-desktop .nav-break {
  border-right: 1px solid #9caac0;
  opacity: 0.2;
  margin: 0 10px;
  height: 60%;
}
@media (max-width: 768px) {
  body.standard-nav.candidate-nav #nav .nav-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 72px;
    margin: 0;
  }
  body.standard-nav.candidate-nav #nav .nav-container.fixed {
    position: fixed;
  }
  body.standard-nav.candidate-nav #nav .nav-container .logo {
    width: 108px;
  }
  body.standard-nav.candidate-nav #nav .nav-container .logo-link {
    display: flex;
    height: 30px;
    margin-left: 0;
    margin-right: 0;
  }
  body.standard-nav.candidate-nav #nav .nav-container .nav__left {
    align-items: center;
  }
  body.standard-nav.candidate-nav #nav .nav-container .nav-mobile {
    width: 100vw;
    display: flex;
    padding: 0;
    height: 42px;
    box-sizing: border-box;
  }
  body.standard-nav.candidate-nav #nav .nav-container .nav-mobile svg {
    height: 20px;
    fill: #ddd;
    opacity: 0.4;
  }
  body.standard-nav.candidate-nav #nav .nav-container .nav-mobile a {
    position: relative;
    border-bottom: 3px solid transparent;
    height: 100%;
    width: 25%;
    text-align: center;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  body.standard-nav.candidate-nav #nav .nav-container .nav-mobile a.active {
    border-bottom-color: #00cba5;
  }
  body.standard-nav.candidate-nav #nav .nav-container .nav-mobile a.active svg {
    fill: #fff;
    opacity: 1;
  }
}
@media (max-width: 768px) {
  body.standard-nav.candidate-nav {
    padding-top: 72px;
  }
  body.standard-nav.candidate-nav #nav {
    height: 72px;
  }
}
body.body-full-height {
  height: 100%;
}
body.ln-bg {
  background-color: #eff1f6;
}
body.app-fixed-nav #nav {
  position: fixed;
}
body.app-fixed-nav #progress-bar {
  position: fixed;
  z-index: 1;
}
@media (max-width: 768px) {
  body.no-mobile-nav.standard-nav,
  body.no-mobile-nav.standard-nav.candidate-nav {
    padding-top: 0;
  }
  body.no-mobile-nav #nav {
    display: none;
  }
  body.no-mobile-footer .footer {
    display: none;
  }
}
.nav__checkout {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 30px;
}
.nav__checkout svg {
  fill: #fff;
  width: 18px;
  height: 18px;
}
.nav__checkout-count {
  position: absolute;
  top: 4px;
  right: 2px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #00cba5;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}
.nav__checkout-mobile {
  position: absolute;
  top: 0;
  right: 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #00cba5;
  padding: 7px;
}
.nav__checkout-mobile svg {
  width: 14px;
  height: 14px;
  fill: #fff;
  margin-right: 5px;
}
@media (min-width: 768px) {
  .nav__checkout-mobile {
    display: none;
  }
}
.nav__checkout-count-mobile {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
}
.nav__user {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #9caac0;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
}
.nav__user:hover {
  color: #fff;
  text-decoration: none;
  border-color: #ddd;
  transition: border-color 100ms linear;
}
.nav__dropdown-arrow svg {
  position: relative;
  top: 2px;
  width: 15px;
  height: 15px;
  padding-left: 5px;
  fill: #fff;
}
.nav__badge {
  opacity: 0;
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: #00cba5;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}
.nav__badge--visible {
  opacity: 1;
  transition: opacity 200ms linear;
  font-weight: 300;
}
.nav__badge--mobile {
  position: absolute;
  top: 4px;
  right: 20px;
  color: #fff;
}
.nav__dropdown {
  position: relative;
}
.nav__dropdown > a {
  display: inline-block;
  border-bottom: 5px solid transparent !important;
}
.nav__dropdown:hover:before {
  content: "";
  position: absolute;
  border: 8px solid transparent;
  top: 18px;
  left: 21px;
  border-bottom-color: #fff;
}
.lp-nav-blue .nav__dropdown:hover:before {
  top: 20px;
}
.nav__dropdown-content {
  display: none;
  position: absolute;
  background: #fff;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
}
.nav__dropdown-content a {
  display: block;
  margin: 11px;
  color: #4a4a4a;
}
.nav__dropdown:hover .nav__dropdown-content,
.nav__dropdown-content:hover {
  display: block;
}
.nav__hamburger {
  position: relative;
  padding-left: 20px;
}
.nav__hamburger.active:before {
  content: "";
  position: absolute;
  border: 8px solid transparent;
  top: 21px;
  left: 21px;
  border-bottom-color: #eff1f6;
}
.nav__hamburger .wsicon-hamburger {
  height: 21px;
  width: 18px;
}
.nav__hamburger-menu {
  display: none;
  position: absolute;
  background: #fff;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  min-width: 220px;
  right: 0;
  margin-top: 10px;
}
.nav__hamburger-menu a {
  display: block;
  margin: 11px;
  color: #4a4a4a;
}
.nav__hamburger-menu a {
  font-size: 13px;
}
.nav__hamburger-menu span {
  color: #666;
  width: 100%;
  display: block;
  background: #eff1f6;
  padding: 8px 10px;
  box-sizing: border-box;
}
.nav__hamburger-menu span.nav__hamburger-divider {
  padding: 0;
  height: 1px;
  background-color: #eff1f6;
}
.nav__hamburger-menu .submenu {
  padding-left: 20px;
}
.nav__hamburger.active .nav__hamburger-menu {
  display: block;
}
.footer {
  margin: 60px 0 0;
  padding: 16px 18px;
  border-top: 1px solid #ccc;
}
.footer .footer-inner {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.footer .footer-logo {
  position: relative;
  flex: 0 0 160px;
  top: 3px;
}
.footer .footer-logo img {
  width: 140px;
  height: auto;
  margin: 0 auto;
  display: block;
}
.footer .footer-links {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 18px;
}
.footer .footer-link {
  font-size: 14px;
  margin: 0 6px;
  color: #4a4a4a;
}
.footer .footer-link:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .footer .footer-inner {
    flex-direction: column;
  }
  .footer .footer-links {
    order: 1;
    display: block;
    align-items: initial;
    text-align: center;
    margin-bottom: 38px;
    padding-left: 0;
  }
  .footer .footer-logo {
    top: initial;
    order: 2;
    flex-basis: 20px;
    margin-bottom: 12px;
  }
  .footer .footer-logo img {
    width: 156px;
  }
  .footer.sohp .footer-logo {
    display: none;
  }
}
.minimal {
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 5px;
  width: 100%;
  padding: 8px 10px 12px 0;
  transition-property: color, border-color;
  transition-duration: 0.2s;
}
.minimal::-webkit-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
.minimal:-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
.minimal::-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
.minimal:-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
.minimal::-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
.minimal:invalid {
  box-shadow: none;
}
.minimal:disabled {
  color: #9b9b9b;
  cursor: not-allowed;
}
.minimal:disabled:hover {
  border-color: #bbb;
}
.minimal:disabled:active {
  color: #9b9b9b;
  margin-bottom: 0;
  border-bottom: 1px solid #bbb;
}
.minimal:disabled:active:hover {
  border-color: #bbb;
}
.minimal:hover {
  border-color: #9b9b9b;
}
.minimal:focus,
.minimal:active {
  border-bottom: 2px solid #00cba5;
  margin-bottom: -1px;
}
.minimal:focus:hover,
.minimal:active:hover {
  border-color: #00cba5;
}
.placeholder-rules::-webkit-input-placeholder {
  color: #bbb;
}
.placeholder-rules:-ms-input-placeholder {
  color: #bbb;
}
.placeholder-rules::-ms-input-placeholder {
  color: #bbb;
}
.placeholder-rules:-moz-placeholder {
  color: #bbb;
}
.placeholder-rules::-moz-placeholder {
  color: #bbb;
}
.placeholder-rules:hover::-webkit-input-placeholder {
  color: #9b9b9b;
}
.placeholder-rules:hover:-ms-input-placeholder {
  color: #9b9b9b;
}
.placeholder-rules:hover::-ms-input-placeholder {
  color: #9b9b9b;
}
.placeholder-rules:hover:-moz-placeholder {
  color: #9b9b9b;
}
.placeholder-rules:hover::-moz-placeholder {
  color: #9b9b9b;
}
.placeholder-rules:focus::-webkit-input-placeholder,
.placeholder-rules:active::-webkit-input-placeholder {
  color: #ddd;
}
.placeholder-rules:focus:-ms-input-placeholder,
.placeholder-rules:active:-ms-input-placeholder {
  color: #ddd;
}
.placeholder-rules:focus::-ms-input-placeholder,
.placeholder-rules:active::-ms-input-placeholder {
  color: #ddd;
}
.placeholder-rules:focus:-moz-placeholder,
.placeholder-rules:active:-moz-placeholder {
  color: #ddd;
}
.placeholder-rules:focus::-moz-placeholder,
.placeholder-rules:active::-moz-placeholder {
  color: #ddd;
}
.placeholder-rules:focus:hover::-webkit-input-placeholder,
.placeholder-rules:active:hover::-webkit-input-placeholder {
  color: #ddd;
}
.placeholder-rules:focus:hover:-ms-input-placeholder,
.placeholder-rules:active:hover:-ms-input-placeholder {
  color: #ddd;
}
.placeholder-rules:focus:hover::-ms-input-placeholder,
.placeholder-rules:active:hover::-ms-input-placeholder {
  color: #ddd;
}
.placeholder-rules:focus:hover:-moz-placeholder,
.placeholder-rules:active:hover:-moz-placeholder {
  color: #ddd;
}
.placeholder-rules:focus:hover::-moz-placeholder,
.placeholder-rules:active:hover::-moz-placeholder {
  color: #ddd;
}
.form-group {
  position: relative;
  margin: 30px 0;
}
.form-group--right {
  text-align: right;
}
label {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  display: block;
  margin-bottom: 5px;
}
.form-error {
  display: block;
  color: #e86232;
}
.option-label {
  font-size: 18px;
  line-height: 24px;
  color: #000;
}
.option-desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #9b9b9b;
  margin-top: 6px;
}
.optional {
  display: inline-block;
  margin-left: 6px;
  font-weight: 500;
  color: #bbb;
}
body.mui-forms input[type="text"],
body.mui-forms input[type="password"],
body.mui-forms input[type="email"],
body.mui-forms input[type="tel"],
body.mui-forms input[type="date"],
body.mui-forms input[type="number"] {
  border-bottom: none;
  color: initial;
  box-sizing: initial;
  display: initial;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 16.5px 14px;
  margin: 0;
}
body.mui-forms .MuiInputBase-sizeSmall input[type="text"],
body.mui-forms .MuiInputBase-sizeSmall input[type="password"],
body.mui-forms .MuiInputBase-sizeSmall input[type="email"],
body.mui-forms .MuiInputBase-sizeSmall input[type="tel"],
body.mui-forms .MuiInputBase-sizeSmall input[type="date"],
body.mui-forms .MuiInputBase-sizeSmall input[type="number"] {
  padding: 8.5px 14px;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"] {
  -webkit-appearance: none;
  display: block;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 5px;
  width: 100%;
  padding: 8px 10px 12px 0;
  transition-property: color, border-color;
  transition-duration: 0.2s;
}
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
input[type="text"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
input[type="date"]::-ms-input-placeholder,
input[type="number"]::-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="date"]:-moz-placeholder,
input[type="number"]:-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="number"]::-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="tel"]:invalid,
input[type="date"]:invalid,
input[type="number"]:invalid {
  box-shadow: none;
}
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled,
input[type="date"]:disabled,
input[type="number"]:disabled {
  color: #9b9b9b;
  cursor: not-allowed;
}
input[type="text"]:disabled:hover,
input[type="password"]:disabled:hover,
input[type="email"]:disabled:hover,
input[type="tel"]:disabled:hover,
input[type="date"]:disabled:hover,
input[type="number"]:disabled:hover {
  border-color: #bbb;
}
input[type="text"]:disabled:active,
input[type="password"]:disabled:active,
input[type="email"]:disabled:active,
input[type="tel"]:disabled:active,
input[type="date"]:disabled:active,
input[type="number"]:disabled:active {
  color: #9b9b9b;
  margin-bottom: 0;
  border-bottom: 1px solid #bbb;
}
input[type="text"]:disabled:active:hover,
input[type="password"]:disabled:active:hover,
input[type="email"]:disabled:active:hover,
input[type="tel"]:disabled:active:hover,
input[type="date"]:disabled:active:hover,
input[type="number"]:disabled:active:hover {
  border-color: #bbb;
}
input[type="text"]:hover,
input[type="password"]:hover,
input[type="email"]:hover,
input[type="tel"]:hover,
input[type="date"]:hover,
input[type="number"]:hover {
  border-color: #9b9b9b;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
input[type="text"]:active,
input[type="password"]:active,
input[type="email"]:active,
input[type="tel"]:active,
input[type="date"]:active,
input[type="number"]:active {
  border-bottom: 2px solid #00cba5;
  margin-bottom: -1px;
}
input[type="text"]:focus:hover,
input[type="password"]:focus:hover,
input[type="email"]:focus:hover,
input[type="tel"]:focus:hover,
input[type="date"]:focus:hover,
input[type="number"]:focus:hover,
input[type="text"]:active:hover,
input[type="password"]:active:hover,
input[type="email"]:active:hover,
input[type="tel"]:active:hover,
input[type="date"]:active:hover,
input[type="number"]:active:hover {
  border-color: #00cba5;
}
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder {
  color: #bbb;
}
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder {
  color: #bbb;
}
input[type="text"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
input[type="date"]::-ms-input-placeholder,
input[type="number"]::-ms-input-placeholder {
  color: #bbb;
}
input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="date"]:-moz-placeholder,
input[type="number"]:-moz-placeholder {
  color: #bbb;
}
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="number"]::-moz-placeholder {
  color: #bbb;
}
input[type="text"]:hover::-webkit-input-placeholder,
input[type="password"]:hover::-webkit-input-placeholder,
input[type="email"]:hover::-webkit-input-placeholder,
input[type="tel"]:hover::-webkit-input-placeholder,
input[type="date"]:hover::-webkit-input-placeholder,
input[type="number"]:hover::-webkit-input-placeholder {
  color: #9b9b9b;
}
input[type="text"]:hover:-ms-input-placeholder,
input[type="password"]:hover:-ms-input-placeholder,
input[type="email"]:hover:-ms-input-placeholder,
input[type="tel"]:hover:-ms-input-placeholder,
input[type="date"]:hover:-ms-input-placeholder,
input[type="number"]:hover:-ms-input-placeholder {
  color: #9b9b9b;
}
input[type="text"]:hover::-ms-input-placeholder,
input[type="password"]:hover::-ms-input-placeholder,
input[type="email"]:hover::-ms-input-placeholder,
input[type="tel"]:hover::-ms-input-placeholder,
input[type="date"]:hover::-ms-input-placeholder,
input[type="number"]:hover::-ms-input-placeholder {
  color: #9b9b9b;
}
input[type="text"]:hover:-moz-placeholder,
input[type="password"]:hover:-moz-placeholder,
input[type="email"]:hover:-moz-placeholder,
input[type="tel"]:hover:-moz-placeholder,
input[type="date"]:hover:-moz-placeholder,
input[type="number"]:hover:-moz-placeholder {
  color: #9b9b9b;
}
input[type="text"]:hover::-moz-placeholder,
input[type="password"]:hover::-moz-placeholder,
input[type="email"]:hover::-moz-placeholder,
input[type="tel"]:hover::-moz-placeholder,
input[type="date"]:hover::-moz-placeholder,
input[type="number"]:hover::-moz-placeholder {
  color: #9b9b9b;
}
input[type="text"]:focus::-webkit-input-placeholder,
input[type="password"]:focus::-webkit-input-placeholder,
input[type="email"]:focus::-webkit-input-placeholder,
input[type="tel"]:focus::-webkit-input-placeholder,
input[type="date"]:focus::-webkit-input-placeholder,
input[type="number"]:focus::-webkit-input-placeholder,
input[type="text"]:active::-webkit-input-placeholder,
input[type="password"]:active::-webkit-input-placeholder,
input[type="email"]:active::-webkit-input-placeholder,
input[type="tel"]:active::-webkit-input-placeholder,
input[type="date"]:active::-webkit-input-placeholder,
input[type="number"]:active::-webkit-input-placeholder {
  color: #ddd;
}
input[type="text"]:focus:-ms-input-placeholder,
input[type="password"]:focus:-ms-input-placeholder,
input[type="email"]:focus:-ms-input-placeholder,
input[type="tel"]:focus:-ms-input-placeholder,
input[type="date"]:focus:-ms-input-placeholder,
input[type="number"]:focus:-ms-input-placeholder,
input[type="text"]:active:-ms-input-placeholder,
input[type="password"]:active:-ms-input-placeholder,
input[type="email"]:active:-ms-input-placeholder,
input[type="tel"]:active:-ms-input-placeholder,
input[type="date"]:active:-ms-input-placeholder,
input[type="number"]:active:-ms-input-placeholder {
  color: #ddd;
}
input[type="text"]:focus::-ms-input-placeholder,
input[type="password"]:focus::-ms-input-placeholder,
input[type="email"]:focus::-ms-input-placeholder,
input[type="tel"]:focus::-ms-input-placeholder,
input[type="date"]:focus::-ms-input-placeholder,
input[type="number"]:focus::-ms-input-placeholder,
input[type="text"]:active::-ms-input-placeholder,
input[type="password"]:active::-ms-input-placeholder,
input[type="email"]:active::-ms-input-placeholder,
input[type="tel"]:active::-ms-input-placeholder,
input[type="date"]:active::-ms-input-placeholder,
input[type="number"]:active::-ms-input-placeholder {
  color: #ddd;
}
input[type="text"]:focus:-moz-placeholder,
input[type="password"]:focus:-moz-placeholder,
input[type="email"]:focus:-moz-placeholder,
input[type="tel"]:focus:-moz-placeholder,
input[type="date"]:focus:-moz-placeholder,
input[type="number"]:focus:-moz-placeholder,
input[type="text"]:active:-moz-placeholder,
input[type="password"]:active:-moz-placeholder,
input[type="email"]:active:-moz-placeholder,
input[type="tel"]:active:-moz-placeholder,
input[type="date"]:active:-moz-placeholder,
input[type="number"]:active:-moz-placeholder {
  color: #ddd;
}
input[type="text"]:focus::-moz-placeholder,
input[type="password"]:focus::-moz-placeholder,
input[type="email"]:focus::-moz-placeholder,
input[type="tel"]:focus::-moz-placeholder,
input[type="date"]:focus::-moz-placeholder,
input[type="number"]:focus::-moz-placeholder,
input[type="text"]:active::-moz-placeholder,
input[type="password"]:active::-moz-placeholder,
input[type="email"]:active::-moz-placeholder,
input[type="tel"]:active::-moz-placeholder,
input[type="date"]:active::-moz-placeholder,
input[type="number"]:active::-moz-placeholder {
  color: #ddd;
}
input[type="text"]:focus:hover::-webkit-input-placeholder,
input[type="password"]:focus:hover::-webkit-input-placeholder,
input[type="email"]:focus:hover::-webkit-input-placeholder,
input[type="tel"]:focus:hover::-webkit-input-placeholder,
input[type="date"]:focus:hover::-webkit-input-placeholder,
input[type="number"]:focus:hover::-webkit-input-placeholder,
input[type="text"]:active:hover::-webkit-input-placeholder,
input[type="password"]:active:hover::-webkit-input-placeholder,
input[type="email"]:active:hover::-webkit-input-placeholder,
input[type="tel"]:active:hover::-webkit-input-placeholder,
input[type="date"]:active:hover::-webkit-input-placeholder,
input[type="number"]:active:hover::-webkit-input-placeholder {
  color: #ddd;
}
input[type="text"]:focus:hover:-ms-input-placeholder,
input[type="password"]:focus:hover:-ms-input-placeholder,
input[type="email"]:focus:hover:-ms-input-placeholder,
input[type="tel"]:focus:hover:-ms-input-placeholder,
input[type="date"]:focus:hover:-ms-input-placeholder,
input[type="number"]:focus:hover:-ms-input-placeholder,
input[type="text"]:active:hover:-ms-input-placeholder,
input[type="password"]:active:hover:-ms-input-placeholder,
input[type="email"]:active:hover:-ms-input-placeholder,
input[type="tel"]:active:hover:-ms-input-placeholder,
input[type="date"]:active:hover:-ms-input-placeholder,
input[type="number"]:active:hover:-ms-input-placeholder {
  color: #ddd;
}
input[type="text"]:focus:hover::-ms-input-placeholder,
input[type="password"]:focus:hover::-ms-input-placeholder,
input[type="email"]:focus:hover::-ms-input-placeholder,
input[type="tel"]:focus:hover::-ms-input-placeholder,
input[type="date"]:focus:hover::-ms-input-placeholder,
input[type="number"]:focus:hover::-ms-input-placeholder,
input[type="text"]:active:hover::-ms-input-placeholder,
input[type="password"]:active:hover::-ms-input-placeholder,
input[type="email"]:active:hover::-ms-input-placeholder,
input[type="tel"]:active:hover::-ms-input-placeholder,
input[type="date"]:active:hover::-ms-input-placeholder,
input[type="number"]:active:hover::-ms-input-placeholder {
  color: #ddd;
}
input[type="text"]:focus:hover:-moz-placeholder,
input[type="password"]:focus:hover:-moz-placeholder,
input[type="email"]:focus:hover:-moz-placeholder,
input[type="tel"]:focus:hover:-moz-placeholder,
input[type="date"]:focus:hover:-moz-placeholder,
input[type="number"]:focus:hover:-moz-placeholder,
input[type="text"]:active:hover:-moz-placeholder,
input[type="password"]:active:hover:-moz-placeholder,
input[type="email"]:active:hover:-moz-placeholder,
input[type="tel"]:active:hover:-moz-placeholder,
input[type="date"]:active:hover:-moz-placeholder,
input[type="number"]:active:hover:-moz-placeholder {
  color: #ddd;
}
input[type="text"]:focus:hover::-moz-placeholder,
input[type="password"]:focus:hover::-moz-placeholder,
input[type="email"]:focus:hover::-moz-placeholder,
input[type="tel"]:focus:hover::-moz-placeholder,
input[type="date"]:focus:hover::-moz-placeholder,
input[type="number"]:focus:hover::-moz-placeholder,
input[type="text"]:active:hover::-moz-placeholder,
input[type="password"]:active:hover::-moz-placeholder,
input[type="email"]:active:hover::-moz-placeholder,
input[type="tel"]:active:hover::-moz-placeholder,
input[type="date"]:active:hover::-moz-placeholder,
input[type="number"]:active:hover::-moz-placeholder {
  color: #ddd;
}
input[type="text"].has-error,
input[type="password"].has-error,
input[type="email"].has-error,
input[type="tel"].has-error,
input[type="date"].has-error,
input[type="number"].has-error {
  border-bottom: 2px solid #e86232;
  margin-bottom: -1px;
}
input[type="text"].has-error:hover,
input[type="password"].has-error:hover,
input[type="email"].has-error:hover,
input[type="tel"].has-error:hover,
input[type="date"].has-error:hover,
input[type="number"].has-error:hover {
  border-bottom: 2px solid #e86232;
}
.ds-input-container input[type="text"],
.ds-input-container input[type="password"],
.ds-input-container input[type="email"],
.ds-input-container input[type="tel"],
.ds-input-container input[type="date"],
.ds-input-container input[type="number"] {
  width: 100%;
  box-sizing: initial;
  border-bottom: none;
  padding: 14px 12px;
  margin: 0;
  color: #162d54;
  font-weight: 500;
  font-size: 16px;
}
.ds-input-container input[type="text"]:focus,
.ds-input-container input[type="password"]:focus,
.ds-input-container input[type="email"]:focus,
.ds-input-container input[type="tel"]:focus,
.ds-input-container input[type="date"]:focus,
.ds-input-container input[type="number"]:focus {
  box-sizing: initial;
  margin: 0;
  border-bottom: none;
  padding: 14px 12px;
}
input[type="number"] {
  line-height: 26px;
}
select,
input[type="month"] {
  position: relative;
  display: block;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 5px;
  width: 100%;
  padding: 8px 10px 12px 0;
  transition-property: color, border-color;
  transition-duration: 0.2s;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/dropdown-arrow.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: bottom 16px right 9px;
  cursor: pointer;
}
select::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
select:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
select::-ms-input-placeholder,
input[type="month"]::-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
select:-moz-placeholder,
input[type="month"]:-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
select::-moz-placeholder,
input[type="month"]::-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
select:invalid,
input[type="month"]:invalid {
  box-shadow: none;
}
select:disabled,
input[type="month"]:disabled {
  color: #9b9b9b;
  cursor: not-allowed;
}
select:disabled:hover,
input[type="month"]:disabled:hover {
  border-color: #bbb;
}
select:disabled:active,
input[type="month"]:disabled:active {
  color: #9b9b9b;
  margin-bottom: 0;
  border-bottom: 1px solid #bbb;
}
select:disabled:active:hover,
input[type="month"]:disabled:active:hover {
  border-color: #bbb;
}
select:hover,
input[type="month"]:hover {
  border-color: #9b9b9b;
}
select:focus,
input[type="month"]:focus,
select:active,
input[type="month"]:active {
  border-bottom: 2px solid #00cba5;
  margin-bottom: -1px;
}
select:focus:hover,
input[type="month"]:focus:hover,
select:active:hover,
input[type="month"]:active:hover {
  border-color: #00cba5;
}
select::-ms-expand,
input[type="month"]::-ms-expand {
  display: none;
}
select.has-error,
input[type="month"].has-error {
  border-bottom: 2px solid #e86232;
  margin-bottom: -1px;
}
select.has-error:hover,
input[type="month"].has-error:hover {
  border-bottom: 2px solid #e86232;
}
textarea {
  font-family: "Inter", sans-serif;
  display: block;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 5px;
  width: 100%;
  padding: 8px 10px 12px 0;
  transition-property: color, border-color;
  transition-duration: 0.2s;
  padding-right: 0;
  height: 56px;
  box-sizing: content-box;
  padding-bottom: 32px;
}
textarea::-webkit-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
textarea:-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
textarea::-ms-input-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
textarea:-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
textarea::-moz-placeholder {
  transition-property: color;
  transition-duration: 0.2s;
}
textarea:invalid {
  box-shadow: none;
}
textarea:disabled {
  color: #9b9b9b;
  cursor: not-allowed;
}
textarea:disabled:hover {
  border-color: #bbb;
}
textarea:disabled:active {
  color: #9b9b9b;
  margin-bottom: 0;
  border-bottom: 1px solid #bbb;
}
textarea:disabled:active:hover {
  border-color: #bbb;
}
textarea:hover {
  border-color: #9b9b9b;
}
textarea:focus,
textarea:active {
  border-bottom: 2px solid #00cba5;
  margin-bottom: -1px;
}
textarea:focus:hover,
textarea:active:hover {
  border-color: #00cba5;
}
textarea::-webkit-input-placeholder {
  color: #bbb;
}
textarea:-ms-input-placeholder {
  color: #bbb;
}
textarea::-ms-input-placeholder {
  color: #bbb;
}
textarea:-moz-placeholder {
  color: #bbb;
}
textarea::-moz-placeholder {
  color: #bbb;
}
textarea:hover::-webkit-input-placeholder {
  color: #9b9b9b;
}
textarea:hover:-ms-input-placeholder {
  color: #9b9b9b;
}
textarea:hover::-ms-input-placeholder {
  color: #9b9b9b;
}
textarea:hover:-moz-placeholder {
  color: #9b9b9b;
}
textarea:hover::-moz-placeholder {
  color: #9b9b9b;
}
textarea:focus::-webkit-input-placeholder,
textarea:active::-webkit-input-placeholder {
  color: #ddd;
}
textarea:focus:-ms-input-placeholder,
textarea:active:-ms-input-placeholder {
  color: #ddd;
}
textarea:focus::-ms-input-placeholder,
textarea:active::-ms-input-placeholder {
  color: #ddd;
}
textarea:focus:-moz-placeholder,
textarea:active:-moz-placeholder {
  color: #ddd;
}
textarea:focus::-moz-placeholder,
textarea:active::-moz-placeholder {
  color: #ddd;
}
textarea:focus:hover::-webkit-input-placeholder,
textarea:active:hover::-webkit-input-placeholder {
  color: #ddd;
}
textarea:focus:hover:-ms-input-placeholder,
textarea:active:hover:-ms-input-placeholder {
  color: #ddd;
}
textarea:focus:hover::-ms-input-placeholder,
textarea:active:hover::-ms-input-placeholder {
  color: #ddd;
}
textarea:focus:hover:-moz-placeholder,
textarea:active:hover:-moz-placeholder {
  color: #ddd;
}
textarea:focus:hover::-moz-placeholder,
textarea:active:hover::-moz-placeholder {
  color: #ddd;
}
textarea.has-error {
  border-bottom: 2px solid #e86232;
  margin-bottom: -1px;
}
textarea.has-error:hover {
  border-color: #e86232;
}
textarea.bordered {
  border: 1px solid #ddd;
  outline: none;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
}
textarea.bordered:hover {
  border-color: #9b9b9b;
}
textarea.bordered:focus,
textarea.bordered:active {
  border: 2px solid #00cba5;
  margin-bottom: 0;
}
textarea.bordered:focus:hover,
textarea.bordered:active:hover {
  border-color: #00cba5;
}
textarea.bordered.has-error {
  border: 2px solid #e86232;
  margin-bottom: 0;
}
textarea.bordered.has-error:hover {
  border-color: #e86232;
}
.textarea-counter {
  float: right;
  padding-top: 5px;
}
.ws-textarea-counter {
  font-size: 13px;
  color: #9b9b9b;
  white-space: nowrap;
  align-self: flex-start;
}
.ws-textarea-counter--error {
  color: #e86232;
}
.has-error .ws-textarea-count {
  color: #e86232;
}
.checkbox-list input[type="checkbox"] + label::before,
.checkbox-single input[type="checkbox"] + label::before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 2px solid #ddd;
  position: absolute;
  left: 0;
  top: 23px;
}
.checkbox-list input[type="checkbox"] + label::after,
.checkbox-single input[type="checkbox"] + label::after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  position: absolute;
  left: 6px;
  top: 7px;
}
.checkbox-list input[type="checkbox"] + label:hover::before,
.checkbox-single input[type="checkbox"] + label:hover::before {
  background-color: #ededed;
  border-color: #ccc;
}
.checkbox-list input[type="checkbox"]:checked + label::before,
.checkbox-single input[type="checkbox"]:checked + label::before {
  background-color: #00cba5;
}
.checkbox-list input[type="checkbox"]:checked + label::after,
.checkbox-single input[type="checkbox"]:checked + label::after {
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/checkmarkIconWhite.svg");
  background-size: cover;
  right: 6px;
  top: 30px;
}
.checkbox-list input[type="checkbox"]:checked + label:hover::before,
.checkbox-single input[type="checkbox"]:checked + label:hover::before {
  background-color: #009976;
  border-color: #ccc;
}
.checkbox-list input[type="checkbox"]:disabled + label,
.checkbox-single input[type="checkbox"]:disabled + label {
  cursor: default;
}
.checkbox-list input[type="checkbox"]:disabled + label::before,
.checkbox-single input[type="checkbox"]:disabled + label::before {
  background-color: #ddd;
  border-color: #ddd;
}
.checkbox-list input[type="checkbox"]:checked:disabled + label,
.checkbox-single input[type="checkbox"]:checked:disabled + label {
  cursor: default;
}
.checkbox-list input[type="checkbox"]:checked:disabled + label::before,
.checkbox-single input[type="checkbox"]:checked:disabled + label::before {
  background-color: #ddd;
}
@media (max-width: 768px) {
  .checkbox-list:not(.checkbox-list-left):not(.checkbox-single) input[type="checkbox"] + label::before,
  .checkbox-single:not(.checkbox-list-left):not(.checkbox-single) input[type="checkbox"] + label::before {
    left: initial;
    right: 0;
    top: 22px;
  }
  .checkbox-list:not(.checkbox-list-left):not(.checkbox-single) input[type="checkbox"] + label::after,
  .checkbox-single:not(.checkbox-list-left):not(.checkbox-single) input[type="checkbox"] + label::after {
    left: initial;
  }
}
.radio-list input[type="radio"] + label::before {
  content: "";
  display: block;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #f5f5f5;
  border: 2px solid #ddd;
  position: absolute;
  left: 0;
  top: 19px;
}
.radio-list input[type="radio"] + label::after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  position: absolute;
  left: 6px;
  top: 28px;
}
.radio-list input[type="radio"] + label:hover::before {
  background-color: #ededed;
  border-color: #ccc;
}
.radio-list input[type="radio"]:checked + label::before {
  background-color: #00cba5;
}
.radio-list input[type="radio"]:checked + label::after {
  background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/checkmarkIconWhite.svg");
  background-size: cover;
}
.radio-list input[type="radio"]:checked + label:hover::before {
  background-color: #009976;
  border-color: #ccc;
}
.radio-list input[type="radio"]:disabled + label {
  cursor: default;
}
.radio-list input[type="radio"]:disabled + label:hover::before {
  background-color: #f5f5f5;
  border-color: #ddd;
}
@media (max-width: 768px) {
  .radio-list:not(.radio-list-left) input[type="radio"] + label::before {
    left: initial;
    right: 0;
    top: 19px;
  }
  .radio-list:not(.radio-list-left) input[type="radio"] + label::after {
    left: initial;
    right: 7px;
    top: 28px;
  }
}
.checkbox-list,
.radio-list {
  list-style: none;
  padding: 0;
}
.checkbox-single {
  margin: 0;
}
.checkbox-list input[type="radio"],
.radio-list input[type="radio"],
.checkbox-single input[type="radio"],
.checkbox-list input[type="checkbox"],
.radio-list input[type="checkbox"],
.checkbox-single input[type="checkbox"] {
  display: none;
}
.checkbox-list input[type="radio"] + label,
.radio-list input[type="radio"] + label,
.checkbox-single input[type="radio"] + label,
.checkbox-list input[type="checkbox"] + label,
.radio-list input[type="checkbox"] + label,
.checkbox-single input[type="checkbox"] + label {
  position: relative;
  display: block;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  padding: 22px 0 18px 48px;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
@media (max-width: 768px) {
  .checkbox-list:not(.checkbox-list-left):not(.radio-list-left):not(.checkbox-single) input[type="radio"] + label,
  .radio-list:not(.checkbox-list-left):not(.radio-list-left):not(.checkbox-single) input[type="radio"] + label,
  .checkbox-single:not(.checkbox-list-left):not(.radio-list-left):not(.checkbox-single) input[type="radio"] + label,
  .checkbox-list:not(.checkbox-list-left):not(.radio-list-left):not(.checkbox-single) input[type="checkbox"] + label,
  .radio-list:not(.checkbox-list-left):not(.radio-list-left):not(.checkbox-single) input[type="checkbox"] + label,
  .checkbox-single:not(.checkbox-list-left):not(.radio-list-left):not(.checkbox-single) input[type="checkbox"] + label {
    padding-left: 0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .checkbox-list,
  .radio-list,
  .checkbox-single {
    /* Don't perform flipping of checkbox and label orientation on IE (no support for ) */
  }
  .checkbox-list input[type="radio"] + label,
  .radio-list input[type="radio"] + label,
  .checkbox-single input[type="radio"] + label,
  .checkbox-list input[type="checkbox"] + label,
  .radio-list input[type="checkbox"] + label,
  .checkbox-single input[type="checkbox"] + label {
    padding-left: 48px;
  }
}
.radio-list.radio-list-inline {
  margin-top: 0;
}
.radio-list.radio-list-inline li {
  display: inline-block;
}
.radio-list.radio-list-inline li:not(:first-child) {
  margin-left: 25px;
}
.radio-list.radio-list-inline input[type="radio"] + label {
  display: inline-block;
  border: 0;
  padding: 0 0 0 40px;
}
.radio-list.radio-list-inline input[type="radio"] + label::before {
  top: 0;
}
.radio-list.radio-list-inline input[type="radio"] + label::after {
  top: 9px;
}
h2 + .checkbox-single,
h3 + .checkbox-single,
.section-title + .checkbox-single {
  margin-top: -12px;
}
.form-switch-toggle {
  position: relative;
}
.form-switch-toggle label {
  font-size: 18px;
  line-height: 24px;
  color: #000;
}
@media (max-width: 768px) {
  .form-switch-toggle label {
    padding-right: 130px;
  }
}
.default-checkbox-list input[type="checkbox"] {
  position: relative;
  top: 2px;
}
.default-checkbox-list label {
  display: inline-block;
  margin-left: 3px;
}
.switch-ios input:checked + label {
  color: #fff;
  outline: none;
  font-weight: 700;
}
.switch-ios.switch-toggle {
  width: 120px;
  height: 42px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 14px;
  border: 1px solid #ececec;
  margin: 14px 0;
}
.switch-ios.switch-toggle label {
  color: #9b9b9b;
  font-weight: 500;
  cursor: pointer;
  height: 42px;
}
.switch-ios.switch-toggle a {
  background-color: #00cba5;
  border-radius: 4px;
  border: none;
  height: 42px;
}
.application .switch-toggle > input {
  min-width: 0;
}
.help-block {
  display: block;
  font-weight: 300;
  font-size: 14px;
  color: #e86232;
}
@media (min-width: 768px) {
  .help-block {
    padding-right: 20px;
  }
}
.radio-single__label {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ddd;
  background-color: #f5f5f5;
  cursor: pointer;
}
.radio-single__label svg {
  fill: #fff;
  opacity: 0;
  width: 16px;
  height: 16px;
}
.radio-single__label:hover {
  background-color: #ddd;
  border-color: #bbb;
}
.radio-single__input {
  display: none;
}
.radio-single__input:checked + .radio-single__label {
  background-color: #00cba5;
}
.radio-single__input:checked + .radio-single__label svg {
  opacity: 1;
}
.radio-single__input:checked + .radio-single__label:hover {
  background-color: #009976;
  border-color: #ddd;
}
.input-group-list {
  display: flex;
  flex-direction: column;
}
.input-group-list > :not(:last-child) {
  margin-bottom: 24px;
}
.input-button-group {
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
}
.input-button-group input {
  box-sizing: border-box;
  height: 46px;
  margin: 0;
  padding: 6px 130px 6px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.input-button-group input:active,
.input-button-group input:focus,
.input-button-group input:hover,
.input-button-group input:focus:hover {
  border: 1px solid #bbb;
  margin-bottom: 0;
}
.input-button-group input:disabled {
  background-color: #f5f5f5;
}
.input-button-group .btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 120px;
  height: 46px;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
}
.input-button-group .btn.loading {
  position: absolute;
}
.input-button-group .btn.btn-secondary {
  background-color: #fff;
}
.text-filter {
  flex-grow: 2;
}
.text-filter .wsicon-search {
  position: absolute;
  left: 5px;
  bottom: 10px;
  width: 15px;
  height: 15px;
  fill: #9b9b9b;
}
.text-filter input {
  padding: 3px 50px 3px 30px;
  font-size: 15px;
}
.text-filter input:active,
.text-filter input:focus {
  border-color: #3d5afe;
}
.text-filter input:active:hover,
.text-filter input:focus:hover {
  border-color: #3d5afe;
}
.text-filter a {
  text-decoration: none;
  color: #3d5afe;
  position: absolute;
  right: 5px;
  font-size: 13px;
  line-height: 18px;
}
.text-filter a:hover {
  text-decoration: underline;
}
.text-filter a:hover.no-underline {
  text-decoration: none;
}
.text-filter a:hover {
  color: #3d5afe;
}
.movable-list {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  border: 1px solid #ddd;
}
@media (min-width: 768px) {
  .movable-list {
    width: 350px;
  }
}
.movable-list__item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: 10px;
}
.movable-list__item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.movable-list__item svg {
  width: 14px;
  height: 14px;
  fill: #9b9b9b;
  cursor: pointer;
}
.movable-list__item svg:hover {
  fill: #000;
}
.movable-list__item-left {
  display: flex;
  align-items: center;
  flex-shrink: 1;
}
.movable-list__item-move {
  display: flex;
  margin-right: 10px;
}
.movable-list__item-move svg {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 3px;
}
.movable-list__item-move svg:first-child {
  transform: rotate(180deg);
  margin-right: 5px;
}
.movable-list__item-move svg[disabled] {
  opacity: 0.25;
  cursor: default;
  pointer-events: none;
}
.movable-list__input-label {
  text-transform: capitalize;
}
.movable-list__item-name {
  display: flex;
  text-transform: uppercase;
  line-height: 18px;
}
.movable-list__item-actions {
  flex: 0 0 75px;
  text-align: right;
}
.movable-list__item-actions .wsicon-pass {
  fill: #ddd;
}
.movable-list__item-actions .wsicon-pass:hover {
  fill: #e86232;
}
.movable-list__item-actions .btn {
  height: 26px;
  padding: 4px 8px 2px;
}
.inline-btn-input {
  position: relative;
  display: flex;
  align-items: center;
}
.inline-btn-input input {
  padding-right: 95px;
}
.inline-btn-input .btn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 768px) {
  .inline-btn-input {
    width: auto;
  }
  .inline-btn-input input {
    font-size: 15px;
  }
  .inline-btn-input .btn {
    padding: 6px 12px;
  }
}
.inline-btn-input--search {
  width: 500px;
  margin: 10px 0 20px 10px;
}
.inline-btn-input--search svg {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  fill: #9b9b9b;
}
.inline-btn-input--search input {
  text-indent: 30px;
  margin-right: 5px;
}
@media (max-width: 768px) {
  .inline-btn-input--search {
    width: auto;
  }
}
.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toggle-switch__field {
  flex: 0 0 40px;
  width: 40px;
  height: 24px;
}
.toggle-switch__label {
  position: relative;
  display: block;
  margin: 0;
  border-radius: 100px;
  cursor: pointer;
  text-indent: -9999px;
  line-height: 24px;
  background-color: #ce2e3a;
  transition: background-color 0.3s ease-out;
}
.toggle-switch__label::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s;
}
.toggle-switch__input {
  display: none;
}
.toggle-switch__input:checked + .toggle-switch__label {
  background-color: #00cba5;
}
.toggle-switch__input:checked + .toggle-switch__label::after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}
.toggle-switch__text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.toggle-switch__text--left {
  margin-left: 5px;
}
.ws-help__help-tooltip-container {
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.24);
  padding: 0;
  max-width: 550px;
}
.ws-help__help-tooltip-container .MuiTooltip-arrow {
  color: #fff;
}
.ws-help__help-tooltip {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0;
}
.ws-help__help-tooltip-title {
  color: #162d54;
  line-height: 1em;
  margin: 24px;
  padding: 0;
}
.ws-help__help-tooltip-content {
  flex-basis: 100%;
  margin: 24px;
  margin-block-start: 0;
}
.ws-help__knowledgebase-callout {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #162d54;
  padding: 12px 24px;
  color: white;
  white-space: nowrap;
}
.ws-help__knowledgebase-callout .btn {
  padding: 9px 18px;
}
.ws-help__help-tooltip-container .ws-help__knowledgebase-callout {
  border-radius: 0 0 4px 4px;
}
.portal-info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #5e6785;
  cursor: pointer;
}
.portal-info-icon .MuiSvgIcon-root {
  width: 17px;
  height: 17px;
}
.portal-info-icon:hover {
  background-color: #f7f8ff;
  color: #162d54;
}
.ws-modal-z {
  z-index: 1500;
}
.blocker {
  padding: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}
.modal {
  margin: 20px 0;
  box-shadow: 0 0 10px #222;
  padding: 16px;
  color: #555;
}
.modal a.close-modal.icon-close {
  text-indent: 0;
  text-decoration: none;
  color: #555;
  top: 12px;
  right: 18px;
  font-size: 40px;
  font-weight: 100;
  height: auto;
  width: auto;
  background: none;
  opacity: 0.4;
}
.modal a.close-modal.icon-close:hover {
  opacity: 0.7;
}
.modal-small h3 {
  font-weight: 300;
  text-align: center;
  padding: 0 60px;
}
.modal-small h3 strong {
  font-weight: 700;
}
.modal-small input {
  width: 230px;
  margin-left: 4px;
  box-sizing: border-box;
}
.modal-small #sms-btn {
  width: 230px;
}
.modal-small .next-container {
  margin: 0;
  padding-bottom: 0;
}
.modal-small .action-container {
  color: #162d54;
  font-size: 18px;
}
.modal-small .modal-btn {
  font-weight: 100;
  border: 1px solid #ccc;
  text-align: center;
  padding: 12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}
.modal-small .modal-btn a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #555;
  display: inline-block;
}
.modal-small .modal-btn.up {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: none;
  color: #00cba5;
}
.modal-small .modal-btn.up > a {
  color: #00cba5;
}
.modal-small .modal-btn.single {
  border-radius: 6px;
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  .modal-small {
    padding: 16px 24px 24px;
  }
}
.modal-actions .btn {
  width: 240px;
  margin: 0 auto;
  display: block;
}
.modal-actions.right {
  text-align: right;
}
.modal-actions.right .btn {
  width: auto;
  margin: 0 auto;
  display: inline-block;
}
.modal-actions.button-stack {
  max-width: 280px;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
}
.modal-actions.button-stack .btn {
  width: 100%;
  margin-bottom: 8px;
}
.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
}
.modal-container.fixed {
  position: fixed;
}
.modal-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-cover.fixed {
  position: fixed;
}
@media (max-width: 640px) {
  .modal-small h3 {
    padding: 0 18px;
  }
  .modal-actions.button-stack .btn-large.btn-primary {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .modal-full.modal {
    width: 100%;
    margin: 0;
    min-height: 100%;
    border-radius: 0;
  }
  .noscroll {
    display: none;
  }
}
.ws-modal-container {
  position: relative;
  z-index: 1500;
}
.ws-modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.5);
}
.ws-modals {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}
.ws-modals > :not(:last-child) {
  display: none;
}
.ws-modal {
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  padding: 40px 80px;
  min-width: 500px;
  max-width: 800px;
}
.ws-modal h2 {
  margin-top: 0;
  color: #162d54;
}
.ws-modal p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 200;
}
.ws-modal .wsicon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ws-modal.ws-modal-small {
  min-width: 400px;
  max-width: 600px;
}
.ws-modal.ws-modal-tiny {
  min-width: 300px;
  max-width: 400px;
  padding: 40px 60px;
}
.ws-modal.ws-modal-center {
  text-align: center;
}
.ws-modal.ws-modal-less-padding {
  padding: 20px;
}
.ws-modal.ws-modal-no-padding {
  padding: 0;
}
.ws-modal .dash-modal-footer {
  padding: 10px;
  height: 36px;
}
.ws-modal .dash-modal-footer .button-right {
  float: right;
}
.ws-modal .dash-modal-footer .button-left {
  float: left;
}
.ws-modal .dash-modal-body {
  background-color: #fafbfb;
  min-height: 625px;
  min-width: 560px;
}
.ws-modal .dash-modal-body.column {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 24px;
}
.ws-modal .dash-modal-body.column.question-review {
  row-gap: 0;
}
.ws-modal .dash-modal-body p.intro-text {
  margin: 0;
  padding: 0 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.ws-modal .dash-modal-body .sub-caption {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  font-weight: 700;
  color: #5e6785;
}
.ws-modal .dash-modal-body .flex-section {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
}
.ws-modal .dash-modal-body .question-accordion-summary {
  display: flex;
  flex-direction: column;
  row-gap: 0;
}
.ws-modal .dash-modal-body .question-header {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #162d54;
}
.ws-modal .dash-modal-body .question-body {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #5e6785;
}
.ws-modal .dash-modal-body .question-response {
  display: flex;
  flex-direction: row;
}
.ws-modal .dash-modal-body .question-response .response-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #162d54;
  min-width: 280px;
  display: flex;
  align-items: center;
}
.ws-modal .dash-modal-body .question-response .response-check {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ws-modal .dash-modal-body .question-accordion .accordion-details {
  row-gap: 16px;
}
.ws-modal .dash-modal-body .question-accordion .accordion-summary {
  height: 88px;
}
.ws-modal .dash-modal-body .dash-modal-progress-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: space-evenly;
}
.ws-modal .dash-modal-body .dash-modal-confirmation-panel {
  padding: 24px;
}
.ws-modal .dash-modal-body ul.bullet-list,
.ws-modal .dash-modal-body ul.bullet-list ul {
  list-style-type: disc;
}
.ws-modal .help-block {
  padding-left: 1rem;
}
.ws-dialog-no-padding {
  padding: 0;
}
.ws-modal__buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ws-modal__buttons > a,
.ws-modal__buttons > button {
  margin: 15px 0;
}
.ws-modal__buttons > a:last-child,
.ws-modal__buttons > button:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .ws-modals {
    display: block;
  }
  .ws-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 0 !important;
    max-width: none !important;
    width: 100vw !important;
    padding: 30px 10px;
    overflow: auto;
  }
  .ws-modal.ws-modal-tiny {
    padding: 30px 20px;
  }
  .ws-modal p,
  .ws-modal h2 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ws-modal-footer {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
  }
}
svg use {
  pointer-events: none;
}
.ws-edit-modal {
  width: 900px;
  max-width: none;
  padding: 40px 0 10px;
}
.ws-edit-modal__header,
.ws-edit-modal__footer {
  padding: 0 20px;
}
.ws-edit-modal__footer {
  text-align: right;
  margin-top: 20px;
}
.ws-edit-modal__add-link {
  flex-grow: 1;
  text-align: right;
  font-size: 13px;
}
.ws-edit-modal__content {
  margin-top: 20px;
  padding: 0 20px;
}
.ws-edit-modal__content--scrollable {
  overflow: auto;
  max-height: 350px;
}
.ws-edit-modal__table {
  width: 100%;
  font-size: 15px;
}
.ws-edit-modal__table th {
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}
.ws-edit-modal__table th,
.ws-edit-modal__table td {
  padding: 5px 10px;
}
.ws-edit-modal__table th:first-child,
.ws-edit-modal__table td:first-child {
  padding-left: 0;
}
.ws-edit-modal__table th:last-child,
.ws-edit-modal__table td:last-child {
  padding-right: 0;
}
.ws-edit-modal__matches {
  margin-bottom: 10px;
}
.ws-edit-modal__matches-count {
  margin-right: 50px;
  font-size: 13px;
}
.ws-edit-modal__matches-links {
  display: flex;
  font-size: 13px;
}
.ws-edit-modal__matches-links a:not(:first-child) {
  margin-left: 20px;
}
.ws-edit-modal__add-pane {
  width: 600px;
  margin: 0 auto;
}
.ws-edit-modal__inline-form {
  font-size: 14px;
}
.ws-edit-modal__inline-form .ws-modal__buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.ws-edit-modal__inline-form .ws-modal__buttons > button {
  margin: 15px;
}
.ws-edit-modal__inline-form .label {
  display: inline-block;
  margin: 10px 20px 10px 0;
  padding: 0;
  width: 140px;
}
.ws-edit-modal__inline-form .label label {
  font-weight: 500;
}
.ws-edit-modal__inline-form select {
  display: inline-block;
  max-width: 330px;
  padding-right: 35px;
  width: unset;
  font-size: inherit;
}
.ws-edit-modal__inline-form input[type="text"],
.ws-edit-modal__inline-form input[type="email"],
.ws-edit-modal__inline-form textarea {
  display: inline-block;
  font-size: inherit;
  width: 330px;
}
.ws-edit-modal__inline-form input[type="number"] {
  display: inline-block;
  font-size: inherit;
  width: 100px;
}
.ws-edit-modal__row-highlight {
  background-color: #f4fcfa;
}
.ws-edit-modal__row-disable {
  color: #bbb;
}
.ws-edit-modal__row-disable .wsicon-help-circle {
  width: 14px;
  height: 14px;
  fill: #bbb;
}
.ws-edit-modal__loader {
  position: absolute;
  top: 17px;
  right: 60px;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.ws-edit-modal__loader svg {
  height: 13px;
  width: 13px;
  margin-right: 5px;
}
.ws-edit-modal__loader .wsicon-approve {
  fill: #00cba5;
}
.ws-edit-modal__loader .wsicon-pass {
  fill: #e86232;
}
.ws-edit-modal__loader .loader2-container {
  position: relative;
  justify-content: flex-start;
  margin-right: 5px;
}
.ws-edit-modal__loader .loader2 {
  width: 13px;
  height: 13px;
}
.slider {
  text-align: center;
  width: 100%;
  margin-left: -10px;
  min-width: 1000px;
}
.slider .slick-arrow {
  position: absolute;
  top: 35%;
  fill: #ddd;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
}
.slider .slick-list {
  padding: 10px 0;
}
.slider .slick-next {
  right: -70px;
  cursor: pointer;
}
.slider .slick-prev {
  left: -70px;
  cursor: pointer;
}
.slider .slick-slide {
  max-width: 340px;
}
@media (max-width: 1200px) {
  .slider {
    max-width: 680px;
    min-width: 680px;
  }
}
@media (max-width: 768px) {
  .slider {
    width: 100vw;
    min-width: 0;
    margin: 0;
  }
}
.slider-card {
  max-width: 320px;
  min-width: 320px;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  text-align: center;
  background-color: #eff1f6;
  margin: 0 10px;
}
.slider-card p {
  color: black;
  font-size: 15px;
  font-weight: 600;
  margin: 15px 0 2px;
  padding: 0 10px;
}
.slider-card span {
  font-size: 13px;
  line-height: 20px;
  padding: 0 10px;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slider-card a {
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .slider-card {
    box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.42);
  }
}
@media (max-width: 768px) {
  .slider-card {
    margin: 0;
  }
}
@media (max-width: 768px) {
  .slider-card--home {
    box-sizing: border-box;
    max-width: 280px;
    min-width: 280px;
    margin: 0 5px;
  }
}
.slider-card--question {
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 257px;
  overflow: auto;
  background-color: #e0f9f4;
}
.slider-card--question > a {
  text-transform: uppercase;
  font-weight: 500;
}
.slider-card--question aside {
  color: #666;
  font-size: 12px;
}
.slider-card--question aside a {
  color: #666;
  border-bottom: 1px dashed #666;
  padding: 0;
  font-size: 12px;
}
.slider-card--question header {
  font-size: 16px;
  line-height: 24px;
  padding: 0 15px;
  margin: 15px 0 20px;
}
.ws-range.noUi-horizontal {
  height: 8px;
  box-shadow: none;
  border: none;
  background-color: #ddd;
}
.ws-range.noUi-horizontal .noUi-connect {
  background-color: #00cba5;
}
.ws-range.noUi-horizontal .noUi-connects {
  cursor: pointer;
}
.ws-range.noUi-horizontal .noUi-handle {
  top: -9px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-color: #ddd;
  cursor: pointer;
  outline: none;
}
.ws-range.noUi-horizontal .noUi-handle::before,
.ws-range.noUi-horizontal .noUi-handle::after {
  display: none;
}
.ws-range.noUi-horizontal .noUi-tooltip {
  bottom: -35px;
  border: none;
  background-color: transparent;
  font-weight: 400;
}
.ds-accordion {
  font-size: 15px;
}
.ds-accordion::before {
  display: none;
}
.ds-accordion .accordion-summary {
  font-weight: 400;
}
.ds-accordion .accordion-summary:hover {
  background-color: #3d5afe0a;
}
.ds-accordion .accordion-summary:active {
  background-color: #3d5afe52;
}
.ds-accordion .accordion-summary__content--extra-content {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ds-accordion .accordion-details {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.5rem;
  background-color: #f4f5f9;
  padding-top: 1rem;
}
.ds-accordion .accordion-expanded {
  background-color: #f4f5f9;
}
.ds-accordion .accordion-collapsed {
  background-color: #fafbfb;
}
.ds-accordion .check-group {
  display: grid;
}
.accordion-hovered .accordion-summary {
  background-color: #3d5afe0a;
}
.accordion-pressed .accordion-summary {
  background-color: #3d5afe52;
}
.facility-address-menu {
  display: grid;
  grid-auto-rows: 1fr;
  height: auto;
}
.facility-address-menu p {
  margin: 0;
}
.facility-address-menu p.name {
  color: #162d54;
}
.facility-address-menu p.address-line {
  color: #5e6785;
  font-size: 13px;
}
.interview-details-accordion .helper-text {
  font-size: 13px;
  color: #5e6785;
  font-weight: 500;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 0;
}
.interview-details-accordion .text-subtitle2 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #5e6785;
}
.action-sheet .action-sheet-option {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 48px;
  text-align: center;
  text-decoration: none;
  padding: 14px 0 12px;
  border: 1px solid #bbb;
  border-bottom-width: 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  color: #4a4a4a;
  cursor: pointer;
}
.action-sheet .action-sheet-option:hover {
  color: #000;
  text-decoration: none;
  border: 1px solid #9b9b9b;
  height: 49px;
  margin-bottom: -1px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 1;
}
.action-sheet .action-sheet-option.action-primary {
  background-color: #00cba5;
  color: #fff;
}
.action-sheet .action-sheet-option.action-primary:hover {
  background-color: #60ffd6;
  color: #fff;
}
.action-sheet .action-sheet-option.action-primary.emphasis {
  font-weight: 700;
}
.action-sheet .action-sheet-option:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.action-sheet .action-sheet-option:last-child {
  border-bottom-width: 1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.action-sheet .action-sheet__non-button-option {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 60px;
  text-align: center;
  text-decoration: none;
  padding: 20px 0 24px;
  margin: 0;
  background: none;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 18px;
  color: #00cba5;
  cursor: pointer;
}
.action-sheet .action-sheet__non-button-option.emphasis {
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}
.action-sheet .action-sheet__non-button-option:hover {
  color: #60ffd6;
  text-decoration: none;
}
.action-sheet .action-sheet__non-button-option:focus {
  outline: none;
}
@media (max-width: 768px) {
  .availability-grid {
    margin: 60px -12px 20px;
  }
}
@media print {
  .availability-grid {
    display: none;
  }
}
.availability-grid__header {
  display: flex;
}
.availability-grid__header-empty {
  flex: 0 0 40%;
  width: 40%;
}
.availability-grid__header-content {
  flex: 0 0 15%;
  width: 15%;
  position: relative;
  height: 26px;
  white-space: nowrap;
}
.availability-grid__header-content span {
  display: flex;
  justify-content: flex-end;
  transform: rotate(45deg) translate(-30%, 0);
  font-size: 11px;
  font-weight: 400;
}
.availability-grid__row {
  display: flex;
  box-sizing: border-box;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.availability-grid__row:last-child {
  border-bottom: 1px solid #ddd;
}
@media (max-width: 768px) {
  .availability-grid__row {
    border-left: none;
  }
}
.availability-grid__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 0 0 40%;
  width: 40%;
  padding: 15px;
  border-right: 1px solid #ddd;
  font-weight: 500;
}
.availability-grid__label .tooltip {
  display: flex;
}
.availability-grid__label svg {
  width: 18px;
  height: 18px;
  fill: #ddd;
  cursor: pointer;
}
@media (max-width: 768px) {
  .availability-grid__label {
    font-size: 15px;
  }
  .availability-grid__label .tooltip {
    display: none;
  }
}
.availability-grid__checkbox {
  flex: 0 0 15%;
  width: 15%;
  height: 60px;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #ddd;
}
.availability-grid__checkbox input {
  display: none;
}
.availability-grid__checkbox svg {
  display: none;
  width: 24px;
  height: 24px;
  fill: #00cba5;
}
.availability-grid__checkbox input + label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.availability-grid__checkbox input:checked + label {
  background-color: #f4fcfa;
}
.availability-grid__checkbox input:checked + label svg {
  display: block;
}
.availability-grid__checkbox input:not([disabled]) + label {
  cursor: pointer;
}
.availability-grid__checkbox input:not([disabled]) + label:hover {
  background-color: #f9f9f9;
}
.availability-grid__checkbox input:checked:not([disabled]) + label:hover {
  background-color: #f4fcfa;
}
.availability-grid__checkbox input:checked:not([disabled]) + label:hover svg {
  fill: #60ffd6;
}
.availability-grid__body {
  position: relative;
}
.availability-grid__invalid .availability-grid__invalid-content {
  text-align: center;
}
.availability-grid__invalid .availability-grid__invalid-content a {
  font-weight: 500;
  text-transform: uppercase;
}
.availability-grid__invalid .availability-grid__invalid-content p {
  line-height: 21px;
}
.availability-grid__invalid:not(.modal) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.availability-grid__invalid:not(.modal) .availability-grid__invalid-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  margin: 0;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  .availability-grid__invalid .availability-grid__invalid-content {
    width: 90%;
  }
}
.auth-component {
  max-width: 460px;
  min-height: 276px;
  transition: max-height 1600ms;
  padding: 48px 0;
  overflow: hidden;
}
.auth-component h1 {
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}
.auth-component h2 {
  font-weight: 500;
  margin-top: 0;
}
.auth-component span.subtitle {
  display: block;
}
.auth-component span.notice {
  font-size: 12px;
  color: #666;
}
.auth-component .user-id {
  font-weight: 500;
  font-size: 16px;
  color: #4a4a4a;
}
.auth-component .userid-input {
  min-height: 62px;
  margin-bottom: 0;
}
.auth-component .login-container,
.auth-component .password-container,
.auth-component .stack-step {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10.4%;
  margin-top: 20px;
}
.auth-component .auth-choice {
  display: block;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.auth-component .auth-choice span {
  font-size: 15px;
  vertical-align: super;
}
.auth-component .auth-choice.disabled {
  color: #ddd;
  cursor: default;
}
.auth-component .auth-choice.disabled:hover {
  text-decoration: none;
}
.auth-component #btn-forgot {
  font-size: 14px;
  margin-top: 12px;
  display: inline-block;
}
.auth-component .btn-back {
  position: relative;
  left: 10.4%;
  display: none;
  margin-left: -14px;
  margin-top: -14px;
}
@media (max-width: 768px) {
  .auth-component .btn-back {
    left: 18px;
  }
}
.auth-component .choice-actions {
  margin-top: 38px;
}
.auth-component .user-id-actions::after {
  content: "";
  display: table;
  clear: both;
}
.auth-component #btn-login,
.auth-component #btn-next {
  float: right;
}
.auth-component #btn-magic {
  width: 100%;
}
@media (max-width: 768px) {
  .auth-component.modal {
    width: 100%;
    margin: 0;
    min-height: 100%;
    border-radius: 0;
  }
  .auth-component .login-container,
  .auth-component .stack-step {
    padding: 0 18px;
  }
}
.verify-input {
  min-height: 72px;
  margin-bottom: 0;
}
.password-container small {
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin: 6px 0;
  color: #9b9b9b;
}
.password-container small.help-block {
  color: #e86232;
}
.password-container #help-chars {
  margin-top: 10px;
}
.password-container #help-alpha {
  margin-bottom: 36px;
}
.auth__code-container h2 {
  margin-bottom: 0;
}
.auth__code-container p {
  margin: 8px 0;
}
.auth__code-container .input-button-group {
  margin-top: 15px;
}
.auth__resend a {
  font-size: 12px;
}
.auth__resend-confirm {
  font-size: 12px;
}
.auth-login__or {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0;
}
.auth-login__or hr {
  flex: 0 0 42%;
  margin: 0;
  border: 0;
  border-bottom: 1px solid #ddd;
}
.auth-login__or span {
  color: #bbb;
}
.toast-message {
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1600;
}
.toast-message > span {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 6px 20px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: 200;
  line-height: 17px;
}
.toast-message > span img {
  width: 14px;
  height: 10px;
  margin-right: 5px;
}
.toast-message > span svg {
  width: 16px;
  height: 16px;
  fill: #fff;
  margin-right: 10px;
}
.toast-message > span.toast--warning {
  color: #000;
  background-color: rgba(237, 175, 31, 0.7);
}
.toast-message > span.toast--error {
  background-color: rgba(232, 98, 50, 0.7);
}
.toast-message.toast-error > span {
  background-color: rgba(232, 98, 50, 0.7);
}
.dropdown {
  position: relative;
}
.dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
}
.dropdown .dropdown-menu li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  background-color: #fff;
  transition: background-color 50ms linear;
}
.dropdown .dropdown-menu li a:hover {
  background-color: #ddd;
}
.dropdown .wsicon-more {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.dropdown.active .dropdown-menu {
  display: block;
  z-index: 1;
}
.wsdd {
  position: relative;
}
.wsdd .wsicon-chevron-down {
  width: 12px;
  height: 12px;
  margin-left: 8px;
}
.wsdd .wsicon-more {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.wsdd.active .wsdd-menu {
  display: block;
}
.wsdd-menu {
  position: absolute;
  top: 90%;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  display: none;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);
}
.wsdd-menu--can-scroll-up::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  pointer-events: none;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
}
.wsdd-menu--can-scroll-down::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  pointer-events: none;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
}
.wsdd-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wsdd-label,
.wsdd-link {
  display: block;
  padding: 10px;
  background-color: #fff;
  transition: background-color 50ms linear;
  cursor: pointer;
}
.wsdd-label:hover {
  text-decoration: none;
  background-color: #ddd;
}
.select-dropdown {
  position: relative;
  box-sizing: border-box;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 12px;
  background-color: #fff;
  cursor: pointer;
}
.select-dropdown:hover {
  border-color: #bbb;
}
.select-dropdown--reset {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
.select-dropdown--open {
  z-index: 1;
}
.select-dropdown--disabled {
  cursor: default;
  background-color: #f5f5f5;
}
.select-dropdown--disabled .select-dropdown__text {
  color: #9b9b9b;
}
.select-dropdown--disabled:hover {
  border-color: #ddd;
}
.select-dropdown__text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
}
.select-dropdown__text > span {
  flex-grow: 1;
  text-align: center;
  white-space: nowrap;
}
.select-dropdown__text > svg {
  width: 11px;
  height: 11px;
  margin-left: 10px;
}
.select-dropdown__dropdown {
  position: absolute;
  top: 100%;
  left: 5px;
  transform: translateY(-5px);
  overflow: auto;
  box-sizing: border-box;
  min-width: 100%;
  max-height: 185px;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 6px;
  white-space: nowrap;
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.1);
}
.select-dropdown--loading {
  min-height: 20px;
  min-width: 40px;
}
.select-dropdown--loading .loader2 {
  width: 10px;
  height: 10px;
  border-width: 2px;
}
.select-dropdown--filter .select-dropdown__dropdown {
  max-height: 200px;
}
.select-dropdown__dropdown-filter .text-filter input {
  padding: 3px 5px 3px 25px;
  margin-top: 0;
}
.select-dropdown__dropdown-filter .text-filter .wsicon-search {
  bottom: 8px;
}
.select-dropdown__dropdown--centered {
  left: 50%;
  transform: translateX(-50%);
}
.select-dropdown__dropdown-item {
  padding: 3px 15px;
  font-size: 14px;
}
.select-dropdown__dropdown-item:hover {
  background-color: #ddd;
}
.select-dropdown__dropdown-item--link {
  cursor: default;
}
.select-dropdown__dropdown-item--link:hover {
  background-color: transparent;
}
.select-dropdown__dropdown-item--selected {
  font-weight: 700;
}
.shift-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  height: 24px;
  width: 24px;
  border-radius: 12px;
}
@media (max-width: 768px) {
  .shift-icon {
    height: 20px;
    width: 20px;
  }
}
.shift-icon.shift-day {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/day-shift.png");
  background-color: #fef9ed;
  background-position-y: -3px;
}
.shift-icon.shift-swing {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/swing-shift.png");
  background-color: #fef9ed;
}
.shift-icon.shift-night {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/night-shift.png");
  background-color: #eff1f6;
}
.shift-icon.tooltip::after {
  width: 60px;
}
.account-modal .modal-content {
  min-height: 200px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.account-modal .center-absolute {
  width: 50px;
  height: 50px;
}
.account-modal .loader {
  display: block;
  top: auto;
  left: auto;
  right: auto;
  border-width: 5px;
  width: 50px;
  height: 50px;
}
#account-modal .pw-container {
  position: relative;
}
#account-modal .pw-container .pw-reset {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  font-size: 14px;
}
#account-modal .btn-primary {
  float: right;
}
#account-modal .modal-content {
  padding: 0 10.4%;
}
.link-group {
  padding: 0 15px;
}
.link-group a {
  display: block;
  border-radius: 0;
  border-bottom: 1px solid #ddd;
  text-transform: none;
  font-size: 18px;
  line-height: 55px;
  font-weight: 500;
  text-align: center;
}
.link-group a:last-child {
  border-bottom: none;
}
.link-group a.secondary {
  font-weight: 200;
}
.link-group a.active {
  color: #00cba5;
  text-decoration: none;
  background-color: #f9f9f9;
}
.link-group a.disabled {
  color: #9b9b9b;
  pointer-events: none;
}
.link-group a:hover {
  color: #00cba5;
  text-decoration: none;
  background-color: #f5f5f5;
}
@media (max-width: 768px) {
  .link-group a {
    font-size: 16px;
    line-height: 48px;
  }
}
.portal-card-group {
  position: relative;
  width: 465px;
  min-height: 300px;
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(22, 45, 84, 0.18);
}
.portal-card-group.capacity-review-group {
  width: 750px;
}
@media (max-width: 768px) {
  .portal-card-group.capacity-review-group {
    width: auto;
  }
}
.portal-card-group.small-card-group {
  width: 385px;
}
.portal-card-group.small-card-group .portal-dialog-card {
  padding-left: 30px;
  padding-right: 30px;
}
.portal-card-group.small-card-group .portal-dialog-card p {
  text-align: center;
  font-size: 13px;
  line-height: 13px;
}
.portal-card-group.small-card-group .portal-dialog-card button {
  margin-top: 60px;
}
@media (max-width: 768px) {
  .portal-card-group {
    width: 100%;
    min-height: 430px;
    margin-top: 70px;
  }
  .portal-card-group.small-card-group {
    width: 95%;
    min-height: 0;
    overflow: auto;
    margin: 10% auto;
  }
  .portal-card-group.small-card-group .portal-dialog-card h2 {
    padding: 0;
  }
}
.portal-card-group .fade-enter {
  opacity: 0.01;
}
.portal-card-group .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}
.portal-card-group .fade-exit {
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.portal-card-group .fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}
.portal-card {
  padding: 30px 0;
}
.portal-card h2,
.portal-card h3,
.portal-card p {
  margin: 0;
}
.portal-card h2 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
}
.portal-card h3 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 200;
  color: #9b9b9b;
  margin: 10px 0 20px;
}
.portal-card p {
  font-weight: 200;
  margin-bottom: 5px;
}
.portal-card .link-group {
  margin-top: 30px;
}
@media (max-width: 768px) {
  .portal-card h2 {
    font-size: 21px;
    line-height: 27px;
  }
  .portal-card h3 {
    font-size: 14px;
    margin: 0 0 10px;
  }
  .portal-card p {
    font-size: 14px;
    margin-bottom: 0;
  }
  .portal-card .link-group {
    margin-top: 10px;
  }
}
.portal-dialog {
  position: relative;
  background-color: #fff;
}
.portal-dialog h2 {
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  margin: 10px 0;
}
.portal-dialog h4 {
  text-align: center;
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 200;
}
.portal-dialog button {
  display: block;
  margin: 0 auto;
  width: 300px;
}
.body--new-ui-check-in .portal-dialog button {
  width: 100%;
  max-width: 420px;
  margin-top: 16px;
}
.body--new-ui-check-in .portal-dialog .questions__follow-up button {
  margin-top: 16px;
}
.portal-dialog .checkbox-list,
.portal-dialog .radio-list {
  list-style: none;
  padding: 0;
}
.portal-dialog .checkbox-list input[type="checkbox"] + label,
.portal-dialog .radio-list input[type="checkbox"] + label,
.portal-dialog .checkbox-list input[type="radio"] + label,
.portal-dialog .radio-list input[type="radio"] + label {
  cursor: pointer;
  border-bottom: none;
  padding-top: 9px;
  padding-bottom: 9px;
}
.portal-dialog .checkbox-list input[type="checkbox"] + label::before,
.portal-dialog .radio-list input[type="checkbox"] + label::before {
  top: 10px;
}
.portal-dialog .checkbox-list input[type="checkbox"] + label::after,
.portal-dialog .radio-list input[type="checkbox"] + label::after {
  top: 18px;
}
.portal-dialog .checkbox-list input[type="radio"] + label::before,
.portal-dialog .radio-list input[type="radio"] + label::before {
  top: 7px;
}
.portal-dialog .checkbox-list input[type="radio"] + label::after,
.portal-dialog .radio-list input[type="radio"] + label::after {
  top: 17px;
  left: 7px;
}
.portal-dialog li {
  font-size: 16px;
  line-height: 40px;
}
.portal-dialog li input {
  margin-right: 15px;
}
@media (max-width: 768px) {
  .portal-dialog h2 {
    margin: 0;
    font-size: 21px;
    line-height: 27px;
  }
  .portal-dialog h4 {
    font-size: 14px;
    margin: 5px 0;
  }
  .portal-dialog button {
    width: 100%;
  }
  .portal-dialog .checkbox-list input[type="checkbox"] + label,
  .portal-dialog .radio-list input[type="checkbox"] + label,
  .portal-dialog .checkbox-list input[type="radio"] + label,
  .portal-dialog .radio-list input[type="radio"] + label {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    border: none;
    font-size: 16px;
  }
  .portal-dialog .checkbox-list input[type="radio"] + label::before,
  .portal-dialog .radio-list input[type="radio"] + label::before {
    top: 8px !important;
    left: 0;
    right: initial;
  }
  .portal-dialog .checkbox-list input[type="checkbox"] + label::before,
  .portal-dialog .radio-list input[type="checkbox"] + label::before {
    top: 10px !important;
    left: 0;
    right: initial;
  }
  .portal-dialog .checkbox-list input[type="checkbox"] + label::after,
  .portal-dialog .radio-list input[type="checkbox"] + label::after {
    left: 5px;
  }
  .portal-dialog .portal-dialog-content {
    position: relative;
  }
  .portal-dialog .portal-dialog-content button {
    width: 100%;
  }
}
.portal-dialog-other textarea {
  display: block;
}
.portal-dialog-error {
  color: #e86232;
  font-size: 14px;
  line-height: 18px;
}
.portal-dialog-instructions {
  background-color: #fef9ed;
  color: #edaf1f;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 40px;
}
.portal-dialog-card {
  padding: 60px 60px 20px;
  text-align: left;
}
.portal-dialog-card h2 {
  margin-top: 24px;
}
.portal-dialog-card .wsicon-chevron-left,
.portal-dialog-card .wsicon-close {
  cursor: pointer;
  position: absolute;
  padding: 20px;
  height: 20px;
  width: 20px;
}
.portal-dialog-card .wsicon-chevron-left {
  top: 20px;
  left: 5px;
}
.portal-dialog-card .wsicon-close {
  top: 20px;
  right: 5px;
}
@media (max-width: 768px) {
  .portal-dialog-card {
    padding: 20px 10px;
  }
  .portal-dialog-card h2 {
    padding: 0 30px;
  }
  .portal-dialog-card svg {
    top: 5px;
    left: 0;
  }
}
.portal-dialog-text {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
}
.portal-dialog-text h2 {
  margin: auto;
  font-size: 38px;
  line-height: 60px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .portal-dialog-text h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}
.portal-dialog__explanation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 535px;
}
.portal-dialog__explanation .portal-dialog-instructions {
  text-align: center;
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  box-sizing: border-box;
}
.portal-dialog__explanation .portal-dialog-instructions h4,
.portal-dialog__explanation .portal-dialog-instructions p {
  color: #4a4a4a;
}
.portal-dialog__explanation .portal-dialog-instructions h4 {
  font-weight: 500;
  margin: 0;
}
.portal-dialog__explanation .portal-dialog-instructions p {
  font-size: 14px;
  font-weight: 100;
  margin: 0 0 20px;
}
.portal-dialog__explanation .portal-dialog-instructions button {
  width: 200px;
  height: 50px;
}
.portal-dialog__explanation-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}
.portal-dialog__explanation-header h2 {
  color: #4a4a4a;
}
#tos-update-dialog {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-sizing: border-box;
  width: 450px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.3);
}
#tos-update-dialog h3 {
  margin-top: 0;
}
#tos-update-dialog p {
  font-size: 14px;
  line-height: 18px;
}
@media (max-width: 768px) {
  #tos-update-dialog {
    width: 90%;
  }
}
.ws-dialog__actions {
  justify-content: space-between;
}
.ws-dialog__actions .MuiButton-root:not(:first-child) {
  margin-left: 5px;
}
#candidate-preferences {
  text-align: left;
  max-width: 750px;
  margin: 30px auto 0;
}
#candidate-preferences h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 20px 0;
  color: #162d54;
}
#candidate-preferences h3 {
  font-size: 14px;
  font-weight: 300;
  color: #000;
  line-height: 18px;
  margin-bottom: 5px;
}
#candidate-preferences .section-title {
  margin: 0;
}
#candidate-preferences > header {
  padding: 10px 30px;
  background-color: #fff;
}
#candidate-preferences > footer {
  padding: 30px;
  text-align: center;
}
#candidate-preferences section > section {
  display: flex;
}
#candidate-preferences section > section > :first-child {
  flex: 0 0 300px;
}
#candidate-preferences section > section > :last-child {
  flex: 1 1 auto;
  width: auto;
}
#candidate-preferences section > section .ws-range {
  top: 24px;
}
#candidate-preferences .prefs-group {
  background: #fff;
  padding: 40px 80px 40px 30px;
  margin: 12px 0;
}
@media (max-width: 768px) {
  #candidate-preferences .prefs-group {
    padding-left: 12px;
    padding-right: 12px;
  }
}
#candidate-preferences .prefs-group-general > section {
  margin-top: 30px;
}
#candidate-preferences .prefs-group-general > section.prefs-status {
  margin-top: 18px;
}
#candidate-preferences .prefs-shifts {
  padding-bottom: 0;
}
#candidate-preferences .prefs-shifts .availability-grid {
  margin: 40px 0 20px;
}
#candidate-preferences .prefs-shifts .availability-grid__header__content {
  height: 50px;
}
@media (max-width: 768px) {
  #candidate-preferences .prefs-shifts .availability-grid {
    margin: 60px -12px 20px;
  }
}
#candidate-preferences .checkbox-list {
  margin-top: 0;
}
#candidate-preferences .checkbox-list li {
  padding: 0;
}
#candidate-preferences .checkbox-list input[type="checkbox"] + label {
  font-size: 16px;
  padding: 10px 0 10px 48px;
  border-bottom: none;
}
#candidate-preferences .checkbox-list input[type="checkbox"] + label::before {
  top: 10px;
}
#candidate-preferences .checkbox-list input[type="checkbox"] + label::after {
  top: 18px;
}
@media (max-width: 768px) {
  #candidate-preferences {
    padding: 0;
    margin-top: 0;
    padding-bottom: 100px;
    background-color: #eff1f6;
  }
  #candidate-preferences section > section {
    flex-direction: column;
  }
  #candidate-preferences section > section > :first-child {
    flex-basis: auto;
  }
  #candidate-preferences section > section .ws-range {
    top: 0;
  }
  #candidate-preferences .prefs-commute-types h3 {
    margin-bottom: 15px;
  }
  #candidate-preferences .candidate-profile {
    padding-bottom: 90px;
  }
  #candidate-preferences > header {
    padding-left: 12px;
    padding-right: 12px;
  }
  #candidate-preferences.candidate-preferences-review .prefs-group-general {
    border-top: 0;
  }
  #candidate-preferences.candidate-preferences-review footer {
    background-color: transparent;
    position: fixed;
    bottom: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  #candidate-preferences.candidate-preferences-review footer button {
    border-radius: 19px;
    min-width: initial;
  }
  #candidate-preferences.candidate-preferences-review footer button.btn-large {
    border-radius: 24px;
  }
  #candidate-preferences.candidate-preferences-review footer button.btn-small {
    border-radius: 15px;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  #candidate-preferences .candidate-profile > section {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.wage-pref-input {
  position: relative;
}
.wage-pref-input input[type="number"] {
  padding-left: 20px;
}
.wage-input-prefix,
.wage-input-suffix {
  position: absolute;
  top: 50%;
  margin-top: -11px;
}
.wage-input-prefix {
  left: 0;
}
.wage-input-suffix {
  left: 50px;
}
.prefs-commute-types-container {
  display: flex;
  justify-content: space-between;
}
.prefs-commute-types-container input[type="checkbox"] {
  display: none;
}
.prefs-commute-types-container input[type="checkbox"] + label {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 49%;
  margin: 0;
  padding: 0 0 15px 0;
  height: 100px;
  cursor: pointer;
  color: #162d54;
  background-color: #eff1f6;
  border-radius: 6px;
  text-transform: uppercase;
  transition: all 100ms ease-in-out;
}
.prefs-commute-types-container input[type="checkbox"] + label svg {
  max-width: 48px;
  fill: #162d54;
  transition: fill 100ms ease-in-out;
}
.prefs-commute-types-container input[type="checkbox"] + label .wsicon-approve {
  fill: #eff1f6;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
}
.prefs-commute-types-container input[type="checkbox"] + label:hover {
  background-color: #d8dde6;
}
.prefs-commute-types-container input[type="checkbox"] + label:hover .wsicon-approve {
  fill: #d8dde6;
}
.prefs-commute-types-container input[type="checkbox"]:checked + label {
  color: #fff;
  background-color: #00cba5;
}
.prefs-commute-types-container input[type="checkbox"]:checked + label svg {
  fill: #fff;
}
.prefs-commute-types-container input[type="checkbox"]:checked + label:hover {
  background-color: #60ffd6;
}
.prefs-commute-types-container input[type="checkbox"]:checked + label:hover .wsicon-approve {
  fill: #fff;
}
.prefs-commute-length {
  margin-top: 30px;
}
.prefs-commute-length .prefs-commute-length-container {
  display: flex;
  flex-direction: column;
}
.prefs-commute-length select {
  margin-top: 0;
}
.prefs-commute-length .prefs-commute-existing-address {
  display: flex;
  align-items: center;
  color: #9b9b9b;
  font-size: 12px;
}
.prefs-commute-length .prefs-commute-existing-address span {
  flex: 0 1 auto;
  padding: 0 10px 0 0;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.prefs-commute-length .prefs-commute-existing-address span::before {
  content: "From: ";
}
.prefs-commute-address {
  margin-top: 30px;
}
.prefs-commute-address input {
  margin-top: 0;
}
.prefs-commute-address .btn {
  float: right;
}
.prefs-commute-address .prefs-commute-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.prefs-commute-address .prefs-commute-address-container .prefs-commute-address-input {
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 15px;
}
.prefs-commute-address .prefs-commute-address-container .form-error {
  font-size: 11px;
  line-height: 14px;
}
.commute-modal__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.prefs-commute-address-dym-container {
  background-color: #fef9ed;
  padding: 10px 5px;
  font-size: 11px;
  line-height: 14px;
}
.pref-modal-mixin {
  padding: 0;
}
.pref-modal-mixin section > section {
  flex-direction: column;
}
.pref-modal-mixin section > section > :first-child {
  flex-basis: auto;
}
.pref-modal-mixin section > section .ws-range {
  top: 0;
}
.pref-modal-mixin .prefs-commute-types h3 {
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .pref-modal-mixin .candidate-profile > section {
    padding-left: 12px;
    padding-right: 12px;
  }
}
#edit-preferences-modal {
  padding: 0;
}
#edit-preferences-modal section > section {
  flex-direction: column;
}
#edit-preferences-modal section > section > :first-child {
  flex-basis: auto;
}
#edit-preferences-modal section > section .ws-range {
  top: 0;
}
#edit-preferences-modal .prefs-commute-types h3 {
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  #edit-preferences-modal .candidate-profile > section {
    padding-left: 12px;
    padding-right: 12px;
  }
}
#edit-preferences-modal .prefs-group {
  margin: 0;
  padding-top: 0;
  padding-right: 30px;
}
@media (max-width: 768px) {
  #edit-preferences-modal .prefs-group {
    padding-right: 12px;
  }
}
.checkbox-list.shift-list input[type="checkbox"] + label {
  display: flex;
  align-items: center;
}
.checkbox-list.shift-list .shift-desc {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.checkbox-list.shift-list .shift-desc span {
  font-size: 16px;
}
.checkbox-list.shift-list .shift-desc em {
  font-size: 12px;
  color: #9b9b9b;
  font-style: normal;
}
.checkbox-list.shift-list .shift-icon {
  display: block;
  height: 40px;
  width: 40px;
}
.pill-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.pill-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: #00cba5;
  padding: 5px 15px;
  margin: 0 10px 10px 0;
  border-radius: 20px;
  min-width: 80px;
}
.pill-list li svg {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  fill: #fff;
  opacity: 0.5;
  transition: opacity 100ms ease-in;
}
.pill-list li a:hover svg {
  opacity: 0.9;
}
#referral-modal {
  max-width: 400px;
}
@media (max-width: 768px) {
  #referral-modal {
    padding: 0;
  }
}
.referral-container {
  text-align: center;
}
.referral-container > header {
  margin-top: 40px;
}
.referral-container h2 {
  margin: 12px 0;
  font-size: 30px;
}
.referral-container h3 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin: 0 0 20px;
  padding: 0 60px;
}
.referral-container .tab-switcher {
  max-width: 600px;
  margin: 0 auto;
}
.referral-container .tab-switcher__footer .btn {
  min-width: 50%;
}
.referral-container .tab-switcher__footer .btn span:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.referral-container .tab-switcher__footer .btn span:last-child svg {
  width: 16px;
  height: 16px;
  fill: #fff;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .referral-container {
    padding: 0 15px;
  }
  .referral-container h2 {
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .referral-container h3 {
    font-size: 13px;
    padding: 0;
    margin-bottom: 15px;
  }
  .referral-container .tab-switcher {
    max-width: none;
    margin: 0 5px;
  }
  .referral-container .tab-switcher__footer {
    margin: 10px 0;
  }
  .referral-container .tab-switcher__footer .btn {
    width: 100%;
  }
}
.referral-header__icon {
  display: inline-block;
  padding: 20px;
  border-radius: 50%;
  background-color: #eff1f6;
}
.referral-header__icon img {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.referral-options__or {
  font-size: 16px;
  color: #bbb;
  margin-bottom: 5px;
}
.referral-options__share {
  font-size: 14px;
  margin: 0;
}
.referral-options__buttons {
  margin: 10px 0;
}
.referral-options__icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 30px;
}
.referral-options__icons svg {
  width: 20px;
  height: 20px;
}
.referral-options__icons a {
  display: inline-block;
  text-decoration: none;
}
.referral-options__icons a:not(:last-child) {
  margin-right: 20px;
}
.referral-options__icons a[data-network="fb"] svg {
  fill: #3b5998;
}
.referral-options__icons a[data-network="tw"] svg {
  fill: #00aced;
}
.referral-options__icons a[data-network="li"] svg {
  fill: #0077b5;
}
.referral-footer {
  margin-top: 60px;
}
.referral-footer a {
  font-weight: 400;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .referral-footer {
    margin-top: 30px;
  }
}
.tab-switcher__body {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.tab-switcher__tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}
.tab-switcher__tab {
  flex: 0 0 50%;
  align-items: center;
  height: 40px;
  margin-bottom: -1px;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 36px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 100ms ease-in;
}
.tab-switcher__tab:hover {
  border-bottom-color: #9b9b9b;
}
.tab-switcher__tab.active {
  border-bottom-color: #00cba5;
  font-weight: 600;
}
.tab-switcher__tab.active:hover {
  border-bottom-color: #60ffd6;
}
.tab-switcher__tab.active.error {
  border-bottom-color: #e86232;
}
.tab-switcher__tab.active.error:hover {
  border-bottom-color: #e86232;
}
.tab-switcher__content_container {
  position: relative;
}
.tab-switcher__content textarea {
  font-size: 14px;
  padding: 8px 12px;
  box-sizing: border-box;
  min-height: 72px;
  border-bottom: 0;
  margin: 0;
}
.tab-switcher__content textarea:active,
.tab-switcher__content textarea:focus,
.tab-switcher__content textarea:hover {
  border: 0;
  margin: 0;
}
.tab-switcher__footer {
  text-align: center;
  margin: 20px 0;
}
.progress-circle {
  position: relative;
}
.progress-circle__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -60%);
}
.progress-circle__svg {
  fill: transparent;
  transform: rotate(135deg);
}
.progress-circle__svg circle {
  fill: transparent;
}
.progress-circle__svg.progress-low .progress-circle__bar {
  stroke: #ce2e3a;
}
.progress-circle__svg.progress-med .progress-circle__bar {
  stroke: #edaf1f;
}
.progress-circle__inner {
  stroke: #9caac0;
}
.progress-circle__bar {
  stroke: #00cba5;
}
.progress-button {
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  border: 1px solid #9caac0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.progress-zero .progress-text {
  color: #9caac0;
}
.progress-low .progress-text,
.progress-low .progress-button {
  color: #ce2e3a;
}
.progress-low .progress-button::before {
  content: "low";
}
.progress-low .progress-circle__bar {
  stroke: #edaf1f;
}
.progress-med .progress-text,
.progress-med .progress-button {
  color: #edaf1f;
}
.progress-med .progress-button::before {
  content: "medium";
}
.progress-med .progress-circle__bar {
  stroke: #edaf1f;
}
.progress-high .progress-text,
.progress-high .progress-button {
  color: #00cba5;
}
.progress-high .progress-button::before {
  content: "high";
}
.progress-high .progress-circle__bar {
  stroke: #00cba5;
}
.progress-bar {
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #ddd;
}
.progress-bar__inner {
  height: 100%;
  background-color: #00cba5;
  transition: width 200ms ease-in-out;
}
.progress-bar__content {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
.progress-bar__metric {
  position: absolute;
  border-left: 2px dashed #edaf1f;
  bottom: 0;
  width: 1px;
  height: 100%;
}
.suggestions__intro {
  font-size: 24px;
  line-height: 36px;
  padding: 16px;
}
@media (max-width: 768px) {
  .suggestions__intro {
    font-size: 13px;
    line-height: 18px;
    padding: 10px 0;
  }
}
.suggestions__intro-skip {
  margin-top: 30px;
  text-align: right;
}
.suggestions__intro-skip a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}
.suggestions__intro-skip a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .suggestions__intro-skip a {
    font-size: 13px;
  }
}
.suggestions__container {
  background-color: #eff1f6;
  padding: 0 0 24px;
  margin: 0 -40px -36px;
}
@media (max-width: 768px) {
  .suggestions__container {
    margin: 0;
    height: 440px;
  }
}
.suggestions__root {
  height: 660px;
  max-width: 728px;
  overflow: hidden;
}
.suggestions__root .suggestions__container {
  padding: 15px 15px 0;
}
@media (max-width: 768px) {
  .suggestions__root {
    height: auto;
    max-width: none;
  }
}
@media (min-height: 690px) and (min-width: 768px) {
  .suggestions__buttons {
    margin: 48px auto auto;
    position: static;
    bottom: initial;
    left: initial;
    transform: initial;
  }
}
.suggestions__buttons-inner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  max-width: 420px;
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .suggestions__buttons-inner {
    max-width: none;
  }
}
.suggestions__pass,
.suggestions__approve,
.suggestions__undo,
.suggestions__apply {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.2);
  transition: box-shadow 100ms ease-in;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.suggestions__pass:hover,
.suggestions__approve:hover,
.suggestions__undo:hover,
.suggestions__apply:hover {
  box-shadow: 0 2px 10px 0 rgba(22, 45, 84, 0.4);
}
@media (max-width: 768px) {
  .suggestions__pass,
  .suggestions__approve,
  .suggestions__undo,
  .suggestions__apply {
    padding: 0;
  }
  .suggestions__pass svg,
  .suggestions__approve svg,
  .suggestions__undo svg,
  .suggestions__apply svg {
    padding: 0;
  }
}
.suggestions__pass,
.suggestions__approve {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  box-sizing: border-box;
  margin: 0 12px;
}
.suggestions__pass.tooltip::after,
.suggestions__approve.tooltip::after {
  min-width: 90px;
  width: initial;
  bottom: 75px;
}
.suggestions__pass.tooltip::before,
.suggestions__approve.tooltip::before {
  bottom: 55px;
}
@media (max-width: 768px) {
  .suggestions__pass,
  .suggestions__approve {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    box-sizing: border-box;
  }
}
.suggestions__pass svg {
  width: 20px;
  height: 20px;
  fill: #e86232;
}
.suggestions__pass:hover svg {
  fill: #eb7449;
}
.suggestions__approve svg {
  width: 25px;
  height: 25px;
  fill: #00cba5;
}
.suggestions__approve:hover svg {
  fill: #60ffd6;
}
.suggestions__undo,
.suggestions__apply {
  width: 46px;
  height: 46px;
  border-radius: 46px;
  box-sizing: border-box;
}
.suggestions__undo svg,
.suggestions__apply svg {
  fill: #9caac0;
  width: 18px;
  height: 18px;
}
.suggestions__undo.tooltip::after,
.suggestions__apply.tooltip::after {
  min-width: 90px;
  width: initial;
  bottom: 60px;
}
.suggestions__undo.tooltip::before,
.suggestions__apply.tooltip::before {
  bottom: 40px;
}
.suggestions__undo:hover svg,
.suggestions__apply:hover svg {
  fill: #c2cad7;
}
@media (max-width: 768px) {
  .suggestions__undo,
  .suggestions__apply {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    box-sizing: border-box;
  }
}
.suggestions__apply svg {
  position: relative;
  right: 1px;
  top: 1px;
}
.suggestions__buttons-inner--full {
  align-items: flex-start;
  justify-content: space-between;
}
.suggestions__buttons-inner--full .suggestions__approve,
.suggestions__buttons-inner--full .suggestions__pass {
  margin: 0;
}
.suggestions__empty,
.suggestions__unlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-top: 30px;
  text-align: center;
}
@media (max-width: 768px) {
  .suggestions__empty,
  .suggestions__unlock {
    margin-top: 60px;
  }
}
.suggestions__empty-header {
  margin: 20px 0 40px;
}
@media (max-width: 768px) {
  .suggestions__empty-header {
    margin-bottom: 100px;
  }
}
.suggestions__empty-subheader {
  margin: 0 0 20px;
  font-size: 13px;
  font-style: italic;
  line-height: 17px;
}
.suggestions__unlock-header {
  margin: 15px 0 10px;
  color: #162d54;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}
.suggestions__unlock-subheader {
  margin: 0 0 50px;
  color: #3d5afe;
}
.suggestions__unlock-footer {
  margin: 10px 0 0;
  font-size: 14px;
  line-height: 18px;
}
.suggestions__root--details {
  height: auto;
  max-width: 960px;
}
.suggestions__root--details .suggestions__buttons {
  width: 940px;
}
@media (min-height: 690px) {
  .suggestions__root--details .suggestions__buttons {
    margin-top: 0;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.suggestions__root--details .suggestions__buttons-inner {
  margin: 0;
}
.suggestions__root--details .position-map-commute-dialog {
  display: none;
}
.suggestions__root--details .suggestions__intro {
  display: none;
}
.suggestions__root--details .suggestions__container {
  display: none;
}
.suggestions__root--details .suggestions__undo,
.suggestions__root--details .suggestions__apply {
  visibility: hidden;
}
.step_suggestions:not(.position-inactive) .back {
  position: fixed;
  top: 86px;
}
@media (max-width: 768px) {
  .step_suggestions:not(.position-inactive) .back {
    top: 16px;
  }
}
#auto-match-modal p {
  margin: 30px 0;
}
#auto-match-modal .btn {
  display: block;
  margin: 0 auto;
  width: 150px;
}
@media (max-width: 768px) {
  .suggestions__intro--test {
    display: flex;
    flex-direction: column;
    margin: 0 -12px 20px;
    padding: 20px;
    background-color: #fff;
    text-align: center;
  }
  .suggestions__intro--test em {
    color: #666;
  }
}
.suggestions-row__container {
  background-color: #eff1f6;
  padding: 0 0 24px;
  margin: 0 -95px;
}
.suggestions-row__container.checkbox-list input[type="checkbox"] + label {
  border-bottom: 0;
  padding: 0;
}
.suggestions-row__container.checkbox-list input[type="checkbox"] + label::before {
  top: 0;
}
.suggestions-row__container.checkbox-list input[type="checkbox"] + label::after {
  top: 7px;
}
@media (max-width: 768px) {
  .suggestions-row__container {
    display: flex;
    width: 100vw;
    overflow: auto;
    margin: 0 -12px;
  }
}
.suggestion-row {
  display: flex;
  margin-bottom: 5px;
  padding: 20px;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}
@media (max-width: 768px) {
  .suggestion-row {
    flex-direction: column;
    flex: 0 0 260px;
    box-sizing: border-box;
    width: 260px;
    min-height: 340px;
    padding: 15px;
    margin: 0 10px;
    border-radius: 5px;
    box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.12);
  }
  .suggestion-row:first-child {
    margin-left: 20px;
  }
  .suggestion-row:last-child {
    margin-right: 20px;
  }
}
@media (max-width: 768px) {
  .suggestion-row--selected {
    border-color: #00cba5;
    box-shadow: 0 2px 14px 0 rgba(0, 203, 165, 0.3);
  }
}
.suggestion-row__checkbox {
  order: 1;
}
@media (min-width: 768px) {
  .suggestion-row__checkbox {
    flex: 0 0 52px;
  }
}
@media (max-width: 768px) {
  .suggestion-row__checkbox {
    flex: 0 0 30px;
  }
}
.suggestion-row__title-container {
  order: 2;
}
@media (min-width: 768px) {
  .suggestion-row__title-container {
    box-sizing: border-box;
    flex: 0 0 210px;
    padding-right: 10px;
  }
}
.suggestion-row__title {
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}
.suggestion-row__title a {
  color: #000;
}
.suggestion-row__title a:hover {
  color: #000;
}
@media (max-width: 768px) {
  .suggestion-row__title {
    font-size: 18px;
    line-height: 21px;
  }
}
@media (min-width: 768px) {
  .suggestion-row__keymeta {
    display: flex;
    flex-direction: column;
  }
}
.suggestion-row__company,
.suggestion-row__location {
  font-size: 13px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 768px) {
  .suggestion-row__company,
  .suggestion-row__location {
    font-size: 12px;
    line-height: 15px;
  }
}
.suggestion-row__company {
  margin-right: 5px;
}
@media (max-width: 768px) {
  .suggestion-row__company {
    margin-right: 3px;
  }
}
.suggestion-row__location {
  color: #9b9b9b;
  text-overflow: ellipsis;
}
@media (min-width: 768px) {
  .suggestion-row__details-container {
    order: 3;
    box-sizing: border-box;
    flex: 0 0 300px;
    padding-right: 60px;
  }
}
@media (max-width: 768px) {
  .suggestion-row__details-container {
    order: 4;
  }
}
.suggestion-row__details {
  position: relative;
}
.suggestion-row__details p {
  margin: 0;
  height: 90px;
  overflow: hidden;
  font-size: 13px;
  line-height: 18px;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
@media (max-width: 768px) {
  .suggestion-row__details p {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .suggestion-row__meta {
    order: 4;
    box-sizing: border-box;
    flex: 0 0 185px;
    padding-right: 5px;
  }
}
@media (max-width: 768px) {
  .suggestion-row__meta {
    order: 3;
    margin: 10px 0;
  }
}
.suggestion-row__commute-times {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.suggestion-row__commute-time {
  display: flex;
  align-items: center;
  flex: 0 1 80px;
  box-sizing: border-box;
  height: 22px;
  padding: 2px 0;
  color: #162d54;
  background-color: #eff1f6;
  border-radius: 12px;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
}
.suggestion-row__commute-time svg {
  fill: #162d54;
  width: 14px;
  height: 14px;
  margin: 0 5px 0 10px;
}
.suggestion-row__commute-time:first-child:not(:last-child) {
  margin-right: 5px;
}
.suggestion-row__meta-field {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.suggestion-row__meta-field .wsicon-wallet {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.suggestion-row__meta-field .wsicon-watch {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.suggestion-row__meta-field:first-child {
  margin-right: 12px;
}
.suggestion-row__meta-value {
  font-size: 12px;
  line-height: 18px;
}
.suggestion-row__button {
  text-align: center;
}
.suggestion-row__button a.btn {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}
.suggestions-row__link {
  order: 5;
}
.suggestions-row__link a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .suggestions-row__link {
    margin-top: 15px;
    text-align: center;
  }
  .suggestions-row__link a {
    color: #9caac0;
  }
  .suggestions-row__link a:hover {
    color: #9caac0;
  }
}
.suggestion-row__auto-match-icon {
  position: relative;
  vertical-align: middle;
  top: -2px;
  height: 40px;
  width: 40px;
}
.suggestion-row__auto-match-bar {
  color: #3d5afe;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 8px 0;
}
.suggestion-row__auto-match-bar em {
  color: #9b9b9b;
  font-style: normal;
  font-weight: 300;
  margin-left: 6px;
}
.suggestion-row__auto-match-bar:hover {
  opacity: 0.7;
}
@media (max-width: 768px) {
  .suggestion-row__auto-match-bar {
    font-size: 12px;
  }
}
.suggestions__submit {
  display: block;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .suggestions__submit {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .suggestions__submit.loading {
    position: fixed;
  }
}
.search-tabs {
  display: none;
}
@media (max-width: 768px) {
  .search-tabs {
    width: 100%;
    display: flex;
    padding: 0 12px 15px;
    margin: 0 -12px 15px;
    border-bottom: 1px solid #ddd;
  }
  .search-tabs .search-tabs__tab {
    height: 28px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 23px 0;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: black;
  }
  .search-tabs .search-tabs__tab svg {
    width: 19.4px;
    height: 30px;
    margin-right: 10px;
    fill: #9caac0;
  }
  .search-tabs .search-tabs__tab--jobs {
    margin-right: 10px;
  }
  .search-tabs .search-tabs__tab--featured {
    border-bottom: 5px solid #00cba5;
  }
  .search-tabs .search-tabs__tab--featured svg {
    fill: #00cba5;
  }
}
.logged-in-location {
  display: none;
}
@media (max-width: 768px) {
  .logged-in-location {
    display: block;
    text-align: right;
  }
  .logged-in-location .logged-in-location__text {
    position: relative;
    padding-left: 20px;
    font-size: 11px;
    color: #9b9b9b;
  }
  .logged-in-location .logged-in-location__text::before {
    position: absolute;
    width: 10px;
    height: 14px;
    top: 0;
    left: 3px;
    background: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/icon-pin.svg");
    background-size: 10px 14px;
    background-repeat: no-repeat;
    content: "";
  }
}
.reference-modal__subheader {
  line-height: 20px;
  font-size: 14px;
}
.reference-modal__jobs {
  border: 1px solid #ddd;
  padding: 10px;
}
.reference-modal__jobs > header {
  font-size: 15px;
  margin-bottom: 5px;
}
.reference-modal__jobs > p {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}
.reference-modal__jobs .radio-list input[type="radio"] + label {
  padding: 21px 0 0 40px;
  border: 0;
  font-size: 15px;
  line-height: 21px;
}
.reference-modal__no-jobs {
  background-color: #fef9ed;
  padding: 10px;
  margin-top: 20px;
}
.cb__copied {
  display: none;
}
.cb__link svg {
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  fill: #00cba5;
}
.cb__link.cb--success .cb__text {
  visibility: hidden;
}
.cb__link.cb--success .cb__copied {
  display: inline-block;
  color: #00cba5;
}
.cb__btn {
  color: #bbb;
  border-color: #ddd;
}
.cb__btn svg {
  width: 18px;
  height: 18px;
  fill: #00cba5;
  margin-right: 10px;
}
.cb__btn.cb--success {
  color: #bbb;
  border-color: #ddd;
  border-color: #00cba5;
}
.cb__btn.cb--success svg {
  width: 18px;
  height: 18px;
  fill: #00cba5;
  margin-right: 10px;
}
.cb__btn.cb--success .cb__text {
  visibility: hidden;
}
.cb__btn.cb--success .cb__copied {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00cba5;
}
.cb__btn.cb--success:hover {
  border-color: #00cba5;
}
.invite-cards {
  position: relative;
  display: flex;
  flex-direction: column;
}
.invite-cards__modal {
  width: 400px;
  padding: 0;
  border-radius: 0;
}
@media (max-width: 768px) {
  .invite-cards__modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    width: auto;
    margin: 0;
    background-color: #eff1f6;
  }
}
.invite-cards__cards {
  padding: 12px;
  margin-top: 1px solid #ddd;
  background-color: #eff1f6;
}
.invite-cards__header {
  color: #fff;
  background-color: #3d5afe;
  padding: 15px 15px 0;
}
.invite-cards__header div {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
.invite-cards__header img {
  display: block;
  margin: 15px auto 0;
}
.invite-cards__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.75);
}
.invite-cards__loader.fixed {
  position: fixed;
}
.invite-cards__loader .circle-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.invite-cards__footer {
  background-color: #eff1f6;
  padding: 15px 15px 35px;
  text-align: center;
}
.invite-cards__footer a {
  color: #9b9b9b;
  font-weight: 500;
}
.invite-cards__footer a:hover {
  color: #9b9b9b;
}
.invite-card {
  position: relative;
  padding: 15px;
  border: 1px solid #ddd;
  margin: 15px 0;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
}
.invite-card:last-child {
  margin-bottom: 0;
}
.invite-card--withdrawn {
  box-shadow: none;
}
.invite-card__position {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}
.invite-card__company {
  color: #666;
  font-size: 14px;
}
.invite-card__interview {
  color: #666;
  font-size: 13px;
}
.invite-card__links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.invite-card__schedule-link {
  font-size: 16px;
  font-weight: 500;
}
.invite-card__withdraw-link {
  margin-top: 12px;
  color: #9b9b9b;
  font-size: 15px;
}
.invite-card__withdraw-link:hover {
  color: #9b9b9b;
}
.invite-card__withdraw {
  text-align: center;
  padding-bottom: 10px;
}
.invite-card__withdraw p {
  margin-top: 0;
  font-size: 14px;
  line-height: 21px;
}
.invite-card__withdraw .link-group a {
  border: 0;
  padding: 8px 0;
  font-size: 16px;
  line-height: 21px;
}
.invite-card__withdraw .link-group a.invite-card__withdraw-no {
  color: #9b9b9b;
  font-weight: 300;
}
.invite-card__withdraw .link-group a.invite-card__withdraw-no:hover {
  color: #9b9b9b;
}
.invite-card__withdraw-success {
  text-align: center;
}
.invite-card__withdraw-success p {
  color: #4a4a4a;
  font-size: 14px;
  line-height: 21px;
}
.tab-list {
  display: flex;
  justify-content: space-between;
}
.tab-list__tab:not(:last-child) {
  margin-right: 40px;
}
.tab-list__link {
  position: relative;
  color: #9b9b9b;
  font-size: 18px;
  line-height: 25px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  transition: all 100ms ease-in;
}
.tab-list__link:hover {
  color: #666;
  border-bottom-color: #00cba5;
  text-decoration: none;
}
.tab-list__badge {
  position: absolute;
  top: -4px;
  right: -18px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  color: #fff;
  background-color: #00cba5;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
}
.tab-list__tab--active .tab-list__link {
  color: #000;
  font-weight: 700;
  border-bottom-color: #00cba5;
}
.sort-header {
  cursor: pointer;
}
.sort-header::after {
  content: "\25BC";
  font-size: 8px;
  vertical-align: middle;
  padding-left: 5px;
  visibility: hidden;
}
.sort-header.sort-asc::after {
  content: "\25B2";
  visibility: visible;
}
.sort-header.sort-desc::after {
  content: "\25BC";
  visibility: visible;
}
.ws-text-bubble {
  border-radius: 4px;
  padding: 16px;
  border: 1px solid #3d5afe;
  box-sizing: border-box;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out, min-width 0.5s ease-in-out, min-height 0.5s ease-in-out;
  min-width: 100%;
  min-height: 96px;
  background-color: white;
  color: #162d54;
}
.ws-text-bubble.entering {
  background-color: #3d5afe;
  color: #fafbfb;
  min-width: 0;
  min-height: 24px;
}
.ws-text-bubble.entered {
  background-color: #3d5afe;
  color: #fafbfb;
  min-width: 0;
  min-height: 24px;
}
.wsicon-shift-day,
.wsicon-shift-swing {
  fill: #edaf1f;
}
.wsicon-shift-night {
  fill: #9caac0;
}
.profile-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 280px;
  padding-top: 40px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.14);
}
.profile-card__icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  flex: 0 0 auto;
}
.profile-card__user {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.profile-card__name {
  margin: 0;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: #000;
}
.profile-card__location {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: #9b9b9b;
}
.profile-card__initials {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  box-sizing: border-box;
  border: 5px solid #fff;
  background-color: #9caac0;
}
.profile-card__initials span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 500;
  color: #eff1f6;
}
.profile-card__stats {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  border-top: 1px solid #ddd;
}
.profile-card__stat {
  box-sizing: border-box;
  flex: 0 0 33.3%;
  height: 60px;
  margin: 0;
  padding: 10px;
  text-align: center;
}
.profile-card__stat:not(:last-child) {
  border-right: 1px solid #ddd;
}
.profile-card__stat div {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}
.profile-card__stat figcaption {
  color: #9b9b9b;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
}
.react-datepicker__month-container {
  float: none;
}
.react-datepicker__day--in-range {
  background-color: #162d54;
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range {
  background-color: #162d54;
  color: #fff;
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range:hover {
  background-color: #9caac0;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #00cba5;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected:hover {
  background-color: #60ffd6;
}
.react-datepicker__close-icon::after {
  background-color: #bbb;
}
.react-datepicker__navigation {
  border-width: 9px;
}
.react-datepicker__portal .react-datepicker__navigation--previous {
  top: 7px;
  left: 0;
}
.react-datepicker__portal .react-datepicker__navigation--next {
  top: 7px;
  right: 0;
}
.pagination-control__container {
  text-align: center;
  display: flex;
  align-items: center;
  margin: 10px;
}
.pagination-control__container svg {
  width: 24px;
  height: 24px;
}
.pagination-control__selector,
.pagination-control__spacer {
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-decoration: none;
  border-radius: 2px;
  color: inherit;
  margin: 0;
}
.pagination-control__selector.disabled,
.pagination-control__spacer.disabled,
.pagination-control__selector.disabled:hover,
.pagination-control__spacer.disabled:hover {
  background: none;
  color: #c2cad7;
  fill: #c2cad7;
}
.pagination-control__selector {
  margin: 0 8px;
  color: #5e6785;
  fill: #5e6785;
}
.pagination-control__spacer {
  /* no spacing required currently */
  display: none;
}
.pagination-control__selector:hover {
  background: #ddd;
  color: #5e6785;
  fill: #5e6785;
  text-decoration: none;
}
.pagination-control__selector--active,
.pagination-control__selector--active:hover {
  background: #3d5afe;
  color: white;
  fill: white;
}
.ws-combo {
  position: relative;
}
.ws-combo__input-container {
  position: relative;
}
.ws-combo__icon {
  position: absolute;
  left: 5px;
}
.ws-combo__icon svg {
  width: 14px;
  height: 14px;
  fill: #bbb;
}
input.ws-combo__input {
  margin: 0;
  padding: 3px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 15px;
  line-height: 18px;
}
input.ws-combo__input:hover,
input.ws-combo__input:focus,
input.ws-combo__input:active {
  border: 1px solid #bbb;
  margin: 0;
}
input.ws-combo__input--withicon {
  padding-left: 25px;
}
.ws-combo__list {
  display: none;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}
.ws-combo__list--active {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  max-height: 300px;
  border: 1px solid #ddd;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.2);
}
.ws-combo__item a {
  display: flex;
  align-items: center;
  padding: 2px 5px;
}
.ws-combo__item a:hover {
  background-color: #f5f5f5;
  text-decoration: none;
}
.ws-combo__item svg {
  height: 16px;
  width: 16px;
  margin-right: 7px;
}
.ws-combo__item--selected a {
  background-color: #f4fcfa;
}
.ws-combo-filter__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.ws-combo-filter__group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.ws-combo-filter__group:not(:first-child) {
  padding-left: 28px;
}
.ws-combo-filter__group:not(:first-child)::before {
  content: "AND";
  position: absolute;
  left: 0;
  margin-right: 5px;
  font-size: 11px;
  color: #9caac0;
}
.ws-combo-filter__item {
  display: flex;
  align-items: center;
  margin: 0 5px 5px 0;
  padding: 2px 8px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 13px;
  line-height: 18px;
  background-color: #eff1f6;
}
.ws-combo-filter__item svg {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.ws-combo-filter__item-remove {
  margin-left: 15px;
}
.ws-combo-filter__item-remove svg {
  height: 8px;
  width: 8px;
  margin-right: 0;
}
.ws-combo-filter__item-remove:hover svg {
  fill: #e86232;
}
.ws-combo-filter__clear {
  font-size: 13px;
}
.ws-combo-filter__recent-item {
  padding: 10px 5px 5px;
  cursor: pointer;
}
.ws-combo-filter__recent-item:hover {
  background-color: #f4fcfa;
}
.ws-filter-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -7px;
}
.ws-filter-group .ws-filter:not(:last-child) {
  margin-right: 15px;
}
.ws-modal .ws-filter-group .ws-filter:not(:last-child) {
  margin-right: 7px;
}
.ws-filter-group > * {
  margin-top: 7px;
}
.ws-filter .wsdd-list {
  min-width: 250px;
  max-height: 250px;
  overflow: auto;
}
.ws-filter .wsdd-label,
.ws-filter .wsdd-link {
  display: flex;
  align-items: flex-start;
  padding: 3px 5px;
  font-size: 13px;
  line-height: 18px;
}
.ws-filter .wsdd-text {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 13px;
  line-height: 13px;
  color: #000;
  background-color: #fff;
}
.ws-filter .wsdd-text:hover {
  color: #000;
  text-decoration: none;
  background-color: #f5f5f5;
}
.ws-filter .wsdd-text > span {
  white-space: nowrap;
}
.ws-filter .wsdd-text .wsicon {
  flex-shrink: 0;
}
.ws-filter input {
  margin: 3px 5px 0 0;
}
.ws-filter__face {
  overflow: hidden;
  text-overflow: ellipsis;
}
.confirm-popover-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.confirm-popover-modal.fixed {
  position: fixed;
}
.confirm-popover-modal-content {
  box-sizing: border-box;
  flex: 0 0 auto;
  min-width: 320px;
  max-width: 90%;
  padding: 15px;
  background-color: #fff;
}
.confirm-popover-modal-content .wsicon-close {
  position: relative;
  top: unset;
  right: unset;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.confirm-popover-modal-content header {
  font-size: 21px;
  font-weight: 500;
}
.confirm-popover-modal-content div {
  margin: 15px 0 30px;
}
.confirm-popover-modal-content footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textfield-indeterminate input,
.textfield-indeterminate textarea,
.textfield-indeterminate div {
  color: #9caac0 !important;
}
.textfield-indeterminate div[role="button"] {
  padding-left: 0;
}
#ws-text-autocomplete {
  padding-left: 10px;
  border-bottom: none;
}
.ws-text-autocomplete__input {
  height: 48px;
  padding-right: 14px;
  border-bottom: none;
}
.ws-text-autocomplete__options {
  background-color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 260px;
  overflow-y: auto;
  padding: 8px 0;
}
.ws-text-autocomplete__single-option {
  display: flex;
  justify-content: space-between;
}
.ws-text-autocomplete__single-option__selected {
  background-color: #eff2ff;
}
.ws-text-autocomplete__container {
  max-width: 270px;
  overflow-y: hidden;
}
.ws-text-autocomplete__container:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d8dde6;
}
.ws-text-autocomplete__container .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-bottom: 1px solid #d8dde6;
  border-radius: none;
}
.ws-text-autocomplete__container .MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid #d8dde6;
  border-radius: unset;
}
.ws-text-autocomplete__container:focus-visible .MuiOutlinedInput-root {
  outline: none !important;
}
.ws-text-autocomplete__container ul {
  padding-inline-start: 0;
}
.ws-text-autocomplete__no-results {
  height: 47px;
  display: flex;
  padding: 4px 24px;
  align-items: center;
}
.ql-editor.ql-blank::before {
  content: none;
}
.ql-editor.ql-blank::after {
  color: #9caac0;
  content: attr(data-placeholder);
  font-style: italic;
  pointer-events: none;
  display: block;
  margin-top: -1.42rem;
}
.ws-rich-text-editor {
  display: grid;
}
.ws-rich-text-editor .text-caption {
  color: #162d54;
}
.ws-rich-text-editor .ws-rich-text-editor--panel {
  display: grid;
  border: 1px solid #c2cad7;
  margin: 1px;
  border-radius: 4px;
  box-sizing: border-box;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ws-rich-text-editor--body {
  flex: 1 1 auto;
  height: "100%";
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-toolbar,
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-container {
  border: none;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-container.ql-snow {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row: 1;
  font-family: "Inter", sans-serif;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-container .floating_label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 17px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #5e6785;
  font-size: 16px;
  line-height: 1;
  left: 16px;
  font-weight: 500;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-container .floating_label.filled {
  transform: translate(-8px, -11px);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.004em;
  padding: 4px;
  background-color: white;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-container:focus-within .floating_label {
  transform: translate(-8px, -11px);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.004em;
  padding: 4px;
  background-color: white;
  color: #3d5afe;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-container:focus-within .floating_label.error {
  color: #ce2e3a;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-container:focus-within .floating_label.error {
  color: #ce2e3a;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-toolbar.ql-snow .ql-formats {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  column-gap: 10px;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-editor {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #162d54;
  padding: 12px 10px 12px 14px;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-snow .ql-toolbar button,
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-snow.ql-toolbar button {
  color: #5e6785;
  fill: #5e6785;
  padding: 2px;
  height: 24px;
  width: 24px;
}
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-snow .ql-toolbar button.clickable:hover,
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-snow.ql-toolbar button.clickable:hover,
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-snow .ql-toolbar button:hover,
.ws-rich-text-editor .ws-rich-text-editor--panel .ql-snow.ql-toolbar button:hover {
  color: #162d54;
  fill: #162d54;
  background-color: #eff2ff;
  border-radius: 24px;
}
.ws-rich-text-editor.ws-rich-text-editor--error .ws-rich-text-editor--panel {
  border-color: #d32f2f;
}
.ws-rich-text-editor.ws-rich-text-editor--error .ws-rich-text-editor--panel .floating_label {
  color: #ce2e3a;
}
.ws-rich-text-editor.ws-rich-text-editor--error .text-caption {
  color: #ce2e3a;
  font-weight: 400;
  margin-top: 3px;
  margin-left: 14px;
}
.ws-rich-text-editor.ws-rich-text-flex .ws-rich-text-editor--panel {
  display: flex;
  flex-direction: column;
}
.ws-rich-text-editor.ws-rich-text-editor--disabled .ql-editor {
  color: rgba(0, 0, 0, 0.38);
  cursor: not-allowed;
}
.ws-rich-text-editor.ws-rich-text-editor--disabled .ql-editor.ql-editor > * {
  cursor: not-allowed;
}
.ws-rich-text-editor.ws-rich-text-editor--disabled .ql-toolbar {
  display: none;
}
.ws-rich-text-editor.ws-rich-text-editor--disabled .ws-rich-text-editor--panel:hover {
  border: 1px solid #c2cad7;
}
.ws-rich-text-editor.ws-rich-text-editor--disabled .floating_label {
  color: rgba(0, 0, 0, 0.38) !important;
}
.ws-rich-text-editor--panel:hover {
  border: 1px solid #5e6785;
  margin: 1px;
}
.ws-rich-text-editor--panel:focus-within {
  border: 2px solid #3d5afe;
  margin: 0;
}
.zero-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 24px;
  gap: 8px;
  text-align: center;
  max-width: 1400px;
}
.zero-state svg {
  width: 48px;
  height: 48px;
  color: #9caac0;
  margin-bottom: 24px;
}
.zero-state .text-body1 {
  color: #5e6785;
}
.zero-state .text-body1 h6 {
  color: #162d54;
}
.zero-state p {
  color: #5e6785;
  padding: 0;
  margin: 0;
}
.zero-state .h6,
.zero-state .link {
  color: #162d54;
}
.zero-state .zero-state__content {
  max-width: 560px;
  padding-left: 24px;
  padding-right: 24px;
  border: none;
}
.zero-state__minimal {
  text-align: center;
  margin: 0px 24px;
}
.zero-state__minimal .h6 {
  color: #9caac0;
}
.zero-state__buttons {
  margin-top: 16px;
}
.zero-state__buttons .MuiButton-root {
  margin-left: 8px;
}
.zero-state__buttons .MuiButton-root:first-child {
  margin-left: 0;
}
.zero-state__wrapper {
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  background-color: #fff;
  padding: 40px;
  margin: 10px auto;
  text-align: left;
}
.card--feedback {
  box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.18);
}
.card--feedback h2 {
  font-weight: 400;
  margin: 0;
}
.card--feedback p {
  margin: 5px 0 0;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 768px) {
  .card--feedback {
    padding: 20px;
  }
}
.card--feedback .next-step {
  margin-top: 20px;
  text-align: center;
}
.card--feedback .social-share {
  margin-top: 20px;
}
.card--feedback .social-share a {
  margin-right: 20px;
}
.card--feedback .social-share a:last-child {
  margin-right: 0;
}
.card--feedback .social-share svg {
  display: inline-block;
  height: 36px;
  width: 36px;
}
.card--feedback .share-facebook svg {
  fill: #1877f2;
}
.card--feedback .share-twitter svg {
  fill: #1d9bf0;
}
.card--feedback .share-linkedin svg {
  fill: #0a66c2;
}
.card__summary {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px 15px;
}
@media (max-width: 768px) {
  .card__summary {
    padding: 5px 10px;
  }
}
.card__body {
  margin: 20px 0;
}
.card__body:first-child {
  margin-top: 0;
}
.card__body:last-child {
  margin-bottom: 0;
}
.card__body--success {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card__body--success svg {
  width: 60px;
  height: 60px;
  fill: #00cba5;
}
.card-container {
  box-sizing: border-box;
  max-width: 600px;
  margin: 10px auto;
}
@media (max-width: 768px) {
  .card-container {
    max-width: 92%;
  }
}
.card-container__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.card-container__footer a:not(:last-child) {
  margin-bottom: 20px;
}
.card-container__footer a:not(.btn) {
  font-size: 15px;
}
.job-card-list-container {
  overflow: hidden;
  margin-bottom: 10px;
}
.job-card-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
@media (max-width: 768px) {
  .job-card-list {
    padding: 10px;
  }
}
.job-card {
  color: #000;
  width: 420px;
  box-shadow: 0 0 16px 0 rgba(22, 45, 84, 0.3);
  background-color: #fff;
  border-radius: 6px;
  text-align: left;
  box-sizing: border-box;
  padding: 0 5px 13px;
  margin: 5px 0;
}
.job-card:first-child {
  margin-top: 0;
}
.job-card:last-child {
  margin-bottom: 0;
}
.job-card .job-card-title-container {
  padding: 25px 15px 12px;
}
.job-card .job-card-meta-container {
  padding: 15px 15px 5px;
  border-top: 1px solid #ddd;
}
.job-card .job-card-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}
.job-card .job-card-title a {
  color: #000;
  display: block;
}
.job-card .job-card-subtitle {
  color: #666;
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;
}
.job-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
}
.job-card ul li {
  display: flex;
}
.job-card ul .job-card-label {
  color: #666;
  display: inline-block;
  flex: 0 0 60px;
  font-weight: 200;
}
.job-card .job-card-button {
  text-align: center;
}
.job-card .job-card-button a.btn {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}
@media (max-width: 768px) {
  .job-card {
    width: 100%;
  }
}
.job-card-list-load-more.hidden {
  display: none;
}
@media (max-width: 768px) {
  .job-card-list-load-more {
    width: 100%;
  }
}
.job-card--new {
  position: relative;
  box-sizing: border-box;
  width: 420px;
  margin: 5px auto;
  border-radius: 4px;
  box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.2);
  background-color: #fff;
  color: #000;
  text-align: left;
  cursor: pointer;
}
.job-card--new:first-child {
  margin-top: 0;
}
.job-card--new:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .job-card--new {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
  }
}
.job-card__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.job-card__title-container {
  padding: 18px 18px 0;
}
.job-card__meta {
  margin: 12px 0;
  padding: 0 18px;
}
.job-card__meta-field {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.job-card__meta-field .wsicon-wallet {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.job-card__meta-field .wsicon-watch {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.job-card__meta-field:first-child {
  margin-right: 12px;
}
.job-card__meta-value {
  font-size: 12px;
  line-height: 18px;
}
.job-card__details {
  padding: 0 18px;
  position: relative;
}
.job-card__details p {
  margin: 0;
  height: 90px;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
}
.job-card__chevron {
  display: block;
  margin: 8px auto 5px;
  width: 18px;
  height: 18px;
}
.job-card__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.job-card__title span {
  color: #162d54;
  text-decoration: none;
}
@media (max-width: 768px) {
  .job-card__title {
    font-size: 16px;
    line-height: 20px;
  }
}
.job-card__subtitle {
  color: #666;
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;
}
.job-card__keymeta {
  display: flex;
  margin-top: 1px;
}
.job-card__company,
.job-card__location {
  font-size: 13px;
  line-height: 21px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.job-card__company:first-child,
.job-card__location:first-child {
  margin-right: 8px;
}
@media (max-width: 768px) {
  .job-card__company,
  .job-card__location {
    font-size: 12px;
    line-height: 15px;
  }
}
.job-card__location {
  color: #9b9b9b;
  text-overflow: ellipsis;
}
.job-card__map {
  overflow: hidden;
  height: 142px;
  margin-top: 12px;
}
.job-card__map .position-map.mobile {
  width: 100%;
  height: 100%;
  display: block;
}
.job-card__map .position-map.mobile > img {
  width: 640px;
  height: 142px;
  transform: translateX(-50%);
  left: 50%;
  position: relative;
}
.job-card__map .position-map.mobile .position-map-commute .position-map-commute-dialog::before {
  margin-left: -8px;
  border-width: 8px;
}
.job-card__button {
  text-align: center;
}
.job-card__button a.btn {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}
.job-card__text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.job-card__text.fixed {
  position: fixed;
}
.job-card__text .job-card__text-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.job-card__text .job-card__text-content p {
  color: #00cba5;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.job-card__text .job-card__text-content svg {
  fill: #00cba5;
  width: 100px;
  height: 100px;
  stroke: #fff;
  stroke-width: 50px;
}
.job-card__auto-match-icon {
  position: relative;
  vertical-align: middle;
  top: -2px;
  height: 40px;
  width: 40px;
}
.job-card__auto-match-bar {
  color: #3d5afe;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 8px 0;
}
.job-card__auto-match-bar em {
  color: #9b9b9b;
  font-style: normal;
  font-weight: 300;
  margin-left: 6px;
}
.job-card__auto-match-bar:hover {
  opacity: 0.7;
}
@media (max-width: 768px) {
  .job-card__auto-match-bar {
    font-size: 12px;
  }
}
.job-card__queue-bar {
  font-size: 14px;
  color: #edaf1f;
  font-weight: 500;
  padding: 12px 18px 0;
}
.question-edit .visible-on-hover {
  visibility: hidden;
}
.question-edit .drag-button-color svg {
  fill: #9caac0;
}
.question-edit:hover .visible-on-hover {
  visibility: visible;
}
.question-edit:hover .drag-button-color svg {
  fill: #5e6785;
}
.question-edit.preview-card,
.question-edit.loader-card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 8px 16px -4px rgba(22, 45, 84, 0.08);
}
.question-edit.preview-card:hover,
.question-edit.loader-card:hover {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 8px -4px rgba(22, 45, 84, 0.08), 0px 16px 24px rgba(22, 45, 84, 0.08);
  border: 2px solid #c1caff;
}
.question-edit.edit-card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 8px -4px rgba(22, 45, 84, 0.08), 0px 16px 24px rgba(22, 45, 84, 0.08);
  border: 2px solid #3d5afe;
}
.question-edit__question-type-item .MuiSvgIcon-root {
  margin: 0 8px;
}
.question-edit .button-disabled {
  color: #9caac0;
}
.question-edit .button-disabled:hover {
  color: #9caac0;
  background-color: transparent;
}
.question-edit .svg-disabled {
  fill: #9caac0 !important;
}
.question-edit .svg-disabled:hover {
  fill: #9caac0 !important;
}
.question-edit__form,
.question-edit__loader {
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.question-edit__form.question-preview,
.question-edit__loader.question-preview {
  padding-top: 20px;
}
.question-edit__form.question-preview .text-caption,
.question-edit__loader.question-preview .text-caption {
  color: #5e6785;
}
.question-edit__form .MuiFormControl-root,
.question-edit__loader .MuiFormControl-root {
  min-width: 240px;
}
.question-edit__form .MuiInputBase-input,
.question-edit__loader .MuiInputBase-input {
  padding-inline: 16px !important;
  box-sizing: border-box;
  height: 48px;
  border: none;
  margin-bottom: 0;
  margin-top: 0;
}
.question-edit__form .MuiInputBase-input:focus,
.question-edit__loader .MuiInputBase-input:focus {
  border-bottom: none;
  margin-bottom: 0px;
}
.question-edit__form [data-shrink="false"].MuiInputLabel-root,
.question-edit__loader [data-shrink="false"].MuiInputLabel-root {
  transform: translate(16px, 12px) scale(1);
}
.question-edit__form .MuiSelect-select,
.question-edit__loader .MuiSelect-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.question-edit__form .MuiListItemText-primary,
.question-edit__loader .MuiListItemText-primary,
.question-edit__form .MuiInputBase-input,
.question-edit__loader .MuiInputBase-input {
  color: #162d54;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.question-edit__form .question-scaled-preview,
.question-edit__loader .question-scaled-preview {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
  font-weight: 600;
  color: #5e6785;
}
.question-edit__form .question-scaled-option-preview,
.question-edit__loader .question-scaled-option-preview {
  color: #5e6785;
  border-radius: 20px;
  border: 1px solid #c2cad7;
  padding: 3.5px 8px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  font-weight: 600;
  letter-spacing: 1.25px;
}
.question-edit__form .question-choice-option-preview,
.question-edit__loader .question-choice-option-preview {
  color: #162d54;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  font-weight: 600;
}
.question-edit__select-menu {
  max-height: 225px;
}
.question-edit__toolbar {
  padding: 4px 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}
.question-edit__info {
  grid-column: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.question-edit__info .MuiIconButton-root.MuiIconButton-sizeMedium {
  padding: 8px;
}
.question-edit__drag-container {
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.question-edit__drag-container .drag-indicator {
  margin: auto;
  cursor: move;
  transform: rotate(90deg);
}
.question-edit__actions {
  grid-column: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.question-edit__actions .MuiIconButton-root.MuiIconButton-sizeMedium {
  padding: 8px;
}
.question-edit__title-preview {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.15px;
  text-align: left;
  color: #162d54;
  margin-bottom: 2px;
}
.question-edit .MuiDivider-root {
  margin: 0px;
}
.question-edit__options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
}
.question-edit__options .option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
}
.question-edit__options .option .scaled-option .MuiOutlinedInput-input {
  width: 25rem;
}
.question-edit__options .option .scaled-option .MuiOutlinedInput-input:disabled {
  color: #d8dde6;
  background-color: #eff1f6;
}
.question-edit__options .option .MuiFormControlLabel-label {
  color: #162d54;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.question-edit__options .option label {
  margin-bottom: 0px;
}
.question-edit__options .option .choice-option {
  max-width: 25rem;
  flex-grow: 1;
}
.question-edit__options .option .choice-option input {
  padding-inline-start: 0 !important;
  padding-bottom: 8px;
}
.question-edit__options .option .choice-option input:focus {
  border-bottom: none;
  margin-bottom: 0px;
}
.question-edit__options .option .required-prompt {
  flex-grow: 1;
  max-width: 25rem;
}
.question-edit__preview-card-state {
  cursor: pointer;
}
.question-edit__preview-card-state * {
  cursor: inherit !important;
}
.question-edit .loader2-container {
  border-radius: 16px;
}
.question-edit__disabled-card-state {
  opacity: 0.5;
  pointer-events: none;
}
.snackbar-notification-action-button {
  border: none;
  color: #8187ff;
}
.snackbar-notification-action-button:hover {
  color: #8187ff;
  background-color: transparent;
}
.ws-filter-bar__accordion--expanded {
  border: 2px solid #ddd;
  border-left: 0px;
  border-right: 0px;
}
.ws-filter-bar__accordion .accordion-summary {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  font-weight: 600;
  color: #162d54;
  background-color: #fafbfb;
}
.ws-filter-bar__accordion .accordion-collapsed {
  background-color: transparent;
}
.ws-filter-bar__accordion .accordion-details {
  background-color: #fafbfb;
  padding: 0px;
}
.ws-filter-bar__accordion__clear-button {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ws-filter-bar__accordion__extra-content {
  color: #5e6785;
}
.ws-filter-bar__accordion__extra-content--stale {
  text-decoration: line-through;
}
.ws-filter-bar__accordion .ws-filter-button__input {
  margin-left: 16px;
  margin-right: 16px;
}
.ws-filter-bar__accordion .ws-filter-button__input--hide {
  display: none;
}
.ws-filter-bar__accordion .ws-filter-button__options {
  margin-block: 0px;
  padding: 0px;
}
.ws-filter-bar__accordion .ws-filter_button__single_option__text {
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 465px;
}
.ws-filter-bar__accordion .ws-filter_button__single_option__text .MuiListItemText-primary {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  font-weight: 400;
  display: block;
}
.intro-container {
  max-width: 750px;
  min-height: 340px;
  margin: 24px auto;
  text-align: center;
  padding: 20px;
  padding-bottom: 200px;
  background: #fff;
  position: relative;
  box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .intro-container {
    margin-top: 0;
  }
}
.intro-container h2 {
  margin-bottom: 40px;
}
.intro-container .featured-position {
  margin-top: 42px;
}
.intro-container .selected-position {
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
  text-align: left;
}
.intro-container .selected-position:first-child {
  border-bottom: none;
}
.intro-container .selected-position .subtitle {
  display: block;
  color: #bbb;
  font-size: 13px;
}
.intro-container .highlight {
  color: #edaf1f;
}
.intro-container .actions {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 40px;
  left: 0;
}
.intro-container .actions p {
  margin: 2px 0;
}
.intro-container .actions .btn {
  margin-top: 24px;
}
.intro-container .actions .loader-container + p {
  max-width: 245px;
  margin: 20px auto;
  font-size: 14px;
}
.intro-container .loader-container {
  overflow: hidden;
}
.intro-container .estimate {
  color: #162d54;
  font-weight: 300;
  margin-top: 6px;
  margin-bottom: 24px;
}
.intro-container table {
  margin: auto;
  margin-bottom: 18px;
}
.intro-container td {
  padding: 6px 15px;
  font-size: 16px;
}
.intro-container td:first-child {
  color: #bbb;
}
.intro-container .loader {
  left: 50%;
  top: 35px;
  margin-left: 90px;
}
.page-candidate-application {
  background: #eff1f6;
}
.content.application {
  width: 100%;
  background: #eff1f6;
  min-height: 100%;
  max-width: initial;
  text-align: center;
}
@media (max-width: 768px) {
  .content.application.step_suggestions #nav {
    display: none;
  }
  .content.application.step_suggestions #app-container {
    padding-top: 0;
  }
  .content.application.step_suggestions #positions-container {
    padding-top: 0;
  }
  .content.application.step_suggestions #step-container {
    margin-bottom: 40px;
  }
}
.content.application .password-container {
  margin: 40px -18px 0;
  box-sizing: border-box;
  padding: 18px;
}
.content.application .tip-lock {
  margin-left: 6px;
}
.content.application .tip-lock svg {
  height: 16px;
  width: 16px;
  fill: #bbb;
  top: 1px;
  position: relative;
}
.content.application .tip-lock + span {
  color: #bbb;
  font-size: 12px;
}
@media (min-width: 768px) {
  .content.application .tip-lock + span {
    display: none;
  }
}
.content.application.step_history #step-container {
  margin-bottom: 60px;
}
.content.application.step_suggestions #step-container,
.content.application.step_review #step-container,
.content.application.step_loading #step-container {
  background: #eff1f6;
  box-shadow: none;
}
@media (max-width: 768px) {
  .content.application #positions-container {
    padding-top: 50px;
  }
}
.signup-form-step {
  max-width: 400px;
  margin: auto;
  padding-top: 36px;
  text-align: center;
}
.signup-form-step p.login-reminder {
  padding: 12px 0;
  background: #f5f5f5;
}
.signup-form-step label,
.signup-form-step .help-block {
  text-align: left;
}
.signup-form-step .btn-primary {
  width: 100%;
}
.signup-form-step .spacer {
  width: 100px;
  border-bottom: 1px solid #ddd;
  margin: 26px auto;
}
.signup-form-step #login-error {
  text-align: center;
  margin-bottom: 20px;
  min-height: 24px;
}
.signup-form-step label[for="phone"] {
  display: flex;
  justify-content: space-between;
}
.step_signup #powered-by-container {
  margin-bottom: 24px;
  display: block;
}
.step_signup #step-container {
  margin-bottom: 24px;
}
.step_signup .next-container {
  display: none;
}
.featured-position {
  margin-bottom: 24px;
}
.featured-position .company-logo {
  width: auto;
  margin-bottom: 26px;
}
.featured-position p,
.featured-position h4 {
  margin: 2px;
}
.featured-position h4 {
  font-size: 22px;
}
.list-container {
  text-align: left;
  max-width: 750px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .list-container {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.form-step {
  padding-bottom: 36px;
}
.form-step h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 20px 0;
  color: #162d54;
  padding-top: 48px;
  margin-bottom: 24px;
  margin-top: 0;
}
.form-step .history-view-header div {
  padding-top: 56px;
}
.form-step h3 {
  font-weight: 100;
  font-size: 24px;
}
.add-form h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 20px 0;
  color: #162d54;
}
#sms-modal p:first-child {
  padding-right: 30px;
}
.btn-container,
.next-container {
  position: relative;
  overflow: hidden;
}
.next-container {
  max-width: 240px;
  margin: auto;
  padding-bottom: 52px;
}
@media (max-width: 768px) {
  .next-container {
    position: fixed;
    max-width: 100%;
    width: 100%;
    bottom: 16px;
    padding: 0;
    z-index: 999;
  }
  .next-container .next-button {
    border-radius: 19px;
    width: 93%;
  }
  .next-container .next-button.btn-large {
    border-radius: 24px;
  }
  .next-container .next-button.btn-small {
    border-radius: 15px;
  }
  .next-container .loader {
    top: 11px;
    right: 25px;
  }
}
.app__history-next-container {
  max-width: 240px;
  margin: auto;
  padding: 26px 0;
}
@media (max-width: 768px) {
  .app__history-next-container {
    position: relative;
  }
  .app__history-next-container .next-button {
    width: 100%;
  }
}
.app__history-next-container .btn,
.next-button {
  padding-left: 45px;
  padding-right: 45px;
}
@media (min-width: 768px) {
  .next-button--fixed {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
}
#education-modal,
.app-history-modal,
#reference-modal {
  padding: 16px 36px 52px;
}
#education-modal .tag,
.app-history-modal .tag,
#reference-modal .tag {
  position: absolute;
  right: 0;
  top: 29px;
  border: 1px solid #edaf1f;
  color: #edaf1f;
  background: #fef9ed;
  border-radius: 3px;
  padding: 2px 8px;
}
#education-modal .tag::before,
.app-history-modal .tag::before,
#reference-modal .tag::before {
  height: 26px;
  width: 40px;
  display: inline-block;
  content: " ";
  position: absolute;
  left: -41px;
  background: #fff;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
}
@media (max-width: 768px) {
  #education-modal,
  .app-history-modal,
  #reference-modal {
    padding: 16px 16px 52px;
  }
}
.content.application,
#education-modal,
#reference-modal,
.app-history-modal {
  position: relative;
  color: #555;
  font-size: 15px;
  font-weight: 300;
}
.content.application .company-explain,
#education-modal .company-explain,
#reference-modal .company-explain,
.app-history-modal .company-explain {
  display: none;
}
.content.application button,
#education-modal button,
#reference-modal button,
.app-history-modal button {
  min-width: 230px;
}
.content.application textarea,
#education-modal textarea,
#reference-modal textarea,
.app-history-modal textarea {
  transition: height 1s;
}
.content.application textarea#duties,
#education-modal textarea#duties,
#reference-modal textarea#duties,
.app-history-modal textarea#duties {
  font-size: 14px;
  line-height: 18px;
}
.content.application textarea#reason_explain,
#education-modal textarea#reason_explain,
#reference-modal textarea#reason_explain,
.app-history-modal textarea#reason_explain,
.content.application textarea#company_explain,
#education-modal textarea#company_explain,
#reference-modal textarea#company_explain,
.app-history-modal textarea#company_explain {
  display: none;
}
.content.application textarea.gaps,
#education-modal textarea.gaps,
#reference-modal textarea.gaps,
.app-history-modal textarea.gaps {
  height: 72px;
}
.content.application h2 + .checkbox-single,
#education-modal h2 + .checkbox-single,
#reference-modal h2 + .checkbox-single,
.app-history-modal h2 + .checkbox-single {
  margin-top: -12px;
}
.content.application .job-in-form-warning-icon,
#education-modal .job-in-form-warning-icon,
#reference-modal .job-in-form-warning-icon,
.app-history-modal .job-in-form-warning-icon {
  display: none;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  top: 30px;
  right: 5px;
  background-color: #edaf1f;
  border-radius: 24px;
  position: absolute;
}
.content.application .job-in-form-warning-icon svg,
#education-modal .job-in-form-warning-icon svg,
#reference-modal .job-in-form-warning-icon svg,
.app-history-modal .job-in-form-warning-icon svg {
  fill: white;
  width: 18px;
  height: 18px;
}
@media (min-width: 768px) {
  .content.application #start-date-flag-missing,
  #education-modal #start-date-flag-missing,
  #reference-modal #start-date-flag-missing,
  .app-history-modal #start-date-flag-missing,
  .content.application #end-date-flag-missing,
  #education-modal #end-date-flag-missing,
  #reference-modal #end-date-flag-missing,
  .app-history-modal #end-date-flag-missing {
    top: 10px;
  }
}
@media (max-width: 768px) {
  .content.application .contract-job-label,
  #education-modal .contract-job-label,
  #reference-modal .contract-job-label,
  .app-history-modal .contract-job-label {
    padding-right: 15px;
  }
}
.app-history-modal #delete-job {
  position: absolute;
  right: 70px;
  top: 18px;
}
.app-history-modal .hide-add {
  display: none;
}
.app-history-modal .hide-edit {
  display: block;
}
.app-history-modal.edit-mode .hide-add {
  display: block;
}
.app-history-modal.edit-mode .hide-edit {
  display: none;
}
.form-group--dates {
  display: flex;
}
.form-group--dates .form-group {
  margin: 30px 0 0;
}
.form-group--dates label,
.form-group--dates div {
  display: inline-block;
}
@media (max-width: 768px) {
  .form-group--dates {
    flex-direction: column;
  }
  .form-group--dates label,
  .form-group--dates div {
    display: block;
  }
}
.month-picker .desktop-date {
  display: inline-block;
  max-width: 100px;
  margin: 0 15px 0 5px;
}
@media (max-width: 768px) {
  .month-picker .desktop-date {
    display: none;
  }
}
.month-picker .mobile-datepicker {
  display: none;
}
@media (max-width: 768px) {
  .month-picker .mobile-datepicker {
    display: block;
  }
}
#app-container {
  padding-top: 54px;
  padding-bottom: 24px;
  box-sizing: border-box;
  max-width: 940px;
  margin: 0 auto;
}
#app-container.editing #progress-bar {
  display: none;
}
@media (min-width: 768px) {
  #app-container.detail-shown {
    height: 0;
    padding-bottom: 6px;
  }
}
@media (max-width: 768px) {
  #app-container.detail-shown {
    padding: 0;
    height: 50px;
  }
}
#app-container.detail-shown .form-step {
  padding: 0;
}
#app-container.detail-shown #step-container {
  margin: 0;
}
#app-container .btn-back {
  position: absolute;
  top: 0;
  left: 8px;
}
@media (max-width: 768px) {
  #app-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 768px) {
  #app-container .availability-grid__header-content {
    height: 34px;
  }
}
.edit-actions {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  overflow: hidden;
  position: relative;
}
.edit-actions .loader {
  top: 42px;
  right: 50%;
  margin-right: -104px;
}
.edit-actions .save-changes {
  display: block;
  margin: 0 auto 24px;
}
.edit-actions .cancel-action {
  display: inline-block;
  font-size: 16px;
}
.application .menu-item:first-child {
  border-top: 0;
}
#apply-now {
  display: none;
  position: fixed;
  bottom: 18px;
  right: 15%;
  z-index: 100;
}
@media (max-width: 768px) {
  #apply-now {
    right: 12px;
  }
}
#tos-container {
  margin: 12px auto 0;
  max-width: 280px;
  font-size: 12px;
  line-height: 20px;
  color: #bbb;
  text-align: center;
}
#powered-by-container {
  color: #9caac0;
  font-size: 13px;
  font-weight: 500;
  display: none;
}
#tos-container a,
#powered-by-container a {
  font-weight: 300;
  text-decoration: none;
  color: #bbb;
}
#tos-container a:hover,
#powered-by-container a:hover {
  text-decoration: underline;
}
#progress-bar {
  background: #ddd;
  height: 6px;
  overflow: hidden;
  width: 100%;
  left: 0;
  position: absolute;
}
.next-container > .next-button {
  display: none;
}
#edu-items,
#reference-items {
  background: #eee;
  color: #bbb;
  width: 100%;
  min-height: 170px;
  margin: auto;
  font-weight: 100;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
}
.candidate-profile.work-history-container {
  padding: 0;
  min-height: 300px;
}
.candidate-profile.work-history-container .center {
  margin-top: 50px;
  position: relative;
  z-index: 10;
}
.candidate-profile.work-history-container > section.work-experience {
  padding: 0;
  border: none;
}
.candidate-profile.work-history-container > section.work-experience article header {
  display: block;
  position: relative;
}
.candidate-profile.work-history-container > section.work-experience article .job-content {
  padding: 20px 10px 20px 0;
}
.candidate-profile.work-history-container > section.work-experience article .job-content .dates {
  position: relative;
}
.candidate-profile.work-history-container > section.work-experience article .job-content .edit-history {
  position: absolute;
  top: 6px;
  right: 0;
}
.candidate-profile.work-history-container > section.work-experience article .job-content .edit-history:hover {
  text-decoration: none;
}
.candidate-profile.work-history-container > section.work-experience article .job-content .edit-history svg {
  width: 18px;
  height: 18px;
  fill: #00cba5;
}
.candidate-profile.work-history-container > section.work-experience article .job-content--disabled .edit-history {
  display: none;
}
@media (min-width: 768px) {
  .candidate-profile.work-history-container > section.work-experience article.gap-explanation .job-content {
    box-shadow: none;
    transition: box-shadow 150ms ease-out;
  }
  .candidate-profile.work-history-container > section.work-experience article.gap-explanation .job-content:hover {
    box-shadow: 0 2px 15px 0 rgba(22, 45, 84, 0.12);
    border-color: #9caac0;
  }
  .candidate-profile.work-history-container > section.work-experience article.gap-explanation .job-content:hover::before {
    border-color: #9caac0;
  }
}
.candidate-profile.work-history-container > section.work-experience article.gap-ignored .job-content {
  margin-top: 0;
}
.candidate-profile.work-history-container > section.work-experience article svg.edit-row {
  position: absolute;
  top: 0;
  right: -10px;
  width: 18px;
  height: 18px;
  fill: #9caac0;
  opacity: 0.7;
  padding: 10px;
}
@media (min-width: 768px) {
  .candidate-profile.work-history-container > section.work-experience article .job-content {
    margin: 21px 0;
  }
}
@media (max-width: 768px) {
  .candidate-profile.work-history-container {
    background-color: #fff;
    margin-bottom: 0;
    padding-bottom: 50px;
  }
  .candidate-profile.work-history-container > section.work-experience article .edit-history {
    opacity: 0;
  }
}
#distance-modal .portal-card-group {
  min-height: 400px;
}
#distance-modal .portal-dialog:not(.portal-dialog-text) h2 {
  margin-bottom: 25px;
}
#distance-modal .button-group :first-child {
  margin-bottom: 10px;
}
#distance-modal .move-date {
  padding-top: 6px;
}
.no-items {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  margin-left: -80px;
}
.add-step-submit {
  display: block;
  width: 240px;
  margin: 20px auto 0;
}
#education-modal .form-group {
  padding: 0;
}
.checkbox-single.address-international-form.form-group label {
  border-bottom: 0;
  font-size: 16px;
}
.address-form-group div {
  margin: 20px 0;
}
.address-form-group .state-zip-holder {
  display: flex;
  justify-content: space-around;
}
.address-form-group .state-zip-holder div {
  width: 100%;
  margin-top: 0;
}
.address-form-group .state-zip-holder div:first-child {
  margin-right: 10px;
}
.form-group--city {
  display: inline-block;
  width: 60%;
  margin: 0;
}
.form-group--state {
  width: 35%;
  float: right;
  margin: 0;
}
#eligibility {
  margin-top: 38px;
}
#eligibility > label {
  font-size: 14px;
  line-height: 20px;
}
#eligibility .switch-toggle {
  top: 50%;
  margin-top: -18px;
}
#eligibility .switch-toggle label {
  line-height: 42px;
}
#what-is-link {
  display: block;
  cursor: pointer;
}
#what-is-modal {
  color: #fff;
  background: #162d54;
  text-align: center;
  padding: 24px;
}
#what-is-modal p {
  font-size: 16px;
  line-height: 24px;
}
#what-is-modal .highlight,
#what-is-modal .highlight a {
  font-size: 19px;
  color: #e7bc17;
}
#what-is-modal img {
  width: 200px;
}
#what-is-modal h2 {
  display: inline-block;
  margin: 0 4px;
}
#what-is-modal a.close-modal.icon-close {
  color: #fff;
}
#what-is-modal hr {
  border: none;
  border-bottom: 1px solid #fff;
  margin: 20px auto;
  width: 90px;
}
.row {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left;
  position: relative;
}
.row p {
  margin: 0;
  color: #555;
}
.row p.title {
  font-weight: 500;
}
.row .remove-row {
  position: absolute;
  right: 10px;
  top: 18px;
  font-size: 32px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
.row .remove-row:hover {
  color: #9b9b9b;
}
.follow-up {
  margin: 30px 0;
}
#step-container {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  background: #fff;
}
#step-container.list-container {
  box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.1);
}
#step-container .loading-step {
  text-align: center;
}
#step-container .loading-step p {
  font-size: 18px;
}
@media (max-width: 768px) {
  #step-container {
    margin-top: 6px;
    margin-bottom: 90px;
  }
}
.content.application.review-step .password-container {
  margin: 0;
  padding: 0;
}
.content.application.review-step .password-container small {
  font-size: 12px;
}
.content.application.review-step .password-container #help-alpha {
  margin-bottom: 15px;
}
.content.application.review-step .next-button {
  border-radius: 25px;
  width: 100%;
}
.content.application.review-step .review-button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px;
}
.content.application.review-step .review-button-container button {
  display: block;
  margin: 0 auto;
  width: 93%;
  border-radius: 100px;
}
@media (min-width: 769px) {
  .content.application.review-step .review-button-container {
    display: none !important;
  }
}
.content.application.review-step:not(.review-editing) #step-container {
  max-width: 750px;
  padding: 0;
  background: transparent;
}
@media (max-width: 768px) {
  .content.application.review-step:not(.review-editing) #step-container {
    max-width: none;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .content.application.review-step:not(.review-editing) .review-button-container {
    display: block;
  }
}
.content.application.review-step.review-editing #step-container {
  background-color: #fff;
}
.content.application.review-step.review-editing #app-container .btn-back {
  top: 24px;
  left: 28px;
}
@media (max-width: 768px) {
  .content.application.review-step.review-editing #app-container .btn-back {
    left: 0;
  }
}
.content.application.review-step.review-editing .form-step h2 {
  padding-top: 80px;
}
.content.application.review-step.review-editing .form-step .history-view-header div {
  padding-top: 88px;
}
@media (max-width: 768px) {
  .content.application.review-step.review-editing .review-button-container {
    display: none;
  }
}
#manager-reference label {
  font-size: 14px;
  border-bottom: none;
  padding-bottom: 20px;
  margin-top: -14px;
  padding-right: 32px;
}
.green-dots,
.green-dots::before,
.green-dots::after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: dots 1.2s infinite;
}
.green-dots {
  font-size: 10px;
  margin: 20px auto;
  margin-top: 80px;
  position: relative;
  text-indent: -9999em;
  animation-delay: -0.4s;
}
.green-dots::before,
.green-dots::after {
  content: "";
  position: absolute;
  top: 0;
}
.green-dots::before {
  left: -32px;
  animation-delay: -0.8s;
}
.green-dots::after {
  left: 32px;
}
.company-questions p {
  margin: 12px 0;
  margin-top: 6px;
}
.company-questions .help-block {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .content.application .logo {
    width: 140px;
    margin-top: 18px;
  }
  #what-is-modal {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    box-sizing: border-box;
    padding-top: 50px;
  }
}
@keyframes dots {
  10% {
    background-color: #00cba5;
    box-shadow: 0 0 0 1px #00cba8;
  }
  0%,
  80%,
  100% {
    background-color: #c2cad8;
  }
}
@media (max-width: 768px) {
  .profile-my-account-link {
    font-size: 13px;
  }
}
.password-auth-modal button {
  display: block;
  width: 100%;
  border-radius: 100px;
  margin-top: 10px;
}
.password-auth-modal small {
  display: block;
}
.gut-check {
  box-sizing: border-box;
  padding: 20px 100px;
  margin: 0 -40px;
  background-color: #fef9ed;
}
@media (max-width: 768px) {
  .gut-check {
    padding: 20px;
    margin: 0 -10px;
  }
}
.gut-check__icon {
  height: 100px;
}
.gut-check__counter {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #bbb;
  font-size: 14px;
}
.gut-check__section--intro {
  text-align: center;
}
@media (max-width: 768px) {
  .gut-check__section--intro p {
    font-size: 14px;
    line-height: 21px;
  }
}
.gut-check__section--outro {
  text-align: left;
}
.gut-check__section--outro header {
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.gut-check__section--outro ul {
  padding-left: 20px;
}
.gut-check__section--outro p:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .gut-check__section--outro header {
    font-size: 16px;
  }
  .gut-check__section--outro p,
  .gut-check__section--outro ul {
    font-size: 14px;
    line-height: 21px;
  }
}
.gut-check__warning {
  color: #edaf1f;
  font-weight: 500;
}
.gut-check__section-text {
  font-weight: 500;
  text-align: center;
}
.gut-check__section-text em {
  border-bottom: 1px dashed #000;
  text-decoration: none;
}
@media (max-width: 768px) {
  .gut-check__section-text {
    font-size: 14px;
    line-height: 21px;
  }
}
.gut-check__buttons {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .gut-check__buttons {
    width: 90%;
    margin-top: 30px;
  }
}
.gut-check__button {
  font-weight: 600;
}
@media (min-width: 768px) {
  .gut-check__button {
    font-size: 16px;
  }
}
.gut-check-step .candidate-profile.work-history-container {
  min-height: 0;
}
.gut-check-step .candidate-profile.work-history-container > section.work-experience {
  margin-bottom: 0;
}
.gut-check-step .gut-check {
  padding: 10px 40px;
  margin-bottom: 20px;
}
.gut-check-step textarea {
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  height: 81px;
  border: 1px solid #ddd;
  border-bottom-width: 2px;
}
.gut-check-step textarea:active,
.gut-check-step textarea:focus {
  border-bottom-color: #00cba5;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .gut-check-step .candidate-profile.work-history-container {
    padding-bottom: 0;
  }
  .gut-check-step .gut-check {
    padding: 10px;
  }
}
.gut-check__region footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.gut-check__region footer .btn {
  min-width: 230px;
  margin-top: 20px;
}
.gut-check__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gut-check__header em {
  border-bottom: 1px dashed #555;
  font-size: smaller;
  font-weight: 600;
}
.gut-check-intro h3 {
  font-weight: 300;
}
@media (max-width: 768px) {
  .gut-check-intro h3 {
    font-size: 21px;
  }
}
.gut-check-intro__options h3 {
  margin-bottom: 10px;
}
.gut-check-intro__options .radio-list {
  margin-top: 0;
}
.gut-check-intro__options .radio-list small {
  display: block;
  color: #bbb;
}
.gut-check-intro__button {
  margin: 40px 0;
}
.gut-check__description p {
  margin-top: 0;
}
.confirm-phone-step footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.confirm-phone-step footer a:not(.btn) {
  font-weight: 400;
}
.confirm-phone-step footer a,
.confirm-phone-step footer > div {
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .confirm-phone-step .btn-primary {
    width: 95%;
  }
}
.history-view-header {
  display: flex;
  justify-content: space-between;
}
.history-view-header a {
  color: #9b9b9b;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px dashed #9b9b9b;
}
.failed-parse,
.success-parse {
  display: none;
  padding-bottom: 20px;
  font-weight: 500;
}
.failed-parse {
  color: #e86232;
}
.file-types-accepted {
  color: #bbb;
  margin-top: 0;
  font-size: 11px;
}
.resume-upload-text {
  margin-bottom: 3px;
  font-size: 13px;
  line-height: 16px;
  color: #9b9b9b;
  padding: 0 24px;
}
@media (max-width: 768px) {
  .resume-upload-text {
    font-size: 12px;
    line-height: 15px;
  }
}
.job-overlay-warning-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  top: 50px;
  background-color: #edaf1f;
  border-radius: 24px;
  position: relative;
}
.job-overlay-warning-icon svg {
  fill: white;
  width: 18px;
  height: 18px;
}
@media (max-width: 768px) {
  .job-overlay-warning-icon {
    top: 27px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
  .job-overlay-warning-icon svg {
    width: 14px;
    height: 14px;
  }
}
#resume-attached-overlay {
  padding: 20px 30px;
}
.app__history-resume h3 {
  text-align: center;
  font-weight: bold;
  margin-top: 0;
}
.app__history-resume div {
  width: 100%;
}
.app__history-resume svg {
  width: 25px;
  height: 27px;
  display: inline;
  padding-right: 10px;
}
.app__history-resume a {
  cursor: pointer;
}
.app__history-resume .resume-name-row {
  display: flex;
}
.app__history-resume .resume-uploader {
  display: none;
}
.app__history-resume .change-resume-label {
  display: inline;
  padding: 0 35px;
  font-size: 15px;
}
.app__history-resume .resume-parser-button-block {
  text-align: center;
  margin-top: 30px;
}
.app__history-resume button {
  padding: 0 60px;
  height: 50px;
}
.app__history-resume #offer-attach-options {
  text-align: center;
}
.app__history-resume .resume-warning {
  color: #edaf1f;
}
.app__history-resume .file-types-allowed {
  color: #9b9b9b;
}
.app__history-resume .invalid-resume-file-alert {
  text-align: center;
  margin-top: 20px;
  color: #e86232;
}
.app-suggested-questions {
  color: #edaf1f;
  margin: 0;
}
.app-warning-icon {
  position: fixed;
  left: 10px;
  bottom: 10px;
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background-color: #edaf1f;
}
.app-warning-icon::after,
.app-warning-icon::before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.app-warning-icon::after {
  border-color: transparent;
  border-top-color: #edaf1f;
  border-width: 5px;
  margin-left: -5px;
}
.app-warning-icon::before {
  border-color: transparent;
  border-top-color: #edaf1f;
  border-width: 6px;
  margin-left: -6px;
}
.app-warning-icon .warning-icon-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
}
.app-warning-icon svg {
  fill: #fff;
  width: 24px;
  height: 24px;
}
.app-step__private {
  display: flex;
  align-items: center;
  margin: -10px 0 30px;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 18px;
}
.app-step__private svg {
  fill: #9b9b9b;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.app-step__private span:first-child {
  display: flex;
  flex: 0 0 auto;
}
@media (max-width: 768px) {
  .app-step__private {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 15px;
  }
  .app-step__private svg {
    width: 18px;
    height: 18px;
  }
}
.skip-button-holder {
  position: fixed;
  right: 0;
  bottom: 30px;
  padding-right: 24px;
  text-align: right;
  padding-top: 30px;
  box-sizing: border-box;
  z-index: 3;
}
.skip-button-holder a {
  cursor: pointer;
  color: #9b9b9b;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
}
#skip-gaps-modal {
  text-align: center;
}
#skip-gaps-modal h2 {
  margin-bottom: 25px;
}
#skip-gaps-modal a {
  display: inline-block;
  margin: 20px 0 10px;
}
#invalid-job-modal {
  text-align: center;
}
#invalid-job-modal h3 {
  margin-bottom: 25px;
  font-weight: bold;
}
#invalid-job-modal p {
  color: #162d54;
}
#invalid-job-modal .job-icon {
  position: relative;
  height: 90px;
  max-width: 60px;
  margin: 0 auto;
}
#invalid-job-modal .job-icon .job-icon-container {
  top: 10px;
  width: 60px;
  height: 60px;
  position: absolute;
  border-radius: 60px;
  box-sizing: border-box;
  background-color: #eff1f6;
}
#invalid-job-modal .job-icon .job-icon-container svg {
  position: absolute;
  left: 25%;
  top: 25%;
  width: 30px;
  height: 30px;
  fill: black;
}
#invalid-job-modal .job-icon .job-overlay-warning-icon {
  top: 10px;
}
.app__history-intro {
  padding-top: 30px;
}
.app__history-intro header {
  color: #162d54;
  font-size: 30px;
  line-height: 36px;
}
@media (max-width: 768px) {
  .app__history-intro header {
    font-size: 24px;
    line-height: 30px;
  }
}
.app__history-inline-tip {
  position: absolute;
  top: 0;
  left: 41px;
  display: none;
  flex-direction: column;
  box-sizing: border-box;
  width: 600px;
  padding: 16px 10px 24px;
  border: 1px solid #00cba5;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.18);
}
.app__history-inline-tip::after,
.app__history-inline-tip::before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.app__history-inline-tip::after {
  border-color: transparent;
  border-right-color: #fff;
  border-width: 5px;
  margin-top: -5px;
}
.app__history-inline-tip::before {
  border-color: transparent;
  border-right-color: #00cba5;
  border-width: 6px;
  margin-top: -6px;
}
.app__history-inline-tip::after,
.app__history-inline-tip::before {
  top: 24px;
}
.app__history-inline-tip > div:not(:last-child) {
  margin-bottom: 21px;
}
.app__history-inline-tip .add-button {
  font-weight: 400;
}
@media (max-width: 768px) {
  .app__history-inline-tip {
    top: -8px;
    left: 35px;
    min-width: 260px;
    width: auto;
  }
}
.app__history-inline-add--with-tip .app__history-inline-tip {
  display: flex;
}
.app__history-toast {
  max-width: 450px;
  text-align: center;
}
@media (max-width: 768px) {
  .app__history-toast {
    position: absolute;
    right: 0;
    bottom: 55px;
    left: 0;
    margin: 0 10px;
    font-size: 14px;
  }
}
.app__history-intro--resume {
  text-align: center;
}
.app__history-intro--resume header {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
}
.app__history-intro--resume footer a {
  color: #9b9b9b;
}
.app__history-intro--resume footer a:hover {
  color: #9b9b9b;
}
.app__history-resume-positions {
  color: #4a4a4a;
  font-size: 14px;
  line-height: 22px;
}
.app__history-resume-form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 20px 0;
  padding: 30px 15px;
  border: 1px solid #ddd;
  text-align: center;
}
.app__history-resume-form > strong {
  color: #000;
  font-size: 18px;
  line-height: 24px;
}
.app__history-resume-form .offer-resume-label {
  margin: 20px 0;
}
.app__history-resume-form .file-types-allowed {
  margin: 0;
  font-size: 13px;
}
.app__history-resume-form .resume-name-row {
  align-items: center;
}
.app__history-resume-form .wsicon-file {
  width: 18px;
  height: 18px;
}
.app__history-resume-form #attached-resume-options {
  margin-top: 20px;
}
.app__history-resume-form .resume-actions {
  display: flex;
  align-items: center;
}
.app__history-resume-form .resume-actions label {
  margin: 0;
  padding: 0 28px;
}
.app__history-resume-form .resume-actions a {
  font-size: 13px;
  line-height: 17px;
}
@media (min-width: 768px) {
  .app__history-resume-form .resume-name-row {
    justify-content: center;
  }
  .app__history-resume-form .resume-actions {
    justify-content: center;
  }
  .app__history-resume-form .resume-actions label {
    padding-left: 0;
  }
}
.app-history-modal__header.app-history-modal__header--first h2 {
  color: #162d54;
  font-weight: 300;
}
@media (max-width: 768px) {
  .app-history-modal__header.app-history-modal__header--first h2 {
    font-size: 24px;
    line-height: 30px;
  }
}
.app-resume-reminder__form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.app-resume-reminder__form select {
  flex: 0 0 45%;
  width: 45%;
}
.app-resume-reminder__note {
  color: #9b9b9b;
  font-size: 13px;
  line-height: 18px;
}
.app-modal__actions {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.app-modal__actions span {
  margin-top: 30px;
  text-align: center;
}
.remind-app__modal-header {
  width: 100%;
  margin: -16px 0 10px -24px;
  padding: 16px 24px 10px;
  background-color: #0031ca;
  text-align: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.remind-app__modal-header span {
  color: #fff;
  font-size: 14px;
}
.remind-app__modal-header h3 {
  color: #fff;
  font-weight: 500;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .remind-app__modal-header {
    margin: -16px 0 10px -16px;
    padding: 16px 16px 10px;
  }
}
.remind-app__modal-clock {
  height: 120px;
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/alarm-clock.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.app-review__reminder {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto -30px;
  padding: 25px;
  background-color: #3d5afe;
  color: #fff;
}
.app-review__reminder > h1 {
  color: #fff !important;
  font-size: 30px;
  line-height: 30px;
}
.app-review__reminder p {
  font-size: 15px;
  line-height: 22px;
}
@media (max-width: 768px) {
  .app-review__reminder {
    margin-bottom: -60px;
    padding-bottom: 60px;
  }
}
.app-review__auto-apply {
  display: none;
  margin-top: 40px;
  font-size: 14px;
}
.app-review__auto-apply a {
  color: #4a4a4a;
  text-transform: uppercase;
  margin: 0 8px;
}
.app-review__auto-apply a.selected {
  color: #00cba5;
  font-weight: 700;
}
@media (max-width: 768px) {
  .app-review__auto-apply {
    display: none;
  }
}
.app-review__reminder-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: none;
  width: 50%;
  margin: 0 auto;
}
.app-review__reminder-footer .password-container {
  float: none;
  width: 100%;
}
.app-review__reminder-footer .password-container input {
  color: #fff;
}
.app-review__reminder-footer .password-container #email,
.app-review__reminder-footer .password-container #phone {
  display: none;
}
.app-review__reminder-footer .password-container small {
  color: #ddd;
}
.app-review__reminder-footer .password-container #help-chars.help-block,
.app-review__reminder-footer .password-container #help-alpha.help-block {
  color: #e86232;
}
.app-review__reminder-footer .password-container #help-chars:not(.help-block),
.app-review__reminder-footer .password-container #help-alpha:not(.help-block) {
  display: none;
}
@media (max-width: 768px) {
  .app-review__reminder-footer {
    width: 100%;
  }
}
.app-review__primary {
  margin: 20px 0;
}
.app-review__secondary a {
  color: #fff;
  border-bottom: 1px dashed #fff;
}
.app-review__secondary a:hover {
  color: #ddd;
  border-color: #ddd;
}
#save-exit-link.btn {
  width: 120px;
  height: 38px;
  padding: 0;
  border: 0;
  color: #fff;
  font-size: 13px;
  line-height: 38px;
  font-weight: 500;
}
.app-questions {
  box-sizing: border-box;
  width: 560px;
  margin: 0 auto;
  padding: 60px 0 20px;
}
.app-questions header {
  padding-top: 15px;
}
.app-questions .progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .app-questions {
    width: auto;
    margin: 0 10px;
  }
}
.app-questions__header {
  margin-bottom: 30px;
  text-align: center;
}
.app-questions__header--highlight {
  color: #edaf1f;
  font-weight: 500;
}
.app-questions__progress {
  color: #bbb;
  font-size: 16px;
}
.app-questions__container {
  padding-top: 30px;
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.18);
}
.app-questions__container .portal-card-group {
  box-shadow: none;
  width: auto;
}
.app-questions__container .portal-dialog-card {
  text-align: center;
}
.app-questions__container .questions__card-group .slideleft-exit {
  top: 0;
}
@media (max-width: 768px) {
  .app-questions__container .questions__card-group {
    margin-top: 0;
  }
}
.app-questions__container .questions__card {
  padding: 30px;
}
@media (max-width: 768px) {
  .app-questions__container .questions__card {
    min-height: 0;
    padding: 20px;
  }
}
.app-questions__container .questions__next-btn {
  position: static;
  left: auto;
  bottom: auto;
  transform: none;
  margin-top: 30px;
}
.app__label-link {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .app__label-link {
    font-size: 11px;
  }
}
.app-step__preferred_shift {
  margin-top: 40px;
}
@media (max-width: 768px) {
  .app-step__preferred_shift > p {
    font-size: 15px;
    line-height: 18px;
  }
}
.app-step__preferred_shift-position:not(:last-child) {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .app-step__preferred_shift-position {
    display: flex;
    align-items: flex-start;
  }
  .app-step__preferred_shift-position > header {
    flex: 0 0 50%;
  }
}
.app-summary {
  padding-bottom: 60px;
}
.app-summary__section {
  box-sizing: border-box;
  max-width: 750px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .app-summary__section {
    padding: 0 12px;
  }
}
.app-summary__section--content {
  padding: 0 12px;
  background-color: #fff;
}
.app-summary__submitted {
  padding: 20px;
  margin-bottom: 30px;
}
.app-summary__header > h1 {
  margin: 10px 0 0;
  font-size: 21px;
  line-height: 28px;
}
.app-summary__header .wsicon-success-circle {
  fill: #00cba5;
  width: 84px;
  height: 84px;
}
@media (max-width: 768px) {
  .app-summary__header > h1 {
    font-size: 16px;
    line-height: 21px;
  }
}
.app-summary__mismatches {
  padding: 30px;
}
.app-summary__mismatches header {
  margin-bottom: 30px;
}
.app-summary__mismatches .app-summary__position:not(:last-child) {
  margin-bottom: 20px;
}
.app-summary__mismatches-cta p {
  font-size: 16px;
  font-weight: 500;
}
.app-summary__positions {
  margin: 10px 0;
}
.app-summary__position {
  color: #4a4a4a;
}
@media (max-width: 768px) {
  .app-summary__position {
    font-size: 13px;
    line-height: 17px;
  }
}
.app-summary__position-mismatch {
  margin-top: 10px;
  color: #e86232;
}
.app-summary__text {
  max-width: 400px;
  margin: 25px auto 0;
  font-size: 13px;
  line-height: 17px;
  color: #4a4a4a;
}
@media (max-width: 768px) {
  .app-summary__text {
    font-size: 12px;
  }
}
.app-summary__auto-apply-section {
  padding: 20px 170px;
  margin-bottom: 30px;
}
.app-summary__auto-apply-section header {
  font-size: 21px;
  line-height: 27px;
  font-weight: 500;
}
.app-summary__auto-apply-section em {
  color: #3d5afe;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .app-summary__auto-apply-section {
    padding: 20px;
  }
  .app-summary__auto-apply-section header {
    font-size: 16px;
    line-height: 21px;
  }
}
.app-summary__similar {
  padding: 30px 0 80px;
}
.app-summary__similar h4 {
  color: #162d54;
  margin-top: 0;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .app-summary__similar {
    background-color: #eff1f6;
  }
  .app-summary__similar .job-card-list-load-more-container {
    padding: 0 10px;
  }
}
#soft-review {
  background: #eff1f6;
  text-align: left;
  padding-top: 12px;
  position: relative;
}
#soft-review hr {
  border-bottom: 1px solid #ddd;
  border-top: none;
}
#soft-review h2 {
  margin: 12px 0 30px;
  text-align: center;
}
#soft-review .alert {
  color: #dda800;
}
#soft-review .soft-review-info {
  position: relative;
  box-sizing: border-box;
  max-width: 700px;
  padding: 60px 0;
  margin: 0 auto 40px;
  background: #fff;
}
#soft-review .soft-review-info aside {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #9b9b9b;
}
#soft-review .soft-review-info header {
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
#soft-review .soft-review-info header h2 {
  margin: 6px 0 0;
}
#soft-review .soft-review-info footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}
#soft-review .soft-review-info footer a {
  font-size: 18px;
  margin: 10px 0;
}
#soft-review .soft-review-info footer .btn-confirm {
  font-weight: 500;
  margin-bottom: 40px;
}
#soft-review .soft-review-summary header {
  border-bottom: 0;
}
#soft-review .soft-review-summary .wsicon-warning {
  width: 40px;
  height: 40px;
  fill: #edaf1f;
}
#soft-review .soft-review-company {
  font-size: 16px;
  margin-bottom: 12px;
}
#soft-review .soft-review-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #edaf1f;
  text-transform: uppercase;
}
#soft-review .soft-review-warning svg {
  width: 18px;
  height: 18px;
  fill: #edaf1f;
  margin-right: 5px;
}
#soft-review .soft-review-content {
  padding: 0 60px;
  font-size: 13px;
}
#soft-review .soft-review-type strong {
  font-weight: 600;
}
#soft-review .soft-review-meta {
  margin-top: 20px;
}
#soft-review .soft-review-icon-container {
  display: flex;
  margin-right: 10px;
}
#soft-review .soft-review-meta-info {
  display: flex;
  flex-direction: column;
}
#soft-review .soft-review-meta-highlight {
  font-style: italic;
  font-weight: 500;
}
#soft-review .soft-review-commute-time {
  display: flex;
  align-items: center;
}
#soft-review .soft-review-commute-time svg {
  width: 18px;
  height: 18px;
  fill: #4a4a4a;
  margin-right: 5px;
}
#soft-review .soft-review-shift-info {
  font-size: 15px;
}
@media (max-width: 768px) {
  #soft-review {
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: #fff;
    top: 50px;
    padding-top: 0;
  }
  #soft-review.fixed {
    position: fixed;
  }
  #soft-review h2 {
    margin-top: 30px;
  }
  #soft-review .soft-review-info {
    padding: 20px 0;
    margin: 0;
    max-width: none;
    width: 100%;
  }
  #soft-review .soft-review-info.soft-review-summary {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  #soft-review .soft-review-info aside {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    text-align: center;
  }
  #soft-review .soft-review-info header {
    margin-top: 50px;
  }
  #soft-review .soft-review-info footer {
    margin-top: 50px;
  }
  #soft-review .soft-review-info footer a {
    display: block;
    width: 100%;
    padding: 20px 0;
    margin: 0 20px;
    border-top: 1px solid #ddd;
    text-align: center;
  }
  #soft-review .soft-review-info footer a.btn-confirm {
    margin-bottom: 0;
  }
  #soft-review .soft-review-content {
    padding: 0 20px;
  }
  #soft-review .soft-review-position {
    font-size: 16px;
  }
  #soft-review section p {
    font-size: 14px;
  }
}
.soft-review-avail .pill-list {
  margin-top: 10px;
}
.soft-review-avail .pill-list li {
  flex: 0 1 auto;
  color: #000;
  background-color: #fef9ed;
  font-size: 13px;
  font-weight: 400;
}
.soft-review-avail .pill-list li.pill-shift-night {
  background-color: #eff1f6;
}
.soft-review-location .radio-list li:not(:last-child) label {
  font-weight: 400;
}
.soft-review-location .radio-list li input[type="radio"] + label {
  border-bottom: 0;
  padding-bottom: 2px;
}
.soft-review-location .text-center {
  margin-top: 40px;
}
.soft-review-location .btn-confirm {
  width: 250px;
}
@media (max-width: 768px) {
  .soft-review-location .btn-confirm {
    width: 90%;
  }
}
#prefs-summary {
  box-sizing: border-box;
  max-width: 750px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
}
#prefs-summary p {
  font-size: 30px;
  line-height: 48px;
  padding: 0 130px;
}
#prefs-summary strong {
  color: #00cba5;
}
#prefs-summary .btn {
  width: 234px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  #prefs-summary {
    padding: 30px 10px;
  }
  #prefs-summary p {
    font-size: 24px;
    line-height: 36px;
    padding: 0;
  }
  #prefs-summary .btn {
    margin-bottom: 20px;
  }
}
.app-summary__questions {
  margin: 30px auto 50px;
}
.app-summary__questions h1 {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
}
.app-summary__questions .slider {
  width: 400px;
  margin: 0 auto;
  min-width: 0;
}
.app-summary__questions .slider .slick-list {
  padding: 10px 30px;
}
.app-summary__questions .slick-dots button {
  min-width: 0;
}
@media (max-width: 768px) {
  .app-summary__questions .slider {
    min-width: 320px;
    max-width: 100%;
  }
  .app-summary__questions .slider .slick-list {
    padding: 10px 20px;
  }
  .app-summary__questions .slider .slick-slide {
    margin: 0 10px;
  }
  .app-summary__questions .slider .slick-arrow {
    display: none;
  }
  .app-summary__questions .slider-card {
    min-width: 0;
    box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.3);
  }
}
.app-summary__questions-subheader {
  margin-top: 0;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 20px;
  padding: 0 20px;
}
.app-summary__countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 28px auto;
}
.app-summary__invites {
  max-width: 750px;
  margin: 0 auto;
  text-align: left;
}
.app-summary__option-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 440px;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-size: 18px;
  background-color: #3d5afe;
  color: white;
}
.app-summary__option-card a {
  margin: 10px 0;
}
.app-summary__option-card .app-summary__job-alerts-phone {
  padding: 4px 32px;
  background-color: #f5f5f5;
  border: 2px solid #9b9b9b;
  color: #9b9b9b;
  border-radius: 5px;
  margin: auto;
}
.app-summary__option-card .app-summary__job-alerts-sms-instructions {
  font-size: 14px;
  line-height: 20px;
  color: #ddd;
}
.app-summary__option-enabled span {
  color: #3d5afe;
}
.profile-card.app-summary__profile-card {
  width: 440px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .profile-card.app-summary__profile-card {
    width: 100%;
  }
}
.app-summary__profile-card-tooltip {
  position: static;
  transform: none;
  box-sizing: border-box;
  width: 440px;
  margin: 0 auto 60px;
  padding: 20px;
}
.app-summary__profile-card-tooltip header {
  font-size: 14px;
  font-weight: 500;
}
.app-summary__profile-card-tooltip p {
  margin: 10px 0 20px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}
.app-summary__profile-card-tooltip footer {
  margin-bottom: 15px;
  font-size: 13px;
}
@media (max-width: 768px) {
  .app-summary__profile-card-tooltip {
    width: 100%;
  }
}
.app-summary__loading-cta {
  position: relative;
  margin-top: 100px;
}
.body--new-ui-check-in .questions__question .link-group {
  padding: 0;
}
.questions__question .link-group a {
  font-size: 21px;
  border: 0;
  line-height: 27px;
  padding: 14px 0;
}
.questions__question .link-group a.questions__answer--selected {
  font-weight: 700;
}
.questions__question .link-group a.questions__answer--selected::after {
  content: "*";
}
@media (max-width: 768px) {
  .questions__question .link-group a {
    font-size: 16px;
  }
}
.questions__title,
.questions__list-label {
  font-size: 28px;
  line-height: 31px;
  text-align: center;
  margin: 0;
}
@media (max-width: 768px) {
  .questions__title,
  .questions__list-label {
    font-size: 18px;
    line-height: 24px;
  }
}
.body--new-ui-check-in .questions__title,
.body--new-ui-check-in .questions__list-label {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: #162d54;
  text-align: left;
}
.questions__list-label {
  text-align: left;
}
@media (max-width: 768px) {
  .questions__list-label {
    font-size: 14px;
  }
}
.questions__avail-label {
  margin-bottom: 50px;
}
.questions__header {
  position: relative;
  height: 85px;
  max-width: 1140px;
  margin: 0 auto 50px;
  border-bottom: 1px solid #ddd;
}
.questions__header-text {
  font-weight: 500;
  line-height: 85px;
}
.questions__header-text div {
  max-width: 940px;
  margin: 0 auto;
}
.questions__header-text svg {
  width: 22px;
  height: 22px;
  fill: #000;
}
.questions__header-text a {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  display: flex;
  text-decoration: none;
}
.questions__header-text a:hover svg {
  fill: #666;
}
.questions__content {
  display: flex;
  justify-content: space-between;
}
.questions__content-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 60%;
}
.questions__content-right {
  flex: 0 0 30%;
}
.questions__card-group {
  width: 560px;
  min-height: 0;
}
.questions__card-group .slideleft-exit {
  position: absolute;
  top: 0;
  opacity: 1;
  transform: translate(0%);
}
.questions__card-group .slideleft-exit.slideleft-exit-active {
  transform: translate(-200%);
  opacity: 0.01;
  transition: transform 500ms ease-in-out, opacity 1000ms ease-in-out;
}
.questions__card {
  padding: 60px;
}
.questions__sublabel {
  margin: 10px 0;
  color: #9b9b9b;
  text-align: center;
  font-size: 14px;
}
.questions__undo {
  position: fixed;
  bottom: 50px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 144px;
  height: 60px;
  padding: 0 30px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #c2cad7;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.questions__undo svg {
  flex: 0 0 20px;
  height: 20px;
  margin-right: 5px;
  fill: #000;
}
.questions__undo:hover {
  color: #000;
  text-decoration: none;
  background-color: #d1d7e1;
}
.questions__skip {
  margin-top: 40px;
}
.questions__skip a {
  font-size: 16px;
  color: #bbb;
}
.questions__empty {
  line-height: 21px;
}
.questions__next {
  margin-top: 50px;
  text-align: center;
}
@media (max-width: 768px) {
  .questions__next {
    position: fixed;
    right: 0;
    bottom: 20px;
    left: 0;
  }
  .questions__next button {
    border-radius: 24px;
  }
}
.questions__commute-from {
  margin: 30px 0;
  font-style: italic;
  text-align: center;
}
.questions__list {
  margin-top: 30px;
}
.questions__pref-commute-length-select {
  margin: 40px 0 33px;
}
.questions__pref-wage-container {
  margin-top: 40px;
}
.questions__pref-wage-container input {
  padding-bottom: 5px;
}
.questions__question-followup {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.questions__question-followup div {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 25px;
  background-color: #eff1f6;
}
.questions__question-followup span {
  font-size: 21px;
  font-weight: 400;
}
.questions__question-followup svg.wsicon-close {
  position: relative;
  top: 1px;
  right: auto;
  width: 14px;
  height: 14px;
  padding: 0;
  margin-right: 10px;
  fill: #9caac0;
  transition: fill 100ms linear;
}
.questions__question-followup svg.wsicon-close:hover {
  fill: #162d54;
}
@media (max-width: 768px) {
  .questions__question-followup {
    margin-top: 20px;
  }
  .questions__question-followup span {
    font-size: 16px;
  }
}
.questions__follow-up {
  margin-top: 40px;
}
.questions__follow-up > label {
  font-size: 18px;
  line-height: 24px;
}
.questions__follow-up .btn {
  margin-top: 20px;
  width: auto;
}
@media (max-width: 768px) {
  .questions__follow-up {
    margin-top: 20px;
  }
  .questions__follow-up > label {
    font-size: 14px;
    line-height: 21px;
  }
}
.questions__follow-up-label-caption {
  margin-top: 24px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #5e6785;
}
.questions__follow-up-multi-select-group {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.questions__follow-up-multi-select-group .MuiButton-root {
  margin-bottom: 16px;
  width: 100%;
  max-width: 420px;
}
.questions__follow-up-multi-select-group.questions__follow-up-multi-select-group--single-col {
  justify-content: center;
}
@media (max-width: 900px) {
  .questions__follow-up-multi-select-group {
    flex-direction: column;
    justify-content: center;
  }
}
.questions__follow-up-multi-select-group-column {
  display: flex;
  flex-direction: column;
  width: calc(50% - 8px);
}
@media (max-width: 900px) {
  .questions__follow-up-multi-select-group-column {
    width: 100%;
  }
}
.questions__follow-up-self-identify {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #162d54;
  display: block;
  margin-top: 1px;
}
.Mui-disabled .questions__follow-up-self-identify {
  color: #9caac0;
}
.questions__error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.9);
}
.questions__error .btn {
  margin-top: 40px;
}
.questions__next-btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px !important;
}
.questions__question-response-notice {
  margin-top: 30px;
  text-align: center;
  color: #9b9b9b;
  font-size: 13px;
}
.questions__loader {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 1100px) {
  .questions__header-text a {
    display: inline-block;
    position: relative;
    left: 0;
    top: 5px;
    margin-left: 20px;
    transform: none;
  }
  .questions__header-text div {
    display: inline-block;
    margin-left: 20px;
  }
}
@media (max-width: 768px) {
  .questions__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 0 20px 0 10px;
    border: 0;
    max-width: none;
    width: 100%;
    height: 40px;
  }
  .questions__header-text {
    display: flex;
    align-items: center;
    flex: 0 0 65%;
    font-size: 13px;
    line-height: 16px;
  }
  .questions__header-text a {
    position: static;
    transform: none;
    margin-left: 0;
  }
  .questions__header-text a svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .questions__header-text div {
    max-width: none;
    margin: 0;
  }
  .questions__header-score {
    position: relative;
  }
  .questions__header-score .progress-text {
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform: translate(-50%, -60%);
  }
  .questions__content {
    padding: 0 10px;
    box-sizing: border-box;
  }
  .questions__content-left {
    flex: 0 0 100%;
  }
  .questions__content-right {
    display: none;
  }
  .questions__card-group {
    width: 100%;
    margin-top: 20px;
  }
  .questions__card {
    box-sizing: border-box;
    padding: 36px 20px;
    min-height: 400px;
  }
  .questions__skip a {
    font-size: 14px;
  }
  .questions__undo {
    position: fixed;
    bottom: 10px;
    width: 124px;
    padding: 0 20px;
  }
}
.question-list__cell .ws-chip-list {
  overflow: hidden;
}
.page-standalone {
  background: #eff1f6;
}
.page-standalone .content {
  max-width: 1200px;
}
.crystal-page {
  background-color: #f4f5f9;
}
.ws-nav-drawer {
  height: 100%;
  overflow-x: hidden;
  flex-shrink: 0;
}
.ws-nav-drawer .closed {
  width: 56px;
}
.ws-nav-drawer .ws-nav-drawer__header {
  display: flex;
  justify-content: center;
}
.ws-nav-drawer .ws-nav-drawer__header .MuiIconButton-root:hover {
  background-color: transparent;
}
.ws-nav-drawer .MuiPaper-root {
  box-shadow: 0px 4px 12px -4px rgba(22, 45, 84, 0.12), 0px 16px 32px 0px rgba(22, 45, 84, 0.16);
}
.ws-nav-drawer .MuiDrawer-paper {
  background-color: #051a3c;
  box-sizing: border-box;
  overflow-x: hidden;
  top: 0;
  height: 100%;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.ws-nav-drawer--small .MuiDrawer-paper {
  top: 58px;
  height: calc(100% - 58px);
}
.ws-nav-drawer--small.ws-nav-drawer--tabs .MuiDrawer-paper {
  top: 105px;
  height: calc(100% - 105px);
}
.ws-nav-drawer .MuiPaper-root {
  background-color: #051a3c;
  color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.ws-nav-drawer .MuiPaper-root .closed {
  width: 56px;
}
.ws-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: calc(100% - 56px);
  position: relative;
  left: 56px;
  transition: left 0.15s, width 0.15s;
}
.ws-content .crystal-page-loader {
  position: relative;
  height: 100vh;
  transition: width 0.15s;
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
}
.ws-content .ws-app-bar {
  position: fixed;
  z-index: 1201;
}
.ws-content.ws-content--open {
  left: 240px;
  width: calc(100% - 240px);
}
.ws-content.ws-content--small {
  left: 0;
  width: 100%;
  margin-top: 105px;
}
.ws-app-bar {
  width: 100%;
  background-color: white;
  margin-left: 0;
  z-index: 11;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.ws-nav-button__list-item {
  max-width: 240px;
  display: flex;
  flex-direction: row;
  height: 40px;
}
.ws-nav-button__list-item.accordion {
  height: 32px;
  padding-left: 32px;
}
.ws-nav-button__list-item .MuiListItemText-primary {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.ws-nav-button__list-item .MuiSvgIcon-root {
  fill: #9caac0;
}
.ws-nav-button__list-item .MuiListItemIcon-root {
  min-width: 24px;
}
.ws-nav-button__list-item .wsicon {
  fill: #9caac0;
}
.ws-nav-button__text_badge__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ws-nav-button__text {
  color: #fff;
}
.ws-nav-button__list-item:hover {
  background-color: rgba(61, 90, 254, 0.08);
}
.ws-nav-button__list-item:hover .MuiListItemText-primary {
  color: #fff;
}
.ws-nav-button__list-item:hover .wsicon,
.ws-nav-button__list-item:hover .MuiSvgIcon-root {
  fill: #fff;
}
.ws-nav-button__collapse-button .MuiIconButton-root:hover {
  background-color: rgba(61, 90, 254, 0.08);
  color: white;
}
.ws-nav-button__collapse-button .MuiIconButton-root:hover svg {
  fill: white;
}
.ws-nav-button__accordion {
  background-color: #051a3c;
  color: rgba(255, 255, 255, 0.87);
}
.ws-nav-button__accordion .accordion-collapsed {
  background-color: #051a3c;
  color: rgba(255, 255, 255, 0.87);
}
.ws-nav-button__accordion .MuiAccordionSummary-root {
  min-height: 40px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.ws-nav-button__accordion .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0;
}
.ws-nav-button__accordion .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
  color: rgba(255, 255, 255, 0.7);
}
.ws-nav-button__accordion .MuiAccordionSummary-root:hover {
  background-color: rgba(61, 90, 254, 0.08);
  color: white;
}
.ws-nav-button__accordion .MuiAccordionSummary-root:hover .MuiAccordionSummary-expandIconWrapper {
  color: white;
}
.ws-nav-button__accordion .MuiAccordionDetails-root {
  background-color: #051a3c;
  color: rgba(255, 255, 255, 0.87);
  padding: 0;
}
.ws-nav-button__accordion .MuiCollapse-root {
  padding-bottom: 8px;
}
.ws-nav-button__selected,
.ws-nav-button__selected:hover {
  background-color: #c1caff;
}
.ws-nav-button__selected .MuiListItemText-primary,
.ws-nav-button__selected:hover .MuiListItemText-primary {
  color: #162d54;
}
.ws-nav-button__selected .MuiListItemText-primary,
.ws-nav-button__selected:hover .MuiListItemText-primary {
  color: #fff;
}
.ws-nav-button__selected .MuiListItemText-secondary,
.ws-nav-button__selected:hover .MuiListItemText-secondary {
  color: rgba(255, 255, 255, 0.6);
}
.ws-nav-button__selected .wsicon,
.ws-nav-button__selected:hover .wsicon,
.ws-nav-button__selected .MuiSvgIcon-root,
.ws-nav-button__selected:hover .MuiSvgIcon-root {
  fill: #fff;
}
.ws-content__body-container {
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.ws-content__body-container--remove-padding {
  padding: unset;
}
.ws-content__body-pane {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ws-content__body {
  max-width: 1400px;
  width: 100%;
  padding-bottom: 16px;
}
.ws-content__body .retain-reports__stack_pane {
  height: calc(100vh - 133px);
}
.ws-content__body .retain-reports__drawer {
  height: calc(100vh - 133px);
}
@media (max-width: 768px) {
  .ws-content__body .retain-reports__stack_pane {
    height: calc(100vh - 150px);
  }
}
.ws-content__body-pane--full-width .ws-content__body {
  max-width: unset;
}
.ws-content__body-pane--full-width .ws-page-header__top-container {
  max-width: unset;
}
.settings-dialog__sms_preview {
  background-color: #f4f5f9;
  padding: 8px 16px;
}
.settings-dialog__warning {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 10px;
  padding: 8px;
  background-color: #fef9ed;
}
.settings-dialog__text {
  flex-grow: 1;
  line-height: 20px;
  color: #162d54;
}
.settings-dialog__select {
  min-width: 140px;
}
.survey-settings__table {
  margin-top: 24px;
  overflow: auto;
}
.survey-settings__table_rows tr {
  height: 64px;
}
.survey-settings__preview_link {
  color: #162d54;
  text-decoration: none;
}
.survey-settings__preview_link:hover {
  color: #162d54;
}
.about-us {
  max-width: 770px;
  padding: 0 20px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .about-us {
    padding: 0 12px;
  }
}
.about-us h1 {
  font-size: 60px;
  line-height: 72px;
  color: #162d54;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 768px) {
  .about-us h1 {
    font-size: 30px;
    line-height: 30px;
  }
}
.about-us .about-us-blurb {
  background: #eff1f6;
  padding: 48px 60px;
  margin-top: 48px;
}
.about-us .about-us-blurb h2 {
  padding: 0 24px 24px;
  margin: 0;
  text-align: center;
}
@media (max-width: 768px) {
  .about-us .about-us-blurb {
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .about-us .about-us-blurb h2 {
    font-size: 18px;
    line-height: 24px;
    padding-left: 0;
    padding-right: 0;
  }
}
.about-us .page-desc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: #000;
  margin: 0 auto;
  max-width: 700px;
  text-align: left;
}
.about-us .section-content {
  max-width: 460px;
}
.about-us .section-content p {
  margin: 8px 0 48px;
}
.about-us .team-section {
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}
.about-us .team-section .section-action {
  flex: 1;
  text-align: right;
}
.about-us .team-section .btn {
  padding-left: 24px;
  padding-right: 24px;
}
@media (max-width: 768px) {
  .about-us .team-section {
    flex-direction: column;
  }
  .about-us .team-section .btn {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.about-us h3 {
  margin-top: 48px;
  margin-bottom: 0;
}
.page-browse-jobs {
  padding-top: 70px;
}
.page-browse-jobs h1 {
  margin: 40px 0;
}
.page-browse-jobs .browse-jobs-page-container {
  min-height: 400px;
  margin: 0 4em;
}
@media (max-width: 768px) {
  .page-browse-jobs {
    margin: 0;
  }
  .page-browse-jobs .browse-jobs-page-container {
    margin: 0 1em;
  }
}
.browse-jobs-page__breadcrumb svg {
  width: 12px;
  height: 12px;
}
.page-browse-jobs__columns {
  column-count: 3;
  column-width: auto;
}
/*remove standard list and bullet formatting from ul*/
.page-browse-jobs__columns ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.page-browse-jobs__columns ul li {
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .page-browse-jobs__columns {
    column-count: 1;
  }
}
.content.interview-policy {
  width: 100%;
  max-width: 960px;
  box-sizing: border-box;
}
.content.interview-policy strong {
  color: #edaf1f;
}
.content.interview-policy hr {
  margin: 35px 0;
}
.content.interview-policy .section {
  padding: 12px;
}
.content.interview-policy .card {
  margin: auto;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 16px 0 rgba(22, 45, 84, 0.3);
}
.content.interview-policy .item-desc,
.content.interview-policy ul {
  margin-top: 0;
  margin-bottom: 35px;
  font-weight: 100;
  color: #666;
}
/* stylelint-disable no-descending-specificity */
.employers-lp {
  margin: 0;
  padding: 0;
}
.employers-lp .sohp__header {
  height: 520px;
}
.employers-lp .sohp__header-content {
  max-width: 960px;
  padding-left: 18px;
  display: flex;
}
@media (max-width: 768px) {
  .employers-lp .sohp__header-content {
    flex-direction: column;
    padding: 80px 24px 10px;
  }
}
.employers-lp .sohp__header-left {
  flex: 0 0 60%;
}
.employers-lp .sohp__header-right {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.employers-lp .sohp__header-right .btn {
  width: 260px;
}
.employers-lp .sohp__header-title {
  font-size: 50px;
  max-width: 400px;
}
@media (max-width: 768px) {
  .employers-lp .sohp__header-title {
    font-size: 38px;
    margin: auto;
    margin-top: 50px;
  }
}
.employers-lp .sohp__header-subtitle {
  font-size: 25px;
  line-height: 40px;
}
@media (max-width: 768px) {
  .employers-lp .sohp__header-subtitle {
    font-size: 22px;
  }
}
.employers-lp .sohp__header-candidates {
  color: white;
  margin-top: 20px;
}
.employers-lp .sohp__hero-media {
  align-items: flex-start;
}
.employers-lp .footer {
  margin-top: 0;
  border-top: 0;
}
.employers-lp .section {
  box-sizing: border-box;
  outline: none;
}
.employers-lp .row {
  position: relative;
  max-width: 1140px;
  margin: auto;
  border-bottom: 0;
  padding: 0 18px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .employers-lp .row {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
    width: 100%;
  }
}
.employers-lp #how-it-works,
.employers-lp #employer-success-stories,
.employers-lp #request-demo-recap,
.employers-lp #pricing {
  background: #eff1f6;
}
.employers-lp .btn-primary {
  outline: none;
}
.employers-lp .btn-primary:active,
.employers-lp .btn-primary:focus {
  background: #00cba5;
  border-color: #00cba5;
}
.employers-lp blockquote {
  border: 0;
  padding: 0;
  margin: 0;
}
.employers-lp .brand-headline {
  color: #162d54;
  font-weight: 700;
  font-size: 68px;
  line-height: 68px;
}
@media (max-width: 768px) {
  .employers-lp .brand-headline {
    font-size: 36px;
    line-height: 36px;
  }
}
.employers-lp h2.smaller-headline {
  font-size: 38px;
  line-height: 48px;
}
@media (max-width: 768px) {
  .employers-lp h2.smaller-headline {
    font-size: 28px;
    line-height: 30px;
  }
}
.employers-lp .brand-tagline {
  color: #4a4a4a;
  font-size: 30px;
  line-height: 1.2em;
  font-weight: 300;
  display: block;
  margin-top: 21px;
}
@media (max-width: 768px) {
  .employers-lp .brand-tagline {
    font-size: 24px;
    line-height: 30px;
  }
}
.employers-lp .brand-description {
  color: #162d54;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  max-width: 460px;
  margin-top: 41px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .employers-lp .brand-description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }
}
.employers-lp .compare-cta {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2em;
}
@media (max-width: 768px) {
  .employers-lp .compare-cta {
    font-size: 14px;
  }
}
.employers-lp .form-group {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 0;
}
.employers-lp #employer-hero {
  min-height: 655px;
  position: relative;
  z-index: 1;
}
.employers-lp #employer-hero .brand-headline {
  margin-top: 0;
  padding-top: 224px;
}
.employers-lp #employer-hero .row {
  min-height: 655px;
}
.employers-lp #employer-hero .hero-info {
  max-width: 554px;
}
.employers-lp #employer-hero .hero-info .btn {
  display: none;
}
@media (max-width: 768px) {
  .employers-lp #employer-hero {
    min-height: initial;
    padding-bottom: 36px;
  }
  .employers-lp #employer-hero .brand-headline {
    padding-top: 100px;
    margin-bottom: 8px;
  }
  .employers-lp #employer-hero .brand-tagline {
    margin-top: 0;
  }
  .employers-lp #employer-hero .row {
    min-height: initial;
  }
  .employers-lp #employer-hero .hero-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: initial;
  }
  .employers-lp #employer-hero .hero-info .btn {
    display: block;
    margin-top: 24px;
  }
}
.employers-lp .slick-dots > li > button {
  margin: 5px;
  padding: 5px;
  height: 8px;
  width: 8px;
  opacity: 1;
  background-color: #c2cad7;
  border-radius: 50%;
  border: none;
}
.employers-lp .slick-dots > li > button:hover {
  background-color: #9caac0;
}
.employers-lp .slick-dots > li.slick-active > button {
  height: 12px;
  width: 12px;
  opacity: 1;
  background-color: #20caa5;
  border-radius: 50%;
  border: none;
}
.employers-lp #our-candidate-network {
  min-height: 668px;
  text-align: left;
}
.employers-lp #our-candidate-network h2 {
  text-align: center;
  margin-top: 0;
}
.employers-lp #our-candidate-network .detail-item {
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-bottom: 60px;
}
.employers-lp #our-candidate-network .item-illo {
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
  align-self: baseline;
}
.employers-lp #our-candidate-network .item-info {
  flex: 1;
  padding-left: 38px;
}
.employers-lp #our-candidate-network .item-title {
  line-height: 27px;
  margin-bottom: 5px;
}
.employers-lp #our-candidate-network .item-desc {
  margin: 0;
}
@media (max-width: 768px) {
  .employers-lp #our-candidate-network .item-illo {
    flex-basis: 48px;
    width: 48px;
    height: 48px;
  }
  .employers-lp #our-candidate-network .item-info {
    padding-left: 24px;
  }
  .employers-lp #our-candidate-network .detail-item {
    padding-left: 0;
    margin-bottom: 30px;
  }
  .employers-lp #our-candidate-network .detail-item:last-child {
    margin-bottom: 60px;
  }
  .employers-lp #our-candidate-network .item-title {
    font-size: 16px;
    line-height: 18px;
  }
  .employers-lp #our-candidate-network .item-desc {
    font-size: 14px;
    line-height: 18px;
  }
}
.employers-lp #our-candidate-network .split-section {
  display: flex;
}
.employers-lp #our-candidate-network .section-left {
  flex: 0 1 506px;
  flex-direction: column;
}
.employers-lp #our-candidate-network .section-right {
  flex: 0 1 480px;
}
.employers-lp #our-candidate-network .section-spacer {
  flex: 1;
}
.employers-lp #our-candidate-network .candidate-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.employers-lp #our-candidate-network .candidate-review blockquote {
  font-size: 21px;
  line-height: 30px;
  color: #162d54;
  text-align: center;
  padding: 0 18px;
}
.employers-lp #our-candidate-network .candidate-review cite {
  font-size: 18px;
  line-height: 24px;
  color: #666;
  display: block;
  margin: 20px auto;
  text-align: center;
}
.employers-lp #our-candidate-network .candidate-review .profile-pic {
  width: 48px;
  height: 48px;
  display: block;
  border-radius: 24px;
  overflow: hidden;
  margin: 0 auto;
}
.employers-lp #our-candidate-network .candidate-review .profile-pic img {
  width: 100%;
}
@media (max-width: 768px) {
  .employers-lp #our-candidate-network .candidate-review blockquote {
    padding: 0;
  }
  .employers-lp #our-candidate-network .candidate-review blockquote.smaller-quote {
    font-size: 18px;
    line-height: 24px;
  }
  .employers-lp #our-candidate-network .candidate-review cite {
    font-size: 14px;
    line-height: 16px;
  }
}
.employers-lp #our-candidate-network .slick-slider {
  max-width: 480px;
}
.employers-lp #our-candidate-network .slick-track {
  min-height: 300px;
}
.employers-lp #our-candidate-network .slick-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 200px;
}
@media (max-width: 768px) {
  .employers-lp #our-candidate-network {
    min-height: 0;
  }
  .employers-lp #our-candidate-network h2 {
    padding-top: 32px;
  }
  .employers-lp #our-candidate-network .split-section {
    flex-direction: column;
  }
  .employers-lp #our-candidate-network .section-right {
    display: none;
  }
  .employers-lp #our-candidate-network .section-spacer {
    order: 2;
    flex-basis: 0;
  }
  .employers-lp #our-candidate-network .section-left {
    order: 1;
    flex-basis: initial;
  }
  .employers-lp #our-candidate-network .slick-slider {
    margin: 0 auto;
  }
  .employers-lp #our-candidate-network .slick-dots {
    bottom: 20px;
  }
  .employers-lp #our-candidate-network .slick-track {
    min-height: 310px;
  }
}
.employers-lp #compare {
  padding-bottom: 100px;
}
.employers-lp #compare h2 {
  text-align: center;
  max-width: 620px;
  margin: 0 auto 18px;
  padding-top: 75px;
  margin-bottom: 48px;
}
.employers-lp #compare .jobs-we-serve {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 48px;
}
@media (max-width: 768px) {
  .employers-lp #compare {
    padding: 0;
  }
  .employers-lp #compare h2 {
    padding-top: 36px;
    margin-bottom: 0;
  }
}
.employers-lp #employer-success-stories {
  min-height: 470px;
}
@media (max-width: 768px) {
  .employers-lp #employer-success-stories {
    padding-top: 24px;
  }
}
.employers-lp .modal-dialog {
  width: 465px;
}
.employers-lp .modal-dialog .modal-body {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
.employers-lp .modal-dialog .modal-body > h4 {
  padding-bottom: 20px;
}
.employers-lp .modal-dialog .modal-header {
  border: none;
}
@media (max-width: 768px) {
  .employers-lp .modal-dialog {
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .employers-lp .modal-dialog .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}
.employers-lp .hs-demo-form {
  padding: 40px 20px 20px;
  width: 480px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  box-shadow: rgba(22, 45, 84, 0.2);
}
@media (max-width: 768px) {
  .employers-lp .hs-demo-form {
    width: 90%;
    padding: 32px 16px 0 16px;
  }
}
.employers-lp .carousel {
  width: 100%;
  min-height: 470px;
  overflow: hidden;
  text-align: center;
}
.employers-lp .carousel .slick-track {
  min-height: 450px;
  display: flex;
  align-items: center;
}
.employers-lp .carousel .employer-success-story {
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  cursor: default;
}
.employers-lp .carousel .employer-success-story blockquote {
  color: #162d54;
  font-size: 24px;
  line-height: 36px;
  margin: 0 auto 26px;
  width: 80%;
  min-width: 280px;
}
.employers-lp .carousel .employer-success-story cite {
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: #666;
  margin-bottom: 20px;
}
.employers-lp .carousel .case-study-callout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 423px;
  min-height: 94px;
  border: 1px solid #c2cad7;
  padding-left: 24px;
  margin: 0 auto;
  box-sizing: border-box;
}
.employers-lp .carousel .case-study-callout::after {
  content: "";
  display: table;
  clear: both;
}
.employers-lp .carousel .case-study-callout .company-logo {
  flex: 0 0 105px;
  height: 60px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.employers-lp .carousel .case-study-callout .company-logo.squarish {
  height: 90px;
}
.employers-lp .carousel .case-study-callout .company-logo#logo-resers {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-resers@2x.png");
}
.employers-lp .carousel .case-study-callout .company-logo#logo-kroger {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-kroger@2x.png");
}
.employers-lp .carousel .case-study-callout .company-logo#logo-transpak {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-transpak.png");
  margin-left: -12px;
  flex-basis: 117px;
}
.employers-lp .carousel .case-study-callout .company-logo#logo-probuild {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-buildersfs.png");
}
.employers-lp .carousel .case-study-callout .company-logo#logo-westrock {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-westrock.png");
}
.employers-lp .carousel .case-study-callout .company-logo#logo-gpi {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-gpi.png");
}
.employers-lp .carousel .case-study-callout .company-logo#logo-alpinefoods {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-alpine.png");
}
.employers-lp .carousel .case-study-callout .company-logo#logo-vetrotech {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/logo-vetrotech@2x.png");
}
.employers-lp .carousel .case-study-callout.quote-only {
  border: 0;
}
.employers-lp .carousel .case-study-callout.quote-only .company-logo {
  flex: 0 0 300px;
}
.employers-lp .carousel .case-study-callout .case-study-info {
  flex: 1;
  text-align: left;
  padding: 0 16px;
}
.employers-lp .carousel .case-study-callout .case-study-link {
  font-size: 18px;
  font-weight: 500;
}
.employers-lp .carousel .case-study-callout .case-study-title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0;
}
@media (max-width: 768px) {
  .employers-lp .carousel .case-study-callout {
    width: 100%;
    padding: 12px;
  }
  .employers-lp .carousel .case-study-callout cite {
    margin-top: 0;
  }
  .employers-lp .carousel .case-study-callout .company-logo {
    flex-basis: 60px;
  }
  .employers-lp .carousel .case-study-callout .company-logo#logo-transpak {
    flex-basis: 60px;
    margin-left: 0;
  }
  .employers-lp .carousel .case-study-callout.quote-only .company-logo {
    flex-basis: 300px;
  }
  .employers-lp .carousel .case-study-callout .case-study-info {
    padding-left: 12px;
  }
  .employers-lp .carousel .case-study-callout .case-study-link {
    font-size: 16px;
  }
}
.employers-lp .carousel .case-study-clickable {
  cursor: pointer;
}
.employers-lp .carousel .case-study-clickable:hover {
  border-color: #9caac0;
}
.employers-lp .carousel .slick-dots {
  width: 100%;
  list-style: none;
  padding: 0;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.employers-lp .carousel .slick-dots > li {
  display: inline-block;
  font-size: 0;
}
@media (max-width: 768px) {
  .employers-lp .carousel .employer-success-story blockquote {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
  }
  .employers-lp .carousel .employer-success-story cite {
    font-size: 14px;
    line-height: 16px;
  }
}
.employers-lp .comparison-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.employers-lp .comparison-table td,
.employers-lp .comparison-table th {
  padding: 20px;
  border: 1px solid #ddd;
}
.employers-lp .comparison-table thead:first-child > tr:first-child > th {
  border: 1px solid #ddd;
  border-bottom: none;
}
.employers-lp .comparison-table td {
  text-align: left;
  color: #6e6e6e;
  font-size: 16px;
}
.employers-lp .comparison-table td.winner {
  font-weight: 700;
}
.employers-lp .comparison-table td.winner::before {
  content: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/starIcon-gold.svg");
  margin-right: 5px;
}
.employers-lp .comparison-table th {
  font-size: 18px;
  font-weight: 700;
  border-top: 1px solid #ddd;
}
.employers-lp .comparison-table th#empty {
  border-left: none;
  border-top: none;
}
.employers-lp .comparison-table .table-cell-muted {
  text-align: center;
  background: #f5f5f5;
}
.employers-lp .comparison-table .table-cell-primary {
  text-align: center;
  color: white;
  background: #20caa5;
}
.employers-lp .comparison-table .workstep-column-logo {
  width: 67%;
}
@media (max-width: 768px) {
  .employers-lp .comparison-table {
    width: 100%;
    margin: 0 auto 36px;
  }
  .employers-lp .comparison-table td,
  .employers-lp .comparison-table th {
    padding: 5px;
  }
  .employers-lp .comparison-table td {
    text-align: left;
    color: #6e6e6e;
    font-size: 12px;
    line-height: 14px;
  }
  .employers-lp .comparison-table th {
    font-size: 8px;
    line-height: 11px;
    font-weight: 700;
  }
  .employers-lp .comparison-table th img {
    height: 8px;
  }
}
.employers-lp #how-it-works {
  min-height: 630px;
  position: relative;
  overflow-x: hidden;
  direction: rtl;
}
.employers-lp #how-it-works ul {
  list-style: none;
  padding: 0;
  font-size: 18px;
  display: block;
}
.employers-lp #how-it-works li {
  margin-top: 22px;
}
.employers-lp #how-it-works h2 {
  padding-top: 120px;
  margin-top: 0;
  margin-bottom: 40px;
}
.employers-lp #how-it-works .section-left {
  width: 389px;
  float: right;
  direction: ltr;
}
.employers-lp #how-it-works .candidate-profile-screenshot {
  display: block;
  position: absolute;
  top: 48px;
  left: -48px;
  width: 705px;
}
@media (min-width: 768px) {
  .employers-lp #how-it-works .row {
    min-width: 1060px;
  }
}
@media (max-width: 768px) {
  .employers-lp #how-it-works {
    display: flex;
    overflow: hidden;
    max-height: 800px;
  }
  .employers-lp #how-it-works h2 {
    text-align: center;
    padding-top: 36px;
  }
  .employers-lp #how-it-works .section-left {
    width: auto;
    float: none;
  }
  .employers-lp #how-it-works .candidate-profile-screenshot {
    width: 100%;
    position: relative;
    top: initial;
    left: initial;
    margin: 0 auto 36px;
    bottom: -44px;
  }
}
.employers-lp #pricing {
  padding-bottom: 64px;
}
.employers-lp #pricing h2 {
  text-align: center;
  padding-top: 75px;
  margin-top: 0;
  margin-bottom: 50px;
}
.employers-lp #pricing .detail-list {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}
.employers-lp #pricing .detail-item {
  flex: 0 1 363px;
  margin-right: 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.employers-lp #pricing .detail-item:last-child {
  margin-right: 0;
}
.employers-lp #pricing .detail-item img {
  display: block;
  width: 90px;
  height: 90px;
  margin: 0 auto;
}
.employers-lp #pricing .detail-item p {
  font-size: 21px;
  line-height: 30px;
  color: #162d54;
  padding: 0 24px 0 30px;
}
@media (max-width: 768px) {
  .employers-lp #pricing .detail-list {
    flex-direction: column;
    align-items: center;
  }
  .employers-lp #pricing .detail-item {
    max-width: 320px;
    flex-basis: initial;
    margin-right: 0;
    margin-bottom: 24px;
    padding: 0;
  }
  .employers-lp #pricing .detail-item p {
    font-size: 16px;
    line-height: 21px;
    padding: 0 12px;
  }
}
.employers-lp #pricing .btn {
  margin: 0 auto;
  display: block;
}
.employers-lp #pricing .btn.btn-large {
  height: 56px;
  border-radius: 4px;
  font-size: 20px;
}
.employers-lp .success-container {
  width: 200px;
  height: 280px;
  margin: 25px auto;
  text-align: center;
  color: #172e53;
}
.employers-lp .success-container svg {
  margin-top: 74px;
  width: 130px;
}
@media (max-width: 468px) {
  .employers-lp table td {
    font-size: 11px;
  }
}
.page-reapply {
  margin-top: 40px;
}
.page-reapply h1 {
  text-align: center;
}
.page-reapply .checkbox-list {
  margin: 60px 0;
}
.page-reapply .checkbox-list input[type="checkbox"] + label {
  display: flex;
  flex-direction: column;
}
.page-reapply .checkbox-list input[type="checkbox"] + label::before {
  top: 34px;
}
.page-reapply .checkbox-list input[type="checkbox"] + label::after {
  left: 5px;
  top: 42px;
}
.page-reapply .checkbox-list li:first-child input[type="checkbox"] + label {
  border-top: 1px solid #ddd;
}
@media (max-width: 768px) {
  .page-reapply .checkbox-list {
    margin-top: 30px;
  }
  .page-reapply .checkbox-list input[type="checkbox"] + label::after {
    left: auto;
  }
}
.page-candidate-profile {
  background-color: #eff1f6;
}
.reference-feedback {
  text-align: center;
}
.reference-feedback h2,
.reference-feedback h4 {
  padding: 0 20px;
  max-width: 600px;
  font-weight: 300;
  margin: 50px auto;
}
.reference-feedback hr {
  width: 200px;
  margin: 40px auto;
}
.reference-feedback .card {
  box-sizing: border-box;
  padding-top: 18px;
}
.reference-feedback .card .btn {
  margin: 40px auto auto;
  width: 200px;
  display: block;
}
.reference-feedback .job-card {
  display: inline-block;
  overflow: hidden;
  margin: 16px 8px;
  max-width: 345px;
  padding: 0;
}
.reference-feedback .job-card .position-map.mobile {
  display: block;
  margin-top: 12px;
}
.reference-feedback .btn-large {
  width: 100%;
  margin-bottom: 40px;
}
.reference-feedback .caption-text {
  margin: 40px auto;
  padding: 0 16px;
}
.reference-feedback .heading-4 {
  text-align: center;
}
.reference-feedback .summary-container {
  max-width: 340px;
  margin: auto;
  text-align: left;
}
.reference-feedback .summary-container ul {
  padding-left: 24px;
}
.reference-feedback .form-container {
  max-width: 600px;
  margin: auto;
}
.interview-feedback .feedback-summary {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 20px auto 0;
}
.interview-feedback .feedback-summary img {
  display: inline-block;
  margin-left: 20px;
}
.interview-feedback .feedback-summary .feedback-summary-text {
  display: inline-block;
  padding-left: 30px;
}
.interview-feedback .feedback-summary h2 {
  margin-bottom: 5px;
}
.interview-feedback .feedback-summary p {
  margin: 5px 0;
}
.interview-feedback .feedback-summary .interview-time-feedback {
  color: #edaf1f;
}
@media (max-width: 768px) {
  .interview-feedback .feedback-summary {
    width: 95%;
  }
  .interview-feedback .feedback-summary img {
    transform: scale(0.7);
  }
  .interview-feedback .feedback-summary .feedback-summary-text {
    padding-left: 10px;
  }
  .interview-feedback .feedback-summary h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .interview-feedback .feedback-summary p {
    font-size: 15px;
    line-height: 21px;
  }
}
.interview-feedback .feedback-form-block {
  max-width: 600px;
  box-sizing: border-box;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form {
  padding-left: 30px;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form input[type="radio"] {
  display: none;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form input[type="submit"] {
  margin: 60px auto 0;
  display: block;
  width: 40%;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form label {
  font-size: 16px;
  line-height: 22px;
  border-bottom: none;
  padding: 10px 0 10px 48px;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form label::before {
  top: 9px;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form label::after {
  top: 18px;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form .feedback-explanation-label {
  padding: 10px 0;
  height: auto;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form .explanation-field {
  padding: 10px 0;
  height: auto;
}
.interview-feedback .feedback-form-block .radio-list.feedback-form .unfilled-error {
  color: #e86232;
  text-align: center;
  display: none;
}
.interview-feedback .feedback-form-block [id^="explanation-block"] {
  display: none;
  margin: 30px 0;
}
.interview-feedback .feedback-form-block .display-explanation {
  display: block;
}
@media (max-width: 768px) {
  .interview-feedback .feedback-form-block .radio-list.feedback-form {
    padding: 0 0 20px;
  }
  .interview-feedback .feedback-form-block .radio-list.feedback-form input[type="submit"] {
    margin: 30px auto 0;
    width: auto;
  }
  .interview-feedback .feedback-form-block .radio-list.feedback-form label {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    border-bottom: none;
    padding-top: 0;
  }
  .interview-feedback .feedback-form-block .radio-list.feedback-form label::before {
    left: 0;
    top: 1px;
  }
  .interview-feedback .feedback-form-block .radio-list.feedback-form label::after {
    left: 6px;
    top: 10px;
  }
}
.interview-feedback .feedback-response {
  display: none;
}
.interview-feedback .feedback-response .feedback-specific-response {
  display: none;
}
.interview-feedback .feedback-response-already {
  display: flex;
}
.interview-feedback .feedback-response,
.interview-feedback .feedback-response-already {
  max-width: 600px;
  min-height: 300px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 30px;
  margin: 30px auto 60px;
  align-items: center;
  justify-content: center;
}
.interview-feedback .feedback-response div,
.interview-feedback .feedback-response-already div {
  display: block;
  text-align: center;
}
.interview-feedback .feedback-response div p,
.interview-feedback .feedback-response-already div p,
.interview-feedback .feedback-response div a,
.interview-feedback .feedback-response-already div a {
  font-size: 18px;
}
.interview-feedback .feedback-response div p,
.interview-feedback .feedback-response-already div p {
  color: #162d54;
  font-weight: 500;
}
.confirmation-header {
  text-align: center;
  margin: 75px 0 15px;
}
.confirmation-header h2 {
  margin-bottom: 0;
}
.confirmation-header p {
  margin-top: 5px;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmation-content p {
  width: 600px;
  font-size: 14px;
  text-align: center;
}
.confirmation-content a {
  text-decoration: none;
  cursor: pointer;
}
.confirm-home-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.confirm-home-link a {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}
.candidate-start-date h2 {
  text-align: center;
}
.candidate-start-date .react-datepicker-wrapper {
  width: 100%;
}
.candidate-start-date__date {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
}
.candidate-update-phone .wsicon-profile {
  width: 16px;
  height: 16px;
  margin-right: 7px;
}
.withdraw {
  width: 100%;
  max-width: 700px;
  min-height: 500px;
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 60px;
  background-color: #fff;
}
.withdraw-summary {
  text-align: center;
}
.withdraw-length {
  color: #e86232;
  display: none;
}
.withdraw-buttons {
  text-align: center;
  margin-top: 40px;
}
.withdraw textarea {
  height: 60px;
  font-size: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  padding-left: 5px;
}
.withdraw-label {
  margin-bottom: 2px;
}
.withdraw-worked {
  text-align: center;
  padding-top: 60px;
}
.withdraw-worked p {
  margin-bottom: 50px;
}
.withdraw-failed {
  text-align: center;
  padding-top: 60px;
}
.withdraw-failed p {
  color: #e86232;
}
#withdraw-error {
  display: none;
}
#withdraw-success {
  display: none;
}
.page-signup {
  margin-top: 50px;
}
.page-signup #how-it-works-link {
  display: none;
}
.page-signup .signup-container {
  display: flex;
}
.page-signup .signup-hero,
.page-signup .signup-form-container {
  flex: 0 0 50%;
}
.page-signup .signup-hero {
  position: relative;
  height: 100vh;
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/cnc-machine-2.jpg");
  background-position: 40% 50%;
  background-size: cover;
  box-shadow: inset -12px -12px 18px -8px rgba(0, 0, 0, 0.2);
}
.page-signup .signup-form-header {
  text-align: center;
}
.page-signup .signup-form-header p {
  font-size: 14px;
  margin: 0;
}
.page-signup .signup-hero-info-candidate,
.page-signup .signup-hero-info-employer {
  padding: 10px 40px;
}
.page-signup .signup-form {
  box-sizing: border-box;
  padding: 60px 75px 20px;
  width: 100%;
}
.page-signup .signup-form-footer {
  text-align: center;
  padding-top: 20px;
}
.page-signup .signup-form-employer-error {
  color: #e86232;
}
.page-signup .success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-signup .success-container svg {
  width: 100px;
  height: 100px;
  padding: 100px 100px 25px;
}
.page-signup .signup-terms {
  display: block;
  margin-top: 20px;
}
.page-signup #btn-password {
  width: 380px;
}
@media (max-width: 768px) {
  .page-signup {
    margin: 0;
  }
  .page-signup #nav {
    display: none;
  }
  .page-signup .signup-container {
    display: block;
  }
  .page-signup .signup-hero {
    display: none;
  }
  .page-signup .signup-form-container {
    height: 100%;
  }
  .page-signup .signup-form {
    padding: 20px 10px;
    width: auto;
  }
  .page-signup .signup-form h1 {
    font-size: 24px;
    line-height: 31px;
  }
  .page-signup .signup-form-header img {
    width: 140px;
  }
  .page-signup .signup-form-header .signup-form-mobile-logo {
    margin-bottom: 30px;
  }
  .page-signup .signup-terms {
    font-size: 12px;
    line-height: 18px;
    padding: 0 40px;
  }
  .page-signup .btn-primary {
    width: 100%;
  }
}
.page-signup .radio-list input[type="radio"] + label {
  border: none;
}
.signup-hero__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(10, 33, 73, 0.85);
}
.signup-hero__cover div {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 240px);
}
.signup-form-buttons {
  display: flex;
  justify-content: center;
  padding: 5px;
}
.signup-form-buttons button {
  height: 45px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 26px;
  padding: 9px 24px;
  margin: 0 5px;
}
@media (max-width: 768px) {
  .signup-form-buttons button {
    padding: 9px 15px;
    height: 40px;
    font-size: 12px;
  }
}
.signup-btn--selected {
  color: #fff;
  background-color: #00cba5;
  border-color: #00cba5;
}
.signup-btn--selected:hover {
  color: #fff;
}
.page-login {
  margin-top: 50px;
}
.page-login #how-it-works-link {
  display: none;
}
.page-login .login-page-container {
  display: flex;
}
.page-login .login-hero,
.page-login .login-form-container {
  flex: 0 0 50%;
}
.page-login .login-hero {
  position: relative;
  height: 100vh;
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/forklift.jpg");
  background-position: 40% 50%;
  background-size: cover;
  box-shadow: inset -12px -12px 18px -8px rgba(0, 0, 0, 0.2);
}
.page-login .login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-login .login-form-header {
  text-align: center;
}
.page-login .login-form-header p {
  font-size: 14px;
  margin: 0;
}
.page-login .login-container-inline {
  margin: 0 auto;
  flex-grow: 2;
}
.page-login .login-container-inline.auth-component {
  padding: 80px 0;
}
.page-login .login-form {
  box-sizing: border-box;
  padding: 150px 20px 20px;
  width: 100%;
}
.page-login .login-form__join {
  text-align: center;
  font-size: 14px;
  margin-top: 60px;
}
.page-login .login-form-employer-error {
  color: #e86232;
}
.page-login .success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-login .success-container svg {
  width: 100px;
  height: 100px;
  padding: 100px 100px 25px;
}
.page-login .login-terms {
  display: block;
  margin-top: 20px;
}
.page-login #handle:focus {
  margin-bottom: 0;
}
.page-login #btn-password {
  width: 380px;
}
@media (max-width: 768px) {
  .page-login {
    margin: 0;
  }
  .page-login #nav {
    display: none;
  }
  .page-login .login-page-container {
    display: block;
  }
  .page-login .login-hero {
    display: none;
  }
  .page-login .login-form-container {
    height: 100%;
  }
  .page-login .login-form {
    padding: 20px 10px;
    width: auto;
  }
  .page-login .login-form h1 {
    font-size: 24px;
    line-height: 31px;
  }
  .page-login .login-form-header img {
    width: 140px;
  }
  .page-login .login-form-header .login-form-mobile-logo {
    margin-bottom: 30px;
  }
  .page-login .login-form-header h1 {
    display: none;
  }
  .page-login .login-form__join {
    text-align: center;
    font-size: 14px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 2em;
  }
}
.page-login .radio-list input[type="radio"] + label {
  border: none;
}
.login-hero__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(10, 33, 73, 0.85);
}
.login-hero__cover div {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 240px);
}
body.portal-page {
  padding: 64px 0 64px 250px;
  background-color: #f4f5f9;
  transition: padding 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}
@media (max-width: 1200px) {
  body.portal-page {
    padding-top: 56px;
    padding-left: 72px;
  }
}
@media (max-width: 900px) {
  body.portal-page {
    padding-left: 0;
  }
  body.portal-page .hidden-mobile {
    display: none !important;
  }
  body.portal-page .mobile-only {
    display: block;
  }
}
body.portal-page--minimized {
  padding-left: 72px;
}
body.portal-page--minimized .MuiTooltip-popper[data-popper-placement*="right"] .portal-nav-sidebar__tooltip {
  display: block;
}
@media (max-width: 1200px) {
  body.portal-page--minimized {
    padding-left: 0;
  }
}
body.portal-page--onboarding {
  padding-top: 0;
  padding-bottom: 80px;
}
body.portal-page--no-nav {
  padding-left: 0;
}
body.portal-nav-left--home-expansion {
  padding-top: 0;
}
.portal > h1 {
  margin-top: 0;
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
}
@media (max-width: 768px) {
  .portal > h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.portal-container__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 16px 32px 0;
}
@media (max-width: 900px) {
  .portal-container__header {
    display: none;
  }
}
.portal-container__title {
  margin: 0;
  display: flex;
  align-items: baseline;
}
.portal-container__title span {
  display: flex;
  align-items: center;
}
.portal-container__title span .MuiSvgIcon-root {
  margin-right: 24px;
}
.portal-container__tabs {
  margin: 0 32px;
  border-bottom: 1px solid #c2cad7;
}
@media (max-width: 900px) {
  .portal-container__tabs {
    margin: 0;
    position: relative;
    z-index: 1002;
  }
}
@media (max-width: 900px) {
  .portal-container__tabs:after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 1px;
    z-index: -1;
    transform: scale(1);
    box-shadow: 0px 0px 16px 6px rgba(22, 45, 84, 0.2);
  }
}
.portal-container__tabs a {
  text-decoration: none;
}
.portal-container__tabs .ds-tabs--full-width {
  background-color: #fff;
}
.portal-container__content {
  margin: 16px 32px;
}
@media (max-width: 900px) {
  .portal-container__content {
    margin: 8px 16px;
  }
}
@media (max-width: 768px) {
  .portal-container__content {
    margin: 8px;
  }
}
.portal-container__content.portal-container__breakout {
  margin: 0;
}
.portal__filter-row {
  margin: 16px 0;
  display: flex;
}
.portal__filter-row .wsdd-menu {
  z-index: 10;
}
.portal__filter-row .btn {
  margin: -8px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 9px 20px;
}
.portal__filter-row-floating {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.portal__filter-row-add-event-btn--tooltip {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  width: 191px;
}
.portal__filter-break-in {
  margin-left: 32px;
}
.portal__content-text {
  margin: 16px 4px;
}
.portal-section,
.portal-section-page,
.portal-section-block {
  position: relative;
  box-sizing: border-box;
  margin: 16px 0;
}
.portal-section > header {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 16px;
}
.portal-section .portal-section-content {
  position: relative;
  box-sizing: border-box;
  padding: 50px;
  font-size: 14px;
  background-color: #fff;
}
.portal-section .portal-section-content.portal-section-empty {
  min-height: 100px;
}
.portal-section__title {
  margin-top: 0;
}
.portal-section-page {
  font-size: 14px;
  padding: 32px;
  background-color: #fff;
}
@media (max-width: 768px) {
  .portal-section-page {
    padding: 16px;
  }
}
.portal-section-page .form-group:first-child {
  margin-top: 0;
}
.portal-section-block {
  padding: 24px;
  border: 1px solid #c2cad7;
  border-radius: 8px;
  background-color: #fff;
}
@media (max-width: 768px) {
  .portal-section-block {
    padding: 16px;
  }
}
.portal-section-block__note {
  margin-top: 5px;
  margin-right: 8px;
  color: #9caac0;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
}
.portal-section-block__note a {
  color: #3d5afe;
  text-decoration: underline;
}
.portal-section-block__note a:hover {
  color: #c2cad7;
}
.portal-section__header {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}
.portal-section--aside {
  box-sizing: border-box;
  flex: 0 0 320px;
  margin-left: 16px;
}
.portal-header {
  padding-left: 60px;
}
.portal-header > h1 {
  font-size: 28px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .portal-header {
    padding: 0 10px;
  }
}
.portal-header-flex {
  display: flex;
  align-items: center;
}
.portal-header-breadcrumb {
  display: flex;
  align-items: center;
  color: #9caac0;
  font-size: 16px;
  line-height: 21px;
}
.portal-header-breadcrumb svg {
  fill: #9caac0;
  width: 8px;
  height: 8px;
  margin-left: 5px;
}
@media (max-width: 768px) {
  .portal-header-breadcrumb {
    padding-left: 10px;
  }
}
.portal-subheader {
  font-size: 13px;
}
.portal-subheader a {
  color: #9b9b9b;
  text-decoration: underline;
}
.portal-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
  background-color: #fff;
  box-shadow: 0 2px 18px 0 rgba(22, 45, 84, 0.2);
}
@media (max-width: 1200px) {
  .portal-nav {
    width: 100vw;
    height: 56px;
    padding: 18px;
  }
}
.portal-nav__logo {
  line-height: 17px;
}
.portal-nav__logo img {
  height: 17px;
}
.portal-nav__logo .portal-nav__logo-icon {
  width: 24px;
  height: 24px;
  fill: #00cba5;
}
@media (max-width: 900px) {
  .portal-nav__logo a.mobile-only {
    display: inline-flex;
  }
  .portal-nav__logo:hover .portal-nav__logo-icon,
  .portal-nav__logo:active .portal-nav__logo-icon,
  .portal-nav__logo:focus .portal-nav__logo-icon {
    fill: #00cba5;
  }
}
.portal-nav__page-title {
  margin-left: 8px;
  color: #162d54;
}
.portal-nav__links {
  display: flex;
  align-items: center;
}
.portal-nav__links .portal-nav-button {
  color: #5e6785;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  text-transform: none;
}
.portal-nav__links .portal-nav-button:not(:last-child) {
  margin-right: 5px;
}
.portal-nav__links .portal-nav-button:hover {
  color: #162d54;
  border-color: #eff2ff;
  background-color: #eff2ff;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
}
@media (max-width: 900px) {
  .portal-nav__links a {
    font-size: 13px;
  }
  .portal-nav__links a:not(:last-child) {
    margin-right: 18px;
  }
  .portal-nav__links a.mobile-only {
    display: inline-flex;
    margin-right: 0;
  }
}
.portal-help-menu {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
}
.notification-bell {
  font-size: 24px;
  padding: 6px;
}
.portal-help-menu-item__icon {
  color: #5e6785;
}
.alerts-popover {
  max-width: 357px;
}
.alerts-popover__empty {
  width: 330px;
  padding: 10px;
}
.alerts-popover__empty img {
  width: 150px;
  display: block;
  margin: auto;
}
.alerts-popover__empty--caption {
  text-align: center;
  padding-top: 7px;
}
.portal-nav-sidebar__container {
  padding: 8px 0;
}
.portal-nav-sidebar.MuiDrawer-modal {
  z-index: 1000;
}
.portal-nav-sidebar .MuiDrawer-paper {
  position: fixed;
  top: 64px;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 250px;
  height: auto;
  padding-bottom: 48px;
  background-color: #051a3c;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  transform: none !important;
}
@media (max-width: 1200px) {
  .portal-nav-sidebar .MuiDrawer-paper {
    top: 56px;
  }
}
.portal-nav-sidebar .MuiDivider-root {
  border-color: rgba(255, 255, 255, 0.12);
  margin: 8px 0;
}
.portal-nav-sidebar .h6 {
  color: rgba(255, 255, 255, 0.87);
}
.portal-nav-sidebar.minimized .MuiDrawer-paper,
.portal-nav-sidebar.exiting .MuiDrawer-paper {
  transform: none !important;
  width: 72px;
  visibility: visible !important;
  overflow-x: hidden;
}
.portal-nav-sidebar.minimized .portal-nav-sidebar__toggle,
.portal-nav-sidebar.exiting .portal-nav-sidebar__toggle {
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}
.portal-nav-sidebar.minimized .portal-nav__badge,
.portal-nav-sidebar.exiting .portal-nav__badge {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-90%);
}
.portal-nav-sidebar.minimized .portal-nav__notification,
.portal-nav-sidebar.exiting .portal-nav__notification {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 900px) {
  .portal-nav-sidebar.minimized .MuiDrawer-paper,
  .portal-nav-sidebar.exiting .MuiDrawer-paper,
  .portal-nav-sidebar.minimized .portal-nav-sidebar__toggle,
  .portal-nav-sidebar.exiting .portal-nav-sidebar__toggle {
    width: 0;
    border: 0;
  }
}
.portal-nav-sidebar.minimized:not(.exiting) .ws-list-item__primary-content,
.portal-nav-sidebar.minimized:not(.exiting) .portal-nav-sidebar__learn-more {
  display: none;
}
.portal-nav-sidebar.minimized:not(.exiting) .ws-list-item__secondary-content {
  margin-left: 0;
}
.portal-nav-sidebar.entering .MuiDrawer-paper {
  overflow: hidden;
}
.portal-nav-sidebar ::-webkit-scrollbar {
  display: none;
}
.portal-nav-sidebar--admin .MuiDrawer-paper {
  background-color: #e86232;
}
.portal-nav-section__links .MuiListItemText-primary,
.portal-nav-sidebar__toggle .MuiListItemText-primary {
  transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.portal-nav-section__links .MuiListItem-root,
.portal-nav-sidebar__toggle .MuiListItem-root {
  transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.portal-nav-section__links a:hover,
.portal-nav-sidebar__toggle a:hover {
  text-decoration: none;
}
.portal-nav-section__links a.disabled,
.portal-nav-sidebar__toggle a.disabled {
  cursor: default;
  pointer-events: none;
}
.portal-nav-section__links a.disabled .MuiSvgIcon-root,
.portal-nav-sidebar__toggle a.disabled .MuiSvgIcon-root,
.portal-nav-section__links a.disabled .wsicon,
.portal-nav-sidebar__toggle a.disabled .wsicon {
  fill: rgba(255, 255, 255, 0.32);
}
.portal-nav-section__links a.disabled .MuiListItemText-primary,
.portal-nav-sidebar__toggle a.disabled .MuiListItemText-primary {
  color: rgba(255, 255, 255, 0.32);
}
.portal-nav-section__links {
  max-height: 0;
  overflow: hidden;
  transition: max-height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.portal-nav-section--active .portal-nav-section__links {
  max-height: 450px;
}
.portal-nav-sidebar__toggle {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 56px;
  background-color: #051a3c;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.portal-nav-sidebar__toggle a {
  display: block;
}
@media (max-width: 900px) {
  .portal-nav-sidebar__toggle {
    display: none;
  }
}
.portal-nav-section__header {
  cursor: pointer;
}
.portal-nav-section__header .MuiSvgIcon-root {
  fill: rgba(255, 255, 255, 0.6);
}
.portal-nav-section__header:hover .MuiSvgIcon-root {
  fill: rgba(255, 255, 255, 0.87);
}
.portal-nav-sidebar__learn-more {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.MuiTooltip-popper[data-popper-placement*="right"] .portal-nav-sidebar__tooltip {
  margin-left: 12px;
  display: none;
}
.portal-nav__badge {
  min-width: 9px;
  padding: 2px 5px 3px;
  border-radius: 9px;
  text-align: center;
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  color: #162d54;
  background-color: #00cba5;
}
.portal-nav-admin-company {
  position: fixed;
  top: 0;
  left: 250px;
  height: 64px;
  font-size: 18px;
  line-height: 64px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: #3d5afe;
  min-width: 200px;
}
@media (max-width: 768px) {
  .portal-nav-admin-company {
    top: auto;
    left: 0;
    bottom: 0;
  }
}
.portal-pane-section {
  box-sizing: border-box;
  display: flex;
  min-height: 600px;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
}
@media (max-width: 768px) {
  .portal-pane-section {
    flex-direction: column;
    margin-top: 30px;
  }
}
.portal-pane-nav {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #ddd;
}
.portal-pane-nav aside {
  display: flex;
  flex-direction: column;
  padding: 30px 0 80px 30px;
}
.portal-pane-nav aside a {
  color: #9b9b9b;
  font-size: 13px;
  line-height: 24px;
}
@media (max-width: 768px) {
  .portal-pane-nav {
    display: none;
  }
}
@media (max-width: 768px) {
  .portal-pane-nav--open {
    display: block;
    position: fixed;
    top: 135px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1;
    background-color: #fff;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 100vw;
  }
}
.portal-pane-nav__links {
  display: flex;
  flex-direction: column;
}
.portal-pane-nav__links hr {
  box-sizing: border-box;
  height: 1px;
  margin: 10px 10px;
  border: 0;
  border-top: 1px solid #ddd;
}
.portal-pane-nav__links a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0 10px 10px;
  margin: 5px 0;
  border-right: 5px solid transparent;
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-transform: uppercase;
}
.portal-pane-nav__links a span {
  display: flex;
}
.portal-pane-nav__links a svg {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  fill: #9b9b9b;
}
.portal-pane-nav__links a svg:hover {
  fill: #000;
}
@media (max-width: 768px) {
  .portal-pane-nav__links a svg {
    width: 18px;
    height: 18px;
  }
}
.portal-pane-nav__links a:hover,
.portal-pane-nav__links a.active {
  color: #000;
  text-decoration: none;
}
.portal-pane-nav__links a:hover .wsicon-search,
.portal-pane-nav__links a.active .wsicon-search {
  fill: #000;
}
.portal-pane-nav__links a.active {
  position: relative;
  top: -1px;
  font-weight: 500;
  border-color: #00cba5;
}
.portal-pane-nav__links a.active:hover {
  border-color: #60ffd6;
}
.portal-pane-nav__links a.active svg {
  position: relative;
  top: 1px;
}
.portal-pane-nav__links-subsection {
  margin-top: -10px;
  padding-left: 20px;
}
.portal-pane-nav__links-subsection a {
  margin: 5px 0;
}
.portal-pane {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.portal-pane header,
.portal-pane article > div {
  display: flex;
  border-bottom: 1px solid #ddd;
}
.portal-pane header {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  font-weight: 100;
  padding: 40px 10px 10px;
}
.portal-pane article > div {
  padding: 10px;
  font-size: 13px;
  line-height: 16px;
}
.portal-pane article > div:hover {
  background-color: #f9f9f9;
  transition: background-color 100ms ease-in;
}
.portal-pane article > div.portal-pane-empty {
  border: none;
}
.portal-pane article > div.portal-pane-empty:hover {
  background-color: transparent;
}
@media (max-width: 768px) {
  .portal-pane header {
    display: none;
  }
  .portal-pane article > div {
    flex-wrap: wrap;
  }
}
.portal-table {
  border-collapse: collapse;
}
.portal-table th {
  padding: 10px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  background-color: #f4f5f9;
}
.portal-table td {
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.portal-table td:first-child,
.portal-table th:first-child {
  padding-left: 32px;
}
@media (max-width: 900px) {
  .portal-table td:first-child,
  .portal-table th:first-child {
    padding-left: 24px;
  }
}
@media (max-width: 768px) {
  .portal-table td:first-child,
  .portal-table th:first-child {
    padding-left: 16px;
  }
}
.portal-table tr:last-child td {
  border-bottom: none;
}
.dropdown .dropdown-menu.portal-dropdown-menu {
  display: none;
}
.dropdown .dropdown-menu.portal-dropdown-menu li {
  cursor: pointer;
  box-sizing: border-box;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 21px;
}
.dropdown .dropdown-menu.portal-dropdown-menu li em {
  color: #9b9b9b;
  font-size: 13px;
  font-style: normal;
}
.dropdown .dropdown-menu.portal-dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.dropdown .dropdown-menu.portal-dropdown-menu li:hover {
  background-color: #ddd;
}
.dropdown.active .dropdown-menu.portal-dropdown-menu {
  display: block;
}
.portal-modal {
  position: relative;
  min-width: 400px;
  max-width: 600px;
  box-sizing: border-box;
  background-color: #fff;
  line-height: 21px;
}
.portal-modal h2 {
  margin-top: 0;
  color: #162d54;
  font-weight: 500;
}
.portal-modal p {
  font-size: 16px;
  line-height: 21px;
}
.portal-modal strong {
  font-weight: 700;
}
.portal-modal ul {
  list-style: disc;
  padding-left: 20px;
}
.portal-modal ul li:not(:last-child) {
  margin-bottom: 20px;
}
.portal-modal .wsicon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.portal-modal.portal-modal-small {
  min-width: 300px;
  max-width: 500px;
}
.portal-modal.portal-modal-center {
  text-align: center;
}
.portal-modal .portal-modal-body {
  padding: 40px;
}
.portal-modal .portal-modal-footer__gold {
  background-color: #fef9ed;
  padding: 40px;
}
.portal-modal .portal-modal-footer__gold a {
  font-weight: 500;
}
.portal-modal .portal-modal-footer__avg_time {
  text-align: center;
}
.portal-modal .portal-modal-footer__avg_time strong {
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #edaf1f;
}
@media (max-width: 768px) {
  .portal-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 0 !important;
    max-width: none !important;
  }
  .portal-modal p,
  .portal-modal h2 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .portal-modal .portal-modal-body {
    padding: 30px 10px;
  }
}
.portal__fast-track-icon {
  /* keep image as large as possible without increasing line heights */
  height: 27px;
  margin: -4px 0;
}
.portal__add-link {
  display: block;
  margin: 20px 0 0 50px;
}
@media (max-width: 768px) {
  .portal__add-link {
    margin-left: 10px;
  }
}
.portal-delete-modal {
  text-align: center;
}
.portal-delete-modal h2 {
  margin-bottom: 10px;
}
.portal-delete-modal p {
  margin: 0 0 20px;
}
.portal-delete-modal__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.portal-delete-modal__footer > :first-child {
  margin-bottom: 20px;
}
.portal__no-show-warning {
  fill: #edaf1f;
  height: 18px;
  width: 18px;
  margin-left: -21px;
  margin-right: 5px;
}
@media (max-width: 768px) {
  .portal__no-show-warning {
    margin-left: 0;
  }
}
.portal-phone-screen-info {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.portal-phone-screen-info .wsicon-help-circle {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  fill: #bbb;
}
.portal-date-filter {
  display: flex;
  align-items: center;
}
.portal-date-filter > span {
  border-bottom: 1px dotted #9b9b9b;
  font-size: 13px;
  line-height: 18px;
  color: #9b9b9b;
  cursor: pointer;
}
.portal-date-filter > svg {
  margin-left: 5px;
  cursor: pointer;
  transition: all 100ms linear;
}
.portal-date-filter .wsicon-chevron-down {
  width: 12px;
  height: 12px;
  fill: #9b9b9b;
  opacity: 0.8;
}
.portal-date-filter .wsicon-close {
  width: 10px;
  height: 10px;
  fill: #e86232;
  opacity: 0.6;
}
.portal-date-filter:hover > span {
  border-bottom-style: solid;
}
.portal-date-filter:hover > svg {
  opacity: 1;
}
.portal-date-filter__dash {
  padding: 0 5px;
}
.portal__export-link {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
}
.portal__export-link svg {
  width: 14px;
  height: 14px;
  margin-right: 3px;
  fill: #00cba5;
}
.portal__export-link:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}
.portal-header-report_a_hire-btn {
  border: none;
  border-radius: 50px;
  width: 12rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3d5aff;
  color: white;
  font-size: 14px;
}
.portal-header-report_a_hire-btn:hover {
  cursor: pointer;
}
.admin-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 3px;
  font-size: 14px;
  background: #ffe388;
  color: #edaf1f;
  z-index: 1600;
  text-align: center;
}
.admin-warning strong {
  font-weight: 700;
}
.admin-warning form {
  display: inline-block;
}
.admin-warning__close {
  position: absolute;
  right: 10px;
  font-size: 24px;
  top: -3px;
  padding: 3px 10px;
  cursor: pointer;
}
.admin-warning__stop {
  background: #ffe9a0;
  color: #deaa0c;
  border: 1px solid #deaa0c;
  border-radius: 3px;
  padding: 3px 10px;
  cursor: pointer;
  margin-left: 10px;
  text-transform: uppercase;
  top: -2px;
  position: relative;
  font-size: 13px;
  line-height: 13px;
}
.admin-warning__stop:hover {
  background: #ffecaf;
  color: #deaa0c;
  text-decoration: none;
}
div[role="tooltip"] {
  z-index: 1600;
}
.portal-developer-api-keys .table-btn button {
  font-size: 12px;
  color: #555;
  padding: 2px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-decoration: none;
  background-color: transparent;
}
.portal-developer-api-keys .table-btn button:hover {
  cursor: pointer;
}
.portal-card-box-shadow {
  box-shadow: 0px 1px 24px 8px rgba(94, 103, 133, 0.08);
  transition: box-shadow 200ms;
}
.portal-card-box-shadow:hover {
  box-shadow: 0px 8px 48px 8px rgba(94, 103, 133, 0.24);
}
.portal-card-box-shadow--no-hover {
  box-shadow: 0px 1px 24px 8px rgba(94, 103, 133, 0.08);
  transition: box-shadow 200ms;
}
.report-a-hire-modal .MuiDialog-paper {
  max-width: 880px;
}
.portal__spaced-row {
  margin-top: 8px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .portal-schedule .content {
    margin: 0;
    max-width: none;
    width: 100%;
  }
}
.suggested-actions-transition {
  position: relative;
  transition: all 2s cubic-bezier(0.1, 0.9, 0.9, 1);
}
.suggested-actions-transition.suggested-actions-transition__hidden {
  transform: translate(0, 200px);
  opacity: 0;
}
.suggested-actions-action__card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  transition: all 2s cubic-bezier(0.1, 0.9, 0.9, 1);
}
.suggested-actions-action__card.suggested-actions-transition__hidden {
  transform: translate(0, 200px);
  opacity: 0;
}
.suggested-actions-dialog .MuiPaper-root[role="dialog"] {
  transition: max-width 0.2s ease-in-out;
}
.suggested-actions-pane {
  min-height: 500px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.suggested-actions-pane.suggested-actions-pane__hidden {
  opacity: 0;
}
.questions--onboard {
  margin-top: 50px;
}
.questions--onboard .progress-bar {
  position: fixed;
  top: 54px;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 768px) {
  .questions--onboard {
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 80px;
    padding: 0 10px;
  }
  .questions--onboard .progress-bar {
    top: 0;
  }
}
@media (max-width: 768px) {
  .onboard.suggestions__root {
    margin-top: -54px;
  }
}
.onboard .suggestions__intro {
  max-width: 800px;
  margin: auto;
  padding: 18px;
  background-color: #eff1f6;
}
.onboard .suggestions__intro h1 {
  margin: 0;
  color: #162d54;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
}
.onboard .suggestions__intro p {
  color: #162d54;
  line-height: 21px;
  font-weight: 200;
}
@media (max-width: 768px) {
  .onboard .suggestions__intro {
    background-color: #3d5afe;
  }
  .onboard .suggestions__intro h1,
  .onboard .suggestions__intro p {
    color: #fff;
  }
  .onboard .suggestions__intro h1 {
    font-size: 18px;
    line-height: 21px;
  }
  .onboard .suggestions__intro p {
    font-size: 14px;
    line-height: 18px;
  }
}
.onboard .suggestions__intro-skip a {
  color: #162d54;
}
@media (max-width: 768px) {
  .onboard .suggestions__intro-skip a {
    color: #fff;
  }
}
.onboard .onboard-empty {
  text-align: center;
  margin: 80px 0;
}
.onboard .onboard-empty p {
  max-width: 400px;
  margin: 24px auto;
}
.onboard .onboard-empty .btn-large {
  margin-top: 24px;
}
.onboard #welcome {
  background: #fff;
}
.onboard #welcome > h3 {
  text-align: center;
  max-width: 600px;
  padding: 0 12px;
  margin: 18px auto;
  font-weight: 300;
}
.onboard .notice {
  padding: 12px;
  background: #eff1f6;
  text-align: center;
}
.onboard .loading-step {
  text-align: center;
}
.onboard #preferences-container footer {
  padding: 0;
  padding-bottom: 20px;
}
.onboard #preferences-container #candidate-preferences {
  background: #fff;
  padding: 16px;
  padding-top: 0;
}
.onboard #preferences-container .prefs-group {
  padding: 0 20px;
}
.onboard #preferences-container .prefs-wage {
  margin-top: 0;
}
.onboard #preferences-container .btn-large {
  border-radius: 50px;
}
.onboard #preferences-container .prefs-commute-address {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 40px;
}
.onboard #preferences-container .prefs-commute-address h3 {
  flex-basis: auto;
}
@media (max-width: 768px) {
  .onboard .suggestions__empty {
    margin-top: 30px;
  }
}
.page-event {
  background-color: #eff1f6;
}
.event {
  margin-top: 15px;
}
.event__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.event__header-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #c2cad7;
  background-size: cover;
}
.event__header-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  flex: 0 0 40%;
  max-width: 40%;
  height: 310px;
  padding: 60px;
  background-color: rgba(22, 45, 84, 0.9);
}
.event__header-details h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}
.event__header-details div {
  font-size: 13px;
  line-height: 24px;
}
.event__header-details div,
.event__header-details div a {
  color: #fff;
}
.event__header-details div a {
  text-decoration: none;
  border-bottom: 1px dotted #fff;
}
.event__content {
  display: flex;
  box-sizing: border-box;
  padding: 50px 0;
  background-color: #fff;
}
.event__content-column:first-child {
  flex: 0 0 60%;
  max-width: 60%;
  box-sizing: border-box;
  padding-left: 80px;
}
.event__content-column:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 40%;
  max-width: 40%;
}
.event__description {
  white-space: pre-wrap;
}
.event__details {
  width: 240px;
}
.event__interested-btn {
  width: 100%;
}
.event__detail-section {
  font-size: 15px;
  line-height: 21px;
  margin-top: 30px;
}
.event__detail-section:first-child {
  margin-top: 0;
}
.event__detail-section strong {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
.event__detail-section svg {
  width: 15px;
  height: 15px;
  fill: #c2cad7;
  margin-right: 7px;
}
.event__detail-content {
  padding-left: 22px;
  color: #666;
}
.event__map-links {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.event__map-links a {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}
.event__footer {
  background-color: #fff;
  padding: 0 70px;
}
.event__footer-company {
  border-top: 1px solid #ddd;
  padding: 50px 0;
}
.event__footer-img {
  margin: 0 auto 26px;
}
.event__footer-desc {
  max-width: 550px;
  margin: 0 auto;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 24px;
}
.event__footer-map {
  position: relative;
  margin: 0 -70px;
  line-height: 0;
}
.event__footer-map svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  fill: #00cba5;
}
.event__registered {
  background-color: #f4fcfa;
  padding: 20px;
  text-align: center;
}
.event__registered svg {
  width: 50px;
  height: 50px;
  fill: #00cba5;
}
.event__registered h1 {
  font-size: 30px;
  line-height: 36px;
  margin-top: 0;
}
.event__registered strong {
  display: block;
}
.event__registered p {
  width: 70%;
  margin: 20px auto;
}
.event-register {
  max-width: 500px;
}
.event-register .signup-form-step {
  padding-top: 0;
}
.event-register__header {
  position: relative;
  text-align: center;
}
.event-register__header a {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.event-register__header svg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.event-register__user {
  text-align: center;
}
.event-register__details {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.event-register__details-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}
.event-register__details-date span:first-child {
  font-size: 15px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.event-register__details-date span:last-child {
  font-size: 36px;
}
.event-register__details-meta {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 21px;
}
.event-register__details-company {
  color: #666;
}
.event-register__positions p {
  margin: 0;
}
.event-register__positions .checkbox-list {
  margin-top: 0;
  margin-bottom: 0;
}
.event-register__positions .checkbox-list input[type="checkbox"] + label {
  border-bottom: 0;
  font-size: 15px;
  line-height: 18px;
  padding-left: 50px;
}
.event-register__positions .help-block {
  padding-left: 47px;
}
.event-register__position-details {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #9b9b9b;
}
.event-register__position-details .shift-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 7px;
}
.event-register__button {
  margin-top: 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .page-event {
    background-color: #fff;
    padding-bottom: 80px;
  }
  .page-event-register {
    padding-bottom: 40px;
  }
  .event {
    margin-top: 0;
  }
  .event__header {
    flex-direction: column;
    justify-content: flex-start;
  }
  .event__header-img {
    position: static;
    width: 100%;
    z-index: 1;
    height: 210px;
  }
  .event__header-details {
    justify-content: flex-start;
    width: 100%;
    max-width: none;
    height: auto;
    padding: 10px;
    background-color: #fff;
  }
  .event__header-details h1 {
    color: #000;
    line-height: 30px;
    font-weight: 300;
  }
  .event__header-details div,
  .event__header-details div a {
    color: #666;
  }
  .event__header-details div a {
    border-bottom: 1px dashed #666;
  }
  .event__content {
    flex-direction: column;
    padding: 10px;
  }
  .event__content-column {
    align-items: flex-start;
  }
  .event__content-column:first-child {
    order: 2;
    flex-basis: 100%;
    width: 100%;
    max-width: none;
    padding-left: 0;
  }
  .event__content-column:last-child {
    order: 1;
    flex-basis: 100%;
    align-items: flex-start;
    width: 100%;
    max-width: none;
    padding-bottom: 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  .event__interested-btn {
    position: fixed;
    right: 10px;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    width: auto;
    border-radius: 24px;
  }
  .event__details {
    width: auto;
  }
  .event__detail-section {
    margin-top: 20px;
  }
  .event__detail-section svg {
    width: 18px;
    height: 18px;
    margin-right: 22px;
  }
  .event__detail-content {
    padding-left: 40px;
    font-size: 14px;
  }
  .event__description {
    padding: 20px 0;
    font-size: 14px;
  }
  .event__footer {
    padding: 10px 10px 0;
  }
  .event__footer-company {
    padding: 20px 0;
  }
  .event__footer-map {
    margin: 0 -10px;
  }
  .event__footer-map img {
    width: 100%;
  }
  .event__registered {
    padding-top: 50px;
  }
  .event__registered p {
    width: 90%;
    font-size: 13px;
    line-height: 18px;
  }
  .event__registered a {
    padding-left: 40px;
    padding-right: 40px;
  }
  .event__footer-img {
    width: 80%;
  }
  .event-register .signup-form-step {
    padding: 0 10px;
  }
  .event-register__details {
    margin: 0 10px 40px;
  }
  .event-register__details-meta {
    font-size: 13px;
    line-height: 16px;
  }
  .event-register__positions {
    padding: 0 10px;
  }
  .event-register__positions p {
    font-size: 14px;
    text-align: left;
  }
  .event-register__positions .help-block {
    padding-left: 0;
  }
}
.page-program,
.page-program-register-success {
  background-color: #eff1f6;
}
.program {
  margin-top: 15px;
}
.program ul {
  margin: 0;
  padding-left: 24px;
}
.program__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.program__header-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #c2cad7;
  background-size: cover;
}
.program__header-back {
  position: absolute;
  top: 20px;
  left: 20px;
}
.program__header-back svg {
  height: 24px;
  width: 24px;
  fill: #fff;
}
.program__header-details {
  box-sizing: border-box;
  flex: 0 0 40%;
  max-width: 40%;
  height: 310px;
  padding: 60px;
  background-color: rgba(22, 45, 84, 0.9);
}
.program__header-details h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin: 16px 0;
}
.program__header-details span {
  display: inline-block;
  max-width: 195px;
  color: #fff;
  float: right;
}
.program__header-details span a {
  color: #fff;
}
.program__header-details img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}
.program__header-share {
  position: absolute;
  top: 25px;
  right: 30px;
  font-size: 14px;
}
.program__header-share a,
.program__header-share a:hover,
.program__header-share a:visited {
  color: #fff;
  text-transform: uppercase;
}
.program__content {
  display: flex;
  box-sizing: border-box;
  padding: 50px 35px;
  background-color: #fff;
}
.program__content-column:first-child {
  flex: 0 0 35%;
  max-width: 35%;
  box-sizing: border-box;
  padding-right: 40px;
}
.program__content-column:last-child {
  display: flex;
  flex: 0 0 65%;
  max-width: 65%;
  flex-direction: column;
  align-items: center;
}
.program__description,
.program__highlights-section {
  white-space: pre-wrap;
}
.program__interested-btn {
  width: 100%;
}
.program__highlights-section {
  font-size: 15px;
  line-height: 21px;
  margin-top: 30px;
}
.program__highlights-section:first-child {
  margin-top: 0;
}
.program__highlights-section strong {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
.program__highlights-section svg {
  width: 15px;
  height: 15px;
  fill: #c2cad7;
  margin-right: 7px;
}
.program__detail-content {
  padding-left: 22px;
  color: #666;
}
.program__map-links {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.program__map-links a {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}
.program__organization {
  margin-top: 40px;
}
.program__organization span {
  font-size: 22px;
  font-weight: 500;
}
.program__organization-desc {
  margin-top: 10px;
}
.program__registered {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
}
.program__registered-btn {
  border: 2px solid #00cba5;
  font-weight: 500;
  padding: 8px 20px;
  text-align: center;
  border-radius: 20px;
  color: #00cba5;
  text-transform: uppercase;
  max-width: 200px;
  margin: auto;
}
.program__registered-btn svg {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  vertical-align: middle;
  fill: #00cba5;
}
.program__registered-title {
  font-size: 18px;
  font-weight: 500;
  color: #162d54;
  margin: 10px 0;
}
.program__registered-contact {
  font-size: 18px;
  font-weight: 500;
  color: #4a4a4a;
  overflow-wrap: break-word;
}
.program-register {
  max-width: 500px;
}
.program-register .signup-form-step {
  padding-top: 0;
}
.program-register__header {
  position: relative;
  text-align: center;
  padding: 0 25px;
}
.program-register__header a {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.program-register__header svg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.program-register__user {
  text-align: center;
}
.program-register__details {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.program-register__details img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 14px;
  vertical-align: middle;
  border: 1px solid #ddd;
}
.program-register__details-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}
.program-register__details-date span:first-child {
  font-size: 15px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.program-register__details-date span:last-child {
  font-size: 36px;
}
.program-register__details-meta {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 21px;
}
.program-register__details-meta--highlight {
  color: #3d5afe;
  font-weight: 400;
  text-align: center;
}
.program-register__details-company {
  color: #666;
}
.program-register__positions p {
  margin: 0;
}
.program-register__positions .checkbox-list {
  margin-top: 0;
  margin-bottom: 0;
}
.program-register__positions .checkbox-list input[type="checkbox"] + label {
  border-bottom: 0;
  font-size: 15px;
  line-height: 18px;
  padding-left: 50px;
}
.program-register__positions .help-block {
  padding-left: 47px;
}
.program-register__position-details {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #9b9b9b;
}
.program-register__position-details .shift-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 7px;
}
.program-register__button {
  margin-top: 20px;
  text-align: center;
}
.program-register-success {
  padding-top: 40px;
  text-align: center;
}
#program-sign-up {
  margin-top: 40px;
}
.program-success__header p {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
}
.program-success__header img {
  border: 1px solid #ddd;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .program-success__header {
    padding: 0 10px;
  }
}
.program-success__details {
  max-width: 480px;
  background-color: #fff;
  padding: 24px;
  margin: auto;
  margin-top: 40px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
.program-success__jobs {
  max-width: 320px;
  background: none;
  padding: 24px;
  margin: auto;
  margin-top: 40px;
  text-align: center;
  border: 1px solid #c2cad7;
}
.program-success__jobs div {
  font-size: 14px;
  margin-bottom: 24px;
}
.program-success__jobs hr {
  border-top: 1px solid #c2cad7;
  margin: 30px 0;
}
@media (max-width: 768px) {
  .page-event {
    background-color: #fff;
    padding-bottom: 80px;
  }
  .page-program-register {
    padding-bottom: 40px;
  }
  .program {
    margin-top: 0;
  }
  .program__header {
    flex-direction: column;
    justify-content: flex-start;
  }
  .program__header-img {
    position: absolute;
    flex: 0 0 100%;
    width: 100%;
    height: 210px;
  }
  .program__header-details {
    justify-content: flex-start;
    flex: 0 0 100%;
    width: 100%;
    max-width: none;
    height: 210px;
    padding: 10px;
    padding-top: 50px;
    text-align: center;
    background-color: rgba(22, 45, 84, 0.6);
  }
  .program__header-details h1 {
    color: #fff;
    line-height: 30px;
    font-weight: 300;
  }
  .program__header-details span {
    float: none;
  }
  .program__header-details img {
    position: absolute;
    right: 10px;
    bottom: -24px;
    border: 1px solid #ddd;
  }
  .program__content {
    flex-direction: column;
    padding: 10px;
  }
  .program-success__details {
    max-width: 320px;
  }
  .program__content-column {
    align-items: flex-start;
  }
  .program__content-column:first-child {
    order: 1;
    flex-basis: 100%;
    width: 100%;
    max-width: none;
    padding: 0;
  }
  .program__content-column:last-child {
    order: 2;
    flex-basis: 100%;
    align-items: flex-start;
    width: 100%;
    max-width: none;
    padding-bottom: 70px;
    border-top: 1px solid #ddd;
  }
  .program__interested-btn {
    position: fixed;
    right: 10px;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    width: auto;
    border-radius: 24px;
  }
  .program__highlights-section {
    margin-top: 12px;
    margin-bottom: 30px;
  }
  .program__highlights-section svg {
    width: 18px;
    height: 18px;
    margin-right: 22px;
  }
  .program__detail-content {
    padding-left: 40px;
    font-size: 14px;
  }
  .program__description {
    padding: 20px 0;
    font-size: 14px;
  }
  .program-register .signup-form-step {
    padding: 0 10px;
  }
  .program-register__details {
    margin: 0 10px 40px;
  }
  .program-register__details-meta {
    font-size: 13px;
    line-height: 16px;
    padding: 6px 0;
  }
  .program-register__positions {
    padding: 0 10px;
  }
  .program-register__positions p {
    font-size: 14px;
    text-align: left;
  }
  .program-register__positions .help-block {
    padding-left: 0;
  }
}
.programs-container__inner {
  max-width: 620px;
  box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.12);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .programs-container__inner {
    border-left: 0;
    border-right: 0;
  }
}
.programs-container__header,
.programs-container__list {
  background: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}
.programs-container__list {
  min-height: 300px;
  padding-bottom: 30px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
@media (max-width: 768px) {
  .programs-container__list {
    border-left: 0;
    border-right: 0;
  }
}
.programs-container__header {
  padding: 28px 24px;
}
.programs-container__header h1 {
  font-size: 20px;
  line-height: 24px;
  color: #162d54;
  margin: 8px 0;
  font-weight: 700;
}
@media (max-width: 640px) {
  .programs-container__header {
    position: relative;
    border: 0;
    background-position: 130px;
    background-repeat: no-repeat;
    padding: 12px;
  }
  .programs-container__header h1 {
    font-size: 18px;
    line-height: 24px;
    margin: 10px 0;
  }
}
#map {
  position: fixed;
  width: 285px;
  height: 248px;
  left: 50%;
  top: 160px;
  margin-left: 210px;
  background: #162d54;
}
@media (max-width: 768px) {
  #map {
    display: none;
  }
}
.programs-container {
  max-width: 940px;
  margin: auto;
  position: relative;
  margin-bottom: 30px;
  color: #4a4a4a;
}
.programs-container__path {
  font-size: 11px;
}
.programs-container__path a {
  color: #4a4a4a;
}
.programs-container__path a:hover {
  text-decoration: underline;
}
@media (max-width: 640px) {
  .programs-container__path {
    margin-top: 50px;
  }
}
.programs-container__desc {
  font-size: 13px;
  line-height: 20px;
}
.program-row {
  position: relative;
  cursor: pointer;
  padding: 12px 24px 24px;
  display: flex;
}
.program-row:hover {
  background: #f5f5f5;
}
.program-row .program-row__img {
  width: 40%;
  margin-right: 20px;
}
.program-row .program-position-row__img {
  background-position: center;
  border: 1px solid #ddd;
}
.program-row .program-row__desc {
  width: 60%;
  margin-top: 10px;
}
.program-row .program-row__desc .program-row__desc-title {
  margin: 0;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  max-width: 80%;
}
@media (max-width: 768px) {
  .program-row .program-row__desc .program-row__desc-title {
    font-size: 14px;
  }
}
.program-row .program-row__desc .program-row__desc-organization,
.program-row .program-row__desc .program-row__desc-wage {
  max-width: 80%;
  color: #4a4a4a;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
}
.program-row .program-row__desc .program-row__desc-entry-label {
  display: inline-block;
  height: 24px;
  margin: 8px 0;
  padding: 0 12px;
  background: #eff2ff;
  border: 1px solid #eff2ff;
  border-radius: 14px;
  color: #3d5afe;
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .program-row {
    flex-direction: column;
    padding: 0 12px 18px 12px;
  }
  .program-row .program-row__img {
    background-position: center;
    width: 100%;
    margin-right: 0;
  }
  .program-row .program-row__desc {
    width: 100%;
  }
}
.program-list {
  max-width: 1200px;
  text-align: left;
}
.program-list .back {
  height: 0;
}
.program-list .back .back__inner {
  position: fixed;
  top: 80px;
  height: 24px;
  width: 24px;
  margin-left: 80px;
}
.program-list .back svg {
  opacity: 0.5;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.program-list .back:hover svg {
  opacity: 1;
}
@media (max-width: 768px) {
  .program-list .back .back__inner {
    position: relative;
    top: 20px;
    height: 24px;
    width: 24px;
    z-index: 1;
    margin-left: 10px;
  }
  .program-list .back .organization__back svg {
    fill: #fff;
  }
}
.program-list a:hover {
  text-decoration: none;
}
.program-group-header {
  padding-left: 24px;
}
.program-group-header h2 {
  text-transform: uppercase;
  font-size: 18px;
}
@media (max-width: 768px) {
  .program-group-header {
    padding-left: 12px;
  }
  .program-group-header h2 {
    font-size: 14px;
  }
}
.organization-header {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.organization-header h2 {
  text-align: center;
  font-size: 18px;
}
.organization-header img {
  display: block;
  max-width: 400px;
  max-height: 110px;
  width: auto;
  height: auto;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.organization-header p {
  max-height: 76px;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  text-align: center;
  overflow: hidden;
}
.organization-header .organization-header__description.expanded {
  max-height: none;
}
.organization-header .organization-header__expand {
  display: none;
  padding-top: 10px;
}
@media (max-width: 768px) {
  .organization-header {
    padding: 12px;
  }
  .organization-header img {
    z-index: 1;
  }
  .organization-header .organization-header__banner {
    position: absolute;
    margin-top: -30px;
    max-width: 620px;
    width: 100%;
    height: 80px;
    background-color: #162d54;
  }
  .organization-header p {
    text-align: left;
  }
}
.organization-browse-all {
  display: block;
  max-width: 620px;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  color: #9caac0;
  border: 1px solid #9caac0;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}
.organization-browse-all:hover {
  color: #162d54;
  border-color: #162d54;
  text-decoration: none;
}
@media (max-width: 768px) {
  .organization-browse-all {
    margin: 50px 10px 0;
  }
}
.organization-share {
  position: fixed;
  left: 50%;
  top: 100px;
  margin-left: 210px;
  z-index: 1;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .organization-share {
    position: absolute;
    left: 80%;
    margin-left: 0;
  }
}
.program-categories__cloud {
  margin-top: 40px;
}
.program-categories__cloud p {
  margin-bottom: 5px;
}
.program-categories__cloud .program-categories__cities {
  display: block;
  margin-top: 10px;
  color: #666;
}
.program-categories__cloud .program-categories__cities:hover {
  text-decoration: underline;
}
.program-categories__cloud .program-categories__links {
  max-width: 620px;
}
.program-categories__cloud .program-categories__links a {
  display: inline-block;
  padding: 10px 15px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 4px;
  font-weight: 300;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .program-categories__cloud {
    margin: 40px 10px 0;
  }
}
.career-box {
  display: flex;
  box-sizing: border-box;
  border: 1px solid #c2cad7;
  border-radius: 3px;
  color: #000;
  background-color: #fff;
  text-decoration: none;
}
.career-box:hover {
  color: #000;
}
.career-box--desktop {
  position: fixed;
  width: 290px;
  padding: 25px 15px 20px;
  margin-left: 680px;
  margin-top: 50px;
  border-color: #00cba5;
  box-shadow: 0 2px 14px 0 rgba(22, 45, 84, 0.18);
}
@media (max-width: 768px) {
  .career-box--desktop {
    display: none;
  }
}
.career-box--mobile {
  align-items: center;
  padding: 10px;
  margin: 20px 0;
  box-shadow: 0 2px 4px 0 rgba(22, 45, 84, 0.18);
}
@media (min-width: 768px) {
  .career-box--mobile {
    display: none;
  }
}
.career-box__icon {
  flex: 0 0 63px;
  width: 63px;
  height: 63px;
  margin-right: 10px;
}
.career-box__content header {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}
.career-box__content p {
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}
.career-box__content span {
  display: inline-block;
  margin-top: 20px;
  color: #00cba5;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .career-box__content {
    padding-right: 20px;
  }
  .career-box__content p {
    font-size: 11px;
    line-height: 14px;
  }
}
.career-box__chevron {
  display: none;
}
@media (max-width: 768px) {
  .career-box__chevron {
    display: block;
    width: 20px;
    height: 20px;
    fill: #bbb;
  }
}
.body__agreement header {
  margin-top: 50px;
}
@media print {
  .body__agreement #nav {
    display: none;
  }
  .body__agreement .footer {
    display: none;
  }
}
.form__agreement {
  width: 100%;
  padding: 10px 0;
  background-color: #eff1f6;
}
.form__agreement .banner__agreement {
  display: none;
  padding: 20px;
  text-align: center;
}
.form__agreement .form__agreement-inner {
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto;
}
.form__agreement .form__agreement-inner .form__error {
  border-bottom: 1px solid #e86232;
}
.form__agreement .form__agreement-inner input {
  margin: 0 20px;
}
.form__agreement .form__agreement-inner input[type="date"] {
  max-width: 175px;
}
.form__agreement .form__agreement-inner input[type="text"] {
  max-width: 300px;
}
.form__agreement .form__agreement-inner a {
  margin-top: 8px;
}
@media (max-width: 768px) {
  .form__agreement .form__agreement-inner {
    flex-direction: column;
    padding: 20px;
  }
  .form__agreement .form__agreement-inner input {
    margin: 5px 0;
  }
  .form__agreement .form__agreement-inner input[type="date"] {
    max-width: 100%;
  }
  .form__agreement .form__agreement-inner input[type="text"] {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .form__agreement {
    position: fixed;
    z-index: 1;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }
}
@media print {
  .form__agreement {
    position: relative;
  }
}
.content__agreement {
  width: 95%;
  padding-top: 100px;
}
.content__agreement h2 {
  text-align: center;
}
.content__agreement h4 {
  text-align: center;
  margin-bottom: 10px;
}
.content__agreement a {
  word-wrap: break-word;
}
.content__agreement li {
  font-weight: 100;
  margin: 16px 0;
}
.content__agreement li > ol {
  list-style-type: lower-alpha;
}
.content__agreement li > ol > li > ol {
  list-style-type: lower-roman;
}
@media (max-width: 768px) {
  .content__agreement {
    padding-top: 0;
  }
}
.box__agreement-details {
  padding: 20px;
  border: 1px solid #4a4a4a;
  font-size: 16px;
}
@media (min-width: 768px) {
  .box__agreement-details .column__agreement-role {
    width: 300px;
  }
}
.box__agreement-details td {
  padding: 10px;
  vertical-align: top;
}
.div__agreement {
  margin-bottom: 20px;
}
.agreement-calc {
  margin-top: 10px;
}
.agreement-calc > section {
  flex: 0 0 45%;
}
.agreement-calc__list {
  list-style: none;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.agreement-calc__list li {
  display: flex;
  justify-content: space-between;
}
.agreement-calc__list li:not(:last-child) {
  margin-bottom: 25px;
}
.agreement-calc__pricing h2 {
  margin-top: 0;
  margin-bottom: 32px;
}
.agreement-calc__text {
  flex: 0 0 300px;
}
.agreement-calc__per {
  margin: 5px 0 0 0;
}
.agreement-calc__per :first-child {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #162d54;
  font-weight: 500;
  background-color: #fff;
}
.agreement-calc__per :last-child {
  font-size: 12px;
  color: #9b9b9b;
}
.agreement-calc__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
}
.agreement-calc__info h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  color: #162d54;
}
.agreement-calc__info p {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
}
.agreement-calc__info > div {
  display: flex;
  padding: 10px;
}
.agreement-calc__info > div > :first-child {
  flex: 0 0 60px;
}
.agreement-calc__info > div img {
  width: 40px;
  margin-top: 7px;
}
.agreement-calc__info-guarantee {
  background-color: #e0f9f4;
}
.agreement-calc__footer {
  margin-bottom: 80px;
}
.agreement-calc__footer h2 {
  margin: 28px 0;
}
.agreement-calc__footer .flex {
  margin-left: -34px;
  width: 100%;
}
.agreement-calc__footer img {
  width: 20px;
}
.agreement-calc__footer > :last-child {
  margin-top: 20px;
}
.agreement-calc__footer > :last-child > :first-child {
  margin-top: 20px;
}
.agreement-calc__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.agreement-calc__form-field {
  flex: 0 0 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agreement-calc__form-field label {
  margin-bottom: 0;
  color: #162d54;
  font-size: 13px;
}
.agreement-calc__form-field input {
  width: 70%;
  border: 1px solid #bbb;
  border-radius: 5px;
  padding: 1px 5px;
  background-color: #fff;
}
.agreement-calc__form-field input:active,
.agreement-calc__form-field input:focus {
  border: 1px solid #00cba5;
  margin-bottom: 0;
}
.agreement-calc__form-field input:disabled {
  color: #000;
  background-color: #eee;
}
.agreement-calc__form-field input#savings {
  color: #03d395;
  font-weight: 700;
}
.agreement-calc__form-group {
  box-sizing: border-box;
  flex: 0 0 720px;
  display: flex;
  border: 1px solid #bbb;
  border-radius: 5px;
  padding: 20px 0;
}
.agreement-calc__form-group label {
  font-weight: 600;
}
.training__header {
  min-height: 300px;
  padding: 50px;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
}
.training__header h1,
.training__header h2 {
  color: #fff;
  line-height: normal;
}
.training__header h2 {
  font-size: 16px;
  font-weight: 100;
}
.training__header div {
  font-weight: 100;
  color: #fff;
}
.training__header-inner {
  max-width: 680px;
  margin: auto;
  text-align: center;
}
.training__content {
  margin-top: -75px;
}
.training__category-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 940px;
  margin-bottom: 18px;
}
@media (max-width: 768px) {
  .training__category-list {
    flex-wrap: nowrap;
    box-sizing: border-box;
    height: 222px;
    width: 100%;
    padding-right: 12px;
    margin-bottom: 24px;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .training__category-list::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
.training__category-title {
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0;
  color: #000;
}
.training__category-title.featured {
  color: #fff;
  text-transform: uppercase;
}
.training__category-title.featured:hover {
  color: #fff;
}
@media (max-width: 768px) {
  .training__category-title.featured--logged-in {
    display: none;
  }
}
.training__category-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
}
.training__category-link span {
  margin-right: 70px;
  text-transform: none;
  color: #9b9b9b;
  font-size: 14px;
  vertical-align: top;
}
.training__category-link:hover {
  color: #000;
  text-decoration: none;
}
.training__category-link:hover span {
  text-decoration: underline;
}
.training__category-link.featured {
  color: #fff;
}
.training__category-link.featured svg {
  fill: #fff;
}
.training__category-link.featured:hover {
  color: #fff;
}
.training__category-link svg {
  vertical-align: middle;
  display: none;
  margin-top: 3px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  fill: #000;
}
@media (max-width: 768px) {
  .training__category-link span {
    display: none;
  }
  .training__category-link svg {
    display: block;
  }
}
.training__card {
  height: 218px;
  width: 280px;
  display: inline-block;
  color: #4a4a4a;
  margin-right: 15px;
  margin-bottom: 36px;
  background: #ededed;
  position: relative;
}
.training__card:hover {
  text-decoration: none;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
  .training__card {
    flex: 0 0 auto;
    margin-left: 12px;
    margin-right: 0;
  }
}
.training__card--career {
  background-color: #fff;
}
.training__card--career h3 {
  font-size: 21px;
  line-height: 27px;
  font-weight: 400;
  margin-top: 0;
}
.training__card--career p {
  font-size: 13px;
  line-height: 18px;
}
.training__card--career a {
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  text-transform: uppercase;
}
.training__card--career:hover {
  box-shadow: none;
}
@media (max-width: 768px) {
  .training__card--career {
    box-sizing: border-box;
    flex-basis: 220px;
    white-space: normal;
    padding-right: 20px;
  }
  .training__card--career h3 {
    font-size: 20px;
  }
}
.training__card-img,
.program-row__img {
  height: 132px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.training__card-img img,
.program-row__img img {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #ddd;
  right: 8px;
  bottom: 8px;
}
.training__program-details {
  position: absolute;
  bottom: 0;
  height: 88px;
  font-size: 13px;
  font-weight: 100;
  padding: 8px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  white-space: normal;
}
.training__program-details.featured {
  background-color: #3d5afe;
  color: #fff;
}
.training__program-title {
  font-size: 15px;
  font-weight: 500;
}
.training__program-desc {
  font-weight: 300;
  line-height: 18px;
}
.training__program-cost {
  text-transform: uppercase;
  color: #edaf1f;
  font-weight: 500;
}
.training__program-org {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 768px) {
  .training__header {
    padding: 18px;
    padding-bottom: 80px;
  }
  .training__header h1 {
    font-size: 28px;
    font-weight: 400;
  }
  .training__header h2 {
    font-size: 13px;
  }
  .training__category-title {
    margin-left: 12px;
  }
}
.training-background-sea {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/sea-training-header.jpg");
}
.training-background-pdx {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/pdx-training-header.jpg");
}
.training-background-lax {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/lax-training-header.jpg");
}
.training-background-sfo {
  background-image: url("https://assets.workstep.com/sha_4d449df9bd853ced037157cebd2bf0dfa499a71f/sfo-training-header.jpg");
}
@media (max-width: 768px) {
  .training__header--logged-in {
    padding: 12px;
    background: none;
  }
}
@media (max-width: 768px) {
  .training__header-inner--logged-in {
    display: none;
  }
}
.training__header-inner-mobile {
  display: none;
  margin-bottom: 85px;
}
.training__header-inner-mobile h1 {
  text-align: left;
  color: #162d54;
}
@media (max-width: 768px) {
  .training__header-inner-mobile {
    display: block;
  }
}
.matches-unlocked-modal {
  text-align: center;
}
.matches-unlocked-modal .ws-modal-footer {
  display: flex;
  flex-direction: column;
}
.matches-unlocked-modal .ws-modal-footer .btn {
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .matches-unlocked-modal {
    top: 25px;
    right: 10px;
    bottom: 25px;
    left: 10px;
  }
  .matches-unlocked-modal.ws-modal.ws-modal-tiny {
    padding: 30px 19px;
  }
  .matches-unlocked-modal .ws-modal-footer {
    position: static;
  }
}
.matches-unlocked-modal__header {
  margin: 15px 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}
.matches-unlocked-modal__subheader {
  margin-bottom: 60px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 200;
}
#matches-container {
  position: relative;
}
#matches-container .loading-step {
  text-align: center;
}
.matches__popup {
  position: absolute;
  z-index: 1;
  top: 235px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 260px;
  padding: 15px;
  border-radius: 6px;
  background-color: #3d5afe;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.matches__popup strong {
  font-size: 13px;
  line-height: 17px;
}
.matches__popup p {
  font-size: 12px;
  line-height: 17px;
}
.matches__popup a {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}
body.page-unsubscribe #nav .nav__right {
  display: none;
}
.unsubscribe__banner {
  position: fixed;
  top: 54px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-sizing: border-box;
  width: 940px;
  padding: 10px;
  background-color: rgba(224, 249, 244, 0.8);
  font-weight: 300;
  text-align: center;
}
@media (max-width: 768px) {
  .unsubscribe__banner {
    width: auto;
    left: 0;
    right: 0;
    transform: none;
  }
}
.unsubscribe__header,
.unsubscribe__footer {
  text-align: center;
}
.unsubscribe__footer {
  margin-top: 40px;
}
.unsubscribe__content {
  width: 100%;
  max-width: 940px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin: 0 auto;
  font-weight: 100;
  background: #fff;
}
.unsubscribe__section {
  padding: 40px 120px;
  border-bottom: 1px solid #ddd;
  font-size: 15px;
}
@media (max-width: 768px) {
  .unsubscribe__section {
    padding: 40px 20px;
  }
}
.unsubscribe__list.checkbox-list input[type="checkbox"] + label {
  display: flex;
  flex-direction: column;
}
.unsubscribe__list.checkbox-list input[type="checkbox"] + label:last-child {
  border-bottom: 0;
}
.unsubscribe__list strong {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
}
.unsubscribe__list span {
  font-size: 15px;
  line-height: 21px;
}
.page-content {
  max-width: 780px;
  padding: 20px;
  box-sizing: border-box;
}
.page-content .heading {
  background: #eff1f6;
  padding: 30px;
  text-align: center;
  font-weight: 300;
}
.page-content h1 {
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  max-width: 600px;
  margin: 40px auto;
}
.page-content h2 {
  margin-top: 50px;
}
.page-content img {
  width: 100% !important;
  height: auto !important;
}
.page-privacy-policy table {
  border: 1px solid #555;
  border-collapse: collapse;
}
.page-privacy-policy table tr:first-child td {
  text-align: center;
}
.page-privacy-policy table td {
  border: 1px solid #555;
  padding: 5px;
  vertical-align: top;
}
.page-privacy-policy table td:nth-child(2) {
  width: 220px;
}
.page-privacy-policy table td:nth-child(4) {
  width: 120px;
}
.page-privacy-policy table td p {
  line-height: 20px;
}
.page-privacy-policy table td p:first-child {
  margin-top: 0;
  margin-bottom: 0;
}
.page-privacy-policy table td p:nth-child(2) {
  margin-top: 5px;
}
.page-privacy-policy table td p:last-child {
  margin-bottom: 0;
}
.page-privacy-policy table td i {
  font-size: 14px;
  line-height: 18px;
}
.page-tos {
  line-height: 21px;
}
.page-tos p {
  line-height: 21px;
}
.page-tos ol {
  list-style-type: decimal;
}
.page-tos ol li {
  margin: 1em 0;
}
.page-tos ol ol {
  list-style-type: lower-alpha;
}
.page-tos ol ol ol {
  list-style-type: lower-roman;
}
@media print {
  .page-candidate-profile {
    visibility: hidden;
  }
  .page-candidate-profile #step-container * {
    visibility: visible;
  }
  .page-candidate-profile #step-container {
    position: absolute;
    left: 0;
    top: 0;
  }
  .page-candidate-profile #step-container .profile-header {
    margin-top: -16px;
    margin-bottom: 24px;
  }
  .page-candidate-profile #step-container .candidate-profile > section {
    padding: 6px;
    margin: 0;
  }
  .page-candidate-profile #step-container .candidate-profile > section.work-experience article.job-description .job-icon {
    display: none;
  }
  .page-candidate-profile #step-container .candidate-profile > section.work-experience article.job-description .job-content {
    padding: 6px;
  }
}
.profile #step-container {
  background-color: transparent;
}
.profile #step-container.list-container {
  background-color: #fff;
}
.profile #step-container.list-container .form-step h2 {
  padding-top: 80px;
}
.profile #step-container.list-container .form-step .history-view-header div {
  padding-top: 88px;
}
.profile #step-container.list-container .btn-back {
  top: 24px;
  left: 28px;
}
@media (max-width: 768px) {
  .profile #step-container.list-container {
    margin-top: 0;
  }
  .profile #step-container.list-container .btn-back {
    left: 0;
  }
}
.profile-checklist {
  padding: 10px 0;
}
@media (max-width: 768px) {
  .profile-checklist {
    width: 100%;
    margin-bottom: -60px;
    padding: 0 0 30px;
  }
}
.profile-checklist__summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 50%;
}
@media (min-width: 768px) {
  .profile-checklist__summary {
    padding-right: 40px;
  }
}
.profile-checklist__content {
  display: flex;
  max-width: 750px;
  margin: 0 auto;
  padding: 0 12px;
}
@media (max-width: 768px) {
  .profile-checklist__content {
    margin: 0 auto 30px;
    display: block;
    text-align: center;
  }
}
.profile-checklist__checklist {
  flex: 50%;
  padding: 0 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-checklist__checklist ul {
  list-style: none;
  padding: 0;
}
.profile-checklist__checklist ul li {
  position: relative;
  padding: 15px 0 15px 24px;
  border-top: 1px solid #ddd;
}
.profile-checklist__checklist ul li:first-child {
  border-top: none;
}
@media (max-width: 768px) {
  .profile-checklist__checklist ul li:first-child {
    border-top: 1px solid #ddd;
  }
}
.profile-checklist__checklist ul li svg {
  position: absolute;
  top: 21px;
  left: 0;
  width: 13px;
  height: 13px;
}
.profile-checklist__checklist ul li svg.wsicon-pass {
  fill: #edaf1f;
}
.profile-checklist__checklist ul li svg.wsicon-approve {
  fill: #00cba5;
}
.profile-checklist__im-done {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
  text-decoration: underline;
}
.profile-checklist--complete {
  background-color: #eff2ff;
  border-bottom: 1px solid #c4b4e6;
}
@media (max-width: 768px) {
  .profile-checklist--complete {
    border-bottom: none;
  }
}
.profile-checklist--complete .profile-checklist__title {
  margin: 1em 0;
}
.profile-checklist--complete p {
  margin-top: 0;
}
.profile-checklist--incomplete {
  background-color: #fef9ed;
}
.profile-checklist__title {
  font-weight: 300;
  margin: 0;
}
@media (max-width: 768px) {
  .profile-checklist__title {
    margin: 0 0 0.5em;
    padding: 1.4em 0 0;
  }
}
.profile-checklist__grade {
  font-weight: 400;
}
.profile-checklist__grade--gold {
  color: #edaf1f;
}
.profile-checklist__grade--purple {
  color: #3d5afe;
}
.profile-checklist__best-results {
  font-size: 13px;
  margin-bottom: 0;
  line-height: 1.3em;
}
@media (max-width: 768px) {
  .profile-checklist__best-results {
    text-align: center;
    padding: 0 12px;
  }
}
.profile-checklist__go-to-home {
  font-weight: 400;
  text-transform: uppercase;
}
.profile-content {
  max-width: 750px;
  margin: 24px auto 0;
}
.profile-header {
  position: relative;
  padding: 30px;
  margin-top: 30px;
  background-color: #fff;
  color: #333;
  border-bottom: 2px solid #c2cad7;
}
@media (max-width: 768px) {
  .profile-header {
    margin-top: 60px;
  }
}
.profile-header .edit-button {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 10px;
}
.profile-header .edit-button a {
  color: #9caac0;
}
.profile-header .edit-button svg {
  display: none;
}
.profile-header .profile-header-container {
  display: flex;
  position: relative;
  min-height: 68.5px;
}
@media (min-width: 768px) {
  .profile-header .profile-header-container:hover .edit-button {
    opacity: 1;
  }
}
.profile-header .profile-header-container .profile-header-icon {
  position: absolute;
  top: -50px;
  left: -5px;
  flex: 0 0 137px;
}
@media print {
  .profile-header .profile-header-container .profile-header-icon {
    display: none;
  }
}
.profile-header .profile-header-container .profile-header-content {
  flex: 1 1 auto;
  margin-left: 140px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.profile-header .profile-header-container .profile-header-content h1 {
  color: #162d54;
  font-size: 30px;
  line-height: 37px;
  font-weight: 300;
  margin: 0;
  display: inline-block;
}
.profile-header .profile-header-container .profile-header-content .profile-header-location {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #9caac0;
  font-size: 14px;
  line-height: 28px;
  font-weight: 300;
}
.profile-header .profile-header-container .profile-header-content .no-summary {
  color: #bbb;
}
.profile-header .profile-header-container .profile-header-content p {
  font-size: 16px;
  margin-bottom: 0;
}
.profile-header .profile-header-container .profile-header-content div {
  display: flex;
  align-items: center;
}
.profile-header .profile-header-container .profile-header-content .js-print-resume {
  width: 70px;
  margin-left: 25px;
  padding: 5px 15px;
  color: #9b9b9b;
  display: flex;
  justify-content: center;
}
@media print {
  .profile-header .profile-header-container .profile-header-content .js-print-resume {
    display: none;
  }
}
.profile-header .profile-header-container .profile-header-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-basis: 190px;
  flex-shrink: 0;
  text-align: right;
}
@media (max-width: 768px) {
  .profile-header .profile-header-container .profile-header-meta {
    flex-basis: 30px;
  }
}
.profile-header .profile-summary {
  position: relative;
  padding-top: 30px;
  font-size: 14px;
}
.profile-header .profile-summary .edit-button {
  top: 35px;
  right: 10px;
}
.profile-header .profile-summary .edit-button a {
  color: #9caac0;
}
.profile-header .profile-summary .edit-button svg {
  display: none;
}
.profile-header .profile-summary p {
  white-space: pre-wrap;
}
.profile-header .profile-summary p:first-child {
  margin-top: 0;
}
.profile-header .profile-summary p:last-child {
  margin-bottom: 0;
}
.profile-header .profile-summary.profile-no-summary {
  border: 1px dashed #edaf1f;
  background-color: #fef9ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin-top: 30px;
}
.profile-header .profile-summary.profile-no-summary .edit-button {
  display: none;
}
.profile-header .profile-summary.profile-no-summary a {
  display: inline-block;
  font-size: 18px;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .profile-header .profile-summary.profile-no-summary {
    text-align: center;
  }
}
@media print {
  .profile-header .profile-summary.profile-no-summary {
    display: none;
  }
}
@media (max-width: 768px) {
  .profile-header .profile-summary .edit-button svg {
    display: block;
  }
}
@media (max-width: 768px) {
  .profile-header {
    padding: 40px 12px 30px;
  }
  .profile-header .profile-header-container {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 0;
    min-height: 0;
  }
  .profile-header .profile-header-container .profile-header-icon {
    flex-basis: auto;
    margin-top: -80px;
    margin-left: -40px;
    top: initial;
    left: 50%;
  }
  .profile-header .profile-header-container .profile-header-icon .profile-header-initials {
    width: 80px;
    height: 80px;
    background-color: #9caac0;
    border-width: 5px;
  }
  .profile-header .profile-header-container .profile-header-icon .profile-header-initials span {
    font-size: 24px;
  }
  .profile-header .profile-header-container .profile-header-content {
    margin-left: 0;
    align-items: center;
  }
  .profile-header .profile-header-container .profile-header-content h1 {
    color: #000;
    font-size: 24px;
    line-height: 31px;
  }
  .profile-header .profile-header-container .profile-header-content .profile-header-location {
    color: #666;
  }
  .profile-header .profile-header-container .profile-header-content .no-summary {
    color: #9b9b9b;
  }
  .profile-header .profile-header-container .profile-header-content p {
    font-size: 14px;
    color: #9b9b9b;
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .profile-header .profile-header-container .profile-header-meta {
    align-items: center;
    margin-left: 0;
    flex-basis: auto;
  }
  .profile-header .profile-header-container .edit-button svg {
    display: block;
    fill: #9caac0;
  }
}
.profile-stats-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}
.profile-stats-summary__stat {
  display: flex;
  flex: 1 1 33%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px 4px 10px;
  border: 1px solid #ddd;
}
.profile-stats-summary__stat:nth-child(2) {
  border-left: none;
  border-right: none;
}
.profile-stats-summary__stat span {
  color: #9b9b9b;
  font-size: 10px;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
}
.profile-stats-summary__stat span:first-child {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000;
  text-transform: none;
}
.profile-header-initials {
  width: 137px;
  height: 137px;
  border-radius: 137px;
  box-sizing: border-box;
  position: relative;
  border: 5px solid #fff;
  background-color: #c2cad7;
}
.profile-header-initials span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  font-weight: 400;
  color: #eff1f6;
}
@media print {
  .hide-for-print {
    display: none;
  }
}
.profile-status {
  font-size: 14px;
  text-align: right;
}
.profile-status strong {
  font-weight: 400;
}
.profile-status a {
  text-decoration: underline;
}
@media print {
  .profile-status {
    display: none;
  }
}
.profile-status-mobile {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  position: absolute;
  right: 5px;
  bottom: 10px;
  background-color: #00cba5;
  border: 2px solid #fff;
}
.profile-status-mobile.candidate-status2,
.profile-status-mobile.candidate-status3,
.profile-status-mobile.candidate-status4 {
  background-color: #edaf1f;
}
.profile-status-mobile .profile-status-mobile-container {
  position: absolute;
  top: 25px;
  left: 9px;
  transform: translateX(-50%);
  width: 250px;
  height: 100px;
  z-index: 1;
  background-color: #fff;
  text-align: center;
  border: 1px solid #ddd;
}
.profile-status-mobile .profile-status-mobile-container p {
  margin-bottom: 0;
  font-size: 14px;
}
.profile-status-mobile .profile-status-mobile-container strong {
  font-size: 16px;
}
.profile-status-mobile .profile-status-mobile-container a {
  display: inline-block;
  margin-top: 8px;
  font-size: 14px;
  text-transform: uppercase;
}
.profile-status-mobile .profile-status-mobile-container::after,
.profile-status-mobile .profile-status-mobile-container::before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.profile-status-mobile .profile-status-mobile-container::after {
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 5px;
  margin-left: -5px;
}
.profile-status-mobile .profile-status-mobile-container::before {
  border-color: transparent;
  border-bottom-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}
.candidate-profile h1,
.candidate-profile h2 {
  color: #162d54;
  margin: 0;
}
.candidate-profile > header {
  margin: 60px auto;
  padding: 0 20px;
}
.candidate-profile > header .no-pw-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.candidate-profile > header .no-pw-modal .next-button {
  width: 90%;
}
.candidate-profile > header .no-pw-modal .app-review__auto-apply {
  display: block;
}
.candidate-profile > header > div {
  float: left;
  width: 50%;
  box-sizing: border-box;
}
.candidate-profile > header > div:first-child {
  padding-right: 30px;
}
.candidate-profile > header > div.review-password-modal {
  border-left: 1px solid #ddd;
  padding-left: 30px;
  min-height: 220px;
}
.candidate-profile > header h1 {
  font-size: 36px;
  line-height: 46px;
  margin-top: 21px;
  display: flex;
  align-items: center;
}
.candidate-profile > header h1 + p {
  margin-top: 4px;
}
.candidate-profile > header p {
  font-size: 16px;
}
@media (max-width: 768px) {
  .candidate-profile > header {
    margin: 0;
    padding: 0;
  }
  .candidate-profile > header h1 {
    font-size: 24px;
    line-height: 31px;
  }
  .candidate-profile > header > div:first-child {
    padding: 10px 12px 60px;
    width: 100%;
    background-color: #eff1f6;
    box-shadow: 0 2px 11px 0 rgba(22, 45, 84, 0.2);
  }
  .candidate-profile > header > div:last-child {
    display: none;
  }
}
.candidate-profile .school-name {
  display: block;
}
.candidate-profile .degree-type {
  display: block;
  text-transform: uppercase;
  color: #9b9b9b;
}
.candidate-profile > section {
  background-color: #fff;
  padding: 40px;
  margin: 10px auto;
}
.candidate-profile > section > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.candidate-profile > section h2 {
  color: #162d54;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0;
}
.candidate-profile > section article {
  font-size: 14px;
}
.candidate-profile > section article p {
  font-size: 14px;
  line-height: 21px;
}
.candidate-profile > section article header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 16px;
  font-weight: 300;
}
.candidate-profile > section article header .item-title {
  font-weight: 700;
}
.candidate-profile > section article header > strong,
.candidate-profile > section article header > span {
  flex: 0 0 50%;
  min-width: 50%;
}
.candidate-profile > section article header .dates {
  flex: 1 1 auto;
  color: #9b9b9b;
}
.candidate-profile > section article header > div {
  display: flex;
  justify-content: space-between;
}
.candidate-profile > section article header > div h3 {
  margin: 0;
}
.candidate-profile > section article header > div .dates {
  font-weight: 500;
}
@media (max-width: 768px) {
  .candidate-profile > section article header {
    flex-direction: column;
  }
  .candidate-profile > section article header > strong,
  .candidate-profile > section article header > span {
    flex: 0 0 auto;
  }
  .candidate-profile > section article header > strong:nth-child(2n),
  .candidate-profile > section article header > span:nth-child(2n) {
    text-align: left;
  }
  .candidate-profile > section article header .item-title {
    order: 1;
    padding-right: 20px;
  }
  .candidate-profile > section article header .company {
    order: 2;
  }
  .candidate-profile > section article header .dates {
    order: 3;
    font-size: 14px;
  }
  .candidate-profile > section article header .location {
    order: 4;
    font-size: 14px;
  }
}
.candidate-profile > section.work-experience h2 {
  margin-bottom: 0;
}
.candidate-profile > section.work-experience .no-items {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbb;
  width: 100%;
  min-height: 170px;
  margin: 0 auto -110px;
  font-weight: 100;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience .no-items {
    min-height: 130px;
  }
}
.candidate-profile > section.work-experience article {
  display: flex;
  padding-right: 20px;
}
.candidate-profile > section.work-experience article > p {
  margin: 0;
}
.candidate-profile > section.work-experience article .job-icon {
  flex: 0 0 70px;
  position: relative;
}
.candidate-profile > section.work-experience article .job-icon .job-line {
  width: 2px;
  height: 100%;
  background-color: #eff1f6;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.candidate-profile > section.work-experience article .job-content {
  padding: 32px 0;
}
.candidate-profile > section.work-experience article .job-content .item-title {
  font-weight: 500;
  padding-top: 3px;
}
.candidate-profile > section.work-experience article .job-content .missing-section {
  color: #edaf1f !important;
}
.candidate-profile > section.work-experience article .job-content .job-category {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  color: #92a9cb;
  margin-bottom: 8px;
}
.candidate-profile > section.work-experience article .job-content .job-tags {
  display: block;
  margin-bottom: 6px;
}
.candidate-profile > section.work-experience article .job-content .job-tags span {
  background: #e3ecfa;
  border-radius: 4px;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 13px;
  color: #5375a0;
  margin-right: 7px;
}
.candidate-profile > section.work-experience article .job-content .job-title {
  flex: 1 1 auto;
  display: block;
  margin-bottom: 4px;
}
.candidate-profile > section.work-experience article .job-content .job-title .job-title__role {
  display: inline-block;
}
.candidate-profile > section.work-experience article .job-content .job-title .job-title__contract {
  display: inline-block;
  height: 24px;
  padding: 2px 5px;
  margin-left: 10px;
  background: #fef9ed;
  border: 1px solid #fef9ed;
  border-radius: 10%;
  color: #edaf1f;
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience article .job-content .job-title .job-title__contract {
    margin-left: 0;
    margin-right: 20px;
  }
}
.candidate-profile > section.work-experience article:first-child .job-line {
  top: 50%;
}
.candidate-profile > section.work-experience article:first-child.job-description .job-content {
  margin-top: 0;
  padding-top: 0;
}
.candidate-profile > section.work-experience article:last-child .job-content {
  padding-bottom: 0;
}
.candidate-profile > section.work-experience article.job-description .job-icon-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 32px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  box-sizing: border-box;
  background-color: #eff1f6;
}
.candidate-profile > section.work-experience article.job-description .job-icon-container svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  fill: #000;
}
.candidate-profile > section.work-experience article.job-description .alert-tooltip::after,
.candidate-profile > section.work-experience article.job-description .alert-tooltip::after {
  width: 80px;
  bottom: 50px;
}
.candidate-profile > section.work-experience article.job-description .alert-tooltip::before,
.candidate-profile > section.work-experience article.job-description .alert-tooltip::before {
  bottom: 30px;
}
.candidate-profile > section.work-experience article.job-description:first-child .job-line {
  top: 0;
}
.candidate-profile > section.work-experience article.job-description:first-child .job-icon-container {
  top: 0;
}
.candidate-profile > section.work-experience article.job-description:first-child .job-overlay-warning-icon {
  top: 0;
}
.candidate-profile > section.work-experience article.job-description:first-child:last-child .job-line {
  display: none;
}
.candidate-profile > section.work-experience article.job-description:last-child:not(:first-child) .job-line {
  height: 32px;
}
.candidate-profile > section.work-experience article.job-description .job-content {
  flex: 1 1 auto;
  padding-left: 15px;
}
.candidate-profile > section.work-experience article.job-description .job-content .company {
  flex: 1 1 auto;
  color: #000;
}
.candidate-profile > section.work-experience article.job-description .job-content .duties {
  color: #000;
  margin-top: 10px;
  white-space: pre-wrap;
}
.candidate-profile > section.work-experience article.job-description .job-content .duties.missing-section {
  white-space: nowrap;
}
.candidate-profile > section.work-experience article.job-description .job-content .location {
  font-size: 14px;
  color: #9b9b9b;
}
.candidate-profile > section.work-experience article.job-description .job-content .reason-for-leaving {
  color: #9caac0;
  font-weight: 500;
  padding-bottom: 10px;
}
.candidate-profile > section.work-experience article.job-description .job-content .reason-for-leaving.portal-reason-for-leaving {
  padding-bottom: 0;
}
.candidate-profile > section.work-experience article .gap-icon {
  padding: 2px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  border: 1px dashed #9caac0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.candidate-profile > section.work-experience article .gap-icon > div {
  background-color: #eff1f6;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.candidate-profile > section.work-experience article.gap-explanation:first-child .job-line {
  top: 50%;
  height: 50%;
}
.candidate-profile > section.work-experience article.gap-explanation:last-child .job-line {
  height: 50%;
}
.candidate-profile > section.work-experience article.gap-explanation .job-content {
  flex: 1;
  position: relative;
  border: 1px dashed #9caac0;
  padding: 15px;
  margin-left: 5px;
  word-wrap: break-word;
}
.candidate-profile > section.work-experience article.gap-explanation .job-content .dates {
  color: #666;
  font-size: 14px;
}
.candidate-profile > section.work-experience article.gap-explanation .job-content p {
  color: #000;
  margin: 0;
  font-size: 14px;
}
.candidate-profile > section.work-experience article.gap-explanation .job-content::before {
  content: "";
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px dashed #9caac0;
  border-bottom: 1px dashed #9caac0;
  background-color: #fff;
}
.candidate-profile > section.work-experience article.gap-explanation.gap-editing .wsicon-trash {
  position: relative;
  top: 2px;
  z-index: 1;
  float: right;
  width: 20px;
  height: 20px;
  fill: #4a4a4a;
}
.candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content {
  border-color: transparent;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  padding: 10px 15px 0;
}
.candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content::before {
  border-color: transparent;
}
.candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content .dates {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
.candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content textarea {
  height: 150px;
  padding-bottom: 0;
}
.candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content textarea.validation-error {
  border-color: #e86232;
}
.candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content a {
  display: block;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience article.gap-explanation.gap-editing .wsicon-trash {
    width: 16px;
    height: 16px;
    top: 0;
  }
  .candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content .dates {
    font-size: 14px;
    line-height: 18px;
  }
  .candidate-profile > section.work-experience article.gap-explanation.gap-editing .job-content a {
    font-size: 14px;
  }
}
.candidate-profile > section.work-experience article.gap-discovery .job-content {
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background-color: #fef9ed;
  border-color: #edaf1f;
  text-align: center;
}
.candidate-profile > section.work-experience article.gap-discovery .job-content:hover {
  cursor: default;
}
.candidate-profile > section.work-experience article.gap-discovery .job-content p {
  margin-top: 0;
}
.candidate-profile > section.work-experience article.gap-discovery h3 {
  color: #edaf1f;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 5px;
}
.candidate-profile > section.work-experience article.gap-discovery .gap-discovery-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 24px;
}
.candidate-profile > section.work-experience article.gap-discovery .gap-discovery-buttons a {
  text-transform: uppercase;
  font-weight: 700;
}
.candidate-profile > section.work-experience article.gap-discovery .gap-discovery-buttons a:first-child {
  font-weight: 300;
}
.candidate-profile > section.work-experience article.gap-ignored a {
  color: #666;
  text-decoration: underline;
}
.candidate-profile > section.work-experience article.gap-ignored .job-content {
  flex-grow: 1;
  margin-left: 5px;
  margin-bottom: 0;
  padding: 10px;
  font-size: 12px;
  color: #666;
  background-color: #eff1f6;
  border-color: #c2cad7;
}
.candidate-profile > section.work-experience article.gap-ignored .job-content:hover {
  cursor: default;
}
.candidate-profile > section.work-experience .app__history-inline-text {
  font-size: 16px;
  transition: color 200ms ease-out;
}
.candidate-profile > section.work-experience .app__history-inline-text:hover {
  text-decoration: none;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience .app__history-inline-text {
    font-size: 14px;
  }
}
.candidate-profile > section.work-experience.app__history-inline-add {
  margin-top: -10px;
}
.candidate-profile > section.work-experience.app__history-inline-add article.job-description {
  align-items: center;
  box-sizing: border-box;
  width: 190px;
  padding: 3px 4px;
  margin-left: 5px;
  border: 1px solid #00cba5;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 200ms ease-out;
}
.candidate-profile > section.work-experience.app__history-inline-add article.job-description .job-icon-container {
  background-color: #fff;
  border: 1px dashed #00cba5;
  transition: all 200ms ease-out;
}
.candidate-profile > section.work-experience.app__history-inline-add article.job-description .job-icon-container svg {
  fill: #00cba5;
  transition: fill 200ms ease-out;
}
.candidate-profile > section.work-experience.app__history-inline-add article.job-description .job-line {
  display: none;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience.app__history-inline-add article.job-description {
    margin-left: -5px;
    width: 160px;
  }
}
.candidate-profile > section.work-experience.app__history-inline-add:not(.app__history-inline-add--with-tip) article.job-description .job-icon {
  flex: 0 0 48px;
  height: 48px;
  margin-right: 10px;
}
.candidate-profile > section.work-experience.app__history-inline-add:not(.app__history-inline-add--with-tip) article.job-description:hover {
  background-color: #009976;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.candidate-profile > section.work-experience.app__history-inline-add:not(.app__history-inline-add--with-tip) article.job-description:hover .job-icon-container {
  background-color: #009976;
  border-color: #fff;
}
.candidate-profile > section.work-experience.app__history-inline-add:not(.app__history-inline-add--with-tip) article.job-description:hover .job-icon-container svg {
  fill: #fff;
}
.candidate-profile > section.work-experience.app__history-inline-add:not(.app__history-inline-add--with-tip) article.job-description:hover .app__history-inline-text {
  color: #fff;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience.app__history-inline-add:not(.app__history-inline-add--with-tip) article.job-description .job-icon {
    flex: 0 0 36px;
    height: 36px;
  }
}
.candidate-profile > section.work-experience.app__history-inline-add--with-tip {
  min-height: 48px;
}
.candidate-profile > section.work-experience.app__history-inline-add--with-tip article.job-description {
  width: auto;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: default;
}
.candidate-profile > section.work-experience.app__history-inline-add--with-tip article.job-description .job-icon-container {
  background-color: transparent;
  border: 1px dashed #00cba5;
}
.candidate-profile > section.work-experience.app__history-inline-add--with-tip article.job-description .job-icon-container svg {
  fill: #00cba5;
}
.candidate-profile > section.work-experience.app__history-inline-add--with-tip .app__history-inline-text {
  display: none;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience.app__history-inline-add--with-tip {
    min-height: 36px;
    padding-bottom: 50px;
  }
}
.candidate-profile > section.work-experience.app__history-inline-add--empty {
  margin: 0;
}
.candidate-profile > section.work-experience.app__history-inline-add--empty article.job-description {
  margin: 0 auto;
}
@media (max-width: 768px) {
  .candidate-profile > section.work-experience article {
    padding: 0;
  }
  .candidate-profile > section.work-experience article .job-icon {
    flex-basis: 36px;
  }
  .candidate-profile > section.work-experience article .gap-icon {
    box-sizing: border-box;
  }
  .candidate-profile > section.work-experience article.gap-explanation .job-content {
    margin-left: 0;
    padding-top: 10px;
  }
  .candidate-profile > section.work-experience article.gap-explanation:not(:first-child) .job-content {
    margin-top: 20px;
  }
  .candidate-profile > section.work-experience article.gap-explanation:not(:first-child) .gap-icon {
    margin-top: 10px;
  }
  .candidate-profile > section.work-experience article.gap-ignored .job-icon,
  .candidate-profile > section.work-experience article.gap-discovery .job-icon {
    display: none;
  }
  .candidate-profile > section.work-experience article.gap-ignored .job-content,
  .candidate-profile > section.work-experience article.gap-discovery .job-content {
    margin: 0 -12px;
    border-top-width: 1px;
    border-top-style: dashed;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
  }
  .candidate-profile > section.work-experience article.gap-discovery .job-content {
    padding-top: 12px;
  }
  .candidate-profile > section.work-experience article.job-description .job-icon-container {
    top: 27px;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    box-sizing: border-box;
  }
  .candidate-profile > section.work-experience article.job-description .job-icon-container svg {
    width: 18px;
    height: 18px;
  }
  .candidate-profile > section.work-experience article.job-description .job-content {
    padding: 21px 0;
    margin-left: 10px;
  }
  .candidate-profile > section.work-experience article.job-description .job-content .company {
    line-height: 14px;
    margin-bottom: 8px;
  }
  .candidate-profile > section.work-experience article.job-description .job-content .dates,
  .candidate-profile > section.work-experience article.job-description .job-content .location {
    line-height: 20px;
    color: #9b9b9b;
  }
  .candidate-profile > section.work-experience article.job-description .job-content .duties {
    word-break: break-word;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 8px;
  }
  .candidate-profile > section.work-experience article.job-description .job-content .reason-for-leaving {
    font-size: 14px;
  }
  .candidate-profile > section.work-experience article.job-description:first-child .job-content {
    padding-top: 0;
  }
}
.candidate-profile > section.r-company-questions .questions-company-name {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
@media (max-width: 768px) {
  .candidate-profile > section.r-company-questions .questions-company-name {
    font-size: 14px;
  }
}
.candidate-profile > section.r-references article {
  display: flex;
  flex-direction: column;
}
.candidate-profile > section.r-references article .reference-meta-row {
  display: flex;
  width: 100%;
}
.candidate-profile > section.r-references article .reference-meta {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.candidate-profile > section.r-references article .manager-info {
  flex-grow: 1;
}
.candidate-profile > section.r-references article .manager-name,
.candidate-profile > section.r-references article .manager-title,
.candidate-profile > section.r-references article .manager-phone {
  display: block;
}
.candidate-profile > section.r-references article .manager-name {
  font-size: 16px;
  font-weight: 700;
}
.candidate-profile > section.r-references article .reference-message {
  width: 100%;
  line-height: 22px;
  margin-top: 10px;
  padding: 10px;
  background-color: #eff1f6;
  box-sizing: border-box;
}
.candidate-profile > section.r-references article .reference-message .confirmed-reference {
  padding: 5px 0 0 5px;
  font-size: 14px;
  color: #162d54;
}
.candidate-profile > section.r-references article .reference-message .confirmed-reference svg {
  color: #162d54;
  width: 12px;
  height: 12px;
  padding-right: 5px;
}
.candidate-profile > section.r-references article.icon-article svg {
  width: 24px;
  height: 24px;
  margin: auto;
}
.candidate-profile > section.r-references article.icon-article .manager-phone {
  display: flex;
  align-items: center;
}
.candidate-profile > section.r-references article.icon-article .manager-phone svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  fill: #9caac0;
}
@media (max-width: 768px) {
  .candidate-profile > section.r-references article .reference-meta {
    flex-direction: column;
    align-items: flex-start;
  }
  .candidate-profile > section.r-references article.icon-article {
    align-items: flex-start;
  }
  .candidate-profile > section.r-references article .wsicon-phone {
    display: none;
  }
}
.candidate-profile .r-header .selected-jobs {
  font-weight: 500;
}
.candidate-profile .basic-information .name {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.candidate-profile .basic-information .address {
  margin-top: 0;
}
.candidate-profile .basic-information table {
  font-size: 16px;
  table-layout: fixed;
}
.candidate-profile .basic-information table td {
  padding: 2px 5px;
}
.candidate-profile .basic-information table td:nth-child(1) {
  padding-left: 0;
  width: 180px;
}
.candidate-profile .basic-information table td:nth-child(2) {
  width: 200px;
  font-weight: bold;
}
.candidate-profile .basic-information table td:last-child {
  color: #bbb;
  font-size: 12px;
  display: table-cell;
}
.candidate-profile .basic-information table td:last-child > svg {
  margin-right: 6px;
}
.candidate-profile .basic-information table td > svg {
  height: 16px;
  width: 16px;
  fill: #bbb;
  top: 1px;
  position: relative;
}
@media (max-width: 768px) {
  .candidate-profile .basic-information table td:first-child {
    display: table-cell;
  }
  .candidate-profile .basic-information table td:last-child {
    display: none;
  }
}
@media (max-width: 768px) {
  .candidate-profile .basic-information .name {
    font-size: 16px;
  }
  .candidate-profile .basic-information .address {
    font-size: 14px;
    line-height: 20px;
  }
  .candidate-profile .basic-information table td:first-child {
    padding-left: 0;
  }
}
.candidate-profile .questions {
  list-style: none;
  padding: 0;
  margin-top: 0;
}
.candidate-profile .questions li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0;
  font-size: 16px;
  line-height: 22px;
}
.candidate-profile .questions li:first-child {
  border-top: 0;
}
.candidate-profile .questions li .question {
  box-sizing: border-box;
  flex: 0 0 70%;
  max-width: 70%;
  padding-right: 60px;
  color: #4a4a4a;
}
.candidate-profile .questions li .answer {
  flex: 0 0 30%;
  font-weight: bold;
  word-break: break-word;
}
.candidate-profile .questions li.long .answer {
  box-sizing: border-box;
  flex-basis: 100%;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 6px;
  margin-top: 12px;
}
.candidate-profile .questions li.followup {
  border-top: 0;
  padding-top: 0;
}
.candidate-profile .questions li.followup .question,
.candidate-profile .questions li.followup .answer {
  flex-basis: 80%;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .candidate-profile .questions li {
    font-size: 14px;
    line-height: 18px;
  }
  .candidate-profile .questions li .question {
    padding-right: 10px;
  }
}
.candidate-profile .review-list article {
  padding: 30px 20px;
  border-bottom: 1px solid #ddd;
}
.candidate-profile .review-list article:first-child {
  padding-top: 0;
}
.candidate-profile .review-list article:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.candidate-profile .review-list article.icon-article {
  display: flex;
  align-items: center;
}
.candidate-profile .review-list .icon-container {
  flex: 0 0 48px;
  margin-right: 20px;
  display: flex;
  box-sizing: border-box;
  background-color: #eff1f6;
  border-radius: 100px;
  padding: 9px;
  width: 48px;
  height: 48px;
}
.candidate-profile .review-list .icon-container svg {
  width: 30px;
  height: 30px;
}
@media (max-width: 768px) {
  .candidate-profile .review-list article {
    padding: 14px 0 18px;
  }
  .candidate-profile .review-list .icon-container {
    flex-basis: 50px;
  }
}
@media (max-width: 768px) {
  .candidate-profile {
    padding-bottom: 50px;
    margin-bottom: -60px;
    background-color: #eff1f6;
  }
  .candidate-profile > section {
    padding: 20px 12px;
    border: none;
  }
  .candidate-profile > section h2,
  .candidate-profile > section article {
    padding: 0;
  }
  .candidate-profile > section h2 {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #9caac0;
  }
  .candidate-profile > section > header {
    margin-bottom: 20px;
  }
  .candidate-profile .item-title {
    font-size: 16px;
  }
}
.app__history .candidate-profile.work-history-container {
  min-height: 350px;
}
.app__history .candidate-profile.work-history-container > section.work-experience:not(.app__history-inline-add) .no-items {
  margin-bottom: 30px;
}
.app__history .candidate-profile.work-history-container > section.work-experience:not(.app__history-inline-add) article.job-description:first-child:last-child .job-line {
  display: block;
}
.app__history .candidate-profile.work-history-container > section.work-experience:not(.app__history-inline-add) article.job-description:last-child:not(:first-child) .job-line {
  height: 100%;
}
.edit-button {
  transition: opacity 100ms ease-in;
}
.edit-button a {
  display: flex;
  text-align: center;
  align-items: center;
  opacity: 0.7;
}
.edit-button a:hover {
  opacity: 1;
  text-decoration: none;
}
.edit-button span {
  display: block;
  text-align: center;
  margin-left: 7px;
}
.edit-button svg {
  display: block;
  height: 16px;
  width: 16px;
  margin: 0 auto;
  fill: #00cba5;
}
@media (max-width: 768px) {
  .edit-button {
    border-left: none;
    margin-top: 0;
    opacity: 1;
  }
  .edit-button a {
    margin: 0;
    color: #9caac0;
    cursor: pointer;
  }
  .edit-button svg {
    fill: #9caac0;
  }
}
@media print {
  .edit-button {
    display: none;
  }
}
#profile-container {
  padding-bottom: 100px;
}
#profile-container #step-container {
  margin-top: 0;
}
#profile-container .next-container {
  display: none;
}
#profile-container .btn-back {
  position: absolute;
  top: 0;
  left: 8px;
}
@media (max-width: 768px) {
  #profile-container {
    padding-bottom: 24px;
  }
}
@media (min-width: 768px) {
  #profile-container .availability-grid__header-content {
    height: 34px;
  }
}
#need-help-modal .modal-body {
  padding: 30px 0 0 0;
  text-align: center;
}
#need-help-modal p {
  margin: 0;
}
#need-help-modal .sms-number {
  font-weight: 500;
  color: #edaf1f;
  margin: 15px 0;
}
.profile__section--empty,
.work-experience__add-job {
  border: 1px dashed #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin-top: 20px;
}
.profile__section--empty .edit-button,
.work-experience__add-job .edit-button {
  display: none;
}
.profile__section--empty a,
.work-experience__add-job a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .profile__section--empty,
  .work-experience__add-job {
    text-align: center;
  }
}
@media print {
  .profile__section--empty,
  .work-experience__add-job {
    display: none;
  }
}
.work-experience__add-job {
  margin-top: 0;
}
